import { Button, Switch } from "antd";
import React, { useState, useMemo } from "react";
import { LockedIcon2 } from "../../../../assets/icons";
import { GeneralCard } from "../AdminCards/GeneralCard";
import "./Draggable.scss";
import {
  CountdownCircleTimer,
  useCountdown,
} from "react-countdown-circle-timer";

export function ComponentCard({
  content,
  blur,
  width,
  height,
  shadow,
  blurDuration,
}) {
  return (
    <>
      {/* {content && content} */}
      {blur ? (
        <BluredCard {...{ content, width, height, shadow, blurDuration }} />
      ) : (
        <NormalCard {...{ content, width, height, shadow, blurDuration }} />
      )}
    </>
  );
}

export default React.memo(ComponentCard);

export const BluredCard = ({
  content,
  width,
  height,
  shadow,
  blurDuration,
}) => {
  const [tempBlur, setTempBlur] = useState(true);

  const renderTime = ({ remainingTime }) => {
    if (remainingTime !== 0) {
      return (
        <div className="timer">
          <strong>{remainingTime}</strong>
        </div>
      );
    } else {
      setTempBlur(true);
    }
  };

  return (
    <div
      className={tempBlur ? "blured-card-wrapper" : "drag-card-wrapper"}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: !!width ? width : null,
        height: !!height ? height : null,
        boxShadow: shadow === false ? "none" : null,
        overflow: "hidden",
      }}
    >
      {/* <button className="open-btn">Hap</button> */}
      {tempBlur ? (
        <div
          className="switch-div"
          style={{ position: "absolute", opacity: 1, zIndex: 1000 }}
        >
          <Switch style={{ width: 100 }} onChange={() => setTempBlur(false)} />
        </div>
      ) : (
        <div
          className="timer-wm"
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "flex-end",
            opacity: 0.8,
            zIndex: 1000,
            top: 0,
            width: "100%",
            // height: "100%",
          }}
        >
          <CountdownCircleTimer
            isPlaying
            size={50}
            duration={!!blurDuration ? blurDuration : 15}
            colors={["#7CFFC4", "#3F88C5", "#FFC857", "#DB3A34"]}
            colorsTime={[7, 5, 2, 0]}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
      )}
      {/* <div className="content">{tempBlur ? content : null}</div> */}
      {/* {!tempBlur && <NormalCard {...{ width, content }} />} */}
      <NormalCard
        {...{ width, height, content, shadow }}
        style={{
          boxShadow: shadow === false ? "none" : null,

          filter: tempBlur ? "blur(12px)" : null,
          transform: tempBlur ? "scale(0.9)" : null,
        }}
      />
    </div>
  );
};

const NormalCard = ({
  content,
  width,
  height,
  style,
  shadow,
  blurDuration,
}) => {
  return (
    <div
      className="drag-card-wrapper"
      style={{
        ...style,
        boxShadow: shadow === false ? "none" : null,
        width: !!width ? width : null,
        height: !!height ? height : null,
      }}
    >
      {content && content}
    </div>
  );
};
