import { useEffect } from "react";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { useState } from "react";
import "./ShiftLogButtonsFilter.scss";

function ShiftLogButtonsFilter({ filterMonths, defaultFirst }) {
  const [activeFilter, setActiveFilter] = useState(null);

  const ButtonFilter = (idx) => {
    if (
      activeFilter ===
      dayjsTZ()
        .subtract(2 - idx, "months")
        .format("MMMM")
    ) {
      setActiveFilter(null);
      filterMonths(null);
    } else {
      setActiveFilter(
        dayjsTZ()
          .subtract(2 - idx, "months")
          .format("MMMM")
      );
      filterMonths(
        dayjsTZ()
          .startOf("month")
          .subtract(2 - idx, "months")
      );
    }
  };

  const getComputedClassname = (idx) => {
    let month = dayjsTZ()
      .subtract(2 - idx, "months")
      .format("MMMM");
    if (month === activeFilter) {
      return "active-filter";
    }
    return "inactive-filter";
  };

  useEffect(() => {
    ButtonFilter(2);
  }, []);

  return (
    <div className="header-filter-buttons">
      {Array.apply(null, Array(3)).map((_, idx) => (
        <button
          key={idx}
          className={getComputedClassname(idx)}
          onClick={() => ButtonFilter(idx)}
        >
          {dayjsTZ()
            .subtract(2 - idx, "months")
            .format("MMMM YYYY")}
        </button>
      ))}
    </div>
  );
}

export default ShiftLogButtonsFilter;
