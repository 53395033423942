import { createElement, useEffect } from "react";
import { IconNgargo } from "../../../../assets/icons";
import { Modal, Tooltip } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import dayjsTZ from "../../../../utils/dayjs";
import { htmlParser } from "../../../../utils";
import { formatChanges } from "../../../punonjesit/EmployeeView/components/ActivityModal/formatChanges";
import { useReactToPrint } from "react-to-print";
import getAgThemePreference from "../../../../utils/getAgThemePreference";

const { headerTextColor, accentColor } = getAgThemePreference();

const AgGridToPrint = ({
  gridApi,
  rowData,
  title,
  tableCols,
  modifyRows,
  className,
  style,
  idKey,
}) => {
  const generateTable = () => {
    let filteredRows = [];
    if (!!gridApi) {
      const selectedNodes = gridApi
        .getSelectedNodes()
        .map((node) => node.data?.[idKey]);
      gridApi.forEachNodeAfterFilter((node) => {
        if (!!selectedNodes?.length) {
          if (selectedNodes.includes(node.data?.[idKey]))
            filteredRows.push(node.data);
        } else {
          filteredRows.push(node.data);
        }
      });
    } else if (!!rowData && Array.isArray(rowData)) {
      filteredRows = [...rowData];
    }
    if (!filteredRows || filteredRows.length === 0) {
      return undefined;
    }

    if (!!modifyRows && typeof modifyRows === "function") {
      filteredRows = modifyRows(filteredRows);
    }

    let div = document.createElement("div");
    div.className = "tableExportContainer";
    div.innerHTML = `
    <style>
      @media print {
        @page { size: landscape; }
      }
      .tableExportContainer{
        margin: 0 30px 30px 30px;
      }
      h2{
        display: flex;
        justify-content: center;
      }
      table{
        width: 100%;
      }
      thead{
        background-color: ${accentColor} !important;
        color: ${headerTextColor} !important;
        font-size: 16px ;
      }
      tr{
        align-content: center;
        min-height: 40px;
      }
      td{
        border: 1px solid ${accentColor} !important;
        margin: 0 30px;
        font-size: 15px;
        text-align: center;
      }
      .generated-on{
        margin-top: 10px;
        font-size: 14px;
        display: flex;
        justify-content: center;
      }
    </style>
    <br><br>
    <h2>${title}</h2>
    <table>
      <thead>
        ${tableCols?.map((el) => `<th>${el?.al || el}</th>`).join("")}
      </thead>
      <tbody>
        ${filteredRows
          ?.map((row, idx) => {
            let tableRow = [];
            tableCols?.forEach((col) => {
              let value = row[col?.en || col];
              let formattedValue = `${
                value === false || !!value
                  ? !!col.hasOwnProperty("replace")
                    ? col.replace[value]
                    : Array.isArray(value)
                    ? value.join(col?.join || ", ")
                    : !!col.hasOwnProperty("format")
                    ? dayjsTZ(value).locale("al").format(col.format)
                    : typeof value === "string" || value instanceof String
                    ? !!htmlParser(value)?.props?.children
                      ? htmlParser(value)?.props.children
                      : formatChanges(value)
                    : formatChanges(value)
                  : ""
              }`;
              tableRow.push(formattedValue);
            });
            return `<tr>
              ${tableRow?.map((val, i) => `<td >${val}</td>`).join("")}
            </tr>`;
          })
          .join("")}
      </tbody>
    </table>
    <span class="generated-on">Gjeneruar më: ${dayjsTZ().format(
      "DD/MM/YYYY HH:mm:ss"
    )}</span>
    `;

    return div;
  };

  const handlePrint = useReactToPrint({
    content: () => generateTable(),
    preserveAfterPrint: true,
    // pageStyle: pageStyle,
  });

  return (
    <div
      className={`headerIcon ${className}`}
      data-testid="headerPrint"
      onClick={handlePrint}
      style={style}
    >
      <Tooltip
        placement="top"
        classNames={{ root: "global-icon-tooltip" }}
        title={"Printo"}
      >
        <IconNgargo width={20} height={20} fill={style?.fill || "#323338"} />
      </Tooltip>
    </div>
  );
};

export default AgGridToPrint;

const pageStyle = `
  h2{
    display: flex;
    justify-content: center;
  }
  table{
    width: 100%;
  }
  thead{
    background-color: ${accentColor} !important;
    color: ${headerTextColor} !important;
    font-size: 16px ;
  }
  tr{
    align-content: center;
    min-height: 40px;
  }
  td{
  border: 1px solid ${accentColor} !important;
  margin: 0 30px;
    font-size: 15px;
    text-align: center;
  }
  .generated-on{
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }`;
