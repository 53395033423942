import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import { Row, Col, Input, Button, Tooltip } from "antd";
import {
  IconKerko,
  DeleteIcon,
  // IconFilter,
  IconNdrysho,
  IconExcel,
  IconNgargo,
} from "../../../../assets/icons";
import "./paisjet_orarit.scss";
import PaisjetOraritPostModal from "./PaisjetOraritPostModal";
import { API } from "aws-amplify";
import GlobalPopupKonfirm from "../../../commonComponents/GlobalPopupKonfirm";
import FilterPaisjetOrarit from "./FilterPaisjetOrarit";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import { htmlParser } from "../../../../utils";
import { osName, osVersion, deviceType } from "react-device-detect";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import HtmlExelTabel from "../../dynamic components/HtmlExelTabel";
import { useReactToPrint } from "react-to-print";
import ExportToPdf from "../../dynamic components/ExportToPdf";
import { useMediaQuery } from "react-responsive";
import MobilePaisjetOrarit from "./MobilePaisjetOrarit";
import { v4 as uuidv4 } from "uuid";
import { programFields as programFieldsReducer } from "../../../../store/actions";
import { LogsIcon } from "../../../Documentation/View/assets";
import PaisjetOraritLogs from "./PaisjetOraritLogs";
import AgGridHeader from "../../../commonComponents/AgGridHeader/AgGridHeader";
import ActivityModal from "../../../punonjesit/EmployeeView/components/ActivityModal/ActivityModal";
import { apiPut } from "../../../DocumentationConfiguration/utils/api";

const FILTER_KEYS = [
  { key: "name", label: "Pajisjet e orarit", type: "string" },
  { key: "description", label: "Pershkrimi per pajisjen", type: "string" },
  { key: "ip", label: "Ip për pajisjen", type: "string" },
];
const EXPORT_COLS = [
  { en: "name", al: "Pajisjet e orarit" },
  { en: "description", al: "Pershkrimi per pajisjen" },
  { en: "ip", al: "Ip për pajisjen" },
  { en: "Sistemi", al: "Sistemi" },
  { en: "shfletuesi", al: "Shfletuesi" },
];

function SistemiPaisjetOrarit() {
  //region COLDEFS
  const columnDefs = [
    {
      headerName: "Nr.",
      field: "fieldId",
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      valueGetter: (p) => p.node.rowIndex + 1,
      flex: 1.5,
    },
    {
      headerName: "Pajisjet e orarit",
      field: "name",
      filter: "agTextColumnFilter",
      flex: 3,
      mobile: true,
      isCollapseTitle: true,
    },
    {
      headerName: "Pershkrimi per pajisjen",
      field: "description",
      filter: "agTextColumnFilter",
      flex: 5,
      cellRenderer: ({ data }) => htmlParser(data?.description),
      mobile: true,
    },
    {
      headerName: "Ip për pajisjen",
      field: "ip",
      filter: "agTextColumnFilter",
      flex: 5,
      mobile: true,
    },
    {
      headerName: "Sistemi",
      field: "sistemi",
      filter: "agTextColumnFilter",
      flex: 5,
      mobile: true,
    },
    {
      headerName: "Shfletuesi",
      field: "shfletuesi",
      filter: "agTextColumnFilter",
      flex: 5,
      mobile: true,
    },
    {
      headerName: "",
      field: "",
      filter: "agTextColumnFilter",
      flex: 3,
      // displays the icons in ag grid and with params.data we take the object of the clicked row
      cellRenderer: ({ data }) => (
        <div className="iconat-paisjet">
          <div variant="outlined" cursor="pointer" className="icon-edit">
            <IconNdrysho
              onClick={() => handleUpdate(data)}
              width={14}
              height={14}
            />
          </div>
          <GlobalPopupKonfirm
            Button={
              <div variant="outlined" cursor="pointer" className="icon-delete">
                <DeleteIcon
                  width={17}
                  height={17}
                  // onClick={() => handleRequestDelete(node.data.id)}
                />
              </div>
            }
            title={"Jeni të sigurt që do të fshini këtë rekord?"}
            onConfirm={() => handleRequestDelete(data?.fieldId)}
            okText={"Po"}
            cancelText={"Jo"}
          />
        </div>
      ),
      mobile: true,
    },
  ];
  const dispatch = useDispatch();

  const [gridApi, setGridApi] = useState(null);
  const [visible, setVisible] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);
  //get the table programFields with redoux
  const { programFields } = useSelector((state) => state.programFields);
  const [dataLista, setDataLista] = useState("");
  const [shouldShowError, setShouldShowError] = useState(false);
  const [agGridSearchInput, setAgGridSearchInput] = useState(null);

  // find the nedded objec in the table
  const filterData = useMemo(() => {
    return programFields?.find(
      (data) => data?.fieldId === "32ca97d2-1eb1-43c3-9e80-a30a60acfc3b"
    );
  }, [programFields]);

  // console.log("filterData", filterData);

  // the state with the initial values
  const [initialValue, setInitialValue] = useState({
    // id: uuidv4(),
    name: "",
    description: "",
    ip: "",
    shfletuesi: deviceType,
    sistemi: `${osName} ${osVersion}`,
  });

  // opens the modal to create a new record
  const handleClickOpen = () => {
    setVisible(true);
    setDataLista({ ...initialValue, id: uuidv4() });
    setShouldShowError(true);
    // form.resetFields();
  };

  // delete the row in the agGrid
  const handleRequestDelete = (id) => {
    let newArray = filterData?.fieldOptions?.filter((r) => r.id !== id);
    API.put(
      "programFields",
      "/programFields/32ca97d2-1eb1-43c3-9e80-a30a60acfc3b",
      {
        body: {
          fieldOptions: newArray,
        },
      }
    ).then(() => {
      let field = [...filterData?.fieldOptions.filter((el) => el.id !== id)];
      let index = programFields.findIndex(
        ({ fieldId }) => fieldId === "32ca97d2-1eb1-43c3-9e80-a30a60acfc3b"
      );
      let newValues = [...programFields];
      if (index !== -1) {
        newValues[index] = { ...newValues[index], fieldOptions: field };
      }
      dispatch(programFieldsReducer(newValues));
      gridApi.applyTransaction({
        remove: [{ id: id }],
      });
    });
  };

  //opens the modal with the state with the object of the clicked row
  const handleUpdate = (oldData) => {
    setVisible(true);
    setDataLista(oldData);
    setShouldShowError(false);
  };

  //make the agGrid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getRowId = useCallback((param) => {
    return param?.id;
  }, []);

  // get ip data
  const getIp = async () => {
    await API.get("getIp", "/getIp").then((res) =>
      setInitialValue((prev) => ({ ...prev, ip: res.split(",")[0] }))
    );
    // setInitialValue((prev) => ({ ...prev, shfletuesi: deviceType }));
  };

  // execute the function when component will render
  useEffect(() => {
    getIp();
  }, []);

  // dropdon filter check to not repeat same value 2 times
  const duplicateCheck = [];
  const descriptionduplicateCheck = [];
  const ipduplicateCheck = [];

  filterData?.fieldOptions &&
    filterData?.fieldOptions
      ?.map((data) => {
        if (duplicateCheck.includes(data.name)) return null;
        duplicateCheck.push(data.name);
        return data;
      })
      .filter((e) => e);

  filterData?.fieldOptions &&
    filterData?.fieldOptions
      ?.map((data) => {
        if (descriptionduplicateCheck.includes(data.description)) return null;
        descriptionduplicateCheck.push(data.description);
        return data;
      })
      .filter((e) => e);

  filterData?.fieldOptions &&
    filterData?.fieldOptions
      ?.map((data) => {
        if (ipduplicateCheck.includes(data.ip)) return null;
        ipduplicateCheck.push(data.ip);
        return data;
      })
      .filter((e) => e);

  let rowData = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowData?.push(node?.data);
  });

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });
  const ITEMS = {
    search: {},
    icons: {
      filter: {
        filterKeys: FILTER_KEYS,
      },
      excel: { tableCols: EXPORT_COLS },
      pdf: { tableCols: EXPORT_COLS },
      print: { tableCols: EXPORT_COLS },
      modals: [
        {
          onClick: () => setLogsVisible(true),
          icon: <LogsIcon width={20} height={20} fill="#323338" />,
        },
      ],
    },
  };

  return (
    <LoadableComp loading={!filterData?.fieldOptions?.length}>
      <div className="globalPageWrapper">
        <AgGridComponent
          gridApi={gridApi}
          getRowId={getRowId}
          rowData={filterData?.fieldOptions}
          rowSelection={{ mode: "singleRow" }}
          onGridReady={onGridReady}
          paginationPageSize={15}
          columnDefs={columnDefs}
          headerProps={{
            items: ITEMS,
            exportTitle: "Pajisjet e orarit",
            children: [
              <Button className="mondayButtonGreen" onClick={handleClickOpen}>
                Shto një pajisje të re
              </Button>,
            ],
          }}
          // setRowData={() => {}}
          idKey={"fieldId"}
        />
      </div>
      {logsVisible ? (
        <ActivityModal
          keylog={filterData?.fieldOptions?.flatMap((el) => el?.logs)}
          setIsModalVisible={setLogsVisible}
        />
      ) : null}
      {visible && (
        <PaisjetOraritPostModal
          {...{
            gridApi,
            visible,
            setVisible,
            programFields,
            dataLista,
            shouldShowError,
            setShouldShowError,
            filterData,
          }}
        />
      )}
    </LoadableComp>
  );
}

export default SistemiPaisjetOrarit;
