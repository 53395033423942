import React from "react";
import { useState, useEffect } from "react";
import "./Forgot.scss";
import {
  Modal,
  Form,
  message,
  TimePicker,
  Button,
  Popconfirm,
  Skeleton,
  Select,
} from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import dayjs from "dayjs";
import { API } from "aws-amplify";
import TextArea from "antd/lib/input/TextArea";
import { getLastOnGoingShift } from "../../../helpers/apiCall";
import {
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
  getDisabledBeforeHours,
} from "../../../utils/disabledTimeFilters";
import { WarningIcon } from "../../notifications/notificationList/filterHeader/src";
import { Checkbox } from "antd";
import {
  requestAllPages,
  requestData,
} from "../../../../../helpers/API/RequestFactory";
import { useDispatch } from "react-redux";
import { clockings } from "../../../../../store/actions";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";

const { Option } = Select;

function Forgot({ showForgot, handleForgot, onClockForgot, apiData }) {
  const [lastShift, setLastShift] = useState(null);
  const [form] = Form.useForm();
  const [isAfterMidnight, setIsAfterMidnight] = useState(null);
  const [spin, setSpin] = useState(false);
  const dispatch = useDispatch();

  const onFinish = async (val) => {
    if (!!val.fillimi && !!val.mbarimi && val.shenimi !== "") {
      setSpin(true);
      let temp = {
        ...val,
        fillimi: dayjsTZ(val.fillimi)
          .set("hour", val.fillimi.get("hour"))
          .set("minute", val.fillimi.get("minute")),
        mbarimi: dayjsTZ(val.mbarimi)
          .set("hour", val.mbarimi.get("hour"))
          .set("minute", val.mbarimi.get("minute")),
        isAfterMidnight,
      };

      await onClockForgot(temp)
        .then(async () => {
          await requestData("clocking").then((res) => dispatch(clockings(res)));
          handleForgot(false);
          form.resetFields();
          setIsAfterMidnight(null);
          setLastShift(null);
          setSpin(false);
        })
        .catch((er) => {
          message.error(
            "Dicka nuk shkoi mirë. Provoni përseri ose kontaktoni supervizorin tuaj!"
          );
          console.log("err", er);
        });
    }
    // console.log("Shift", shift);
  };

  useEffect(() => {
    // console.log("api data", apiData);
    if (apiData.length > 0) {
      let lsh = getLastOnGoingShift(apiData);
      setLastShift(lsh);
    }
  }, [apiData]);

  console.log("API DATA", apiData);

  useEffect(() => {
    if (lastShift !== null) {
      form.setFieldsValue({
        fillimi: dayjsTZ(lastShift?.clockInDate),
      });
    }
  }, [lastShift]);

  return (
    <div>
      <Modal
        className="forgotModal"
        centered={true}
        title={"Kritike, nevojitet konfirmimi juaj!"}
        open={showForgot}
        destroyOnClose={true}
        // onOk={() => handleForgot(false)}
        onCancel={() => handleForgot(false)}
        footer={null}
        width={650}
      >
        {lastShift !== null ? (
          <div className="forgot-modal-content">
            <div className="warning-forgot-intro">
              <span
                className="warning-title"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "#ea3943",
                }}
              >
                <span
                  style={{
                    fill: "#ea3943",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "5px",
                  }}
                >
                  <WarningIcon />
                </span>
                Ju keni harruar të mbyllni turnin e fundit. Ju lutem konfirmoni
                orarin që u larguat dhe arsyen përkatëse!
              </span>
            </div>

            <br />
            <div className="forgot-form">
              <Form form={form} onFinish={onFinish}>
                <div className="first-row-forgot">
                  <Form.Item
                    className="start-shift-forgot"
                    name={"fillimi"}
                    label="Fillimi i turnit"
                    rules={[
                      {
                        required: false,
                        message: "Fillimi i turnit nevojitet",
                      },
                    ]}
                  >
                    <TimePicker
                      defaultValue={dayjsTZ(lastShift?.clockInDate)}
                      value={dayjsTZ(lastShift?.clockInDate)}
                      disabled={true}
                      width={180}
                      format={"HH:mm | DD/MM/YYYY"}
                    />
                  </Form.Item>
                  <Form.Item
                    className="after-midnight-checkbox"
                    name="isAfterMidnight"
                    label="Lloji i turnit"
                    rules={[
                      { required: true, message: "Lloji i turnit nevojitet" },
                    ]}
                  >
                    <Select
                      placeholder="Zgjidh llojin"
                      onChange={(e) => setIsAfterMidnight(e)}
                    >
                      <Option key={1} value={false}>
                        Turn Normal
                      </Option>
                      <Option key={2} value={true}>
                        Turn Pas Mesnate
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="end-shift-forgot"
                    name={"mbarimi"}
                    label="Mbarimi i turnit:"
                    rules={[
                      { required: true, message: "Mbarimi i turnit nevojitet" },
                    ]}
                  >
                    <TimePicker
                      placeholder="Zgjidh orarin"
                      format={"HH:mm"}
                      value={isAfterMidnight ? dayjsTZ() : null}
                      defaultValue={isAfterMidnight ? dayjsTZ() : null}
                      disabledTime={() => ({
                        disabledHours: !isAfterMidnight
                          ? () =>
                              getDisabledBeforeCustomHours(
                                lastShift?.clockInDate
                              )
                          : () => [],
                        disabledMinutes: !isAfterMidnight
                          ? (hour) =>
                              getDisabledBeforeCustomMinutes(
                                lastShift?.clockInDate,
                                hour
                              )
                          : () => [],
                      })}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  className="note-forgot"
                  name={"shenimi"}
                  label="Arsyeja/Shënimi"
                  rules={[{ required: true, message: "Shënimi nevojitet" }]}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 18 }}>
                  <MondayButton
                    className="mondayButtonGreen"
                    htmlType="submit"
                    spin={spin}
                  >
                    Konfirmo
                  </MondayButton>
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : (
          <Skeleton />
        )}
      </Modal>
    </div>
  );
}

const layout = {
  // labelCol: {
  //   span: 12,
  // },
  // wrapperCol: {
  //   span: 16,
  // },
};
export default Forgot;
