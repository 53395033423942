import { htmlParser } from "../../../utils";
import dayjsTZ from "../../../utils/dayjs";

const getColor = (val) => {
  switch (val) {
    case "Aprovuar":
      return "#1da193";
    case "Refuzuar":
      return "#ea3943";
    case "Pending":
      return "#FCC94A";
  }
};
// const getDayType = (programField, dayType) => {
//   const types = {};
//   (programField?.fieldOptions || []).forEach((el) => {
//     types[el?.name] = el?.description;
//   });

//   return types[dayType];
// };
// export const colDefs = (programField) => [
//   {
//     headerName: "Field ID",
//     field: "fieldId",
//     valueGetter: "node.rowIndex + 1",
//     flex: 1,
//     hide: true,
//   },
//   {
//     headerName: "Lloji i lejes",
//     field: "dayType",
//     filter: "agTextColumnFilter",
//     flex: 3,
//     mobile: true,
//     cellRenderer: ({ data }) => getDayType(programField, data?.dayType),
//   },
//   {
//     headerName: "Statusi i lejes",
//     field: "approved",
//     filter: "agTextColumnFilter",
//     flex: 3,
//     mobile: true,
//     cellRenderer: ({ data }) => (
//       <div
//         className="status-column"
//         style={{ backgroundColor: getColor("Aprovuar") }}
//       >
//         Aprovuar
//       </div>
//     ),
//   },
//   {
//     headerName: "Data e lejes",
//     field: "clockInDate",
//     filter: "agTextColumnFilter",
//     flex: 3,
//     mobile: true,
//     isCollapseTitle: true,
//     format: "DD/MM/YYYY",
//     valueGetter: ({ data }) => dayjsTZ(data?.clockInDate).format("DD/MM/YYYY"),
//     valueFormatter: ({ data }) => {
//       try {
//         return dayjsTZ(data?.clockInDate).format("DD/MM/YYYY");
//       } catch (err) {
//         console.error("employeeWorkStartDate--err");
//         return true;
//       }
//     },
//   },
//   // {
//   //   headerName: "Data e mbarimit",
//   //   field: "requestPeriod",
//   //   filter: "agTextColumnFilter",
//   //   flex: 3,
//   //   valueFormatter: function (params) {
//   //     try {
//   //       return dayjsTZ(params.value.slice(-1)[0]).format("DD/MM/YYYY");
//   //     } catch (err) {
//   //       console.error("employeeWorkStartDate--err");
//   //     }
//   //   },
//   // },
//   // {
//   //   headerName: "Detajet e lejes",
//   //   field: "",
//   //   filter: "agTextColumnFilter",
//   //   flex: 3,
//   //   cellRenderer: (params) => (
//   //     <div>
//   //       <Button
//   //         className="request-details-btn"
//   //         onClick={() => {
//   //           handleClickOpen();
//   //           setSpecificData(params.data);
//   //         }}
//   //       >
//   //         Detaje
//   //       </Button>
//   //     </div>
//   //   ),
//   // },
//   // {
//   //   headerName: "Dite pa fundjave",
//   //   field: "requestPeriod",
//   //   filter: "agTextColumnFilter",
//   //   flex: 3,
//   //   cellRenderer: (params) => (
//   //     <div
//   //       style={{ display: "flex", justifyContent: "center", marginRight: 80 }}
//   //     >
//   //       <span>{`${excludeWeekends(
//   //         new Date(dayjsTZ(params.value[0]).format("YYYY/MM/DD")),
//   //         new Date(dayjsTZ(params.value.slice(-1)[0]).format("YYYY/MM/DD"))
//   //       )}`}</span>
//   //     </div>
//   //   ),
//   // },
// ];
export const colDefs = () => [
  {
    field: "requestId",
    flex: 1,
    hide: true,
  },
  {
    headerName: "Lloji i lejes",
    field: "requestType",
    filter: "agTextColumnFilter",
    flex: 3,
    mobile: true,
  },
  {
    headerName: "Përshkrimi i lejes",
    field: "requestDescription",
    filter: "agTextColumnFilter",
    flex: 3,
    mobile: true,
    cellRenderer: ({ data }) => htmlParser(data?.requestDescription),
  },
  {
    headerName: "Statusi i lejes",
    field: "requestStatus",
    filter: "agTextColumnFilter",
    flex: 3,
    mobile: true,
    cellRenderer: ({ data }) => (
      <div className="status-column">
        <span style={{ backgroundColor: getColor(data?.requestStatus) }}>
          {data?.requestStatus}
        </span>
      </div>
    ),
  },
  {
    headerName: "Krijuar në",
    field: "createdAt",
    filter: "agTextColumnFilter",
    flex: 3,
    mobile: true,
    isCollapseTitle: true,
    format: "DD/MM/YYYY",
    valueGetter: ({ data }) => dayjsTZ(data?.createdAt).format("DD/MM/YYYY"),
  },
  {
    headerName: "Periudha e lejes",
    field: "requestPeriod",
    filter: "agTextColumnFilter",
    flex: 3,
    mobile: true,
    cellRenderer: ({ data }) => {
      const range = data?.requestPeriod || [];
      return `${dayjsTZ(range[0]).format("DD/MM/YYYY")} - ${dayjsTZ(
        range[range.length - 1]
      ).format("DD/MM/YYYY")}`;
    },
  },
];
