import { useState, useEffect, useRef, useMemo } from "react";
import { DeleteFilled, EyeFilled } from "@ant-design/icons";
import AgGridComponent from "../AG-grid/AgGridComponent";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import { getAnnouncements, sortDates } from "./utils/methods";
import HappyHolidaysModal from "../../assets/holidays/Modals/HappyHolidaysModal";
import ModalAnnounce from "./ModalAnnounce/ModalAnnounce";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import "./Announcements.scss";
import { htmlParser } from "../../utils";
import { IconEditWhite, IconExcel, IconNgargo } from "../../assets/icons";
import AnnouncementReplies from "./AnnouncementReplies/AnnouncementReplies";
import NewAnnouncement from "./NewAnnouncement/NewAnnouncement";
import MondayButton from "../commonComponents/MondayButton/MondayButton";
import { useMediaQuery } from "react-responsive";

const initialData = {
  announcementTitle: "",
  announcementBody: "",
  announcementUrl: "https://portal.flex.al/kryefaqja",
  announcementTarget: {
    departments: [],
  },
  announcementTeam: {
    teams: [],
  },
  endAnnouncementDate: null,
  userResponses: {},
  announcementCategory: "needAction",
  createdBy: "",
};
export const categories = {
  importantAnnouncement: "Lajmërim",
  needAction: "Lajmërim që ka nevojë për përgjigje",
  otherWish: "Urim",
};

const FILTER_KEYS = [
  { key: "createdBy", label: "Krijuar nga", type: "string" },
  { key: "announcementTitle", label: "Titulli", type: "string" },
  {
    key: "announcementCategory",
    label: "Kategoria",
    type: "string",
    filterType: "equals",
  },
  {
    key: "createdAt",
    label: "Koha e krijimit",
    type: "date-range",
  },
];
const EXPORT_COLS = [
  { en: "announcementTitle", al: "Titulli" },
  { en: "announcementCategory", al: "Kategoria" },
  { en: "announcementBody", al: "Përmbajtja" },
  { en: "announcementUrl", al: "Url" },
  { en: "createdBy", al: "Krijuar nga" },
  { en: "usersResponses", al: "Përgjigjet" },
];
const ITEMS = {
  search: {},
  icons: {
    filter: {
      filterKeys: FILTER_KEYS,
      replaceable: {
        announcementCategory: Object.entries(categories).map(([_, val]) => ({
          [val]: val,
        })),
      },
    },
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
    delete: { endpoint: "announcements" },
  },
};

function Announcements() {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialData);
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState({
    modeType: "new",
    otherData: {},
  }); // OTHER MODES: edit, view

  const retrievedData = async () => {
    await getAnnouncements().then((r) => {
      const newRowData = (r || [])
        .map((el) => {
          let toReturn = { ...(el || {}), responses: {} };
          Object.values(el?.usersResponses || {}).forEach(
            ({ response: res = "" }) => {
              if (!!res) {
                toReturn.responses = {
                  ...(toReturn.responses || {}),
                  [res]: (toReturn.responses[res] || 0) + 1,
                };
              }
            }
          );
          return toReturn;
        })
        .sort((a, b) => dayjsTZ(b.createdAt).diff(dayjsTZ(a.createdAt)));

      setRowData(newRowData);
      setLoading(false);
    });
  };

  const retrieveEditData = (data) => {
    setData(data);
    setVisible(true);
    setMode({ modeType: "edit", otherData: data });
  };

  const onReset = () => {
    setData(initialData);
    setMode({ modeType: "new", otherData: {} });
  };

  // region MODIFYROWSTOEXP
  const modifyRowsToExport = (rows) => {
    if (!rows?.length) return [];
    let modifiedRows = [];
    rows?.forEach((row) => {
      const structuredResponses = [];
      for (const [key, obj] of Object.entries(row?.usersResponses || [])) {
        structuredResponses.push({ key: key, ...obj });
      }
      let userResponses = Object.groupBy(
        structuredResponses,
        ({ response }) => response
      );
      const responses = [];
      for (const [res, arr] of Object.entries(userResponses)) {
        responses.push(`${res}: ${arr?.length}`);
      }
      const toReturn = {
        ...row,
        announcementCategory: categories[row?.announcementCategory],
        usersResponses: !!responses?.length ? responses : 0,
      };
      modifiedRows.push(toReturn);
    });
    return modifiedRows;
  };

  const sortedAnnouncements = (data = []) =>
    data?.sort(
      (a, b) =>
        dayjsTZ(a?.createdAt).valueOf() - dayjsTZ(b?.createdAt).valueOf()
    );

  useEffect(() => {
    retrievedData();
  }, []);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  const colDefs = useMemo(() => {
    const cols = [
      {
        headerName: "Konfirmoi",
        field: "confirm",
        color: "#1da193",
      },
      {
        headerName: "Anashkaloi",
        field: "skip",
        color: "#f5f5f7",
      },
      {
        headerName: "Refuzoi",
        field: "refuse",
        color: "#e74646",
      },
    ];

    const customCols = cols.map(({ headerName, field, color }) => ({
      headerName,
      field: `responses.${field}`,
      width: 75,
      cellRenderer: ({ data }) =>
        data?.responses?.[field] ? (
          <span
            style={{
              backgroundColor: color,
              padding: "7px 10px",
              borderRadius: 5,
            }}
          >
            {data?.responses?.[field]}
          </span>
        ) : null,
    }));

    return columnDefs({
      retrieveEditData,
      isMobileView,
      customCols,
    });
  }, [isMobileView]);

  //region RETURN
  return (
    <LoadableComp loading={loading}>
      <div className="globalPageWrapper">
        <div className="announcementsWrapper">
          <AgGridComponent
            gridApi={gridApi}
            rowData={sortedAnnouncements(rowData)}
            rowSelection={{ mode: "multiRow" }}
            onGridReady={(p) => setGridApi(p.api)}
            columnDefs={colDefs}
            paginator={true}
            headerProps={{
              items: ITEMS,
              exportTitle: "Lista e Lajmërimeve",
              modifyRows: modifyRowsToExport,
              children: [
                <MondayButton
                  className="mondayButtonGreen"
                  onClick={() => setVisible(true)}
                >
                  Lajmërim i Ri
                </MondayButton>,
              ],
            }}
            idKey={"announcementId"}
            onRowClick={(_, row) => retrieveEditData(row)}
          />
        </div>
      </div>
      {!!visible ? (
        <NewAnnouncement
          {...{
            mode,
            data: !!Object.keys(data).length ? data : initialData,
            gridApi,
            onReset,
            visible,
            setVisible,
            retrievedData,
          }}
        />
      ) : null}
    </LoadableComp>
  );
}

export default Announcements;

//region COLUMN DEFS
const columnDefs = ({
  retrieveEditData = () => {},
  isMobileView,
  customCols = [],
}) => {
  return [
    {
      headerName: "Titulli",
      field: "announcementTitle",
      sortable: true,
      filter: true,
      width: 200,
      mobile: true,
      isCollapseTitle: true,
    },
    {
      headerName: "Kategoria",
      field: "announcementCategory",
      sortable: true,
      filter: true,
      width: 200,
      mobile: true,
      valueGetter: ({ data }) => categories[data?.announcementCategory],
      cellRenderer: ({ data }) => categories[data?.announcementCategory],
    },
    {
      headerName: "Përmbajtja",
      field: "announcementBody",
      sortable: true,
      filter: true,
      width: 200,
      mobile: true,
      cellRenderer: ({ data }) => htmlParser(data?.announcementBody),
    },
    {
      headerName: "Url",
      field: "announcementUrl",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Krijuar në",
      field: "createdAt",
      sort: "desc",
      filter: "agDateColumnFilter",
      mobile: true,
      format: "DD/MM/YYYY",
      cellRenderer: ({ data }) => dayjsTZ(data?.createdAt).format("DD/MM/YYYY"),
    },

    {
      headerName: "Krijuar Nga",
      field: "createdBy",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Përgjigjet",
      field: "usersResponses",
      sortable: true,
      filter: true,
      width: 200,
      mobile: true,
      cellRenderer: ({ data }) => {
        let responses = {};
        Object.values(data?.usersResponses || {}).forEach((res) => {
          responses[res?.response] = (responses[res?.response] || 0) + 1;
        });
        return isMobileView ? (
          <div>
            {Object.entries(responses).map(([key, val]) => (
              <span>
                {[key]}: {val}
              </span>
            ))}
          </div>
        ) : (
          <div className="userResponses">
            <AnnouncementReplies
              userResponses={data?.usersResponses || {}}
              id={data?.announcementId}
              title={data?.announcementTitle}
            />
            <span
              style={{ backgroundColor: "#1da193" }}
              onClick={() => retrieveEditData(data)}
            >
              <IconEditWhite height={15} width={15} />
            </span>
          </div>
        );
      },
    },
  ].concat(
    customCols.map((el) => ({
      ...el,
      sortable: true,
      filter: true,
      width: el?.width || 200,
    }))
  );
};

//region PREVIEW MODAL
const PreviewModal = ({ params }) => {
  const [visible, setVisible] = useState(false);
  console.log("params", params);
  return (
    <>
      <span
        style={{
          cursor: "pointer",
          width: 28,
          height: 32,
          marginRight: 10,
          fontSize: 28,
          // backgroundColor: "#f5f5f5",
          padding: 5,
          borderRadius: 5,
          color: "#1da193",
        }}
        onClick={() => setVisible(true)}
      >
        <EyeFilled
          style={{
            color: "#fff",
            padding: 3,
            borderRadius: 3,
            backgroundColor: "#1da193",
          }}
        />
      </span>
      {visible && (
        <ModalAnnounce
          {...{
            previewMode: true,
            previewAnnouncement: structuredClone(params.data),
            setPreview: setVisible,
          }}
        />
      )}
    </>
  );
};
export const data = [
  [
    "Lloji",
    "Numri i pergjigjeve",
    { role: "style" },
    {
      sourceColumn: 0,
      role: "annotation",
      type: "string",
      calc: "stringify",
    },
  ],
  ["Konfirmoi", 8.94, "#b87333", null],
  ["Anashkaloi", 10.49, "silver", null],
  ["Refuzoi", 19.3, "gold", null],
];
