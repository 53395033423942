import { Popconfirm } from "antd";
import { useState, useEffect } from "react";
import { Flex } from "../../../assets/icons";
import { useGeneralInformationsContext } from "./context";
import "./Popover.scss";

const CancelAddEmployeeModalPopover = ({
  visible,
  setVisible,
  setModalVisible,
  modalVisible,
  classNames = "employee-create-confirm",
  title,
  form,
  setCurrentStep,
}) => {
  const onConfirm = () => {
    setVisible(false);
    setModalVisible(false);
    form.resetFields();
    !!setCurrentStep &&
      typeof setCurrentStep === "function" &&
      setCurrentStep(0);
  };

  return !!visible && !!modalVisible ? (
    <Popconfirm
      classNames={{ root: classNames }}
      okText={"Po"}
      cancelText="Jo"
      title={title}
      onConfirm={onConfirm}
      open={visible}
      onCancel={() => setVisible(false)}
      icon={<Flex />}
    />
  ) : null;
};

export default CancelAddEmployeeModalPopover;
