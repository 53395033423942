import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import "./GeneratePage.scss";
import { message } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import GeneratePageHeader from "./GeneratePageHeader";
import ReportContent from "./ReportContent/ReportContent";
import { useSelector } from "react-redux";
import { processFactory } from "../processFactory/processFactory";
import { reportsConstants } from "./constants";
import LoadableComp from "./../../commonComponents/LoadableComp/LoadableComp";
import { API } from "aws-amplify";
import { useRedux } from "../../../hooks/useRedux";
import useMemoCompare from "../../../hooks/useMemoCompare";

function GeneratePage({}) {
  const { reportType } = useParams();
  const navigate = useNavigate();
  const [reportClockings, setReportClockings] = useRedux(
    "reportClockings",
    [],
    false
  );

  const { wages } = useSelector((state) => state.wages);
  const { clockings } = useSelector((state) => state.clockings);
  const { employees } = useSelector((state) => state.employeesList);
  const { prepayments } = useSelector((state) => state.prepayments);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);

  const [view, setView] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updatingTable, setUpdatingTable] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);
  const [customFilter, setCustomFilter] = useState(null);
  const [manualColumns, setManualColumns] = useState(null);

  const approvalsMemo = useMemo(() => {
    if (!loading && reportType === "approvals") {
      const data = processFactory(
        reportType,
        [],
        activeFilter,
        reportsConstants[reportType],
        manualColumns,
        employees
      );
      return data;
    } else return;
  }, useMemoCompare([activeFilter, manualColumns, employees, reportClockings, reportType, loading]));
  const supervisorApprovalsMemo = useMemo(() => {
    if (!loading && reportType === "supervisorApprovals") {
      return processFactory(
        reportType,
        [],
        activeFilter,
        reportsConstants[reportType],
        manualColumns
      );
    } else return;
  }, useMemoCompare([activeFilter, manualColumns, reportType, loading]));
  const clockingTypesReportsMemo = useMemo(() => {
    if (!loading && reportType === "clockingTypesReports") {
      return processFactory(
        reportType,
        [],
        activeFilter,
        reportsConstants[reportType],
        manualColumns,
        employees
      );
    } else return;
  }, useMemoCompare([activeFilter, manualColumns, employees, reportType, loading]));
  const requestsMemo = useMemo(() => {
    if (!loading && reportType === "requests") {
      return processFactory(
        reportType,
        employeeRequests?.map((el) => ({
          ...el,
          employeeDetails: employees?.find((e) => e.userSub === el.userSub),
        })),
        activeFilter,
        reportsConstants[reportType],
        manualColumns
      );
    } else return;
  }, useMemoCompare([employeeRequests, employees, activeFilter, manualColumns, reportType, loading]));
  const bankForwardPayMemo = useMemo(() => {
    if (!loading && reportType === "bankForwardPay") {
      setView("month");
      return processFactory(
        reportType,
        wages.map((el) => ({
          ...el,
          employeeDetails: employees.find(
            (e) => e.employeeId === el.employeeId
          ),
        })),
        activeFilter,
        reportsConstants[reportType],
        manualColumns
      );
    } else return;
  }, useMemoCompare([wages, employees, activeFilter, manualColumns, reportType, loading]));
  const prepaymentsMemo = useMemo(() => {
    if (!loading && reportType === "prepayments") {
      return processFactory(
        reportType,
        prepayments.map((el) => ({
          ...el,
          employeeDetails:
            employees.find((e) => e.employeeId === el.employeeId) || {},
        })),
        activeFilter,
        reportsConstants[reportType],
        manualColumns
      );
    } else return;
  }, useMemoCompare([prepayments, employees, activeFilter, manualColumns, loading]));
  const detailedTaxesMemo = useMemo(() => {
    if (!loading && reportType === "detailedTaxes") {
      return processFactory(
        reportType,
        wages.map((el) => ({
          ...el,
          employeeDetails: employees.find(
            (e) => e.employeeId === el.employeeId
          ),
        })),
        activeFilter,
        reportsConstants[reportType],
        manualColumns
      );
    } else return;
  }, useMemoCompare([wages, employees, activeFilter, manualColumns, loading]));
  const employeesListMemo = useMemo(() => {
    if (!loading && reportType === "employeesList") {
      return processFactory(
        reportType,
        employees,
        activeFilter,
        reportsConstants[reportType],
        manualColumns
      );
    } else return;
  }, useMemoCompare([employees, activeFilter, manualColumns, loading]));
  const suspensionsMemo = useMemo(() => {
    if (!loading && reportType === "suspensions") {
      return processFactory(
        reportType,
        employees,
        activeFilter,
        reportsConstants[reportType],
        manualColumns
      );
    } else return;
  }, useMemoCompare([employees, activeFilter, manualColumns, loading]));

  const contentData = {
    approvals: approvalsMemo,
    supervisorApprovals: supervisorApprovalsMemo,
    clockingTypesReports: clockingTypesReportsMemo,
    requests: requestsMemo,
    bankForwardPay: bankForwardPayMemo,
    prepayments: prepaymentsMemo,
    detailedTaxes: detailedTaxesMemo,
    employeesList: employeesListMemo,
    suspensions: suspensionsMemo,
  };

  // region RETRIEVE FILTERS
  const filterReportsClockings = async (e) => {
    if (JSON.stringify(e) !== JSON.stringify(activeFilter)) {
      !!customFilter && setCustomFilter(null);
      setUpdatingTable(true);
      await getReportsClockings(e?.timeInterval)
        .then((r) => {
          const newClockings = [];
          r.forEach((cl) => {
            const idx = (employees || []).findIndex(
              (el) => el?.employeeId == cl?.employeeId
            );
            if (idx !== -1) {
              const employeeDetails = employees[idx];
              newClockings.push({ ...cl, employeeDetails });
            } else {
              newClockings.push(cl);
            }
          });

          setReportClockings(
            newClockings.sort((a, b) => b?.clockOutDate - a?.clockOutDate)
          );
          setActiveFilter(e);
          message.success({
            key: "approvals",
            content: "Fetched successfully!",
          });
          setUpdatingTable(false);
        })
        .catch((err) => {
          console.log("Error: ", err);
          message.error({
            key: "approvals",
            content: "Failed fetching clockings!",
          });
          setUpdatingTable(false);
        });
    }
  };
  const updateColumns = (cols, callback) => {
    !!callback && callback();
    setManualColumns(cols);
  };
  const onGenerate = (filter) => {
    const arr = { ...(contentData[reportType] || { rowData: [] }) };

    if (!!Object.keys(filter || {}).length) {
      arr.rowData =
        arr?.rowData?.filter((el) => {
          return el.some((v) =>
            Object.values(filter).flat(Infinity).includes(v)
          );
        }) || [];
      setCustomFilter(arr);
    } else {
      setCustomFilter(null);
    }
  };

  useLayoutEffect(() => {
    if (
      !!clockings &&
      !!employees &&
      !!employeeRequests &&
      !!wages &&
      !!prepayments
    )
      setLoading(false);
  }, [clockings, employees, employeeRequests, wages, prepayments]);

  useEffect(() => {
    if (!!reportType && !contentData.hasOwnProperty(reportType)) {
      setTimeout(() => {
        navigate("/raportet");
      }, 1500);
    }
  }, [reportType]);

  //region RETURN
  return (
    <LoadableComp loading={loading}>
      <div className="globalPageWrapper">
        <div className="generate-page">
          <GeneratePageHeader
            {...{
              filterReportsClockings,
              contentData: contentData[reportType],
              updateColumns,
              setLoading,
              onGenerate,
            }}
            resetCustom={() => setCustomFilter(null)}
            view={view}
            src={reportType}
          />
          <ReportContent
            contentData={
              !!customFilter ? customFilter : contentData[reportType]
            }
            infos={reportsConstants}
            page={reportType}
            activeFilter={activeFilter}
            updatingTable={updatingTable}
          />
        </div>
      </div>
    </LoadableComp>
  );
}

export default GeneratePage;

export const getReportsClockings = async (
  timeInterval,
  employeeId = false,
  dayType = false
) => {
  return new Promise(async (resolve, reject) => {
    let filterClockingObj = {
      limiter: dayjsTZ(timeInterval[0]).startOf("day").valueOf(),
      endLimiter: dayjsTZ(timeInterval[1])
        .add(14, "days")
        .endOf("day")
        .valueOf(),
    };
    if (employeeId) {
      filterClockingObj.employeeId = employeeId;
    }
    if (dayType) {
      filterClockingObj.dayType = dayType;
    }
    await API.get(`clocking`, `/clocking`, {
      queryStringParameters: {
        ...filterClockingObj,
        // limiter: moment(timeInterval[0]).startOf("day").valueOf(),
        // endLimiter: moment(timeInterval[1])
        //   .add(14, "days")
        //   .endOf("day")
        //   .valueOf(),
        // limiter: moment("01/1/2022", "DD/MM/YYYY").valueOf(),
        // start: params,
        // end: moment().valueOf(),
      },
    })
      .then((r) => resolve(r))
      .catch((error) => console.error(error));
  });
};
