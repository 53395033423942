import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { QuestionsMark } from "./Image";
import { Button, Form, Modal, Space, Typography } from "antd";
import moment from "moment";
import dayjsTZ from "../../../../utils/dayjs";
import RenderDynamicComponents from "../../../RenderDynamicComponents/RenderDynamicComponents";
import { FormItems } from "./Components/FormItems";
import { useSelector } from "react-redux";
import "./KrijoKandidat.scss";
import { InterviewsContext } from "../../data";
import { ModalXIcon } from "../../../../assets/icons";
import { API } from "aws-amplify";
import { isEmpty } from "lodash";
import { useAuth } from "../../../../authentication/authHelpers";
import dayjs from "dayjs";
// import { InlineWidget, useCalendlyEventListener } from "react-calendly";
// import {
//   cancelScheduledEvent,
//   getScheduledEvent,
// } from "../../../../utils/calendlyEvents";

const { Text } = Typography;

const CreateCandidate = () => {
  const { candidates } = useSelector((state) => state.candidates);
  const {
    isChanged,
    isEditable,
    setIsChanged,
    driveRequest,
    setIsEditable,
    createUserPage,
    temporaryArray,
    setUploadedFiles,
    setCreateUserPage,
    setTemporaryArray,
    googleDriveFolderId,
    kandidatiSelektuar,
    tempUploadedFiles,
    resetGoogleDriveProps,
    setShowUserInformationPage,
  } = useContext(InterviewsContext);

  const { programFields } = useSelector((state) => state.programFields);
  const { departments } = useSelector((state) => state.departments);
  const [form] = Form.useForm();
  const [importedInputs, setImportedInputs] = useState([]);
  // const [eventUrls, setEventUrls] = useState("");
  // const [inviteeFormData, setInviteeFormData] = useState(null);
  // const [calendlyValues, setCalendlyValues] = useState(null);

  // useCalendlyEventListener({
  //   onEventScheduled: (e) =>
  //     setEventUrls({
  //       event: e.data.payload?.event.uri,
  //       invitee: e.data.payload?.invitee.uri,
  //     }),
  // });
  // useEffect(() => {
  //   if (eventUrls === "") {
  //     if (!!isEditable) {
  //       setInviteeFormData(
  //         dayjsTZ(kandidatiSelektuar?.applicantInterviewDate).format()
  //       );
  //     }
  //   } else {
  //     getScheduledEvent(eventUrls?.event, REACT_APP_CALENDLY_TOKEN).then(
  //       (res) => {
  //         console.log("res:", res);
  //         setInviteeFormData(dayjsTZ(res.start_time).format());
  //       }
  //     );
  //     cancelScheduledEvent(
  //       kandidatiSelektuar?.applicantEventURI,
  //       REACT_APP_CALENDLY_TOKEN
  //     );
  //   }
  // }, [eventUrls]);

  const applicantReferenceStatus = Form.useWatch(
    "applicantReferenceStatus",
    form
  );
  const applicantTrainingStatus = Form.useWatch(
    "applicantTrainingStatus",
    form
  );
  const selectedDepartment = Form.useWatch("applicantDepartment", form);
  // const selectedDate = Form.useWatch("applicantInterviewDate", form);

  //Ketu filtrojme te candidatet per te gjetur se cilet prej tyre e kane intervisten ne diten e perzgjedhur
  const selectedDateCandidateArray = useMemo(() => {
    return candidates.filter(
      (items) =>
        dayjsTZ(items?.applicantInterviewDate).format("YYYY-MM-DD") ===
          dayjsTZ(kandidatiSelektuar?.applicantInterviewDate).format(
            "YYYY-MM-DD"
          ) && kandidatiSelektuar?.applicantId !== items.applicantId
    );
  }, [candidates, kandidatiSelektuar]);

  const disabledTimesArray = selectedDateCandidateArray.map((obj) =>
    dayjsTZ(obj["applicantInterviewDate"]).format("HH:mm ")
  );

  const newInputs = programFields?.find(
    (el) => el?.fieldName === "Konfigurimi i Kandidateve 2"
  );

  //region >resetValues()
  const resetValues = () => {
    if (!isEditable && !!googleDriveFolderId?.length) {
      try {
        driveRequest.deleteDriveItem(googleDriveFolderId);
      } catch (err) {
        console.log(err);
      }
    } else {
      tempUploadedFiles.map((id) => {
        try {
          driveRequest.deleteDriveItem(id);
        } catch (err) {
          console.log(err);
        }
      });
    }
    setIsEditable(false);
    setCreateUserPage(false);
    setTemporaryArray(null);
    resetGoogleDriveProps();
    // if (!!eventUrls?.event) {
    //   cancelScheduledEvent(eventUrls?.event, REACT_APP_CALENDLY_TOKEN);
    // }
  };

  //region GET DROPDOWNS
  useEffect(() => {
    // Ketu behet check-u, ne rast se kemi edit kontrollohen fields qe ka objekti qe kemi per te edituar dhe shfaqen ato, ne rast se do shtohet kandidat vendosen gjithe fields.
    try {
      let availableFields = [];
      if (isEditable) {
        setUploadedFiles(kandidatiSelektuar?.googleDriveFiles);
        availableFields = newInputs?.fieldOptions.filter((item) =>
          Object.keys(kandidatiSelektuar).some(
            (key) => item.formItemName === key && !item.deletedField
          )
        );
      } else {
        availableFields = newInputs?.fieldOptions.filter(
          (item) => !item.deletedField
        );
      }
      availableFields?.forEach((item) => {
        if (item.formItemName === "applicantDepartment") {
          let options = [];
          departments?.forEach(({ departmentName }) => {
            options.push(departmentName);
          });
          item.options = options;
        }
        item.rules = (item.rules || []).map((rule) => {
          if (
            rule.hasOwnProperty("pattern") &&
            typeof rule?.pattern === "string"
          ) {
            const { pattern, ...rest } = rule;

            return {
              ...rest,
              async validator(_, value) {
                const rg = new RegExp(pattern);

                if (!rg.test(value)) {
                  return Promise.reject(rest?.message);
                }

                return Promise.resolve();
              },
            };
          } else {
            return rule;
          }
        });
      });
      console.log({ availableFields });
      setImportedInputs(availableFields);
    } catch (error) {
      console.error("An error occurred:", error);
    }

    const initialData = {
      applicantFirstName: null,
      applicantLastName: null,
      applicantDepartment: [],
      applicantRoles: [],
      applicantStatus: "Listuar për intervistë",
      applicantTeam: null,
      applicantBirthday: null,
      applicantPersonalNumber: null,
      applicantPhoneNumber: null,
      applicantAddress: null,
      applicantDiplomaLevel: null,
      applicantUniversity: null,
      applicantUniversityField: null,
      applicantEmail: null,
      applicantTrainingStatus: true,
      applicantTraining: null,
      applicantReferenceStatus: true,
      applicantReference: null,
      applicantAdvantages: [],
      applicantDisadvantages: [],
      // applicantInterviewDate: dayjsTZ().startOf("day"), // dayjsTZ().startOf("day"), // Perfshihet Data intervistes dhe orari intervistes //cuz its Calendly now
      applicantStartDate: null,
      applicantPayment: null,
    };
    if (isEditable) {
      if (!temporaryArray) {
        form.setFieldsValue({
          ...kandidatiSelektuar,
          applicantBirthday: kandidatiSelektuar?.applicantBirthday
            ? dayjsTZ(kandidatiSelektuar?.applicantBirthday)
            : null,
          applicantStartDate: kandidatiSelektuar?.applicantStartDate
            ? dayjsTZ(kandidatiSelektuar?.applicantStartDate)
            : null,
        });
      } else {
        form.setFieldsValue({
          ...temporaryArray,
          applicantBirthday: kandidatiSelektuar?.applicantBirthday
            ? dayjsTZ(kandidatiSelektuar?.applicantBirthday)
            : null,
          applicantStartDate: temporaryArray?.applicantStartDate
            ? dayjsTZ(temporaryArray?.applicantStartDate)
            : null,
        });
      }
    } else if (!isEditable) {
      if (!temporaryArray) {
        form.setFieldsValue(initialData);
      } else {
        form.setFieldsValue({
          ...temporaryArray,
          applicantBirthday: kandidatiSelektuar?.applicantBirthday
            ? dayjsTZ(kandidatiSelektuar?.applicantBirthday)
            : null,
          // applicantInterviewDate: dayjsTZ(
          //   temporaryArray?.applicantInterviewDate
          // ),
          applicantStartDate: temporaryArray?.applicantStartDate
            ? dayjsTZ(temporaryArray?.applicantStartDate)
            : null,
        });
        setIsChanged(true);
      }
    }
  }, [programFields]);

  useEffect(() => {
    if (
      !!selectedDepartment?.length &&
      (!!isEditable ? kandidatiSelektuar : temporaryArray)
        ?.applicantDepartment !== selectedDepartment
    ) {
      form.setFieldValue("applicantRoles", []);
    } else
      form.setFieldValue(
        "applicantRoles",
        (!!isEditable ? kandidatiSelektuar : temporaryArray)?.applicantRoles
      );
    setImportedInputs((prev) =>
      prev?.map((item) => {
        if (item.formItemName === "applicantRoles") {
          let options = [];
          departments
            ?.find(
              ({ departmentName }) => departmentName === selectedDepartment
            )
            ?.departmentRoles?.forEach(({ name }) => {
              options.push(name);
            });
          if (!!options.length)
            item = {
              ...item,
              options,
              disabled: false,
              placeholder: "Selektoni Roli...",
            };
          else
            item = {
              ...item,
              disabled: true,
              placeholder: "Zgjidh departamentin fillimisht",
            };
          return item;
        } else return item;
      })
    );
  }, [selectedDepartment, kandidatiSelektuar]);

  //region COMPARE OBJS
  //Function to compare 2 objects and check id the key/value of the second are the same with the first
  const compareObject = (originalObject, changedObject) => {
    for (const key in changedObject) {
      if (!!changedObject[key]) {
        if (Array.isArray(changedObject[key])) {
          if (originalObject[key]?.length === changedObject[key]?.length) {
            for (let i = 0; i < changedObject[key].length; i++) {
              if (typeof changedObject[key][i] === "object") {
                if (
                  !compareObject(originalObject[key][i], changedObject[key][i])
                ) {
                  return false;
                }
              } else if (originalObject[key][i] !== changedObject[key][i]) {
                {
                  return false;
                }
              }
            }
          } else {
            return true;
          }
        } else if (
          Object.getPrototypeOf(changedObject[key]).constructor.name ===
          "Object"
          // typeof changedObject[key] === "object" &&
          // !(changedObject[key] instanceof Date)
        ) {
          if (!compareObject(originalObject[key], changedObject[key])) {
            return false;
          }
        } else if (changedObject[key] instanceof Date) {
          if (changedObject[key].getTime() !== originalObject[key].getTime()) {
            return false;
          }
        } else {
          if (changedObject[key] !== originalObject[key]) {
            return false;
          }
        }
      }
    }
    return true;
  };

  //region CONFIRM DELETE
  const showDeleteConfirm = () => {
    let localIsChanged = isChanged;
    if (isEditable) {
      let formValues = form.getFieldsValue();
      let originalValues;
      for (const key in formValues) {
        originalValues = kandidatiSelektuar;
        if (
          dayjs.isMoment(formValues[key]) ||
          dayjs.isDayjs(formValues[key])
        ) {
          formValues[key] = dayjsTZ(formValues[key])
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          originalValues[key] = dayjsTZ(originalValues[key])
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        }
      }
      const isObjectChanged = compareObject(originalValues, formValues);

      if (!isChanged) {
        setIsChanged(isObjectChanged);
        localIsChanged = !isObjectChanged;
      }
    }

    if (localIsChanged) {
      Modal.confirm({
        title: "A jeni të sigurt?",
        icon: <QuestionsMark />,
        content: (
          <Text>
            Ju po anuloni{"  "}
            {!!isEditable ? "ndryshimin" : "shtimin"} e një kandidati në listë.
            <Space className="space-gap">
              Nëse jeni të sigurt për këtë veprim, klikoni
              <span className="text-bold">"Po, anuloje"</span>
            </Space>
          </Text>
        ),
        okText: "Po, anuloje",
        cancelText: "Jo, mos e anulo",
        width: "660px",
        className: "cancelModal",
        centered: true,
        cancelButtonProps: {
          type: "primary",
          style: {
            background: "#ea3943",
            borderColor: "#ea3943",
            borderRadius: "5px",
          },
        },
        okButtonProps: {
          className: "ant-btn-confirm",
        },
        onOk() {
          resetValues();
        },
        onCancel() {
          setIsChanged(false);
        },
      });
    } else {
      resetValues();
    }
  };

  //region ON FINISH
  const onFinishHandle = async () => {
    try {
      form.validateFields().then((res) => {
        // if (!inviteeFormData) message.warning("Data e intervistës nevojitet!");
        // else {
        const didApplicantReApply = !isEditable
          ? checkApplicantReApplied(res, candidates)
          : kandidatiSelektuar?.applicantReApplied;
        setTemporaryArray({
          ...res,
          applicantEventURI: kandidatiSelektuar?.applicantEventURI || "",
          applicantReApplied: didApplicantReApply,
          applicantInterviewDate: kandidatiSelektuar?.applicantInterviewDate,
          applicantBirthday: dayjsTZ(
            res?.applicantBirthday || kandidatiSelektuar?.applicantBirthday
          ).format(),
          applicantStartDate: dayjsTZ(
            res?.applicantStartDate || kandidatiSelektuar?.applicantStartDate
          ).format(),
        });
        setShowUserInformationPage(true);
        setCreateUserPage(false);
        // }
      });
    } catch (errorInfo) {
      console.error("Validation error: ", errorInfo);
    }
  };

  //birthday disabled date
  const disabledDate = (current) => {
    return (
      current && dayjsTZ(current).valueOf() > dayjsTZ().valueOf() - 568025136000
    );
  };

  const formItemData = useMemo(() => {
    return FormItems({
      applicantReferenceStatus,
      applicantTrainingStatus,
      // handlePassword,
      // showPassword,
      form,
      disabledTimesArray,
      selectedDateCandidateArray,
      selectedDate: kandidatiSelektuar?.applicantInterviewDate,
    });
  }, [
    applicantTrainingStatus,
    applicantReferenceStatus,
    // showPassword,
    disabledTimesArray,
    selectedDateCandidateArray,
    kandidatiSelektuar?.applicantInterviewDate,
  ]);

  //region RETURN
  return (
    <div className="form-container">
      <div className="form-first-grid-container">
        <div className="form-content-small-input">
          {RenderDynamicComponents(
            importedInputs?.flatMap((items) =>
              items.type !== "customquill" &&
              items.type !== "quill" &&
              items.type !== "tagSelect" &&
              items.formItemName !== "applicantInterviewDate" &&
              items.formItemName !== "applicantPayment" &&
              items.formItemName !== "applicantDisqualificationReason"
                ? items?.formItemName === "applicantBirthday"
                  ? {
                      ...items,
                      disabledDate: disabledDate,
                    }
                  : items?.formItemName === "applicantRoles"
                  ? {
                      ...items,
                      mode: "multiRow",
                    }
                  : items
                : []
            )
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
          {RenderDynamicComponents(formItemData["Interview Input Fields"])}
        </div>
        <div className="form-content-quill-input">
          {RenderDynamicComponents(
            importedInputs?.filter(
              (items) =>
                items.type === "customquill" ||
                items.type === "quill" ||
                items.type === "tagSelect"
            )
          )}
        </div>
      </div>
      <div className="form-second-grid-container">
        <div className="form-second-grid-first-row">
          {RenderDynamicComponents(formItemData["Calendar"])}
          {RenderDynamicComponents(formItemData["TimePicker"])}

          {/* {
                <InlineWidget
                  url="https://calendly.com/a-berziu-flex/30min?hide_gdpr_banner=1"
                  pageSettings={{
                    hideLandingPageDetails: true,
                    hideEventTypeDetails: true,
                    primaryColor: "20ac9d",
                    textColor: "4d5055",
                  }}
                  prefill={{
                    email: `${
                      !isEditable
                        ? calendlyValues?.email || ""
                        : kandidatiSelektuar.applicantEmail || ""
                    }`,
                    firstName: `${
                      !isEditable
                        ? calendlyValues?.firstName || ""
                        : kandidatiSelektuar.applicantFirstName || ""
                    }`,
                    lastName: `${
                      !isEditable
                        ? calendlyValues?.lastName || ""
                        : kandidatiSelektuar.applicantLastName || ""
                    }`,
                  }}
                />
              } */}
        </div>
        <div className="form-second-grid-second-row">
          {RenderDynamicComponents(formItemData["Interview Input Fields"])}
        </div>
      </div>
    </div>
  );
};

export default CreateCandidate;

export const checkApplicantReApplied = (data, candidates) => {
  let index = (candidates || []).findIndex(
    (el) =>
      el.applicantFirstName === data.applicantFirstName &&
      el.applicantLastName === data.applicantLastName &&
      el.applicantPersonalNumber === data.applicantPersonalNumber
  );
  return index > -1;
};
