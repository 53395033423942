import React, { useContext, useEffect, useMemo, useState } from "react";
import "./ListaEkandidateve.scss";
import { Tabs } from "antd";
import {
  Pergjithshme,
  AvantazhetDisavantazhet,
  Dokumentet,
  InformacioneShtese,
} from "./Kategorite";
import { InterviewsContext } from "../data";
import { useUploadedFiles } from "../../Documentation/hooks/useUploadedFiles";
import { useSelector } from "react-redux";
import { toSentenceCase } from "../../../utils";

const CANDIDATE_CONFIGURATION_FIELD_ID = "a086b42f-cd92-4e6c-8e91-cfe78d66f07f";

const KandidatiTabs = () => {
  const { programFields } = useSelector((state) => state.programFields);
  const { kandidatiSelektuar } = useContext(InterviewsContext);

  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [
    uploadedFiles,
    setUploadedFiles,
    formattedDriveFiles,
    onPickerSuccess,
  ] = uploadHandlers;
  const [filesToBeDeleted, onDelete] = deleteHandlers;

  //UseEffect to change the uploaded files when the selected candidated change

  useEffect(() => {
    setUploadedFiles(kandidatiSelektuar?.googleDriveFiles);
  }, [kandidatiSelektuar]);

  const candidateSteps = useMemo(() => {
    if (!programFields) return [];
    return (
      programFields.find(
        (el) => el?.fieldId === CANDIDATE_CONFIGURATION_FIELD_ID
      )?.fieldOptions || []
    );
  }, [programFields]);

  const tabs = candidateSteps
    .map((step, idx) => ({
      key: idx,
      label: toSentenceCase(step?.name),
      children: <Pergjithshme fields={step?.fields} />,
    }))
    .concat([
      {
        key: "3",
        label: "Dokumentet",
        children: (
          <Dokumentet
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            formattedDriveFiles={formattedDriveFiles}
            onPickerSuccess={onPickerSuccess}
            onDelete={onDelete}
            filesToBeDeleted={filesToBeDeleted}
          />
        ),
      },
      {
        key: "4",
        label: "Informacione Shtese",
        children: <InformacioneShtese uploadedFiles={uploadedFiles} />,
      },
    ]);

  return (
    <div className="kandidadiTabs">
      <Tabs className="tabs" items={tabs} defaultActiveKey="1" />
    </div>
  );
};

export default KandidatiTabs;
