import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  // IconExcel,
  IconKerko,
  // IconVeprime,
  IconNgargo,
  IconPunonjesit,
  // DeleteIcon,
  IconExcel,
} from "../../../../assets/icons";
import { CaretDownFilled } from "@ant-design/icons";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import { API, Auth } from "aws-amplify";
import { Select, Form, message } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { useReactToPrint } from "react-to-print";
import "./siguria_perdoruesit.scss";
import SiguriaPerdoruesitModal from "./SiguriaPerdoruesitModal";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import SiguriaPerdoruesitFilterModal from "./SiguriaPerdoruesitFilterModal";
import PritTablePerdoruesit from "./PritTablePerdoruesit";
import { useNavigate } from "react-router-dom";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import ExportToPdf from "../../dynamic components/ExportToPdf";
import { useMediaQuery } from "react-responsive";
import MobileSiguriaPerdoruesit from "./MobileSiguriaPerdoruesit";
import { useSelector } from "react-redux";
import AgGridHeader from "../../../commonComponents/AgGridHeader/AgGridHeader";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import Enable2FaModal from "./Enable2FaModal/Enable2FaModal";
import getAgThemePreference from "../../../../utils/getAgThemePreference";
import colorShade from "../../../../utils/colorShade";

const EXPORT_COLS = [
  { en: "given_name", al: "Emri" },
  { en: "family_name", al: "Mbiemri" },
  { en: "gender", al: "Gjinia" },
  { en: "email", al: "Email" },
  { en: "userRole", al: "Lloji i userit" },
  { en: "department", al: "Departamenti" },
  { en: "department_role", al: "Pozicioni" },
  { en: "phone_number", al: "Nr.Tel" },
  { en: "createdAt", al: "Krijuar në", format: "DD/MM/YYYY" },
];
const ITEMS = {
  search: {},
  icons: {
    filter: {
      filterKeys: [
        { key: "given_name", label: "Emri", type: "string" },
        {
          key: "family_name",
          label: "Mbiemri",
          type: "string",
        },
        { key: "gender", label: "Gjinia", type: "string" },
        { key: "email", label: "Email", type: "string" },
        { key: "userRole", label: "Lloji i userit", type: "string" },
        { key: "department", label: "Departamenti", type: "string" },
        { key: "department_role", label: "Pozicioni", type: "string" },
        { key: "phone_number", label: "Nr.Tel", type: "string" },
        { key: "createdAt", label: "Krijuar në", type: "string" },
      ],
    },
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
  dropdowns: [
    {
      keyName: "department",
      placeholder: "Të gjithë",
      icon: <IconPunonjesit width={15} />,
    },
  ],
};

function SiguriaPerdoruesit() {
  const navigate = useNavigate();
  const { mode, accentColor } = getAgThemePreference();

  // columnDefs for the agGrid
  const columnDefs = [
    {
      headerName: "Punonjësi",
      field: "employeeFullName",
      filter: "agTextColumnFilter",
      flex: 2,
      mobile: true,
      isCollapseTitle: true,
      cellClickKey: "employeeId",
      cellRenderer: ({ data }) => (
        <div
          className="navigate-row"
          onClick={() =>
            navigate(`/konfigurimet/perdoruesit/${data?.identityId}`)
          }
          style={
            mode.includes("dark")
              ? { color: "#FFF" }
              : { color: colorShade(accentColor, 0.25) }
          }
        >
          {data?.employeeFullName}
        </div>
      ),
    },
    {
      headerName: "Gjinia",
      field: "gender",
      filter: "agTextColumnFilter",
      flex: 2,
      mobile: true,
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter",
      flex: 2,
      mobile: true,
    },
    {
      headerName: "Lloji i userit",
      field: "userRole",
      filter: "agTextColumnFilter",
      mobile: true,
      flex: 2,
    },
    {
      headerName: "Departamenti",
      field: "department",
      filter: "agTextColumnFilter",
      mobile: true,
      flex: 3,
      onCellClick: () => navigate(`/departamentet`),
    },
    {
      headerName: "Pozicioni",
      field: "departmentRole",
      filter: "agTextColumnFilter",
      flex: 2,
      mobile: true,
      onCellClick: () => navigate(`/departamentet`),
    },
    {
      headerName: "Nr.Tel",
      field: "phone_number",
      filter: "agTextColumnFilter",
      flex: 2,
      mobile: true,
    },
    {
      headerName: "Krijuar",
      field: "createdAt",
      filter: "agTextColumnFilter",
      flex: 2,
      mobile: true,
      cellRenderer: (params) => {
        try {
          return dayjsTZ(params.value).format("DD/MM/YYYY");
        } catch (err) {
          console.error("createdAt--err");
        }
      },
    },
  ];

  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [user, setUser] = useState();
  const [mfaOpen, setMfaOpen] = useState(false);
  // const [selected, setSelected] = useState(null);
  const [agGridSearchInput, setAgGridSearchInput] = useState(null);
  const { Option } = Select;
  const { users } = useSelector((state) => state.usersList);

  // console.log(tableData);

  // making ag grid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // get data when page is renderd
  useEffect(() => {
    if (!!users) {
      setTableData(
        (users?.allUsers.Items || []).map((el) => ({
          ...el,
          employeeFullName: el?.given_name + " " + el?.family_name,
        }))
      );
    }
  }, [users]);

  // state for email and verification code
  const [konfig, setKonfig] = useState({
    email: "",
    verifyCode: "",
  });

  // change the state of email and verification code
  const HandleChange = (objField, e) => {
    const value = objField;
    setKonfig((prev) => ({ ...prev, [value]: e }));
  };

  // change the state of data with the new value
  const onChange = (objField, e) => {
    const value = objField;
    setData((prev) => ({ ...prev, [value]: e }));
  };

  // state to  add new data to grid
  const [data, setData] = useState({
    // identityId: uuidv4(),
    given_name: "",
    family_name: "",
    gender: "",
    email: "",
    department: "",
    userRole: "",
    departmentRole: "",
    userSub: "",
    phone_number: "",
    // createdAt: dayjsTZ(),
  });

  // close the modal and clean the form
  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setUser(null);
  };

  const mfaModalData = useMemo(() => {
    if (!users?.allUsers?.Items?.length) return [];
    else
      return users?.allUsers?.Items?.map((user) => ({
        id: user?.identityId,
        name: `${user?.given_name} ${user?.family_name}`,
        is2FaActive: !!user?.auth2Fa?.is2FaActive,
        visible: true,
      }));
  }, [users?.allUsers?.Items]);

  // delete items from grid
  // const deleteItems = () => {
  //   const selectData = gridApi.getSelectedNodes();
  //   let selectedData = selectData.map((node) => node.data.identityId);
  //   let selecData = selectData.map((node) => node.data);
  //   gridApi.applyTransaction({
  //     remove: selecData,
  //   });
  //   API.del("users", `/users/${selectedData}`).then(() => {
  //     setTableData((prev) =>
  //       prev.filter((item) => item.identityId !== data.identityId)
  //     );
  //   });
  // };

  // submit the form
  const formSubmit = async () => {
    await Auth.signUp({
      username: form.getFieldValue("email"),
      password: form.getFieldValue("password"),
      attributes: {
        phone_number:
          "+" +
          form
            .getFieldValue("phone_number")
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", ""),
        family_name: form.getFieldValue("family_name"),
        given_name: form.getFieldValue("given_name"),
        gender: form.getFieldValue("gender"),
        "custom:department": form.getFieldValue("department"),
        "custom:userRole": form.getFieldValue("userRole"),
        "custom:role": form.getFieldValue("departmentRole"),
      },
    })
      .then((res) => {
        // console.log({ res });
        setData((prev) => ({ ...prev, sub: res.userSub }));
        setUser(res);
      })
      .catch((err) => console.log(err));
  };

  /*** confirm user with email pascode  */
  const userConfig = () => {
    Auth.confirmSignUp(
      form.getFieldValue("email"),
      form.getFieldValue("verifyCode")
    ).then((res) => {
      if (res === "SUCCESS") {
        message
          .success("Krijimi i nje useri u bë me sukses")
          .then((r) => onClose());
      }
    });
  };

  // function handleMenuClick(e) {
  //   console.log("click", e);
  // }

  // dropdown search for department
  const departmentFilter = async (e) => {
    await gridApi.getColumnFilterInstance("department").then((comp) => {
      comp.setModel({
        type: "contains",
        filter: e,
      });
    });
    gridApi.onFilterChanged();
    // setSelected(e);
  };
  // check for duplicate employe department name
  const duplicateCheck = [];

  tableData &&
    tableData
      ?.map((data) => {
        if (duplicateCheck.includes(data.department)) return null;
        duplicateCheck.push(data.department);
        return data;
      })
      ?.filter((e) => e);

  //print fille
  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let rowData = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowData?.push(node?.data);
  });

  console.log(tableData);
  return (
    <LoadableComp loading={!!tableData ? false : true}>
      <div className="globalPageWrapper">
        <AgGridComponent
          gridApi={gridApi}
          rowData={tableData}
          rowSelection={{ mode: "singleRow" }}
          onGridReady={onGridReady}
          paginationPageSize={16}
          columnDefs={columnDefs}
          suppressServerSideInfiniteScroll={"full"}
          // enableCellChangeFlash={true}
          rowModelType={"serverSide"}
          headerProps={{
            items: ITEMS,
            children: [
              <MondayButton
                className="mondayButtonGreen"
                onClick={() => setVisible(true)}
              >
                Krijo përdorues
              </MondayButton>,
              <MondayButton
                className="mondayButtonGreen"
                onClick={() => setMfaOpen(true)}
              >
                Konfiguro 2FA
              </MondayButton>,
            ],
          }}
          idKey={"identityId"}
          onRowClick={(rowId) => navigate(`/konfigurimet/perdoruesit/${rowId}`)}
        />
      </div>
      {visible ? (
        <SiguriaPerdoruesitModal
          formSubmit={formSubmit}
          userConfig={userConfig}
          user={user}
          onClose={onClose}
          form={form}
          onChange={onChange}
          HandleChange={HandleChange}
          open={visible}
          setOpen={setVisible}
          tableData={tableData}
          setData={setData}
        />
      ) : null}
      {!!mfaOpen ? (
        <Enable2FaModal
          open={mfaOpen}
          gridApi={gridApi}
          setOpen={setMfaOpen}
          dataList={mfaModalData}
          title="Modifiko 2FA për punonjësit"
        />
      ) : null}
    </LoadableComp>
  );
}

export default SiguriaPerdoruesit;
