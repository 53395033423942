import { Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../../authentication/authHelpers";
import UserWageHistory from "../../../dashboard/UserDashboard/UserWageHistory";
import PaymentGrid from "../../../punonjesit/EmployeeView/Payment/PaymentGrid";
import UserActionCards from "../../../punonjesit/EmployeeView/Personal/User/UserActionCards";
import WageGrid from "./WageGrid";
import LoadableComp from "./../../../../components/commonComponents/LoadableComp/LoadableComp";
import WageInfo from "./WageInfo/WageInfo";
import { useSelector } from "react-redux";
import {
  BluredCard,
  ComponentCard,
} from "../../../dashboard/AdminDashboard/Draggable/ComponentCard";
import { useMediaQuery } from "react-responsive";
import EmployeeWagesMobileView from "./EmployeeWagesMobileView";

function EmployeeWageView() {
  const { employee, employeeId } = useAuth();

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  return (
    <LoadableComp loading={!employee}>
      <div className="globalPageWrapper">
        {!!employeeId && (
          <div className="employeeWageView">
            {isMobileView ? <WageGrid employee={employeeId} /> : null}
            <div className="employeeWageCards">
              {/* {<EmployeeWageCard />} */}
              <UserActionCards
                contentChild={
                  <ComponentCard
                    blurDuration={30}
                    width={"100%"}
                    height={"calc(100% - 45px)"}
                    blur={true}
                    content={
                      <WageInfo
                        showPaymentInfo={true}
                        employeesData={employee}
                      />
                    }
                  />
                }
                headerName={"Informacioni i pagesës"}
                style={{ flex: 1 }}
              />
              <UserActionCards
                contentChild={
                  <ComponentCard
                    blurDuration={30}
                    width={"100%"}
                    height={"calc(100% - 45px)"}
                    blur={true}
                    content={<UserWageHistory employee={employeeId} />}
                  />
                }
                headerName={"Historiku Pagat"}
                style={{ flex: 1 }}
              />
            </div>

            {/* <PaymentGrid style={{ width: "100%", paddingTop: 0 }} /> */}
            {!isMobileView ? <WageGrid employee={employeeId} /> : null}
          </div>
        )}
      </div>
      {/* <EmployeeWagesMobileView
          employeeId={employeeId}
          employeesData={employeesData}
        /> */}
    </LoadableComp>
  );
}

export default EmployeeWageView;
