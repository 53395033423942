import React, { useState, useEffect, useMemo, useRef } from "react";
import { Modal, TimePicker, message, Alert } from "antd";

import "../../../Pagat/approvals/newShiftModal/NewShiftModal.scss";
import { ModalXIcon, WarningIcon } from "../../../../assets/icons";
// import TextArea from "antd/lib/input/TextArea";
import { DatePicker, Select } from "antd";
import moment from "moment-timezone";
import {
  getDisabledAllHours,
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
  getDisabledButGracePeriodMins,
  getDisabledAfterCustomHours,
  getDisabledAfterCustomMinutes,
  getDisabledAfterMinutes,
} from "../../../dashboard/utils/disabledTimeFilters";
import { BreedingRhombusSpinner } from "react-epic-spinners";
import { createNewShiftByAdmin } from "../../../Pagat/approvals/utils";
import { useAuth } from "../../../../authentication/authHelpers";
import SuccessComponent from "../../../commonComponents/SuccessComponent/SuccessComponent";
import { RichTextEditor } from "../../../commonComponents";
import { useDispatch, useSelector } from "react-redux";
import CrashShift from "../../../Pagat/approvals/crashShiftModal/CrashShift";
import { API } from "aws-amplify";
import { calculateDayType } from "../../utils/calculateDayType";
import {
  notificationUserToAdmin,
  notificationUserToAdminNoDbPost,
} from "../../../../utils/sendNotification";
import useReactIpLocation from "react-ip-details";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import { requestAllPages } from "../../../../helpers/API/RequestFactory";
import { htmlParser } from "../../../../utils";
import { debounce } from "lodash";
import { addClocking } from "../../../../store/actions";
import { getAppliedShifts } from "./clockOutModal/utils";
import { WitchIcon } from "../../../../assets/holidays/icons";
import axios from "axios";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import dayjsTZ from "../../../../utils/dayjs";
import dayjs from "dayjs";

// * @EneaXharau - Websocket handler added
const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));
const OFF_SHIFT_POLICIES_FIELD_ID = "3d077179-a545-43ec-98be-7e1bf71b09aa";
const CLOCKIN_TYPES_FIELD_ID = "e685cd30-b119-11ec-829e-112b583587c7";

const { Option } = Select;

function NewUserShift({
  selfType,
  newShiftVisib,
  setNewShiftVisib,
  onRefresh,
  setPrevShift,
  liveTime,
  shiftConfiguration,
}) {
  const auth = useAuth();
  const dispatch = useDispatch();

  const { programFields } = useSelector((state) => state.programFields);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);

  const [newShift, setNewShift] = useState({
    clockInDate: dayjsTZ(),
    clockOutDate: null,
    note: "",
    employeeId: null,
  });
  const [dateDayJs, setDateDayJs] = useState(dayjsTZ());
  const [saving, setSaving] = useState(false);
  const [crashError, setCrashError] = useState(false);
  const [crashData, setCrashData] = useState([]);
  const [successVisibility, setSuccessVisibility] = useState(false);
  const [dayType, setDayType] = useState(null);
  const [gracePeriod, setGracePeriod] = useState(0);
  const [graceWarnings, setGraceWarning] = useState({
    enable: false,
    text: "",
  });
  const [ip, setIp] = useState("");
  const [manualShift, setManualShift] = useState(false);
  //   const { ipResponse } = useReactIpLocation();
  const [disabled, setDisabled] = useState(false);

  const getExactLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {});
  };

  const { ipResponse } = useReactIpLocation({ numberToConvert: 100 });
  const clockinRef = useRef();

  //region ON SAVE
  const onSave = async () => {
    // message.info("u thirr");
    const temp = newShift?.note.replace(/<[^>]+>/g, "");
    const noteLen = temp.replace(/\s/g, "").length;
    const noteReq = graceWarnings.enable && noteLen < 4 ? false : true;

    if (
      !!newShift.clockInDate &&
      !!newShift.employeeId &&
      dayType !== null &&
      noteReq
    ) {
      setSaving(true);

      let date1 = dayjsTZ();
      let clockIn = dayjsTZ(newShift.clockInDate);
      let date2 = dayjsTZ();
      let clockOut = dayjsTZ(newShift.clockOutDate);
      date1 = date1
        .set("hour", clockIn.get("hour"))
        .set("minute", clockIn.get("minute"))
        .set("second", "00");
      date2 = date2
        .set("hour", clockOut.get("hour"))
        .set("minute", clockOut.get("minute"))
        .set("second", "00");

      const toSend = {
        clockInDate: dayjsTZ(date1).valueOf(),
        clockOutDate:
          newShift.clockOutDate !== null ? dayjsTZ(date2).valueOf() : null,
        employeeId: newShift.employeeId,
        clockingCategory: "user",
        dayType: dayType,
        clockInNote: newShift.note,
        clockOutNote: "",
        editedClockIn:
          dayjsTZ().format("HH:mm") !== dayjsTZ(date1).format("HH:mm")
            ? true
            : false,
        editedClockOut: false,
        editedByAdmin: false,
        forgotClockOut: false,
        approved: false,
        clockingLogs: [
          {
            createdAt: dayjsTZ().valueOf(),
            note: "Krijuar nga vetë punonjësi.",
            newShiftByAdmin: false,
            newShiftByUser: true,
            username: auth?.user?.given_name + " " + auth?.user?.family_name,
            userSub: auth?.user?.userSub,
            ipResponse: {
              ...ipResponse,
              IPv4: ip,
            },
          },
        ],
      };

      await createNewShiftByAdmin(toSend, newShift.employeeId, employeeRequests)
        .then(async (res) => {
          console.log("here", res);
          /**
           * * @EneaXharau - Added socket emitter
           * * .send() takes -> { request: String, body: any} -> stringified
           */
          dispatch(addClocking(res));
          setNewShiftVisib(false);
          setSuccessVisibility(true);

          if (socketHandler.readyState === socketHandler.OPEN) {
            socketHandler.send(
              JSON.stringify({
                request: "user_clockIn",
                body: res,
                id: newShift.employeeId,
              })
            );
          }

          // notificationUserToAdminNoDbPost(
          // 	{
          // 		notificationTitle: "Filloi Turni",
          // 		createdAt: dayjsTZ().format("DD/MM/YYYY"),
          // 		notificationPriority: false,
          // 		notificationCategory: "Info",
          // 		notificationBody: `${auth?.userAccess[0]?.given_name} ${
          // 			auth?.userAccess[0]?.family_name
          // 		} filloi turnin ${dayjsTZ(toSend.clockInDate).format("HH:mm")}`,
          // 		isAnnouncement: false,
          // 		notificationUrl: "/aprovimet",
          // 		notificationFirstName: auth?.userAccess[0]?.given_name,
          // 		notificationLastName: auth?.userAccess[0]?.family_name,
          // 	},
          // 	users?.allUsers?.Items
          // );
        })
        .catch((errArr) => {
          setCrashError(true);
          setCrashData(errArr);
        });
      setSaving(false);
      setPrevShift(true);
    } else {
      message.error({
        key: "newShift",
        content:
          "Gabim në plotësimin e të dhënave! Ju lutem plotësoni të gjitha fushat!",
      });
    }
  };
  const handleReset = () => {
    setNewShift({
      clockInDate: dayjsTZ(),
      clockOutDate: null,
      note: "",
      employeeId: null,
    });
    setDateDayJs(dayjsTZ());
    onRefresh();
  };

  /**
   * // Note to add: retrieve grace period from settings, and add it to the logic below same with 0 grace period
   * @param {*} date
   */
  //region >checkGracePeriod()
  const checkGracePeriod = (date) => {
    let h_dayjs = dayjsTZ();
    h_dayjs = h_dayjs.set("hour", "13").set("minute", "30").set("second", "30");

    if (dayjsTZ().diff(date, "minutes", true) <= gracePeriod) {
      if (dayjsTZ().diff(date, "minutes", true) < 1) {
        setGraceWarning({
          text: "",
          enable: false,
        });
        return true;
      } else {
        setGraceWarning({
          text: "Nevojitet një shënim për këtë ndryshim!",
          enable: true,
        });
        return true;
      }
    } else {
      setGraceWarning({
        text: "",
        enable: false,
      });
      return false;
    }
  };

  //region EDIT CLOCKIN
  const onEditClockIn = (e) => {
    let date = dayjsTZ();
    let time = dayjsTZ(e)
      .set("hour", e.get("hour"))
      .set("minute", e.get("minute"));

    setGraceWarning({
      text: "",
      enable: false,
    });
    if (time.diff(dayjsTZ(), "minutes", true) > 0.8) {
      message.error({
        key: "newShift",
        content: "Nuk mund të zgjedhësh një kohë në të ardhmen!",
      });
      setNewShift((prev) => ({ ...prev, clockInDate: dayjsTZ() }));
    } else if (gracePeriod === 0) {
      message.error({
        key: "newShift",
        content: "Ju nuk mund të ndryshoni orën e fillimit!",
      });
      let h_dayjs = dayjsTZ();

      h_dayjs = h_dayjs
        .set("hour", "13")
        .set("minute", "30")
        .set("second", "30");

      if (dayjsTZ().diff(h_dayjs, "minutes", true) > 1) {
        setGraceWarning({
          text: "Nevojitet një shënim! (Të paktën 5 karaktere!)",
          enable: true,
        });
      }
      return;
    } else {
      date = date
        .set("hour", e.get("hour"))
        .set("minute", e.get("minute"))
        .set("second", e.get("second"));

      const graceTime = checkGracePeriod(date);

      if (e !== null && newShift.clockOutDate !== null && graceTime) {
        if (
          dayjsTZ(date).valueOf() < dayjsTZ(newShift.clockOutDate).valueOf()
        ) {
          setNewShift((prev) => ({ ...prev, clockInDate: date }));
          setManualShift(true);
        } else {
          message.error({
            key: "newShift",
            content: "Fillimi i turnit nuk mund të jetë më vonë se mbarimi.",
          });
        }
      } else if (!graceTime) {
        message.error({
          key: "newShift",
          content:
            "Ju nuk mund të ndryshoni orën e fillimit më shumë se vlera e lejuar!",
        });
        setGraceWarning({
          text: "Nevojitet një shënim!",
          enable: true,
        });
        setNewShift((prev) => ({ ...prev, clockInDate: dayjsTZ() }));
      } else {
        setNewShift((prev) => ({ ...prev, clockInDate: date }));
        setManualShift(true);
      }
    }
  };

  //region EDIT CLOCKOUT
  const onEditClockOut = (e) => {
    let date2 = dayjsTZ();

    date2 = date2
      .set("hour", e.get("hour"))
      .set("minute", e.get("minute"))
      .set("second", e.get("second"));

    if (e !== null && newShift.clockInDate !== null) {
      // changed clockIn
      if (dayjsTZ(date2).valueOf() > dayjsTZ(newShift.clockInDate).valueOf()) {
        setNewShift((prev) => ({ ...prev, clockOutDate: date2 }));
      } else {
        message.error({
          key: "newShift",
          content: "Mbarimi i turnit nuk mund të jetë më shpejt se fillimi.",
        });
      }
    } else {
      setNewShift((prev) => ({ ...prev, clockInDate: date2 }));
    }
  };

  const onCalendarPick = (e) => {
    if (e !== null && e !== undefined) {
      setDateDayJs(e);
    }
  };

  //region AUTO SUGGEST
  const getAutoSuggest = (time) => {
    // let time = dayjsTZ(time);
    let l_dayjs = dayjsTZ();
    l_dayjs = l_dayjs.set("hour", "13").set("minute", "15").set("second", "00");

    let h_dayjs = dayjsTZ();
    h_dayjs = h_dayjs.set("hour", "13").set("minute", "31").set("second", "30");
    let autoSuggestion = dayjsTZ();
    autoSuggestion = autoSuggestion
      .set("hour", "13")
      .set("minute", "30")
      .set("second", "00");
    // Morning Shift
    let l_dayjs2 = dayjsTZ();
    l_dayjs2 = l_dayjs2
      .set("hour", "09")
      .set("minute", "40")
      .set("second", "00");

    let h_dayjs2 = dayjsTZ();
    h_dayjs2 = h_dayjs2
      .set("hour", "10")
      .set("minute", "01")
      .set("second", "30");

    let lower_bound2 = dayjsTZ(l_dayjs2).valueOf();
    let upper_bound2 = dayjsTZ(h_dayjs2).valueOf();

    if (
      dayjsTZ(time).valueOf() > lower_bound2 &&
      dayjsTZ(time).valueOf() < upper_bound2
    ) {
      autoSuggestion = autoSuggestion
        .set("hour", "10")
        .set("minute", "00")
        .set("second", "00");
      return autoSuggestion;
    } else {
      let lower_bound = dayjsTZ(l_dayjs).valueOf();
      let upper_bound = dayjsTZ(h_dayjs).valueOf();
      if (
        dayjsTZ(time).valueOf() > lower_bound &&
        dayjsTZ(time).valueOf() < upper_bound
      ) {
        // setShift((prev) => ({ ...prev, clockOutDate: autoSuggestion }));
        return autoSuggestion;
      } else {
        return dayjsTZ();
        // return autoSuggestion;
      }
    }
  };
  const leaves = useMemo(() => {
    if (!!Object.keys(auth?.userAccess[0]).length && !!employeeRequests) {
      return (employeeRequests || []).filter(
        (el) => el?.userSub === auth?.userAccess[0]?.userSub
      );
    } else return [];
  }, [auth?.userAccess[0], employeeRequests]);

  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res?.data?.ip);
  };

  useEffect(() => {
    getIp();
  }, []);

  useEffect(() => {
    if (selfType === true && !!Object.keys(auth).length) {
      setNewShift((prev) => ({
        ...prev,
        employeeId: auth?.employeeId || null,
      }));
    }
  }, [selfType, auth]);

  useEffect(() => {
    if (leaves.length >= 0 && !!Object.keys(programFields || {}).length) {
      let test = calculateDayType(
        programFields,
        leaves,
        newShift.clockInDate,
        auth?.employee?.employeeTeam
      );
      const temp =
        programFields
          ?.find((el) => el.fieldId === OFF_SHIFT_POLICIES_FIELD_ID)
          ?.fieldOptions?.find((el) => el.name === "Grace Period")?.formula ||
        0;
      setGracePeriod(parseInt(temp));
      let tempTime = dayjsTZ();
      tempTime = tempTime
        .set("hour", "13")
        .set("minute", "30")
        .set("second", "00");
      let tempTime2 = dayjsTZ();
      tempTime2 = tempTime2
        .set("hour", "10")
        .set("minute", "01")
        .set("second", "30");
      // Greater than 13:30
      if (dayjsTZ().diff(dayjsTZ(tempTime), "minutes", true) > 1) {
        setGraceWarning({
          text: "Nevojitet një shënim i vlefshëm për këtë vonesë! (Të paktën 5 karaktere.)",
          enable: true,
        });
      } else {
        const time1PM = dayjsTZ().set("hour", 13).set("minute", 0);
        if (
          dayjsTZ().diff(dayjsTZ(tempTime2), "minutes", true) > 1 &&
          dayjsTZ().diff(time1PM, "minutes", true) < 1
        ) {
          setGraceWarning({
            text: "Nevojitet një shënim i vlefshëm për këtë vonesë! (Të paktën 5 karaktere.)",
            enable: true,
          });
        } else {
          setGraceWarning({
            text: "",
            enable: false,
          });
        }
      }
      // else if (
      // 	dayjsTZ().diff(dayjsTZ(tempTime), "minutes", true) < 1 ||
      // 	dayjsTZ().diff(dayjsTZ(tempTime2), "minutes", true) < 1
      // ) {
      // 	setGraceWarning({
      // 		text: "",
      // 		enable: false,
      // 	});
      // }

      if (!!test) {
        setDayType(test);
      } else {
        setDayType();
      }
    } else {
      setDayType("Regular");
    }
  }, [leaves, newShift, programFields, auth?.employee?.employeeTeam]);

  useEffect(() => {
    if (!manualShift && !successVisibility) {
      const clockInDate = getAutoSuggest(liveTime);

      const { given_name, family_name } = auth?.user || {};

      let note = "";
      if (given_name === "Ermis" && family_name === "Dauti") {
        note =
          "<p><span style='background-color: rgb(255, 251, 230);'>vonesë</span></p>";
      }
      setNewShift((prev) => ({ ...prev, clockInDate, note }));
    }
  }, [liveTime, manualShift, auth]);

  //region RETURN
  return (
    <>
      <Modal
        destroyOnClose={true}
        width={918}
        className="new-shift lightHeader"
        title={"Konfirmo Turn Të Ri"}
        open={newShiftVisib}
        onOk={() => setNewShiftVisib(false)}
        onCancel={() => setNewShiftVisib(false)}
        centered={true}
        closeIcon={<ModalXIcon />}
        footer={[
          <MondayButton
            className="mondayButtonRed"
            onClick={() => setNewShiftVisib(false)}
          >
            Mbyll Faqen
          </MondayButton>,
          <MondayButton
            className="mondayButtonGreen"
            disabled={saving}
            onClick={onSave}
          >
            Shto Turnin
          </MondayButton>,
        ]}
      >
        <div className="new-shift-wrapper">
          <div className="warning-title">
            <span className="warn-icon">
              <WarningIcon />
            </span>

            <span style={{ color: "#ea3943" }}>
              Përshëndetje,{" "}
              <strong>
                {auth?.userAccess[0]?.given_name +
                  " " +
                  auth?.userAccess[0]?.family_name}
              </strong>
              . Ju jeni duke shtuar një turn, siguroni të dhënat përpara se të
              ruani ndryshimet!{" "}
              {`Turni i përcaktuar: 
              (${
                shiftConfiguration?.appliedShifts?.length > 0
                  ? getAppliedShifts(shiftConfiguration?.appliedShifts)
                  : getAppliedShifts(shiftConfiguration?.applicableShifts)
              }
              )`}
            </span>
          </div>{" "}
          {gracePeriod > 0 && (
            <div>
              <br />
              <Alert
                message={`Ju mund të ndryshoni orarin e fillimit deri në ${gracePeriod} minuta para vetëm me arsye të vlefshme! `}
                type="info"
                showIcon
              />
            </div>
          )}
          <div className="to-edit-fields">
            {" "}
            <div className="shift-field-start" style={{ marginLeft: "0" }}>
              <label style={{ fontWeight: "600" }}>
                <strong style={{ color: "red" }}>*</strong>Fillimi i Turnit:
              </label>
              <TimePicker
                value={newShift.clockInDate}
                placeholder="Fillimi"
                // inputReadOnly
                format="HH:mm"
                onChange={onEditClockIn}
                // disabledHours={() => getDisabledAllHours()}
                // disabledMinutes={(val) => getDisabledButGracePeriodMins(newShift.clockOutDate, val)}
                disabledTime={() => ({
                  disabledHours: getDisabledAfterCustomHours,
                  disabledMinutes: (hour) => getDisabledAfterMinutes(hour),
                })}
                showNow={false}
                allowClear={false}
                ref={clockinRef}
              />
            </div>
            <div style={{ marginLeft: "0" }} className="shift-field-end">
              <label style={{ fontWeight: "600" }}>Mbarimi i Turnit: </label>
              <TimePicker
                value={newShift.clockOutDate}
                placeholder="Mbarimi"
                disabled={true}
                format="HH:mm"
                disabledTime={() => ({
                  disabledHours:
                    newShift.clockInDate !== null
                      ? () => getDisabledBeforeCustomHours(newShift.clockInDate)
                      : () => [],
                  disabledMinutes:
                    newShift.clockInDate !== null
                      ? (hour) =>
                          getDisabledBeforeCustomMinutes(
                            newShift.clockInDate,
                            hour
                          )
                      : () => [],
                })}
                onChange={onEditClockOut}
                showNow={false}
                allowClear={false}
              />
            </div>
            <div style={{ marginLeft: "0" }} className="shift-field-end">
              {/* <Alert message="Warning" type="warnings" showIcon /> */}
              <label style={{ fontWeight: "600", marginLeft: "0px" }}>
                <strong style={{ color: "red" }}>*</strong>Data e Turnit:
              </label>

              <span
                style={{
                  width: "100%",
                  color: "white",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  marginLeft: "0px",
                  cursor: "pointer",
                }}
              >
                <DatePicker
                  allowClear={false}
                  format={"DD/MM/YYYY"}
                  disabled={true}
                  defaultValue={dateDayJs}
                  //   value={dateDayJs}
                  onChange={onCalendarPick}
                  popupClassName="calendar-date"
                  placeholder="Zgjidh Datën"
                  disabledDate={(current) => {
                    return dayjsTZ() < current;
                  }}
                />
              </span>
            </div>
            <div className="shift-field-end" style={{ marginLeft: "0" }}>
              <label style={{ fontWeight: "600" }}>
                <strong style={{ color: "red" }}>*</strong>Lloji i turnit:{" "}
              </label>

              <Select
                className="daytype-select"
                disabled
                showSearch
                value={dayType}
                placeholder="Zgjidh nje opsion"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {programFields
                  ?.find((el) => el.fieldId === CLOCKIN_TYPES_FIELD_ID)
                  ?.fieldOptions?.map((el, idx) => (
                    <Option key={`shiftType-${idx}`} value={el?.name}>
                      {el?.description}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
          <div className="to-note">
            {graceWarnings.enable && (
              <Alert message={graceWarnings?.text} type="warning" showIcon />
            )}

            <label style={{ fontWeight: "600" }}>Shënimi:</label>
            <RichTextEditor
              className={"to-note-textarea"}
              value={newShift.note}
              onChange={(e) => setNewShift((prev) => ({ ...prev, note: e }))}
            />
          </div>
          <div className="hours-generation">
            <span style={{ fontWeight: "600" }}>
              Orët totale:{" "}
              {dayjsTZ(newShift.clockOutDate)
                .diff(dayjsTZ(newShift.clockInDate), "hours", true)
                .toFixed(2) > 0
                ? dayjsTZ(newShift.clockOutDate)
                    .diff(dayjsTZ(newShift.clockInDate), "hours", true)
                    .toFixed(2)
                : 0}{" "}
              orë
            </span>
            <div className="animated-hours">
              <div
                className="calculated-hours"
                style={{
                  width: `${
                    dayjsTZ(newShift.clockOutDate)
                      .diff(dayjsTZ(newShift.clockInDate), "hours", true)
                      .toFixed(2) > 0 &&
                    dayjsTZ(newShift.clockOutDate)
                      .diff(dayjsTZ(newShift.clockInDate), "hours", true)
                      .toFixed(2) <= 8
                      ? (dayjsTZ(newShift.clockOutDate)
                          .diff(dayjsTZ(newShift.clockInDate), "hours", true)
                          .toFixed(2) /
                          8) *
                        100
                      : dayjsTZ(newShift.clockOutDate)
                          .diff(dayjsTZ(newShift.clockInDate), "hours", true)
                          .toFixed(2) > 8
                      ? 100
                      : 0
                  }%`,
                }}
              ></div>
            </div>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              {saving && <BreedingRhombusSpinner size={40} color="#1da193" />}
            </div>
          </div>{" "}
        </div>
        <CrashShift
          type={"new"}
          crashData={crashData}
          open={crashError}
          setCrashError={setCrashError}
        />
      </Modal>
      {!!successVisibility ? (
        <SuccessComponent
          open={successVisibility}
          setOpen={setSuccessVisibility}
          text={`Turni u fillua me sukses në orën ${dayjsTZ(
            newShift.clockInDate
          ).format("HH:mm")} `}
          onOk={handleReset}
        />
      ) : null}
    </>
  );
}

export default NewUserShift;
