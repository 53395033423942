import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Card, message } from "antd";
const gridStyle = {
  width: "20%",
  textAlign: "center",
};

function HoursPreview({ payObj, allHours, otherHours, totalHours }) {
  const [necessaryData, setNecesseryData] = useState(hourConstants);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!!payObj) {
      setLoading(true);
      let temp = necessaryData || {};
      temp.normalHours.amount = payObj?.allhours?.normalHours || 0;
      temp.lateHours.amount = payObj?.allhours?.lateHours || 0;
      temp.extraHours.amount = payObj?.allhours?.extraHours || 0;
      temp.otherHours.amount = payObj?.requests?.totalities.hours || 0;
      temp.totalHours.amount =
        temp.normalHours.amount +
        temp.lateHours.amount +
        temp.extraHours.amount +
        temp.otherHours.amount;
      setNecesseryData(temp);

      setLoading(false);
    }

    return () => setNecesseryData(hourConstants);
  }, [payObj?.allHours, payObj?.requests?.totalities.hours]);

  const cardGrid = useMemo(() => {
    let cards = [];

    Object.entries(necessaryData || {}).forEach(([hourType, obj]) => {
      cards.push(
        <Card.Grid style={gridStyle}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <strong>{statusTitles?.[hourType]}</strong>
            <span>{obj?.amount.toFixed(2) || 0} orë</span>
          </div>
        </Card.Grid>
      );
    });
    return cards;
  }, [statusTitles, necessaryData]);

  return !loading ? (
    <Card
      title={
        `Oret e Punës: ` +
        (necessaryData?.totalHours?.amount.toFixed(2) || 0) +
        " orë"
      }
      className="hoursPreviewWrapper"
    >
      {cardGrid}
    </Card>
  ) : null;
}

export default HoursPreview;

const statusTitles = {
  normalHours: "Para 19:00",
  extraHours: "22:00 - 07:30",
  lateHours: "19:00 - 22:00",
  otherHours: "Të tjera: ",
  totalHours: "Totali: ",
};

const hourConstants = {
  normalHours: {
    amount: 0,
    pay: 0,
  },
  extraHours: {
    amount: 0,
    pay: 0,
  },
  lateHours: {
    amount: 0,
    pay: 0,
  },
  otherHours: {
    amount: 0,
    pay: 0,
  },
  totalHours: {
    amount: 0,
    pay: 0,
  },
};
