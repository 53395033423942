import { Alert } from "antd";
import React from "react";
import { currencyformatter } from "../utils/formatter";
import "./CustomWarnings.scss";
import { warnifyTax } from "./utils";
function CustomWarnings({ payStubObj }) {
	console.log("payStubObj", payStubObj);
	return (
		<div className="custom-warnings">
			<div className="warnify"> {warnifyTax(payStubObj)}</div>
			{/* <strong>Pagesa Bruto: {currencyformatter.format(payStubObj?.bruto?.totalBruto)} </strong>
			<strong>Pagesa Neto: {currencyformatter.format(payStubObj?.taxes?.netoPay?.netoPay)}</strong> */}

			<div className="amount-cards-wrapper">
				<AmountCard
					title={"Paga Bruto"}
					color={"#E6FAE5"}
					amount={payStubObj?.bruto?.totalBruto || 0}
				/>
				<AmountCard
					title={"Paga Neto"}
					color={"#D4EFD7"}
					amount={payStubObj?.taxes?.netoPay?.netoPay || 0}
				/>
				<AmountCard title={"Turnet"} amount={payStubObj?.earnings?.totalWage || 0} />
				<AmountCard title={"Pushimet"} amount={payStubObj?.requests?.totalities?.earnings || 0} />
				<AmountCard
					title={"Paga per kontributet"}
					fontSize={14}
					amount={payStubObj?.taxes?.netoPay?.contributions || 0}
				/>
				<AmountCard
					title={"Sig. individi"}
					fontSize={16}
					amount={payStubObj?.taxes?.netoPay?.totalIns || 0}
				/>
				<AmountCard
					fontSize={16}
					title={"Sig. Punedhenesi"}
					amount={payStubObj?.taxes?.netoPay?.totalEmployeeerIns || 0}
				/>
			</div>
		</div>
	);
}

export default CustomWarnings;

export const AmountCard = ({ title, amount, color, fontSize, textColor }) => {
	return (
		<div
			className="amount-card"
			style={{ background: !!color ? color : null, color: !!textColor ? textColor : null }}
		>
			<div className="amount-card__content">
				<span className="amount-card_title" style={{ fontSize: !!fontSize ? fontSize : null }}>
					{title}
				</span>
				<span className="amount-card_amount">
					{noSignFormatter.format(amount).replace("ALL", "")}
				</span>
			</div>
		</div>
	);
};

const noSignFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "ALL",
	minimumFractionDigits: 2,
	// maximumFractionDigits: 2,

	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
