import dayjsTZ from "../../../../utils/dayjs";
import dayjs from "dayjs";
import { weekFiltering } from "../../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/filtering";
export const onPickMonth = async (momentInstance, gridApi) => {
  let start = dayjsTZ(momentInstance).startOf("month").valueOf();
  let end = dayjsTZ(momentInstance).endOf("month").valueOf();

  await gridApi.getColumnFilterInstance("fillim_turni").then((comp) => {
    comp.setModel({
      type: "inRange",
      filter: start,
      filterTo: end,
    });
  });
  gridApi.onFilterChanged();
};

export const onWeekFilter = async (filterType, gridApi, instance) => {
  let filterArr = weekFiltering(dayjsTZ(instance), filterType);

  let start = dayjsTZ(filterArr[0]).format("YYYY-MM-DD");
  let end = dayjsTZ(filterArr[1]).format("YYYY-MM-DD");
  // console.log("filterArr", filterArr, selected.muaji);
  await gridApi.getColumnFilterInstance("fillim_turni").then((comp) => {
    comp.setModel({
      type: "inRange",
      filterType: 'date',
      dateFrom: start,
      dateTo: end,
    });
  });
  gridApi.onFilterChanged();
};

export const onStatusFilter = async (statusInstance, gridApi) => {
  if (statusInstance !== undefined) {
    await gridApi.getColumnFilterInstance("approved").then((comp) => {
      comp.setModel({
        type: "equals",
        filter: `${statusInstance}`,
      });
    });
    gridApi.onFilterChanged();
  } else {
    gridApi.destroyFilter("approved");
  }
};

export const onDepartmentFilter = async (
  departmentInstance,
  gridApi,
  filterInstance
) => {
  if (departmentInstance !== undefined) {
    await gridApi.getColumnFilterInstance(filterInstance).then((comp) => {
      comp.setModel({
        type: "equals",
        filter: `${departmentInstance}`,
      });
    });
    gridApi.onFilterChanged();
  } else {
    gridApi.destroyFilter(filterInstance);
  }
};

export const onEmployeeFilter = async (employeeId, gridApi, filterInstance) => {
  if (employeeId !== undefined) {
    await gridApi.getColumnFilterInstance(filterInstance).then((comp) => {
      comp.setModel({
        type: "equals",
        filter: `${employeeId}`,
      });
    });
    gridApi.onFilterChanged();
  } else {
    gridApi.destroyFilter(filterInstance);
  }
};

export const onBranchFilter = async (branchName, gridApi) => {
  if (branchName !== undefined) {
    await gridApi
      .getColumnFilterInstance("employee_details.employeeTeam")
      .then((comp) => {
        comp.setModel({
          type: "equals",
          filter: `${branchName}`,
        });
      });
    gridApi.onFilterChanged();
  } else {
    gridApi.destroyFilter("employee_details.employeeTeam");
  }
};

export const onEmployeeWageTypeFilter = async (
  wageType,
  gridApi,
  filterInstance
) => {
  if (wageType !== undefined) {
    await gridApi.getColumnFilterInstance(filterInstance).then((comp) => {
      comp.setModel({
        type: "equals",
        filter: `${wageType}`,
      });
    });
    gridApi.onFilterChanged();
  } else {
    gridApi.destroyFilter(filterInstance);
  }
};

export const handleDateRange = async (e, gridApi) => {
  console.log(e);
  if (e !== null) {
    let start = e[0].startOf("day").valueOf();
    let end = e[1].endOf("day").valueOf();
    console.log({ start, end });
    await gridApi.getColumnFilterInstance("fillim_turni").then((comp) => {
      comp.setModel({
        type: "inRange",
        filter: start,
        filterTo: end,
      });
    });
    // console.log("Dater", "start0", start, "end", end);
    gridApi.onFilterChanged();
  } else if (e === null) {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setGridOption("quickFilterText", null);
  }
};
