import { useLayoutEffect, useMemo, useState } from "react";
import AgGridComponent from "../../AG-grid/AgGridComponent";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import InnerModal from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/InnerModal";
import { FetchIcon } from "../../../assets/icons";
import { Dropdown, message } from "antd";
import {
  approveShifts,
  deleteClockIns,
  disapproveShifts,
} from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/apiHelpers";
import EditShiftModal from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/EditShiftModal/EditShiftModal";
import AdminShiftLogs from "./adminShiftLogs/AdminShiftLogs";
import NewShiftModal from "./newShiftModal/NewShiftModal";
import LoadableComp from "./../../commonComponents/LoadableComp/LoadableComp";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../authentication/authHelpers";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { clockings as clockingsAction } from "../../../store/actions";
import { requestDataWithParams } from "../../../helpers/API/RequestFactory";
import GeneralAdminShift from "./adminShiftLogs/GeneralAdminShiftLogs";
import { useMediaQuery } from "react-responsive";

import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import getAgThemePreference from "../../../utils/getAgThemePreference";
import { onWeekFilter } from "./gridApiMethods/filtering";
import { weekFiltering } from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/filtering";
import FetchDateRangeModal from "./FetchDateRangeModal/FetchDateRangeModal";
import { coldefs } from "./coldefs";
import useMemoCompare from "../../../hooks/useMemoCompare";
import ChartSummary from "../../dashboard/components/charts/Chart";

const FILTER = {
  filterKeys: [
    {
      key: "employeeDepartmentName",
      label: "Departamenti",
      type: "string",
    },
    {
      key: "employeeFullName",
      label: "Punonjësi",
      type: "string",
    },
    {
      key: "employeeTeam",
      label: "Branch",
      type: "string",
    },
    {
      key: "approved",
      label: "Statusi",
      type: "string",
      filterType: "equals",
    },
    {
      key: "data",
      label: "Data",
      type: "date-range",
    },
  ],
  replaceable: {
    approved: [{ Aprovuar: "I aprovuar" }, { "Pa Aprovuar": "I pa aprovuar" }],
  },
};
const WEEKS = [
  { key: "firstWeek", label: "Java e parë" },
  { key: "secondWeek", label: "Java e dytë" },
  { key: "thirdWeek", label: "Java e tretë" },
  { key: "fourthWeek", label: "Java e katërt" },
  { key: "fifthWeek", label: "Java e fundit" },
];
const CLOCKIN_TYPES_FIELD_ID = "e685cd30-b119-11ec-829e-112b583587c7";
const { foregroundColor } = getAgThemePreference();

//region APPROVALS()
function Approvals({
  singleEmployee = false,
  showChart = false,
  defaultFilter = false,
  colDefsGenerator = coldefs,
}) {
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.usersList);
  const { programFields } = useSelector((state) => state.programFields);
  const { employees: allEmployees, activeEmployees } = useSelector(
    (state) => state.employeesList
  );

  const [rowData, setRowData] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [openInner, setOpenInner] = useState(false);
  const [editedShift, setEditedShift] = useState(null);
  const [clockins, setClockins] = useState([]);
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const [openEditShift, setOpenEditShift] = useState(false);
  const [editableShift, setEditableShift] = useState([]);
  const [shiftLogsVisibility, setShiftLogsVisibility] = useState(false);
  const [shiftAdminLog, setShiftAdminLog] = useState(null);
  const [shiftAdminGeneralLog, setShiftAdminGeneralLog] = useState(null);
  const [openFetchRange, setOpenFetchRange] = useState(false);
  const [openNewShift, setOpenNewShift] = useState(false);
  const [approvedEnable, setApprovedEnable] = useState(false);
  const [activeFilter, setActiveFilter] = useState(defaultFilter || {});

  const employees = useMemo(
    () =>
      !!singleEmployee
        ? [singleEmployee]
        : showActiveOnly
        ? activeEmployees
        : allEmployees,
    useMemoCompare([
      singleEmployee,
      showActiveOnly,
      activeEmployees,
      allEmployees,
    ])
  );

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  //region ON APPROVE
  const onApprove = async (type) => {
    let selectedNodes = gridApi?.getSelectedNodes() || [];
    let selectedData = selectedNodes.map((node) => node.data);
    const isThereUnfinished = selectedData?.find(
      (el) => el?.clockOutDate === null
    );

    if (!isThereUnfinished) {
      if (selectedData?.length <= 20) {
        if (type === "approve") {
          // if (selectedData.filter((el) => el.approved === true).length > 0) {
          // 	message.error("Ju nuk mund te zgjidhni dy statuse te ndryshme!");
          // 	gridApi.deselectAll();
          // } else
          if (selectedData.length > 0) {
            let adminlog = {
              approved: true,
              createdAt: dayjsTZ().valueOf(),
              approvedBy:
                auth?.userAccess[0]?.given_name +
                " " +
                auth?.userAccess[0]?.family_name,
            };
            message.loading({
              key: "inProgress",
              content: "Action in progress...",
            });
            await approveShifts(selectedData, adminlog).then((newShifts) => {
              refreshData(newShifts);
              gridApi?.deselectAll();
            });
          }
        }
        if (type === "disapprove") {
          if (selectedData.filter((el) => el.approved === false).length > 0) {
            message.error("Ju nuk mund të zgjidhni dy statuse të ndryshme");
            gridApi?.deselectAll();
          } else if (
            selectedData.filter((el) => el.approved === false).length === 0 &&
            selectedData.length > 0
          ) {
            let adminlog = {
              approved: false,
              createdAt: dayjsTZ().valueOf(),
              approvedBy:
                auth?.userAccess[0]?.given_name +
                " " +
                auth?.userAccess[0]?.family_name,
            };
            // console.log("selected data", selectedData);
            await disapproveShifts(selectedData, adminlog).then((newShifts) => {
              refreshData(newShifts);
              gridApi?.deselectAll();
            });
          }
        }
      } else {
        message.error(
          "Ju nuk mund të zgjidhni më shumë se 20 turne për aprovim!"
        );
      }
    } else {
      message.error("Ju nuk mund të zgjidhni turne të pambyllura!");
    }
  };

  const handleSelectionChanged = () => {
    let selectedNodes = gridApi?.getSelectedNodes() || [];
    let selectedData = selectedNodes.map((node) => node.data);
    if (selectedData.length > 0) {
      setApprovedEnable(true);
    } else {
      setApprovedEnable(false);
    }
  };

  const onShiftAdminLog = (log) => {
    setShiftAdminLog(log);
    setShiftLogsVisibility(true);
  };

  const onEditShift = (e) => {
    setEditableShift(e);
    setOpenEditShift(true);
  };

  //region >onDelete()
  const onDelete = async (ids) => {
    if (!!ids?.length) {
      await deleteClockIns(
        ids?.map((el) => ({ clockingId: el })),
        () => updateClockins(clockins, ids)
      );
    } else {
      let selectedNodes = gridApi?.getSelectedNodes() || [];
      let selectedData = selectedNodes.map((node) => node.data);
      if (!!selectedData?.length) {
        const toRemove = selectedData?.map((el) => ({
          clockingId: el?.clockingId,
        }));
        gridApi.applyTransaction({
          remove: toRemove,
        });
        await deleteClockIns(selectedData, () =>
          updateClockins(
            clockins,
            toRemove.map((el) => el?.clockingId)
          )
        );
      }
    }
  };

  const updateClockins = (array, toRemove) => {
    if (!!toRemove && Array.isArray(toRemove)) {
      const results = (array || []).filter(
        (el) => !toRemove.includes(el?.clockingId)
      );
      setClockins(results);
      dispatch(clockingsAction(results));
    } else {
      setClockins(array);
      dispatch(clockingsAction(array));
    }
  };

  //region HANDLE PASSWORD
  const handlePassword = (selectedIds) => {
    Swal.fire({
      title: "Vendos kodin personal!",
      input: "password",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Konfirmo",
      cancelButtonText: "Anulo",
      confirmButtonColor: "#1da193",
      cancelButtonColor: "#ea3943",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value === users?.pinNumber) {
          message.success("Kodi i vendosur është i saktë!");
          onDelete(selectedIds);
        } else {
          message.error("Kodi i vendosur është gabim!");
          handlePassword(selectedIds);
        }
      }
    });
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const showModal = (e, val, type) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenInner(true);
    setEditedShift([val, type]);
  };

  const clearFilters = () => {
    gridApi?.setFilterModel(null);
    gridApi?.onFilterChanged(null);
    gridApi?.setGridOption("quickFilterText", null);
    setActiveFilter({});
    // getShiftData(); //no needed, user should refresh data
  };

  //region >getRespectiveData()
  const getRespectiveData = async (monthFilter) => {
    if (Array.isArray(monthFilter)) {
      return await requestDataWithParams(
        "clocking",
        dayjsTZ(monthFilter[0]).startOf("day").valueOf(),
        dayjsTZ(monthFilter[1]).endOf("day").valueOf()
      );
    } else {
      if (
        dayjsTZ(monthFilter).format("YYYY-MM") === dayjsTZ().format("YYYY-MM")
      ) {
        return await requestDataWithParams(
          "clocking",
          dayjsTZ(monthFilter).startOf("month").valueOf(),
          dayjsTZ(monthFilter).endOf("month").valueOf()
        );
      } else {
        return await requestDataWithParams(
          "clocking",
          dayjsTZ(monthFilter).startOf("month").valueOf(),
          dayjsTZ(monthFilter).endOf("month").valueOf()
        );
      }
    }
  };
  //region >populateData()
  const populateData = (arr, filter) => {
    const toReturn = [];

    const dateMatches = (date) => {
      let doesMatch = false;
      if (Array.isArray(filter)) {
        //probably but better not
        // setActiveFilter((prev) => ({ ...prev, range: filter }));
        doesMatch =
          dayjsTZ(date).valueOf() > dayjsTZ(filter[0]).valueOf() &&
          dayjsTZ(date).valueOf() < dayjsTZ(filter[1]).valueOf();
      } else {
        doesMatch =
          dayjsTZ(date).format("MMMM YYYY") ===
          dayjsTZ(filter).format("MMMM YYYY");
      }
      return doesMatch;
    };

    (arr || []).forEach((el, idx) => {
      if (
        el.clockInDate !== null &&
        !!el?.employeeId &&
        !!dateMatches(el?.clockInDate)
      ) {
        let employee;
        const foundIdx = employees.findIndex(
          (e) => e?.employeeId === el.employeeId
        );
        if (foundIdx > -1) {
          employee = employees[foundIdx];
          const toPush = {
            ...el,
            nr: idx,
            data: el.clockInDate,
            fillim_turni: el?.clockInDate,
            mbarim_turni: el?.clockOutDate,
            clockingId: el.clockingId,
            employeeDepartmentName: employee?.employeeDepartmentName,
            employeeTeam: employee?.employeeTeam,
            employeeFullName:
              employee?.employeeFirstName + " " + employee?.employeeLastName,
            breakTime: employee?.breakTime,
            isBreakTimePaid: employee?.isBreakTimePaid,
            employee_details: employee,
            oret_totale: el?.clockOutDate
              ? dayjsTZ(el?.clockOutDate)
                  .diff(dayjsTZ(el?.clockInDate), "hours", true)
                  .toFixed(2)
              : "0.00",
          };
          toReturn.push(toPush);
        }
      }
    });
    return toReturn;
  };

  //region >getShiftData()
  const getShiftData = async (val) => {
    //val is an object of filter only when fetching previous month's data
    const isObject = typeof val === "object" && !Array.isArray(val);
    const filter = !!val
      ? isObject
        ? Object.values(val).filter(Boolean)
        : val
      : !!activeFilter?.range
      ? [activeFilter?.range]
      : dayjsTZ();

    const dataset = [];
    const results = [];

    if (Array.isArray(filter)) {
      const promises = filter.map(async (el) => await getRespectiveData(el));
      await Promise.all(promises)
        .then((res) => {
          res?.forEach((arr, idx) => {
            const populated = populateData(arr, filter[idx]);
            results.push(...arr);
            dataset.push(...populated);
          });
        })
        .catch((err) => console.log("Error: ", err));
    } else {
      await getRespectiveData(filter)
        .then((res) => {
          const populated = populateData(res, filter);

          results.push(...res);
          dataset.push(...populated);
        })
        .catch((err) => console.log("Error: ", err));
    }
    setRowData(dataset.sort((a, b) => b.clockInDate - a.clockInDate));
    if (!!isObject) {
      setActiveFilter((prev) => ({ ...prev, ...val }));
    }
    dispatch(clockingsAction(results));
    setClockins(results);
  };

  //region >onRowClick()
  const onRowClick = (rowId) => {
    const idx = rowData?.findIndex((el) => el?.clockingId === rowId);
    if (idx !== -1) {
      return onEditShift(rowData[idx]);
    } else {
      return () => {};
    }
  };

  const onMobileWeekFilter = (e) => {
    const arr = weekFiltering(dayjsTZ(), e, true);
    setActiveFilter((prev) => ({ ...prev, range: arr }));
  };
  // this was previously commented
  // const onPaginationChanged = (e) => {
  // 	setPaginationGird(e.api);
  // 	if (e.api?.paginationGetCurrentPage() > 0) {
  // 		setCurrentGridPage(e.api.paginationGetCurrentPage());
  // 	}
  // };

  // useMemo(async () => {
  // 	let pageNr = JSON.parse(window.localStorage.getItem("gridPageApprovals"));
  // 	let parseNr = parseInt(pageNr);
  // 	if (!!parseNr && !!paginationGrid) {
  // 		await paginationGrid?.paginationGoToPage(parseNr);
  // 	}
  // }, [paginationGrid]);

  // useMemo(async () => {
  // 	if (currentGridPage !== null && !!currentGridPage) {
  // 		window.localStorage.setItem("gridPageApprovals", currentGridPage === 1 ? 0 : currentGridPage);
  // 	}
  // }, [currentGridPage]);

  // useLayoutEffect(() => {
  // 	if (activeEmployees !== null && departments !== null) {
  // 		message.info("second time");
  // 		getShiftData();
  // 	}
  // }, [activeEmployees, departments]);

  // useEffect(() => {
  // 	if (gridApi !== null && location.state !== null) {
  // 		!!location?.state?.data?.muaji && onPickMonth(location.state.data.muaji, gridApi);
  // 		!!location?.state?.paga &&
  // 			onEmployeeFilter(location.state.paga.employeeId, gridApi, "employee_details.employeeId");
  // 		// console.log("Locationnnn", location);
  // 	}
  // }, [gridApi, location, rowData]);

  const items = [
    {
      key: 1,
      label: (
        <span style={{ color: "#1da193" }} onClick={() => onApprove("approve")}>
          Aprovo
        </span>
      ),
    },
    {
      key: 2,
      label: (
        <span
          style={{ color: "#ea3943" }}
          onClick={() => onApprove("disapprove")}
        >
          Hiqe Aprovimin
        </span>
      ),
    },
  ];
  //week filtered on mobile
  const filteredRowData = useMemo(() => {
    if (!activeFilter?.range) return;
    else {
      let toReturn = [];
      const [start, end] = activeFilter?.range || [];

      (rowData || []).forEach((el) => {
        if (
          !!el?.fillim_turni &&
          el?.fillim_turni > dayjsTZ(start).valueOf() &&
          el?.fillim_turni < dayjsTZ(end).endOf("day").valueOf()
        ) {
          toReturn.push(el);
        }
      });
      return toReturn;
    }
  }, [activeFilter, rowData]);

  //region >refreshData()
  const refreshData = (shifts) => {
    const filter = !!Object.keys(activeFilter).length
      ? Object.values(activeFilter).filter(Boolean)
      : [dayjsTZ()];

    const toRefresh = [
      [clockins || [], setClockins],
      [filteredRowData || rowData || [], setRowData],
    ];

    toRefresh.forEach(([state, setState], idx) => {
      const newState = state.map((el) => {
        const idx = shifts?.findIndex(
          (shift) => shift?.clockingId === el?.clockingId
        );
        if (idx !== -1) {
          const matchingShift = shifts[idx];
          return { ...el, ...matchingShift };
        } else return el;
      });

      const populated = [];
      filter.forEach((el) => {
        populated.push(...(populateData(newState, el) || []));
      });

      idx === 0 && dispatch(clockingsAction(populated));
      setState(populated);
    });
  };

  const dayTypes = useMemo(() => {
    if (!programFields) return {};
    const idx = programFields.findIndex(
      (el) => el?.fieldId === CLOCKIN_TYPES_FIELD_ID
    );
    if (idx === -1) return {};
    const toReturn = {};
    programFields[idx]?.fieldOptions?.forEach((el) => {
      toReturn[el?.name] = el?.description;
    });
    return toReturn;
  }, [programFields]);

  const EXPORT_COLS = [
    {
      en: "approved",
      al: "Statusi",
      replace: {
        true: "I aprovuar",
        false: "I pa aprovuar",
      },
    },
    { en: "employeeFullName", al: "Punonjësi" },
    { en: "employeeTeam", al: "Team" },
    { en: "employeeDepartmentName", al: "Departamenti" },
    { en: "data", al: "Data", format: "DD/MM/YYYY" },
    { en: "dayType", al: "Kategoria", replace: dayTypes },
    { en: "fillim_turni", al: "Fillimi i turnit", format: "HH:mm" },
    { en: "mbarim_turni", al: "Mbarimi i turnit", format: "HH:mm" },
    { en: "breakTime", al: "Pushimi", join: "-" },
    { en: "oret_totale", al: "Orët Totale" },
  ];

  //region ITEMS
  const ITEMS = {
    search: { handleClean: clearFilters },
    ...(!singleEmployee
      ? {
          switch: {
            checked: showActiveOnly,
            onSwitch: () => setShowActiveOnly((prev) => !prev),
            onChecked: "Shiko për të gjithë punonjësit",
            onUnChecked: "Shfaq vetëm për punonjësit aktiv",
          },
        }
      : {}),
    icons: {
      filter: {
        ...(!singleEmployee ? FILTER : {}),
        children: [
          <div className="weekFilters">
            <h3>Filtro javën</h3>
            {WEEKS?.map(({ key, label }) => (
              <MondayButton
                className="mondayButtonGreen"
                onClick={() =>
                  !!isMobileView
                    ? onMobileWeekFilter(key)
                    : onWeekFilter(
                        key,
                        gridApi,
                        dayjsTZ(activeFilter?.range?.[0])
                      )
                }
              >
                {label}
              </MondayButton>
            ))}
          </div>,
        ],
      },
      excel: { tableCols: EXPORT_COLS },
      pdf: { tableCols: EXPORT_COLS },
      print: { tableCols: EXPORT_COLS },
      delete: { onClick: (selectedIds) => handlePassword(selectedIds) },
      ...(!singleEmployee
        ? {
            refresh: {
              onClick: () => {
                message.loading({
                  key: "getShiftData",
                  content: "Loading...",
                  duration: 0,
                });
                getShiftData().then(() => {
                  message.success({
                    key: "getShiftData",
                    content: "Loaded Successfully!",
                  });
                });
              },
            },
            modals: [
              {
                onClick: () => setShiftAdminGeneralLog(true),
                title: "Historiku",
              },
              {
                onClick: () => setOpenFetchRange(true),
                title: "Filtro në muajt e mëparshëm",
                icon: (
                  <FetchIcon
                    width={20}
                    height={20}
                    //winter
                    fill={foregroundColor}
                  />
                ),
              },
            ],
          }
        : {}),
    },
  };

  //region COLDEFS
  const columnDefs = useMemo(
    () =>
      colDefsGenerator({
        navigate,
        isMobileView,
        onEditShift,
        showModal,
        clockins,
        getShiftData,
        onShiftAdminLog,
      }),
    [clockins, colDefsGenerator]
  );

  useLayoutEffect(() => {
    if (!!employees) {
      getShiftData(defaultFilter);
    }
  }, [employees]);

  const exportTitle = () => {
    const { range } = activeFilter ?? {};
    const date = Array.isArray(range)
      ? dayjsTZ(range[0]).isSame(dayjsTZ(range[1]), "month")
        ? dayjsTZ().format("MMMM YYYY")
        : `${dayjsTZ(range[0]).format("DD MMMM YYYY")} - ${dayjsTZ(
            range[1]
          ).format("DD MMMM YYYY")}`
      : dayjsTZ().format("MMMM YYYY");
    if (!!singleEmployee) {
      return (
        "Turnet | " +
        singleEmployee?.employeeFirstName +
        " " +
        singleEmployee?.employeeLastName +
        " - " +
        date
      );
    } else {
      return "Aprovimet | " + date;
    }
  };

  //region RETURN
  return (
    <LoadableComp loading={!!rowData ? false : true}>
      <div
        className="globalPageWrapper"
        style={!!showChart && !isMobileView ? chartedApprovals : {}}
      >
        <AgGridComponent
          className="approvalsAgGrid"
          gridApi={gridApi}
          rowData={filteredRowData || rowData}
          rowSelection={{
            mode: "multiRow",
            enableClickSelection: true,
            selectAll: "currentPage",
          }}
          onGridReady={onGridReady}
          paginationPageSize={20}
          columnDefs={columnDefs}
          onSelectionChanged={handleSelectionChanged}
          defaultColDef={{
            resizable: true,
            sortable: true,
            filter: true,
          }}
          headerProps={{
            items: ITEMS,
            exportTitle: exportTitle(),
            children: [
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <MondayButton
                  className="mondayButtonGreen"
                  onClick={() => setOpenNewShift(true)}
                >
                  Krijo një hyrje
                </MondayButton>
                {!isMobileView ? (
                  <Dropdown.Button
                    className="approve-buttons"
                    classNames={{ root: "approveMenu" }}
                    menu={{ items }}
                    onClick={() => onApprove("approve")}
                    disabled={approvedEnable ? false : true}
                  >
                    Aprovo
                  </Dropdown.Button>
                ) : null}
              </div>,
            ],
          }}
          onRowClick={onRowClick}
          idKey={"clockingId"}
          paginator={true}
        />
        {!!showChart ? (
          <ChartSummary
            dataSrc={clockins}
            dataTitle="Employee hours"
            employeeChart={employees[0]?.employeeId}
          />
        ) : null}
      </div>
      {!!openNewShift ? (
        <NewShiftModal
          {...{
            employees,
            open: openNewShift,
            setOpen: setOpenNewShift,
          }}
          refreshData={getShiftData}
        />
      ) : null}
      {!!openInner ? (
        <InnerModal
          isModalVisible={openInner}
          setIsModalVisible={setOpenInner}
          {...{ editedShift, clockins }}
        />
      ) : null}
      {openEditShift ? (
        <EditShiftModal
          open={openEditShift}
          setOpen={setOpenEditShift}
          oldShift={editableShift}
          refreshData={refreshData}
        />
      ) : null}
      {openFetchRange ? (
        <FetchDateRangeModal
          open={openFetchRange}
          setOpen={setOpenFetchRange}
          hasRange={true}
          pickers={[{ picker: "month", format: "MMMM YYYY" }]}
          fetch={getShiftData}
        />
      ) : null}
      {!!shiftAdminLog ? (
        <AdminShiftLogs
          {...{
            setShiftAdminLog,
            shiftLogsVisibility,
            setShiftLogsVisibility,
            shiftAdminLog,
          }}
        />
      ) : null}
      {shiftAdminGeneralLog ? (
        <GeneralAdminShift
          shiftLogsVisibility={shiftAdminGeneralLog}
          setShiftLogsVisibility={setShiftAdminGeneralLog}
        />
      ) : null}
    </LoadableComp>
  );
}

export default Approvals;

const chartedApprovals = {
  flexDirection: "row",
  height: "100%",
  display: "grid",
  gridTemplateColumns: "3fr 1fr",
  maxWidth: 1300,
  minHeight: 600,
};
