import "./UserCard.scss";
import React from "react";

function UserActionCards({ contentChild, headerName, style }) {
  return (
    <div className="user-action-card" style={style}>
      <div className="user-action-header">
        <span className="user-header-title">{headerName}</span>
      </div>
      {contentChild}
    </div>
  );
}

export default UserActionCards;
