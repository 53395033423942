import { Col, Row } from "antd";
import { API } from "aws-amplify";
import React, { useState, useEffect, useMemo } from "react";
import './WageInfo.scss';

function WageInfo({ showPaymentInfo, employeesData }) {
  const [tableData, setTableData] = useState();
  const displayShifts = useMemo(() => {
    if (!!tableData && !!employeesData) {
      let temp = [];
      let shifts = employeesData?.employeeShiftId || [];
      if (shifts.length > 0) {
        shifts?.map((shift) => {
          let name = tableData.find((el) => el?.shiftId === shift);
          if (!!name) {
            temp.push(name);
          }
        });
        if (temp.length > 0) {
          return (
            <div className="style-width-input" style={{ width: "200%" }}>
              {" "}
              {temp?.map((t, i) => (
                <>
                  {i === temp.length - 1 ? (
                    <span>{t?.shiftName}</span>
                  ) : (
                    <span>{t?.shiftName + ", "}</span>
                  )}
                </>
              ))}
            </div>
          );
        } else if (temp.length === 0) {
          return <strong style={{ color: "Red" }}>Nuk ka te dhena</strong>;
        }
      }
      return <strong style={{ color: "Red" }}>Nuk ka te dhena</strong>;
    }
  }, [tableData, employeesData]);
  const getShifts = async () => {
    await API.get("shifts", "/shifts").then((r) => setTableData(r));
  };
  useEffect(() => {
    getShifts();
  }, [employeesData, showPaymentInfo]);

  const infoRows = {
    employeeMonthlyNetSalary: "Paga neto",
    employeeGrossMonthlySalary: "Paga bruto",
    employeePayrollType: "Lloji i pagës",
    employeeSalaryPerHour: "Pagesa për orë",
    employeePayrollFrequency: "Frekuenca e pagës",
  };

  const Com = () => (
    <div className="wageInfoContainer">
      {Object.entries(infoRows).map(([key, label]) => (
        <div className="wageInfoRow">
          <span className="infoLabel">{label}:</span>
          <span className="infoData">
            {key === "employeeSalaryPerHour"
              ? parseFloat(employeesData?.[key] || 0).toFixed(2)
              : employeesData?.[key]}
          </span>
        </div>
      ))}
      <div className="wageInfoRow">
        <span className="infoLabel">Orari automatik i paguar: </span>
        <span className="infoData">{displayShifts}</span>
      </div>
    </div>
  );
  return showPaymentInfo === true ? <Com /> : null;
}

export default WageInfo;
