import { CalculatorFilled, QuestionCircleOutlined } from "@ant-design/icons";
import {
  ArkitektIcon,
  EngenieringIcon,
  Flex,
  FlexDepIcon,
  IconDepartment,
  IT,
} from "../../../../assets/icons";
import "./kompania_pozicionet.scss";

//Zgjedh icon sipas emrit te departamentit
export const iconPickerDep = (depName) => {
  switch (depName) {
    case "IT & Software Development":
      return <IT className="White-it" />;
      break;
    case "Finance & Accounting":
      return (
        <CalculatorFilled
          className="White-finnance"
        //   style={{ fontSize: "20px", color: "#323338" }}
        />
      );
      break;
    case "Human Resources":
      return <IconDepartment className="White-it" width={22} height={20} />;
      break;
    case "Flex":
      return <Flex className="White-it" width={22} height={20} />;
      break;
    case "Civil Engineering":
      return <EngenieringIcon className="White-it" style={{ fontSize: "20px", color: "#1d3943" }} />;
      break;
    case "Architecture & Engineering":
      return <ArkitektIcon className="White-it" />;
      break;
    case "Administration":
      return <FlexDepIcon className="White-it" />;
      break;
    default:
      return <QuestionCircleOutlined />;
      break;
  }
};
