import React, { useEffect, useMemo, useState } from "react";
import { Select, Form, message, Spin, Switch } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import "./te_dhenat_akses.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../DriveRequest";
import { useMediaQuery } from "react-responsive";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import LoadableComp from "../../../../commonComponents/LoadableComp/LoadableComp";
import { useDispatch } from "react-redux";
import { users as usersReducer } from "../../../../../store/actions";
import AccessConfig from "./AccessConfig/AccessConfig";

const USER_ROLES_FIELD_ID = "c0156b01-161f-4333-97f3-9f2bdd6bc1fc";

function PerdoruesitTeDhenatAksesit() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { identityId } = useParams();

  const { programFields } = useSelector((state) => state.programFields);
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { users } = useSelector((state) => state.usersList);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const [user, setUser] = useState({});
  const [currentFile, setCurrentFile] = useState(null);
  const [spinning, setSpinning] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState({});

  const currentEmpl = useMemo(() => {
    if (!activeEmployees) return {};

    return activeEmployees.find?.((el) => el?.userSub === user?.userSub) || {};
  }, [activeEmployees, user]);

  const handleHeaderSave = () => {
    form
      .validateFields()
      .then(async (res) => {
        const toSend = {
          userRole: res?.userRole,
          auth2Fa: { ...(user?.auth2Fa || {}), is2FaActive: res?.is2FaActive },
        };
        await API.put("users", `/users/${user.identityId}`, {
          body: { ...toSend },
        })
          .then(() => {
            let newUsers = [...(users.allUsers.Items || [])];

            const index = newUsers.findIndex(
              ({ identityId }) => identityId === user.identityId
            );
            if (index > -1) {
              newUsers[index] = {
                ...newUsers[index],
                ...toSend,
              };
            }
            const toDispatch = {
              ...users,
              allUsers: { ...users.allUsers, Items: [...newUsers] },
            };
            dispatch(usersReducer(toDispatch));
            message.success({
              key: "headerSave",
              content: "Statusi u ndryshua me sukses",
            });
          })
          .catch((err) => console.log("Error PUT /users: ", err));
      })
      .catch((err) => console.log("Error validating fields: ", err));
  };

  const handleConfigSave = async (key, data) => {
    if (!!key && !!data?.length) {
      setSaving((prev) => ({ ...prev, [key]: true }));

      const toSend = {
        accessConfig: { ...user?.accessConfig, [key]: [...data] },
      };

      await API.put("users", `/users/${user?.identityId}`, {
        body: { ...toSend },
      })
        .then(async () => {
          let newUsers = [...(users.allUsers.Items || [])];

          const index = newUsers.findIndex(
            ({ identityId }) => identityId === user.identityId
          );
          if (index > -1) {
            newUsers[index] = {
              ...newUsers[index],
              ...toSend,
            };
          }
          const toDispatch = {
            ...users,
            allUsers: { ...users.allUsers, Items: [...newUsers] },
          };
          dispatch(usersReducer(toDispatch));
          message.success({
            key: "configSave",
            content: "Aksesi u dha me sukses",
          });
        })
        .catch((err) =>
          message.error({ key: "configSave", content: "Ndodhi nje gabim!" })
        );
      setSaving((prev) => ({ ...prev, [key]: false }));
    }
  };

  // const deleteItems = () => {
  //   API.del("users", `/users/${user.identityId}`).then((r) => {
  //     window.location.pathname = "/konfigurimet/siguria/perdoruesit";
  //   });
  // };

  const getImage = async () => {
    await driveRequest
      .getImageSrc(currentEmpl?.googleDriveFileId)
      .then((image) => {
        setCurrentFile(image?.src);
        setSpinning(false);
      });
  };
  const getUser = (id) => {
    const foundUser =
      (users?.allUsers?.Items || []).find((el) => el?.identityId === id) || {};
    setUser(foundUser);
    setLoading(false);
  };

  useEffect(() => {
    let timeout;
    if (!!currentEmpl?.googleDriveFileId) {
      getImage();
    } else {
      timeout = setTimeout(() => {
        setSpinning(false);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [currentEmpl]);

  useEffect(() => {
    if (!!identityId && !!users?.allUsers?.Items) {
      getUser(identityId);
    }
  }, [identityId, users]);

  const userRoles = useMemo(() => {
    if (!programFields) return [];
    const field = programFields?.find(
      ({ fieldId }) => fieldId === USER_ROLES_FIELD_ID
    );
    if (!field) return [];
    return field?.fieldOptions?.filter((el) => el !== user?.userRole);
  }, [programFields]);

  const employeeName = useMemo(
    () => (
      <div className="employeeName">
        <h3>{user?.given_name + " " + user?.family_name}</h3>
        <h1>{user?.userRole}</h1>
      </div>
    ),
    [user]
  );
  const dates = useMemo(
    () => (
      <>
        <div className="dataBlock">
          <h3>Data e regjistrimit në portal</h3>
          <h1>{dayjsTZ(user.createdAt).format("DD/MM/YYYY")}</h1>
        </div>
        <div className="dataBlock">
          <h3>Data e fundit në portal</h3>
          <h1>{dayjsTZ(user?.lastLoggedIn).format("DD/MM/YYYY , HH:mm")}</h1>
        </div>
      </>
    ),
    [user]
  );
  const isMobileView = useMediaQuery({
    query: "(max-width: 850px)",
  });

  //region RETURN
  return (
    <LoadableComp loading={loading}>
      <div className="globalPageWrapper">
        <div className="employeeAccesses">
          <div className="accessesHeader">
            <div className="employeePfp">
              <Spin spinning={spinning}>
                {!!currentFile && <img src={currentFile} alt="Flex" />}
                {!spinning && !currentFile && "No photo uploaded"}
              </Spin>
              {isMobileView ? employeeName : null}
              {isMobileView ? dates : null}
            </div>
            <div className="employeeData">
              <div className="dataBlock">
                {!isMobileView ? employeeName : null}
                <Form form={form}>
                  <Form.Item name="userRole">
                    <Select
                      placeholder="Selekto Rolin"
                      variant={false}
                      suffixIcon={<CaretDownOutlined />}
                      defaultValue={user?.userRole}
                    >
                      {userRoles?.map((el) => (
                        <Option key={el} value={el}>
                          {el}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="enable2FaSwitch">
                    <span>Mundëso 2FA</span>
                    <Form.Item name="is2FaActive">
                      <Switch defaultChecked={!!user?.auth2Fa?.is2FaActive} />
                    </Form.Item>
                  </div>
                  <MondayButton
                    className="mondayButtonGreen"
                    onClick={handleHeaderSave}
                  >
                    Konfirmo
                  </MondayButton>
                </Form>
              </div>
              <div className="dataBlock">
                <h3>Faqja më aktive</h3>
                <Link to="/kryefaqja">
                  <h1>Kryefaqja</h1>
                </Link>
                <div className="flexButtons">
                  <MondayButton
                    className="mondayButtonBlue"
                    onClick={() => navigate("/konfigurimet/perdoruesit")}
                  >
                    Të gjithë përdoruesit
                  </MondayButton>
                  <MondayButton disabled={true} className="mondayButtonRed">
                    Fshij përdoruesin
                  </MondayButton>
                </div>
              </div>
              {!isMobileView ? dates : null}
            </div>
          </div>
          <div className="accessesData">
            {/* <KryefaqjaAcessRights {...{ user, setUser }} />
          <RouteAcessRights {...{ user, setUser }} />
          <KonfigurimetAcessRights {...{ user, setUser }} /> */}

            <AccessConfig
              title={"Konfigurimi i Sidebar"}
              configs={user?.accessConfig?.sidebarConfig[0]}
              saving={saving}
              property={"sidebarConfig"}
              handleSave={handleConfigSave}
            />
            <AccessConfig
              title={"Konfigurimi i Kryefaqes"}
              configs={user?.accessConfig?.routeConfig}
              saving={saving}
              property={"routeConfig"}
              handleSave={handleConfigSave}
            />
            <AccessConfig
              title={"Konfigurimi i Settings"}
              configs={user?.accessConfig?.settingsConfig}
              saving={saving}
              property={"settingsConfig"}
              handleSave={handleConfigSave}
            />
            {/* <Cardkonfig
            contentChild={<KryefaqjaAcessRights {...{ user, setUser }} />}
            width="100%"
            height="100%"
            title={
              <div>
              <IconKryefaqja width={15} height={15} />
              &nbsp; Faqja Kryesore
              </div>
              }
              />
              <Cardkonfig
              contentChild={<RouteAcessRights {...{ user, setUser }} />}
              width="100%"
              height="100%"
              title={
                <div>
                <IconPagat width={15} height={15} />
                &nbsp; Route akses
                </div>
                }
                />
                <Cardkonfig
                contentChild={<KonfigurimetAcessRights {...{ user, setUser }} />}
                width="100%"
                height="100%"
                title={
                  <div>
                  <Iconkonfigurimet width={15} height={15} />
                  &nbsp; Konfigurimet
                  </div>
                  }
                  /> */}
          </div>
        </div>
      </div>
    </LoadableComp>
  );
}

export default PerdoruesitTeDhenatAksesit;
