const mobileRowIdsByKey = (obj, key) => {
  let array = [];
  Object.entries(obj || {}).forEach(([id, value]) => {
    if (!!value?.[key]) {
      array.push(id);
    }
  });
  return array;
};

export default mobileRowIdsByKey;