import { useEffect, useMemo, useState } from "react";
import "./MobileAgGrid.scss";
import Row from "./Row/Row";
import dayjsTZ from "../../../utils/dayjs";
import { Alert } from "antd";

const MobileAgGrid = ({
  className,
  rowData,
  selectedObj,
  setSelectedObj,
  idKey,
  columnDefs,
  rowClickKey,
  onRowClick = () => {},
  activeFilter,
  setTogglerUtils,
}) => {
  const [filteredTableData, setFilteredTableData] = useState([]);

  const getLabel = (field) => {
    const idx = colDefs.findIndex((col) => col?.field === field);
    if (idx !== -1) {
      const col = colDefs[idx];
      const label = col?.headerName;

      if (!!label) {
        return label;
      } else {
        return field;
      }
    } else {
      return field;
    }
  };

  const generateRow = (col, row) => {
    const key = col?.field;
    const data = row[key];

    const label = getLabel(key);
    let obj = { label };

    let rowClickParams = {};
    if (!!col?.cellClickKey && !!col?.onCellClick) {
      if (!!row[col?.cellClickKey]) {
        rowClickParams["clickId"] = row[col?.cellClickKey];
      }
    }
    if (!!col?.stateKeys?.length) {
      const states = [];
      col?.stateKeys?.forEach((key) => {
        if (!!row?.[key]) {
          states.push(row?.[key]);
        }
      });
      rowClickParams["states"] = states;
    }
    if (!!col?.onCellClick) {
      obj["onClick"] = () => col?.onCellClick(rowClickParams);
    }
    if (!!col?.isCollapseTitle) {
      obj["isCollapseTitle"] = true;
    }
    if (typeof data !== "boolean" && !data) {
      //will add more cases
      obj = { ...obj, value: col?.defaultValue || "-" };
    } else {
      if (!!col?.cellRenderer) {
        obj = { ...obj, value: col?.cellRenderer({ data: { ...row } }) };
      } else if (Array.isArray(data)) {
        obj = { ...obj, value: data.join(", ") };
      } else if (!!col?.format) {
        obj = { ...obj, value: dayjsTZ(data).format(col?.format) };
      } else {
        obj = { ...obj, value: data };
      }
    }

    return obj;
  };

  const customFilter = (key, value, data) => {
    if (!data || !value || !key) return data;
    else {
      const results = [];

      data.forEach((row) => {
        let values;
        if (key === "search") {
          values = Object.values(row.content || {})
            .map((obj) => obj.value)
            .filter((el) => typeof el === "string" || typeof el === "number");
        } else {
          values = Object.entries(row.content || {})
            .map(([keyName, obj]) => (keyName === key ? obj.value : null))
            .filter((el) => typeof el === "string" || typeof el === "number");
        }
        const doesMatch = values.some((el) =>
          el.toString().toLowerCase().includes(value.toString().toLowerCase())
        );
        if (!!doesMatch) {
          results.push(row);
        }
      });
      return results;
    }
  };

  const colDefs = useMemo(() => {
    if (!columnDefs) return [];
    return columnDefs?.filter((col) => !!col?.mobile) || [];
  }, [columnDefs]);

  const tableData = useMemo(() => {
    const data = [];

    (rowData || [])?.forEach((row) => {
      const clickId = row?.[rowClickKey || idKey];
      let rowObj = {
        selected: !!row?.selected,
        rowId: row[idKey],
        content: {},
        collapsed: true,
        ...(!!clickId ? { onClick: () => onRowClick(clickId, row) } : {}),
      };
      colDefs.forEach((col) => {
        rowObj.content[col?.field] = generateRow(col, row);
      });
      data.push(rowObj);
    });
    return data;
  }, [rowData, columnDefs]);

  const setData = (rowId, obj) => {
    let filtered = [...tableData];
    if (!rowId) {
      const filters = Object.entries(activeFilter || {});
      if (!!filters.length) {
        filters.forEach(([key, value]) => {
          filtered = customFilter(key, value, tableData);
        });
      }

      const selected = Object.entries(selectedObj || {});
      if (!!selected.length) {
        selected.forEach(([id, obj]) => {
          filtered = [...filtered].map((el) =>
            el.rowId === id ? { ...el, ...(obj || {}) } : el
          );
        });
      }
    } else {
      filtered = filteredTableData.map((el) =>
        el.rowId === rowId ? { ...el, ...(obj || {}) } : el
      );
      setSelectedObj((prev) => ({
        ...prev,
        [rowId]: { ...(prev?.[rowId] || {}), ...obj },
      }));
    }
    setFilteredTableData(filtered);
  };

  const toggleAll = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    setFilteredTableData((prev) =>
      prev.map((el) => ({ ...el, collapsed: value }))
    );
  };

  const toggled = useMemo(() => {
    return (filteredTableData || []).some((el) => !!el?.collapsed);
  }, [filteredTableData]);

  useEffect(() => {
    setTogglerUtils((prev) => ({ ...prev, toggled, toggleAll }));
  }, [toggled]);

  useEffect(() => {
    if (!!tableData) {
      setData();
    }
  }, [tableData, activeFilter]);

  return (
    <div
      className={`mobileAgGridWrapper ${className}`}
      data-testid="mobileAgGridWrapper"
    >
      <div className="mobileAgContent">
        {!!filteredTableData.length ? (
          filteredTableData.map((data) => <Row data={data} setData={setData} />)
        ) : (
          <Alert message="There is no data." type="warning" />
        )}
      </div>
    </div>
  );
};

export default MobileAgGrid;
