import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { processClockings } from "./process";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { NgjitjeIcon, ZbritjeIcon } from "../../../../../assets/icons";
import useReactIpLocation from "react-ip-details";
import { HollowDotsSpinner } from "react-epic-spinners";

function ClockingsLogs() {
  const { clockings } = useSelector((state) => state.clockings);
  const { employees } = useSelector((state) => state.employeesList);

  let usersList = useMemo(() => {
    if (!!clockings && !!employees) {
      return processClockings(clockings, employees);
    }
    return null;
  }, [clockings, employees]);
  const { ipResponse } = useReactIpLocation();

  // const onTest = () => {
  // 	console.log(`Full ip response $}`, ipResponse);
  // 	navigator.geolocation.getCurrentPosition(function (position) {
  // 		console.log("Exact position!", position.coords);
  // 	});
  // };

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return (
    <>
      {!!clockings && !!employees && !!ipResponse && !!usersList ? (
        <div
          className="wage-history-dashboard"
          style={{
            width: "100%",
            height: "100%",
            padding: 10,
            borderRadius: "0 0 10px 10px",
            overflowY: "scroll",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <button onClick={() => onTest()}>TEST</button> */}
            {usersList?.map((item, idx) => (
              <ListItem
                user={`${
                  (item?.employeeInfo?.name || "") +
                  " " +
                  (item?.employeeInfo?.surname || "")
                }`}
                type={item?.inOrOut === "in" ? "Clock In" : "Clock Out"}
                time={dayjsTZ(item?.time).format("HH:mm")}
                color={item?.color || "gray"}
                item={item}
              />
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1da193" size={24} />
        </div>
      )}
    </>
  );
}

export default ClockingsLogs;

const ListItem = ({ user, type, time, color, item }) => {
  return (
    <span
      className="historiku-turn-name"
      style={{
        display: "flex",
        lineHeight: "0.1em",
        margin: "10px 10px 10px 10px",
        alignItems: "center",
      }}
    >
      {type === "Clock In" ? <NgjitjeIcon /> : <ZbritjeIcon />} &nbsp;
      {user} bëri {type} në orën &nbsp;
      <span
        className="line-historia-turneve-dashboard"
        style={{
          display: "flex",
          flex: 1,
          paddingLeft: 10,
          paddingRight: 10,
          justifyContent: "center",
          alignItems: "center",
          padding: "auto",
          background:
            type === "Clock In"
              ? "linear-gradient(to right, #20AC9D, transparent)"
              : "linear-gradient(to right, #ea3943, transparent)",
          color: "#BCBCBC",
          height: "2px",
        }}
      >
        &nbsp;
      </span>
      <span
        className="lable-orari-historiku-turn"
        style={{
          display: "flex",
          paddingLeft: 10,
          paddingRight: 10,
          height: "100%",
          lineHeight: 0,
          background: "white",
          alignItems: "center",
        }}
      >
        {/* &nbsp; {time} */}
        <TimeLabel {...{ color, time, type }} />
      </span>
    </span>
  );
};

const TimeLabel = ({ color, time, type }) => {
  return (
    <span
      style={{
        width: 61,
        height: 21,
        background: type === "Clock In" ? "#20AC9D" : "#ea3943",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 2,
        color: "white",
        // color: type === "Clock In" ? "white" : null,
      }}
    >
      {time}
    </span>
  );
};
