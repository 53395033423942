import React, { useState } from "react";
import { Modal, TimePicker, message, Skeleton } from "antd";
import { approveShift, editShift } from "../utils/apiHelpers";
// import TextArea from "antd/lib/input/TextArea";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../../utils/dayjs";
import "./EditShiftModal.scss";
import { useAuth } from "../../../../../../authentication/authHelpers";
import {
  getDisabledAfterCustomHours,
  getDisabledAfterCustomMinutes,
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
} from "../../../../../dashboard/utils/disabledTimeFilters";
// import swal from "sweetalert";
import { ModalXIcon, WarningIcon } from "../../../../../../assets/icons";
import CrashShift from "../../../../../Pagat/approvals/crashShiftModal/CrashShift";
import SuccessComponent from "../../../../../commonComponents/SuccessComponent/SuccessComponent";
import { RichTextEditor } from "../../../../../commonComponents";
import { useSelector } from "react-redux";
import { Select } from "antd";
import { notificationToAllAdminAndSecifikUser } from "../../../../../../utils/sendNotification";
import useReactIpLocation from "react-ip-details";
import MondayButton from "../../../../../commonComponents/MondayButton/MondayButton";

const { Option } = Select;
function EditShiftModal({ open, setOpen, oldShift = {}, refreshData }) {
  const { ipResponse } = useReactIpLocation();
  const auth = useAuth();

  const { programFields } = useSelector((state) => state.programFields);
  const { users } = useSelector((state) => state.usersList);

  const [newShift, setNewShift] = useState(oldShift);
  const [saving, setSaving] = useState(false);
  const [crashError, setCrashError] = useState(false);
  const [crashData, setCrashData] = useState([]);
  const [successVisibility, setSuccessVisibility] = useState(false);

  //region HANDLE SAVE
  const handleSave = async () => {
    if (newShift?.clockInDate && oldShift !== null) {
      setSaving(true);

      const adminlog = {
        createdAt: dayjsTZ().valueOf(),
        oldShift: [{ ...oldShift, clockingLogs: [] }], // Empty clockingLogs because not needed & API constraints
        username: auth?.user?.given_name + " " + auth?.user?.family_name,
        userSub: auth?.user?.userSub,
        userRole: auth?.userRole,
        note: newShift?.note,
        ipResponse: ipResponse || null,
      };
      const toSend = {
        editedByAdmin: true,
        approved: !!newShift?.approved,
        clockInDate: dayjsTZ(newShift?.clockInDate).valueOf(),
        clockOutDate: newShift?.clockOutDate
          ? dayjsTZ(newShift?.clockOutDate).valueOf()
          : null,
        dayType: newShift?.dayType,
      };
      let adminlog2 = {
        approved: true,
        createdAt: dayjsTZ().valueOf(),
        userSub: auth?.user?.userSub,
        approvedBy: auth?.user?.given_name + " " + auth?.user?.family_name,
      };
      await editShift(
        oldShift.clockingId,
        toSend,
        oldShift.employeeId,
        adminlog
      )
        .then(async (editedShift) => {
          if (!!newShift?.approved) {
            await approveShift(editedShift, adminlog2)
              .then(() => {
                refreshData && refreshData([editedShift]);
                setSuccessVisibility(true);
              })
              .catch((err) => console.log("approveShift error", err));
          } else {
            refreshData && refreshData([editedShift]);
            setSuccessVisibility(true);
          }
        })
        .then(() => {
          if (newShift?.approved === true && oldShift.approved === false)
            notificationToAllAdminAndSecifikUser(
              {
                notificationTitle: "Turni u Aprovua",
                createdAt: dayjsTZ().format("DD/MM/YYYY"),
                notificationPriority: false,
                notificationBody: `${auth?.user?.given_name} ${
                  auth?.user?.family_name
                } aprovoi turnin e ${
                  newShift?.employee_details.employeeFirstName
                }  ${
                  newShift?.employee_details.employeeLastName
                } për datën ${dayjsTZ(newShift?.data).format("DD/MM/YYYY")}`,
                isAnnouncement: false,
                notificationUrl: "/aprovimet",
                notificationFirstName: auth?.user?.given_name,
                notificationLastName: auth?.user?.family_name,
              },
              (users || {}).allUsers?.Items?.filter(
                (el) =>
                  el?.userSub === newShift?.employee_details?.userSub ||
                  el?.userRole === "Admin" ||
                  el?.userRole === "Super Admin"
              ),
              (users || {}).allUsers?.Items?.find(
                (el) => el?.userSub === newShift?.employee_details?.userSub
              ),
              true
            );
        })
        .catch((errArr) => {
          setCrashError(true);
          setCrashData(errArr);
        });
      setSaving(false);
    } else {
      message.error(
        "Ka një gabim. Sigurohuni që të plotësoni të gjitha fushat."
      );
    }
  };

  // console.log(oldShift);
  //region HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
  };

  const toggleApprove = () => {
    if (!!newShift?.clockOutDate) {
      setNewShift((prev) => ({ ...prev, approved: !prev?.approved }));
    } else {
      message.error("Ju nuk mund të aprovoni një turn të pa përfunduar!");
    }
  };

  //region EDIT CLOCKIN
  const onEditClockIn = (e) => {
    let date = dayjsTZ(newShift?.clockInDate);

    if (!!e) {
      date = date
        .set("hour", e.get("hour"))
        .set("minute", e.get("minute"))
        .set("second", "00");

      if (newShift?.clockOutDate !== null) {
        if (
          dayjsTZ(date).valueOf() < dayjsTZ(newShift?.clockOutDate).valueOf()
        ) {
          setNewShift((prev) => ({ ...prev, clockInDate: date.valueOf() }));
        } else {
          message.error(
            "Fillimi i turnit nuk mund të jetë më vonë se mbarimi."
          );
        }
      } else {
        setNewShift((prev) => ({ ...prev, clockInDate: date.valueOf() }));
      }
    } else {
      setNewShift((prev) => ({ ...prev, clockInDate: null }));
    }
  };

  //region EDIT CLOCKOUT
  const onEditClockOut = (e) => {
    let date = dayjsTZ(newShift.clockInDate);

    if (!!e) {
      date = date
        .set("hour", e.get("hour"))
        .set("minute", e.get("minute"))
        .set("second", "00");

      if (newShift?.clockInDate !== null) {
        if (
          dayjsTZ(date).valueOf() > dayjsTZ(newShift?.clockInDate).valueOf()
        ) {
          setNewShift((prev) => ({ ...prev, clockOutDate: date.valueOf() }));
        } else {
          message.error(
            "Mbarimi i turnit nuk mund të jetë më shpejt se fillimi."
          );
        }
      }
    } else {
      setNewShift((prev) => ({ ...prev, clockOutDate: null }));
    }
  };

  //region RETURN
  return (
    <>
      <Modal
        destroyOnClose={true}
        width={758}
        className="change-shift lightHeader"
        title={`Ndrysho turnin e ${
          newShift?.employee_details?.employeeFirstName
        } ${newShift?.employee_details?.employeeLastName} | ${dayjsTZ(
          newShift?.data
        ).format("DD/MM/YYYY")}`}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={handleCancel}
        centered={true}
        closeIcon={<ModalXIcon />}
        footer={[
          <MondayButton
            key={1}
            testid="mbyllFaqen"
            className="mondayButtonRed"
            onClick={handleCancel}
          >
            Mbyll Faqen
          </MondayButton>,
          <MondayButton
            key={2}
            testid="ruajNdryshimet"
            className="mondayButtonGreen"
            onClick={handleSave}
            spin={saving}
          >
            Ruaj Ndryshimet
          </MondayButton>,
        ]}
      >
        {oldShift !== null ? (
          <div className="edit-shift-wrapper">
            <div className="warning-title">
              <span className="warn-icon">
                <WarningIcon />
              </span>

              <span style={{ color: "#ea3943" }}>
                Ju jeni duke ndryshuar një turn, siguroni të dhënat përpara se
                të ruani ndryshimet!
              </span>
            </div>
            <div className="to-edit-fields">
              <div className="shift-field-start">
                <label style={{ fontWeight: "600" }}>Fillimi i Turnit:</label>
                <TimePicker
                  value={
                    newShift?.clockInDate !== null
                      ? dayjsTZ(newShift?.clockInDate)
                      : null
                  }
                  placeholder="Fillimi"
                  data-testid="fillimi"
                  format="HH:mm"
                  onChange={onEditClockIn}
                  disabledTime={() => ({
                    disabledHours:
                      newShift?.clockOutDate !== null
                        ? () =>
                            getDisabledAfterCustomHours(newShift?.clockOutDate)
                        : () => [],
                    disabledMinutes:
                      newShift?.clockOutDate !== null
                        ? (hour) =>
                            getDisabledAfterCustomMinutes(
                              newShift?.clockOutDate,
                              hour
                            )
                        : () => [],
                  })}
                  showNow={false}
                  allowClear={false}
                />
              </div>
              <div className="shift-field-end">
                <label style={{ fontWeight: "600" }}>Mbarimi i Turnit: </label>
                <TimePicker
                  value={
                    newShift?.clockOutDate !== null
                      ? dayjsTZ(newShift?.clockOutDate)
                      : null
                  }
                  placeholder="Mbarimi"
                  data-testid="mbarimi"
                  format="HH:mm"
                  allowClear
                  disabledTime={() => ({
                    disabledHours:
                      newShift?.clockInDate !== null
                        ? () =>
                            getDisabledBeforeCustomHours(newShift?.clockInDate)
                        : () => [],
                    disabledMinutes:
                      newShift?.clockInDate !== null
                        ? (hour) =>
                            getDisabledBeforeCustomMinutes(
                              newShift?.clockInDate,
                              hour
                            )
                        : () => [],
                  })}
                  onChange={onEditClockOut}
                  showNow={false}
                  // allowClear={false}
                />
              </div>
              <div className="shift-field-end">
                <label style={{ fontWeight: "600" }}>Lloji i turnit: </label>

                <Select
                  className="daytype-select"
                  showSearch
                  value={newShift?.dayType}
                  onChange={(val) =>
                    setNewShift((prev) => ({ ...prev, dayType: val }))
                  }
                  placeholder="Zgjidh nje opsion"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {(
                    (programFields || []).find(
                      (el) => el.fieldName === "ClockIn Types"
                    ) || {}
                  )?.fieldOptions?.map((el, idx) => (
                    <Option key={`shiftType-${idx}`} value={el?.name}>
                      {el?.description}
                    </Option>
                  ))}
                </Select>
              </div>
              <MondayButton
                testid="approvalBtn"
                className={
                  newShift?.approved ? "mondayButtonRed" : "mondayButtonGreen"
                }
                onClick={toggleApprove}
              >
                {newShift?.approved === true ? "Hiq Aprovimin" : "Aprovo"}
              </MondayButton>
            </div>
            <div className="to-note">
              <label style={{ fontWeight: "600" }}>
                {" "}
                <strong style={{ color: "red" }}>*</strong>Shënimi:
              </label>
              <RichTextEditor
                className={"to-note-textarea"}
                value={newShift?.note}
                onChange={(e) => setNewShift((prev) => ({ ...prev, note: e }))}
              />
            </div>
            <div className="hours-generation" data-testid="totalHours">
              <span style={{ fontWeight: "600" }}>
                {`Orët totale: ${
                  dayjsTZ(newShift?.clockOutDate)
                    .diff(dayjsTZ(newShift?.clockInDate), "hours", true)
                    .toFixed(2) > 0
                    ? dayjsTZ(newShift?.clockOutDate)
                        .diff(dayjsTZ(newShift?.clockInDate), "hours", true)
                        .toFixed(2)
                    : 0
                } orë`}
              </span>
              <div className="animated-hours">
                <div
                  className="calculated-hours"
                  style={{
                    width: `${
                      dayjsTZ(newShift?.clockOutDate)
                        .diff(dayjsTZ(newShift?.clockInDate), "hours", true)
                        .toFixed(2) > 0 &&
                      dayjsTZ(newShift?.clockOutDate)
                        .diff(dayjsTZ(newShift?.clockInDate), "hours", true)
                        .toFixed(2) <= 8
                        ? (dayjsTZ(newShift?.clockOutDate)
                            .diff(dayjsTZ(newShift?.clockInDate), "hours", true)
                            .toFixed(2) /
                            8) *
                          100
                        : dayjsTZ(newShift?.clockOutDate)
                            .diff(dayjsTZ(newShift?.clockInDate), "hours", true)
                            .toFixed(2) > 8
                        ? 100
                        : 0
                    }%`,
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Skeleton />
            <Skeleton />
          </div>
        )}
      </Modal>
      {crashError ? (
        <CrashShift
          type={"edit"}
          crashData={crashData}
          open={crashError}
          setCrashError={setCrashError}
        />
      ) : null}
      {successVisibility ? (
        <SuccessComponent
          open={successVisibility}
          setOpen={setSuccessVisibility}
          text="Turni u ndryshua me sukses!"
          onOk={handleCancel}
        />
      ) : null}
    </>
  );
}

export default EditShiftModal;
