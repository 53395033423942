import React, { useEffect, useMemo, useState } from "react";
import { FlexLogo } from "../PaySub/assets/logo";
import "./PayStub.scss";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import { useDispatch, useSelector } from "react-redux";
// import { matchDepartamentWithEmployee } from "../approvals/utils";
import PayStubTable from "./payStubTable/PayStubTable";
import PayStubFinalTable from "./payStubTable/PayStubFinalTable";
import { Alert, Button, message, Popconfirm, Skeleton } from "antd";
import { FulfillingSquareSpinner } from "react-epic-spinners";
import {
  preparePaySub,
  prepareRequests,
  prepareSummaryEarnings,
} from "./employeeCalc/preparePaySub";
import {
  prepareAdditions,
  prepareDeductions,
} from "../wages/editables/deductAdd/calculations";
import { API } from "aws-amplify";
import { useAuth } from "../../../authentication/authHelpers";
import { wages as wagesDisp } from "../../../store/actions";
import { requestMultipleTables } from "../../../helpers/API/RequestFactory";
import { previewPrevious } from "./employeeCalc/previewPrevious";
import NewPayStub from "./previewPayStub/NewPayStub";
import { prepareTimesheetTable } from "./employeeCalc/timesheet";

function PayStub({
  payStubObj,
  componentRef,
  printTimesheet,
  viewType,
  timestance,
}) {
  const [firstTable, setFirstTable] = useState({});
  const [secondTable, setSecondTable] = useState({});
  const [thirdTable, setThirdTable] = useState({});
  const [fourthTable, setFourthTable] = useState({});
  const [fifthTable, setFifthTable] = useState({});
  const [finalTable, setFinalTable] = useState({});
  const [calculated, setCalculated] = useState(false);
  const [timesheetTable, setTimesheetTable] = useState({});

  const auth = useAuth();
  const dispatch = useDispatch();
  const { wages } = useSelector((state) => state.wages);
  const { programFields } = useSelector((state) => state.programFields);

  const { employees } = useSelector((state) => state.employeesList);
  // Old Value
  const [previousPaySub, setPreviousPaySub] = useState(null);
  const [alreadyHas, setAlreadyHas] = useState(false);
  const [restoredPayStub, setRestoredPayStub] = useState(null);
  const [wageStatus, setWageStatus] = useState("Draft");
  // const payStubObj = useMemo(() => {
  // 	if (!!previousPaySub) {
  // 		return previousPaySub;
  // 	} else {
  // 		return payStub;
  // 	}
  // }, [payStub]);

  const statusTypes = useMemo(() => {
    if (!!programFields) {
      const temp = programFields?.find((el) => el?.fieldName === "wageStatus");
      return !!temp ? temp?.fieldOptions || [] : [];
    } else return [];
  }, [programFields]);

  const onGenerateFinal = async () => {
    console.log("onGenerateFinal", payStubObj);
    if (restoredPayStub === null) {
      const {
        employeeFirstName,
        employeeLastName,
        employeeSalaryPerHour,
        employeePayrollType,
        employeeDepartmentName,
      } = payStubObj?.employeeInfo;
      await API.post("wages", "/wages", {
        body: {
          employeeId: payStubObj?.employeeInfo?.employeeId,

          hoursCalculations: {
            allhours: payStubObj?.allhours,
            allShiftsUser: payStubObj?.allShiftsUser,
          },
          earningsCalculations: payStubObj.earnings || [],
          holidays: payStubObj?.holidays,
          startPeriod: dayjsTZ(payStubObj?.momentInstance?.start).valueOf(),
          endPeriod: dayjsTZ(payStubObj?.momentInstance?.end).valueOf(),
          taxes: payStubObj?.taxes,
          prepayments: payStubObj?.prepayments,
          bruto: payStubObj?.bruto,
          requests: payStubObj?.requests,
          employeeInfo: {
            employeeFirstName,
            employeeLastName,
            employeeSalaryPerHour,
            employeePayrollType,
            employeeDepartmentName,
          },
          wageStatus: wageStatus,
          wageNote: payStubObj?.note || null,
          paymentType: [payStubObj?.employeeInfo?.employeePayrollType],
          issuedBy: {
            username: auth?.user?.given_name + " " + auth?.user?.family_name,
            full_name: `${auth?.userAccess[0]?.given_name} ${auth?.userAccess[0]?.family_name}`,
          },
        },
      })
        .then(async (success) => {
          message.success("U shtua me sukses!");
          await reloadDedAdd();
        })
        .catch((err) => {
          console.log("wage add error", err);
          message.error("Gabim ne gjenerimin e pages");
        });
    } else if (restoredPayStub !== null) {
      const wageId = restoredPayStub?.wageId;
      const {
        employeeFirstName,
        employeeLastName,
        employeeSalaryPerHour,
        employeePayrollType,
      } = restoredPayStub?.employeeInfo;
      await API.put("wages", `/wages/${wageId}`, {
        body: {
          // employeeId: payStubObj?.employeeInfo?.employeeId,

          hoursCalculations: {
            allhours: payStubObj?.allhours,
            allShiftsUser: payStubObj?.allShiftsUser,
          },
          earningsCalculations: payStubObj.earnings || [],

          holidays: payStubObj?.holidays,
          startPeriod: dayjsTZ(payStubObj?.momentInstance?.start).valueOf(),
          endPeriod: dayjsTZ(payStubObj?.momentInstance?.end).valueOf(),
          taxes: payStubObj?.taxes,
          prepayments: payStubObj?.prepayments,
          bruto: payStubObj?.bruto,
          requests: payStubObj?.requests,
          employeeInfo: {
            employeeFirstName,
            employeeLastName,
            employeeSalaryPerHour,
            employeePayrollType,
          },
          wageStatus: wageStatus,
          wageNote: payStubObj?.note || null,
          paymentType: [payStubObj?.employeeInfo?.employeePayrollType],
          issuedBy: {
            username: auth?.user?.given_name + " " + auth?.user?.family_name,
            full_name: `${auth?.userAccess[0]?.given_name} ${auth?.userAccess[0]?.family_name}`,
          },
        },
      })
        .then(async (success) => {
          message.success("U rigjenerua me sukses!");
          await reloadDedAdd();
        })
        .catch((err) => {
          console.log("wage add error", err);
          message.error("Gabim ne rigjenerimin e pages");
        });
    }
  };

  const reloadDedAdd = async () => {
    const getData = async () => {
      await requestMultipleTables(["wages"]).then((res) => {
        dispatch(wagesDisp(res[0]));
      });
    };
    await getData();
  };

  const onRegenerate = () => {
    setPreviousPaySub(null);
    setAlreadyHas(false);
  };

  const onDelete = async () => {
    if (!!restoredPayStub?.wageId) {
      await API.del("wages", `/wages/${restoredPayStub?.wageId}`)
        .then((res) => message.success("U fshi me sukses!"))
        .then(async () => {
          setPreviousPaySub(null);
          setAlreadyHas(false);
          await reloadDedAdd();
        });
    }
  };

  const onChangeStatus = (val) => {
    setWageStatus(val);
  };

  // const onDelete = async () => {
  // 	await API.del("wages", "/wages/52b2d500-cc7e-11ec-8c3e-5f3ca97ede55").then((res) =>
  // 		message.success("U fshi")
  // 	);
  // };

  useEffect(() => {
    if (!viewType) {
      if (!!payStubObj && !previousPaySub) {
        // console.log("paystub", payStubObj);
        setFirstTable(preparePaySub(payStubObj) || {});
        // setSecondTable(prepareRequests(payStubObj) || {});
        setSecondTable(
          payStubObj?.requests?.totalities?.earnings > 0
            ? payStubObj?.requests || {}
            : {}
        );
        setThirdTable(payStubObj?.taxes || {});
        setFourthTable(
          prepareDeductions(payStubObj?.prepayments?.deductions?.data || [])
        );
        setFifthTable(
          prepareAdditions(payStubObj?.prepayments?.earnings?.data || [])
        );
        setFinalTable(prepareSummaryEarnings(payStubObj) || {});
        setTimesheetTable(
          printTimesheet ? prepareTimesheetTable(payStubObj || []) || [] : {}
        );

        setTimeout(() => {
          setCalculated(true);
        }, 1600);
      } else if (alreadyHas === true && !!previousPaySub) {
        setCalculated(true);
        const temp = previewPrevious(previousPaySub, employees);
        console.log("temp", temp);
        setRestoredPayStub(temp);
        setFirstTable(preparePaySub(temp) || {});
        setSecondTable(temp?.requests || {});
        setThirdTable(temp?.taxes);
        setFourthTable(
          prepareDeductions(temp?.prepayments?.deductions?.data || [])
        );
        setFifthTable(
          prepareAdditions(temp?.prepayments?.earnings?.data || [])
        );
        setFinalTable(prepareSummaryEarnings(temp) || {});
        setTimesheetTable(
          printTimesheet ? prepareTimesheetTable(payStubObj || []) || [] : {}
        );
        setWageStatus(temp?.wageStatus);
      } else {
        setCalculated(true);
      }
    } else if (viewType === "Employee") {
      if (alreadyHas === true && !!previousPaySub) {
        setCalculated(true);
        const temp = previewPrevious(previousPaySub, employees);
        setRestoredPayStub(temp);
        setFirstTable(preparePaySub(temp) || {});
        setSecondTable(temp?.requests || {});
        setThirdTable(temp?.taxes);
        setFourthTable(
          prepareDeductions(temp?.prepayments?.deductions?.data || [])
        );
        setFifthTable(
          prepareAdditions(temp?.prepayments?.earnings?.data || [])
        );
        setFinalTable(prepareSummaryEarnings(temp) || {});
        setTimesheetTable(
          printTimesheet ? prepareTimesheetTable(payStubObj || []) || [] : {}
        );
        setWageStatus(temp?.wageStatus);
      } else {
        setCalculated(true);
      }
    }
  }, [payStubObj, alreadyHas, previousPaySub, printTimesheet, viewType]);

  useEffect(() => {
    if (!!wages) {
      if (wages.length > 0) {
        if (!viewType) {
          let temp = wages.filter(
            (el) =>
              dayjsTZ(el?.startPeriod).format("DD/MM/YYYY") ===
                dayjsTZ(payStubObj?.momentInstance?.start).format(
                  "DD/MM/YYYY"
                ) && el?.employeeId === payStubObj?.employeeInfo?.employeeId
          );
          if (temp.length > 0) {
            setFirstTable({});
            setSecondTable({});
            setThirdTable({});
            setFourthTable({});
            setFifthTable({});
            setFinalTable({});
            setTimesheetTable({});
            setPreviousPaySub(temp[0]);
            setAlreadyHas(true);
          } else {
            setPreviousPaySub(null);
            setAlreadyHas(false);
          }
        } else if (viewType === "Employee" && !!timestance) {
          let temp = wages.filter(
            (el) =>
              dayjsTZ(el?.startPeriod).format("DD/MM/YYYY") ===
                dayjsTZ(timestance?.start)
                  .startOf("month")
                  .format("DD/MM/YYYY") &&
              el?.employeeId === payStubObj?.employeeInfo?.employeeId
          );
          if (temp.length > 0) {
            setFirstTable({});
            setSecondTable({});
            setThirdTable({});
            setFourthTable({});
            setFifthTable({});
            setFinalTable({});
            setTimesheetTable({});
            setPreviousPaySub(temp[0]);
            setAlreadyHas(true);
          } else {
            setPreviousPaySub(null);
            setAlreadyHas(false);
          }
        }
      }
    }
  }, [wages, viewType, timestance]);

  //region RETURN
  return (
    <div ref={componentRef}>
      {/* <button onClick={() => onDelete()}>DELETE</button> */}
      {alreadyHas ? (
        <NewPayStub
          newObj={payStubObj}
          payStubObj={restoredPayStub}
          {...{
            calculated,
            componentRef,
            firstTable,
            secondTable,
            thirdTable,
            fourthTable,
            fifthTable,
            finalTable,
            alreadyHas,
            previousPaySub,
            onRegenerate,
            onGenerateFinal,
            timesheetTable,
            statusTypes,
            onChangeStatus,
            wageStatus,
            onDelete,
            viewType,
          }}
        />
      ) : (
        <NewPayStub
          newObj={payStubObj}
          {...{
            payStubObj,
            calculated,
            componentRef,
            firstTable,
            secondTable,
            thirdTable,
            fourthTable,
            fifthTable,
            finalTable,
            alreadyHas,
            previousPaySub,
            onRegenerate,
            onGenerateFinal,
            timesheetTable,
            statusTypes,
            onChangeStatus,
            wageStatus,
            onDelete,
            viewType,
          }}
        />
      )}
    </div>
  );
}

export default PayStub;
