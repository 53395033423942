import { Drawer } from "antd";
import React, { useMemo, useState } from "react";
import { Select } from "antd";
import "./FilterDrawer.scss";
import { useReportsData } from "../generate/reportContext/reportHelpers";
import { ModalXIcon } from "../../../assets/icons";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import { CloseCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

function FilterDrawer({
  visible,
  setVisible,
  contentData,
  resetCustom,
  onGenerate,
}) {
  const { drawerFilter, changeDrawerFilter } = useReportsData();

  const [filters, setFilters] = useState(drawerFilter || {});
  const [renderInitial, setRenderInitial] = useState(true);

  const onClearFilter = () => {
    resetCustom();
    setFilters({});
    changeDrawerFilter({});
    setRenderInitial(true);
    onGenerate({});
  };

  const options = useMemo(() => {
    if (!!Object.keys(filters).length) {
      const res = {};
      Object.entries(filters).forEach(([key, _]) => {
        let idx = contentData.columnsDef.indexOf(key);
        if (idx !== -1) {
          contentData.rowData.map((el, _) => {
            el?.map((e, id) => {
              if (id === idx) {
                res[key] = [...(res?.[key] || []), e];
              }
            });
          });
        }
      });
      const toReturn = {};
      Object.entries(res).forEach(([key, arr]) => {
        toReturn[key] = [...new Set(arr)].map((el, idx) => (
          <Option key={idx} value={el}>
            {el}
          </Option>
        ));
      });
      return toReturn;
    }
    return {};
  }, [filters, contentData]);

  //region RETURN
  return (
    <div>
      <Drawer
        width={700}
        title="Më shumë filtrime"
        placement="right"
        onClose={() => setVisible(false)}
        open={visible}
        closeIcon={<ModalXIcon />}
      >
        <div
          className="filter-item-wrapper"
          style={{
            marginLeft: 20,
            display: "flex",
            flexDirection: "column",
            gap: 30,
          }}
        >
          {Object.entries(filters)
            .filter(([_, val]) => !!val)
            .map(([item, filter]) => (
              <FilterItem
                {...{
                  item,
                  filter,
                  options,
                  setFilters,
                  onGenerate,
                  contentData,
                  setRenderInitial,
                }}
              />
            ))}
          {renderInitial ? (
            <FilterItem
              {...{
                options,
                filters,
                setFilters,
                onGenerate,
                contentData,
                renderInitial,
                setRenderInitial,
              }}
            />
          ) : null}
          <div className="action-btns">
            <MondayButton
              className="mondayButtonGreen"
              onClick={() => setRenderInitial(true)}
            >
              Shto filtrim
            </MondayButton>
            {!!Object.values(filters).length ? (
              <MondayButton className="mondayButtonRed" onClick={onClearFilter}>
                Pastro Filtrimin
              </MondayButton>
            ) : null}
            {!!Object.values(filters).length ? (
              <MondayButton
                className="mondayButtonGreen"
                onClick={() => {
                  onGenerate(filters);
                  changeDrawerFilter(filters);
                }}
              >
                Gjenero
              </MondayButton>
            ) : null}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default FilterDrawer;

//region <FilterItem />
const FilterItem = ({
  contentData,
  options,
  filters,
  setFilters,
  item,
  filter,
  setRenderInitial,
  onGenerate,
}) => {
  const { drawerFilter, changeDrawerFilter } = useReportsData();

  const [field, setField] = useState(item);
  const [selected, setSelected] = useState(filter);

  const clearFilter = () => {
    setFilters((prev) => {
      const newData = Object.fromEntries(
        Object.entries(prev || {}).filter(([key, _]) => key !== item)
      );
      Object.keys(drawerFilter || {}).length && onGenerate(newData);
      if(!Object.keys(newData || {}).length){
        setRenderInitial(true);
        changeDrawerFilter({});
      }
      return newData;
    });
  };

  return (
    <div className="filter-item-drawer">
      <span>Filtrimi</span>
      <Select
        placeholder={"Zgjidh një fushë filtrimi..."}
        style={{
          width: 230,
        }}
        value={field || item || null}
        onChange={(e) => {
          setField(e);
          setFilters((prev) => ({ ...prev, [e]: null }));
        }}
        showSearch
      >
        {contentData?.columnsDef
          .filter((f) => f !== "" && !Object.keys(filters || {}).includes(f))
          .map((el) => (
            <Option value={el}>{el}</Option>
          ))}
      </Select>
      {!!field || !!filter ? (
        <Select
          style={{
            width: 230,
          }}
          placeholder={"Zgjidh Vlerën"}
          defaultValue={field !== item || !selected ? null : selected}
          mode="multiple"
          onChange={(e) => setSelected(e)}
          onBlur={() => {
            setFilters((prev) => ({ ...prev, [field]: selected }));
            setRenderInitial(false);
          }}
          showSearch
        >
          {options[field]}
        </Select>
      ) : null}
      {!!field ? (
        <div onClick={clearFilter}>
          <CloseCircleOutlined
            style={{ fontSize: 16, color: "#ea3943", cursor: "pointer" }}
          />
        </div>
      ) : null}
    </div>
  );
};
