import React, { useEffect, useState } from "react";
import "../vacations.scss";
import { Row, Col, Badge, Skeleton } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../../utils/dayjs";

const EmployeesList = ({ employeesData, leave }) => {
  const [onVacNow, setOnVacNow] = useState([]);
  const [calculated, setCalculated] = useState([]);
  //Percakton ngjyren sipas llojit te pushimeve
  const colorVacations = (type) => {
    let color = "";
    switch (type) {
      case "Pushime":
        color = "#1da193";
        break;
      case "Dite Personale":
        color = "#858585";
        break;
      case "Leje lindje":
        color = "#DE8EBD";
        break;
      default:
        color = "#ea3943";
    }
    return color;
  };

  //Ditet e pushimeve te mbetura per cdo punonjes
  const getRemainingDays = (objectItem) => {
    let now = dayjsTZ().startOf("day"); //Data e sotme
    let futureDate =
      objectItem?.requestType === "Dite Personale"
        ? dayjsTZ(objectItem?.requestPeriod[0]).add(1, "days") //Dita e fundit e lejes
        : dayjsTZ(objectItem?.requestPeriod.slice(-1)[0]);
    let days = futureDate.diff(now, "days"); //Diferenca midis dites se fundit te pushimeve dhe dates se sotme
    return days;
  };

  useEffect(() => {
    //Merr lejet qe jane diten e sotme
    if (leave?.length >= 0 && employeesData?.length >= 0) {
      let onVac = leave.filter((l) => {
        if (l?.requestStatus === "Aprovuar") {
          if (l?.requestType === "Dite Personale") {
            if (
              dayjsTZ(l?.requestPeriod[0]).format("DD/MM/YYYY") ===
              dayjsTZ().format("DD/MM/YYYY")
            ) {
              return true;
            }
            return false;
          } else {
            if (
              l?.requestPeriod[0] <= dayjsTZ().valueOf() &&
              l?.requestPeriod.slice(-1)[0] > dayjsTZ().valueOf()
            ) {
              return true;
            }
            return false;
          }
        }
      });
      setOnVacNow(onVac);
    }
  }, [leave, employeesData]);
  useEffect(() => {
    if (onVacNow?.length >= 0 && employeesData?.length >= 0) {
      //Per cdo user qe eshte me pushime aktualisht, zgjidhen ata qe kane userSub te sakte
      // dhe merr emrin, mbiemrin, ditet e mbetura te pushimeve
      let test = onVacNow.map((el) => {
        let filterItem = employeesData.filter(
          (emp) => emp?.userSub === el?.userSub
        );
        if (filterItem?.length >= 0) {
          return {
            ...el,
            employeeFirstName: filterItem[0]?.employeeFirstName,
            employeeLastName: filterItem[0]?.employeeLastName,
            remainingDays: getRemainingDays(el),
          };
        }
        return el;
      });
      setCalculated(test);
    }
  }, [onVacNow, employeesData]);
  return (
    <>
      {onVacNow?.length >= 0 && employeesData?.length >= 0 ? (
        <div className="employees-list">
          <div className="list-title">
            {onVacNow.length} punonjës me pushime
          </div>
          <Row className="emp-days">
            <Col span={12}>Punonjësit</Col>
            <Col className="remain-text" span={12}>
              Ditë të mbetura
            </Col>
          </Row>
          <div className="emp-days-container">
            <div className="emp-days-item">
              {calculated?.map((el) => (
                <div key={el?.employeeId}>
                  <Badge
                    className="employee-badger"
                    color={colorVacations(el?.requestType)}
                    text={el.given_name + " " + el?.family_name}
                  />
                  <span className="remaining-days">
                    {el?.remainingDays} ditë
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Skeleton />
      )}
    </>
  );
};
export default EmployeesList;
