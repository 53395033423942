import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import StatusesPanel from "./StatusesPanel/StatusesPanel";
import "./DocumentationPage.scss";
import { documentationsPageColDef } from "./documentationPageData";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import { clearFilter, onFilter } from "./utils/onFilter";
import useObserver from "./hooks/useObserver";
import { uniqBy } from "lodash";
import { apiRoutes } from "../../apiRoutes";
import { fetchData } from "../../fetchData";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { API } from "aws-amplify";
import AgGridComponent from "../AG-grid/AgGridComponent";

// * @EneaXharau -  Websocket handler and listener
// const socketHandler = new WebSocket(getSocketUrl("/documentation/sockets"));

const EXPORT_COLS = [
  { en: "docType", al: "Lloji" },
  { en: "docStatus", al: "Statusi" },
  { en: "name", al: "Punonjësi" },
  { en: "employeeDepartmentName", al: "Departamenti" },
  { en: "employeeRoleName", al: "Roli" },
  { en: "notes", al: "Shënime" },
  { en: "createdAt", al: "Krijuar në", format: "DD/MM/YYYY" },
  { en: "expirationDate", al: "Data e Skadencës", format: "DD/MM/YYYY" },
];
const ITEMS = {
  search: {},
  icons: {
    filter: {
      filterKeys: [
        { key: "name", label: "Punonjesi", type: "string" },
        {
          key: "employeeDepartmentName",
          label: "Departamenti",
          type: "string",
        },
        { key: "employeeRoleName", label: "Roli", type: "string" },
      ],
    },
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};

const DocumentationPage = () => {
  const navigate = useNavigate();

  const { employees } = useSelector((state) => state.employeesList);
  const { accessToken } = useSelector((state) => state.accessToken);

  const [gridApi, setGridApi] = useState(null);
  const [documentations, setDocumentations] = useState([]);
  const [docConfig, setDocConfig] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [gridData, setGridData] = useState([]);

  const gridContainerRef = useRef();
  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  useObserver({
    element: gridContainerRef,
    callback: () => {
      !!gridApi && gridApi.sizeColumnsToFit();
    },
  });

  //* maps all documents uploaded with all their info and shows them at DocumentationPage
  const statuses = useMemo(
    () =>
      uniqBy(
        docConfig
          .flatMap(({ documentationsAvailable }) => documentationsAvailable)
          .flatMap(({ docStatuses = [] }) => docStatuses),
        "statusName"
      ),

    [docConfig]
  );

  //* gets statuses from docConfiguration and shows them at DocumentationPage
  const populatedStatuses = useMemo(
    () =>
      statuses.map((el) => ({
        ...el,
        value: documentations.filter(
          ({ docStatus }) => docStatus === el?.statusName
        ).length,
      })),
    [statuses, documentations]
  );

  const populatedColDefs = documentationsPageColDef({
    navigate,
    accessToken,
    statuses: statuses.map(({ statusName: status, statusColor: color }) => ({
      status,
      color,
    })),
    employees,
    isMobileView,
  });

  //region ROW DATA
  // const populateRowData = () => {
  //   // return !selectedCategory

  //   ''  ? documentations
  //     : documentations
  //         .filter(({ categoryName }) => categoryName === selectedCategory)
  //         .reduce((acc, curr = {}, _, arr) => {
  //           const { recordId, recordName, categoryName } = curr;
  //           const filteredArr = arr.filter(
  //             ({ recordId: arrRecordId }) => arrRecordId === recordId
  //           );
  //           return !!acc
  //             .map(({ recordId: accRecordId }) => accRecordId)
  //             .includes(recordId)
  //             ? acc
  //             : [
  //                 ...acc,
  //                 {
  //                   recordId,
  //                   recordName,
  //                   categoryName,
  //                   allGoogleDriveUploads: filteredArr.map(
  //                     ({ googleDriveUploads, docType }) => ({
  //                       docType,
  //                       googleDriveUploads,
  //                     })
  //                   ),
  //                   missingFiles: filteredArr
  //                     .filter(
  //                       ({ googleDriveUploads = [] }) =>
  //                         !googleDriveUploads.length
  //                     )
  //                     .map(({ docType }) => docType),
  //                   nearestExpDate: min(
  //                     filteredArr
  //                       .filter(
  //                         ({ expirationDate }) =>
  //                           expirationDate > dayjsTZ().valueOf()
  //                       )
  //                       .map(({ expirationDate }) => expirationDate)
  //                   ),
  //                 },
  //               ];
  //         });
  // };

  /* shows all data of an documentation uploaded
    at DocumentationView --------------------------------- commented out and changed to state to make this future proof*/
  // const gridData = !selectedCategory
  // 	? documentations
  // 	: documentations
  // 			.filter(({ categoryName }) => categoryName === selectedCategory)
  // 			.reduce((acc, curr = {}, _, arr) => {
  // 				const { recordId, recordName, categoryName } = curr;

  // 				const filteredArr = arr.filter(
  // 					({ recordId: arrRecordId }) => arrRecordId === recordId
  // 				);
  // 				return !!acc
  // 					.map(({ recordId: accRecordId }) => accRecordId)
  // 					.includes(recordId)
  // 					? acc
  // 					: [
  // 							...acc,
  // 							{
  // 								recordId,
  // 								recordName,
  // 								categoryName,
  // 								allGoogleDriveUploads: filteredArr.map(
  // 									({ googleDriveUploads, docType }) => ({
  // 										docType,
  // 										googleDriveUploads,
  // 									})
  // 								),
  // 								missingFiles: filteredArr
  // 									.filter(
  // 										({ googleDriveUploads = [] }) =>
  // 											!googleDriveUploads.length
  // 									)
  // 									.map(({ docType }) => docType),
  // 								nearestExpDate: min(
  // 									filteredArr
  // 										.filter(
  // 											({ expirationDate }) =>
  // 												expirationDate > dayjsTZ().valueOf()
  // 										)
  // 										.map(({ expirationDate }) => expirationDate)
  // 								),
  // 							},
  // 					  ];
  // 			});'

  //region AG GRID
  // * @EneaXharau - Promise function to fetch data for tables: documentation and docConfiguration and set them in state

  const fetchDataRefresh = () => {
    Promise.allSettled([
      fetchData(apiRoutes.documentation),
      fetchData(apiRoutes.docConfiguration),
    ]).then(([{ value: docRes }, { value: docConfigRes }]) => {
      setDocumentations(docRes);
      setDocConfig(docConfigRes);
      setLoading(false);
    });
  };

  const onStatusCardClick = (statusName) => {
    setActiveFilter((currFilter) =>
      currFilter === statusName ? "" : statusName
    );
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const changeExpirationStatus = async (doc, status) => {
    if (!!doc && !!status) {
      await API.put("documentation", `/documentation/${doc?.docId}`, {
        body: {
          docStatus: status,
        },
      })
        .then(() => {})
        .catch((err) => console.log("Error changing expiration status:", err));
    } else console.log("Invalid arguments!");
  };

  useEffect(() => {
    fetchDataRefresh();
  }, []);

  useEffect(() => {
    !!gridApi &&
      (!!activeFilter
        ? onFilter({ gridApi, column: "docStatus", current: activeFilter })
        : clearFilter({ gridApi, column: "docStatus" }));
  }, [activeFilter]);

  useEffect(() => {
    gridData?.forEach((el) => {
      if (!!el?.expirationDate && el?.expirationDate < Date.now()) {
        changeExpirationStatus(el, "Skaduar");
      }
    });

    if (!!gridApi) {
      gridApi.redrawRows();
      gridApi.sizeColumnsToFit();
    }
  }, [gridData]);

  useEffect(() => {
    setGridData(
      documentations
        ?.map((el) => {
          for (let i = 0; i < employees?.length; i++) {
            const employee = employees[i];
            if (employee?.employeeId === el?.recordId) {
              return {
                ...el,
                name: `${employee?.employeeFirstName} ${employee?.employeeLastName}`,
                employeeDepartmentName: `${employee?.employeeDepartmentName}`,
                employeeRoleName: `${employee?.employeeRoleName}`,
              };
            }
          }
        })
        .filter(Boolean)
    );
  }, [employees, documentations]);

  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // useEffect(() => {
  //   socketHandler.onmessage = (msg) => {
  //     const { request, body } = JSON.parse(msg.data);
  //     switch (request) {
  //       case "document_added_received": {
  //         fetchDataRefresh();
  //         break;
  //       }
  //       case "document_removed_received": {
  //         fetchDataRefresh();
  //         break;
  //       }
  //       case "document_changed_received": {
  //         fetchDataRefresh();
  //         break;
  //       }
  //       default:
  //         break;
  //     }
  //   };
  //   return () => socketHandler.close();
  // }, []);

  //region RETURN
  return (
    <div className="globalPageWrapper">
      {/* {isMobileView ? ( */}
      <div className="documentationPageWrapper">
        <LoadableComp loading={loading}>
          {!!populatedStatuses?.length ? (
            <StatusesPanel
              {...{
                data: populatedStatuses,
                onClick: onStatusCardClick,
              }}
            />
          ) : null}
          <AgGridComponent
            gridApi={gridApi}
            columnDefs={populatedColDefs}
            rowData={gridData}
            rowSelection={{ mode: "multiRow" }}
            customDefaultColDef={{
              enablePivot: true,
              enableColResize: true,
              enableRowGroup: true,
              editable: false,
              sortable: true,
              flex: 1,
              minWidth: 100,
              filter: true,
            }}
            paginationPageSize={11}
            onGridReady={onGridReady}
            rowHeight={45}
            headerHeight={45}
            ref={gridContainerRef}
            paginator={true}
            headerProps={{
              items: ITEMS,
              exportTitle: "Lista e Dokumenteve",
              setRowData: setGridData,
            }}
            // setRowData={setGridData}
            rowClickKey="recordId"
            onRowClick={(rowId) => navigate(`/punonjesit/specifikat/${rowId}`)}
            idKey="docId"
          />
        </LoadableComp>
      </div>
      {/* ) : (
        <div className="base-mobile-div">
          <MobileHtmlTabel
            header={["Emri", "Lloj Doc.", "Statusi", "Krijuar", "Skadon"]}
            tableData={gridData?.map?.(
              ({
                name = "",
                docType,
                docStatus,
                createdAt,
                expirationDate,
              }) => ({
                name,
                statusHeaderKey: docType,
                docStatus,
                createdAt: dayjsTZ(createdAt).format("DD/MM/YYYY"),
                expirationDate: dayjsTZ(expirationDate).format("DD/MM/YYYY"),
              })
            )}
            isHeader={true}
            isLink={false}
          />
        </div>
      )} */}
      {/* <DokumentacionePrint tableData={gridData} componentRef={componentRef} /> */}
    </div>
  );
};
export default DocumentationPage;
