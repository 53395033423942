import { useMediaQuery } from "react-responsive";
import colorShade from "../../utils/colorShade";
import dayjsTZ from "../../utils/dayjs";
import getAgThemePreference from "../../utils/getAgThemePreference";

const { mode, accentColor } = getAgThemePreference();

const colDefs = (navigate, isMobileView, renderConditions) =>
  [
    {
      headerName: "ID e punonjesit",
      field: "employeeId",
      hide: true,
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      // filter: "agTextColumnFilter",
      // valueGetter: "node.rowIndex + 1",
      // resizable: true,
      // flex: 1.2,
    },
    {
      headerName: "Punonjësi",
      field: "employeeFullName",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 2,
      mobile: true,
      isCollapseTitle: true,
      cellClickKey: "employeeId",
      onCellClick: ({ clickId }) =>
        navigate(`/punonjesit/specifikat/${clickId}`),
      ...(!isMobileView
        ? {
            cellRenderer: function ({ data }) {
              try {
                return (
                  <div
                    className="navigate-row"
                    onClick={() => {
                      navigate(`/punonjesit/specifikat/${data?.employeeId}`);
                    }}
                    style={
                      mode.includes("dark")
                        ? { color: "#FFF" }
                        : { color: colorShade(accentColor, 0.25) }
                    }
                  >
                    {data?.employeeFullName}
                  </div>
                );
              } catch (err) {
                if (data?.employeeFullName) {
                  return data?.employeeFullName;
                } else {
                  return null;
                }
              }
            },
          }
        : {}),
    },
    {
      headerName: "Departamenti",
      field: "employeeDepartmentName",
      filter: "agTextColumnFilter",
      flex: 3,
      resizable: true,
      mobile: true,
      //can be done
      // cellClickKey: "employeeDepartmentId",
      // onCellClick: ({ clickId }) =>
      //   navigate(`/dep/${clickId}`),
      onCellClick: () => navigate(`/departamentet`),
    },
    {
      headerName: "Dega",
      field: "employeeTeam",
      filter: "agTextColumnFilter",
      flex: 3,
      resizable: true,
      mobile: true,
    },
    {
      headerName: "Roli",
      field: "employeeRoleName",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
      mobile: true,
      isCollapseTitle: true,
      onCellClick: () => navigate(`/departamentet`),
    },
    {
      headerName: "Email punës",
      field: "employeeEmailWork",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
      mobile: true,
    },
    {
      headerName: "Data Punësimit",
      field: "employeeHireDate",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 2,
      mobile: true,
      format: "DD/MM/YYYY",
      cellRenderer: function ({ data }) {
        if (data?.employeeHireDate !== undefined) {
          return dayjsTZ(data?.employeeHireDate).format("DD/MM/YYYY");
        } else {
          return <div>Pa përcaktuar</div>;
        }
      },
    },
    {
      headerName: "Data Përfundimit",
      field: "employeeContractEndDate",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 2,
      mobile: true,
      format: "DD/MM/YYYY",
      cellRenderer: function ({ data }) {
        if (data?.employeeContractEndDate !== undefined) {
          return dayjsTZ(data?.employeeContractEndDate).format("DD/MM/YYYY");
        }
      },
    },
    {
      headerName: "Lloji i punësimit",
      field: "employeeTypeOfEmployment",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
      hide: true,
    },
    {
      headerName: "createdAt",
      field: "createdAt",
      flex: 2.5,
      sort: "desc",
      filterParams: { apply: true, newRowsAction: "keep" },
      resizable: true,
      hide: true,
    },
    {
      headerName: "Emri",
      field: "employeeFirstName",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
      hide: true,
    },
    {
      headerName: "Mbiemri",
      field: "employeeLastName",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
      hide: true,
    },
    { headerName: "Status", field: "employeeStatus", hide: true },
  ].map((el) => ({
    ...el,
    hide: el.hasOwnProperty("hide") ? el.hide : renderConditions?.[el?.field],
    ...(el.hasOwnProperty("mobile")
      ? renderConditions.hasOwnProperty(el?.field)
        ? { mobile: !renderConditions?.[el?.field] }
        : { mobile: el.mobile }
      : {}),
  }));

export default colDefs;
