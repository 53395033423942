import { API } from "aws-amplify";
import moment from "moment";
import dayjsTZ from "../utils/dayjs";
import dayjs from "dayjs";
import {
  requestAllPages,
  requestData,
  requestDataWithParams,
} from "../helpers/API/RequestFactory";
import axios from "axios";
import { message } from "antd";

function isWeekend(date = new Date()) {
  return date.getDay() === 6 || date.getDay() === 0;
}

export const automateRequestsToApprovals = async (userData, ipResponse) => {
  let weekendCond = isWeekend();

  if (!weekendCond) {
    const employees = await API.get("employees", "/employees").then(
      (res) => res
    );
    let applicableShifts = await API.get("shifts", "/shifts").then(
      (res) => res
    );
    // console.log("employees", { applicableShifts, employees });
    let requests = await requestDataWithParams(
      "employeeRequests",
      dayjsTZ().subtract(1, "years").valueOf()
    ).then((res) => {
      // console.log("res", res);
      return res
        ?.filter((reqs) => {
          if (reqs?.requestStatus === "Aprovuar") {
            let existsToday = reqs?.requestPeriod?.find(
              (el) =>
                dayjsTZ(el).format("DD/MM/YYYY") ===
                dayjsTZ().format("DD/MM/YYYY")
            );
            const time10AM = dayjsTZ().set("hour", 10).set("minute", 0);
            //check if it is after 8am
            let isAfter10am = dayjsTZ().isAfter(time10AM);
            let hasConfirmed = reqs?.lastCreated || null;
            // let hasConfirmed = null;

            if (!!existsToday && isAfter10am) {
              if (!!hasConfirmed) {
                if (hasConfirmed !== dayjsTZ().format("DD/MM/YYYY")) {
                  return true;
                }
              } else {
                return true;
              }
            }
          } else return false;
        })
        .map((el) => {
          let selectedEmployee =
            employees?.find((employee) => employee?.userSub === el?.userSub) ||
            null;
          let employeeShift = selectedEmployee?.employeeShiftId?.[0] || null;
          return {
            ...el,
            shift:
              applicableShifts?.find(
                (shift) => shift?.shiftId === employeeShift
              ) || null,
            employee: selectedEmployee,
            employeeShift,
          };
        });
    });
    // console.log("requests", requests);
    if (requests?.length === 0) {
      return Promise.reject("No requests to convert");
    } else {
      return requests?.forEach(async (employeeReq) => {
        await createNewShift(employeeReq, userData, ipResponse).then(
          async (res) => {
            await markCreated(employeeReq);
          }
        );
      });
    }
  } else {
    return Promise.reject("Weekend");
  }
};

export const createNewShift = async (emplReq, userData, ipResponse) => {
  const employeeId = emplReq?.employeeId || emplReq?.employee?.employeeId;
  if (!employeeId) {
    message.error("Mungon ID e punonjesit");
    // console.log("Mungon ID e punonjesit", emplReq);
    return Promise.reject("Mungon ID e punonjesit");
  } else {
    console.log("emplReq?.shift", emplReq?.shift);
    const res = await axios.get("https://api.ipify.org/?format=json");
    const tempResp = ipResponse || {};
    tempResp.IPv4 = res?.data?.ip || "";

    let startShift = !!emplReq?.shift?.shiftStartTime
      ? dayjsTZ(emplReq?.shift?.shiftStartTime)
      : dayjsTZ().set("hour", 13).set("minute", 30);
    let endShift = !!emplReq?.shift?.shiftEndTime
      ? dayjsTZ(emplReq?.shift?.shiftEndTime)
      : dayjsTZ().set("hour", 21).set("minute", 30);

    const [s_hour, s_minute] = [startShift.hour(), startShift.minute()];
    const [e_hour, e_minute] = [endShift.hour(), endShift.minute()];

    const toSend = {
      clockInDate: dayjsTZ()
        .set("hour", s_hour)
        .set("minute", s_minute)
        .valueOf(),
      clockOutDate: dayjsTZ()
        .set("hour", e_hour)
        .set("minute", e_minute)
        .valueOf(),
      employeeId,
      clockingCategory: "manualAdmin",
      dayType: "HolidayRequest",
      clockInNote: "",
      clockOutNote: "",
      editedClockIn: false,
      editedClockOut: false,
      editedByAdmin: true,
      forgotClockOut: false,
      approved: false,
      clockingLogs: [
        {
          createdAt: dayjsTZ().valueOf(),
          note: "Krijuar ne menyre automatike",
          newShiftByAdmin: true,
          username:
            userData?.user?.given_name + " " + userData?.user?.family_name,
          userSub: userData?.user?.userSub,
          ipResponse: tempResp || null,
        },
      ],
    };
    return await API.post("clocking", "/clocking", {
      body: {
        ...toSend,
      },
    }).then(async (res) => {
      return await markCreated(emplReq);
    });
  }
};

export const markCreated = async (emplReq) => {
  return await API.put(
    "employeeRequests",
    `/employeeRequests/${emplReq?.requestId}`,
    {
      body: {
        lastCreated: dayjsTZ().format("DD/MM/YYYY"),
      },
    }
  );
};
