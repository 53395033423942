import { PlusCircleFilled, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Typography,
  DatePicker,
  Modal,
  TimePicker,
} from "antd";
import { add, divide, multiply, subtract } from "lodash";
import moment from "moment-timezone";
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useLayoutEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusIcon } from "../../../../assets/icons";
import { useAuth } from "../../../../authentication/authHelpers";
import { requestMultipleTables } from "../../../../helpers/API/RequestFactory";
import { prepayments } from "../../../../store/actions";
import deductionsReducer from "../../../../store/reducers/deductions";
import earningsReducer from "../../../../store/reducers/earnings";
import ShiftModal from "../../../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftModal";
import { paySubFactory } from "../../PaySub/employeeCalc/employeeSubFactory";
import Additionals from "../../wages/editables/deductAdd/Additionals";
import {
  addPrepayment,
  editPrepayment,
  onDeletePrepayment,
  validatePrevious,
} from "../../wages/editables/deductAdd/api";
import { getSummary } from "../../wages/editables/deductAdd/calculations";
import Deductions from "../../wages/editables/deductAdd/Deductions";
import Summary from "../../wages/editables/deductAdd/Summary";
import CustomWarnings, {
  AmountCard,
} from "../../wages/warnings/CustomWarnings";
import DayPayHeader from "./DayPayHeader";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
const { Option } = Select;

function ActionsDay({
  employeeId,
  timeperiod,
  setVisible,
  empInfo,
  data,
  getShiftData,
}) {
  const [currentMode, setCurrentMode] = useState("additions");
  const [deductionsData, setDeductionsData] = useState([]);
  const [additionsData, setAdditionsData] = useState([]);
  const [salaryPerHour, setSalaryPerHour] = useState(0);
  const { earnings } = useSelector((state) => state.earnings);
  const { deductions } = useSelector((state) => state.deductions);
  const { clockings } = useSelector((state) => state.clockings);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { programFields } = useSelector((state) => state.programFields);
  const [earningsAddi, setEarningsAddit] = useState([]);
  const [hasPrev, setHasPrev] = useState({
    has: false,
    data: null,
    editMode: false,
  });
  const [loading, setLoading] = useState(false);
  const [realTime, setRealTime] = useState(null);
  const auth = useAuth();
  const dispatch = useDispatch();

  const [newType, setNewType] = useState(null);
  const [rowObj, setRowObj] = useState({
    type: null,
    hours: null,
    days: null,
    note: "",
    rate: 1,
    amount: null,
  });

  const [amounts, setAmounts] = useState({
    normal: 0,
    late: 0,
    extra: 0,
    additions: 0,
    deductions: 0,
    totalDay: 0,
    savedTotalDay: 0,
  });
  const resetFields = () => {
    setRowObj({
      type: null,
      hours: null,
      days: null,
      note: "",
      rate: 1,
      amount: null,
    });
  };

  const summaryData = useMemo(() => {
    const tempSummary = getSummary(deductionsData, additionsData);
    setAmounts((prev) => ({
      ...prev,
      additions: tempSummary?.additions?.totalities?.amount,
      deductions: tempSummary?.deductions?.totalities?.amount,
      totalDay: subtract(
        add(
          prev?.savedTotalDay || 0,
          tempSummary?.additions?.totalities?.amount || 0
        ),
        tempSummary?.deductions?.totalities?.amount || 0
      ),
    }));
    return tempSummary;
  }, [deductionsData, additionsData]);

  const typeDropDownData = useMemo(() => {
    if (!!earnings && !!deductions) {
      if (currentMode === "additions") {
        setRowObj((prev) => ({ ...prev, type: null }));
        setEarningsAddit(earnings.map(({ earningName }) => earningName) || []);
      } else if (currentMode === "deductions") {
        setRowObj((prev) => ({ ...prev, type: null }));
        setEarningsAddit(
          deductions.map(({ deductionName }) => deductionName) || []
        );
      }
    }
  }, [currentMode, earnings, deductions]);

  const onChange = (key, val) => {
    console.log("key", key, "val", val);
    const perHour = salaryPerHour || 1;

    if (key === "hours") {
      // const perHour = employeeInfo?.employeeSalaryPerHour || 1;

      setRowObj((prev) => ({
        ...prev,
        [key]: val,
        days: divide(val, 8),
        amount: multiply(val, perHour),
      }));
    } else if (key === "amount") {
      setRowObj((prev) => ({ ...prev, [key]: val, hours: 0, days: 0 }));
    } else if (key === "days") {
      setRowObj((prev) => ({
        ...prev,
        [key]: val,
        hours: multiply(8, val),
        amount: multiply(multiply(8, val), perHour),
      }));
    } else {
      setRowObj((prev) => ({ ...prev, [key]: val }));
    }
  };

  const onAdd = () => {
    let { type, hours, days, note, rate } = rowObj;
    if (!!currentMode && !!type && hours >= 0 && days >= 0 && !!rate) {
      if (currentMode === "additions") {
        setAdditionsData((prev) => [...prev, rowObj]);
        resetFields();
      } else if (currentMode === "deductions") {
        setDeductionsData((prev) => [...prev, rowObj]);
        resetFields();
      }
    } else {
      message.error("Gabim, plotesoni te gjitha fushat!");
    }
  };

  const reloadDedAdd = async () => {
    const getData = async () => {
      await requestMultipleTables([
        "earnings",
        "deductions",
        "prepayments",
      ]).then((res) => {
        dispatch(earningsReducer(res[0]));
        dispatch(deductionsReducer(res[1]));
        dispatch(prepayments(res[2]));
      });
    };
    await getData();
  };

  const checkPrev = async () => {
    setDeductionsData([]);
    setAdditionsData([]);
    const result = await validatePrevious(employeeId, timeperiod).then(
      (res) => res
    );
    // console.log("Check Prev", result);
    if (!!result) {
      setHasPrev({ has: true, data: result, editMode: false, new: false });
    } else {
      setHasPrev({ has: false, data: null, editMode: false, new: false });
      // setDeductionsData([]);
      // setAdditionsData([]);
    }
    setLoading(false);
  };

  const onEditMode = () => {
    setHasPrev((prev) => ({ ...prev, has: false, editMode: true, new: false }));
    const { deductions, earnings } = hasPrev?.data;
    console.log("hasPrev", hasPrev);
    setDeductionsData(deductions?.data);
    setAdditionsData(earnings?.data);
  };

  // const deleteAndClear = async () => {
  // 	await onDeletePrepayment(hasPrev?.data);
  // 	await reloadDedAdd();
  // 	setHasPrev({ has: false, data: null, editMode: false, new: true });
  // 	setDeductionsData([]);
  // 	setAdditionsData([]);
  // };

  /**
   * This will take care of saving data to the database
   */
  const onFinish = async () => {
    console.log({ amounts, summaryData });
    await API.put("clocking", `/clocking/${data?.clockingId}`, {
      body: {
        totalities: {
          ...data?.totalities,
          totalPay: amounts?.totalDay,
          additions: amounts?.additions,
          deductions: amounts?.deductions,
          summaryData: summaryData,
          lastGeneratedBy:
            auth?.userAccess[0]?.given_name +
            " " +
            auth?.userAccess[0]?.family_name,
        },
      },
    })
      .then((res) => {
        setTimeout(() => {
          getShiftData();
          Swal.fire("U ruajt me sukses!", "", "success");
        }, 1500);
      })
      .catch((err) => {
        message.error("Gabim, provoni perseri!");
        console.log("Ndodhi nje gabim ne shtimin e add/deductions", err);
      });
  };

  // useMemo(() => {
  // 	setDeductionsData([]);
  // 	setAdditionsData([]);
  // }, [momentInstance]);

  useEffect(() => {
    if (!!employeeId && !!timeperiod) {
      setLoading(true);
      // checkPrev();
      setLoading(false);
    }
  }, [employeeId, timeperiod]);

  useLayoutEffect(() => {
    if (!!empInfo?.totalities) {
      setSalaryPerHour(empInfo?.totalities?.employeeRate?.toFixed(2));
      setAmounts({
        normal: empInfo?.totalities?.normal?.pay,
        late: empInfo?.totalities?.late?.pay,
        extra: empInfo?.totalities?.extra?.pay,
        additions: empInfo?.totalities?.additions || 0,
        deductions: empInfo?.totalities?.deductions || 0,
        totalDay: empInfo?.totalities?.totalPay || 0,
        lastGeneratedBy: empInfo?.totalities?.lastGeneratedBy || null,
        savedTotalDay:
          empInfo?.totalities?.normal?.pay +
          empInfo?.totalities?.late?.pay +
          empInfo?.totalities?.extra?.pay,
      });
      setDeductionsData(empInfo?.totalities?.summaryData?.deductionsArr || []);
      setAdditionsData(empInfo?.totalities?.summaryData?.additionsArr || []);
    }
  }, [empInfo]);

  //region RETURN
  return (
    <>
      {/* <div style={{ width: "100%", padding: "10px" }}>
				{amounts?.lastGeneratedBy ? (
					<Alert
						message="KUJDES!"
						
						description={
							<span>
								Per kete punonjes ne kete periudhe eshte gjeneruar nje liste me fitimet ose/dhe
								zbritjet nga <strong> {amounts?.lastGeneratedBy}</strong>.
							</span>
						}
						type="warning"
						showIcon
						closable
					/>
				) : (
					<Alert
						message="Krijim i ri"
						description="Ju jeni duke krijuar llogaritje te reja per pagen!"
						type="success"
						showIcon
					/>
				)}
			</div> */}
      <DayPayHeader
        {...{ data, dayType, programFields, salaryPerHour, setSalaryPerHour }}
      />
      {loading === false ? (
        <div className="actions-ded-add" style={{ marginTop: 30 }}>
          {hasPrev.has === false && (
            <>
              <div className="actions-wrapper" style={{ margin: 20 }}>
                <div className="action-btns">
                  <div className="first">
                    <span
                      onClick={() => setCurrentMode("additions")}
                      className="action-btn"
                      style={
                        currentMode === "additions"
                          ? { background: "#1da193", color: "white" }
                          : null
                      }
                    >
                      Fitime
                    </span>
                    <span
                      className="action-btn"
                      onClick={() => setCurrentMode("deductions")}
                      style={
                        currentMode === "deductions"
                          ? { background: "#ea3943 ", color: "white" }
                          : null
                      }
                    >
                      Zbritje
                    </span>
                  </div>
                </div>
                <br />
                <div className="add-row">
                  <span className="add-element">
                    <label>Lloji:</label>
                    <Select
                      showSearch
                      placeholder="Zgjidh "
                      optionFilterProp="children"
                      style={{ width: 200 }}
                      allowClear
                      value={rowObj?.type}
                      defaultValue={rowObj?.type}
                      onChange={(val) => onChange("type", val)}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space
                            align="center"
                            style={{ padding: "0 8px 4px" }}
                          >
                            <Input
                              placeholder="Shto Opsion"
                              value={newType}
                              onChange={(e) => setNewType(e.target.value)}
                            />
                            <Typography.Link
                              onClick={() => {
                                setEarningsAddit((prev) => [...prev, newType]);
                                setNewType(null);
                              }}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <PlusOutlined />
                            </Typography.Link>
                          </Space>
                        </>
                      )}
                    >
                      {earningsAddi?.map((el) => (
                        <Option value={el}>{el}</Option>
                      ))}
                    </Select>
                  </span>
                  <span className="add-element">
                    <label>Shuma:</label>
                    <InputNumber
                      min={0}
                      style={{ width: "150px" }}
                      placeholder="Zgjidh Shumen"
                      value={rowObj?.amount}
                      defaultValue={rowObj?.amount}
                      onChange={(val) => onChange("amount", val)}
                    />
                  </span>
                  <span className="add-element">
                    <label>Oret:</label>
                    <InputNumber
                      min={0}
                      style={{ width: "150px" }}
                      placeholder="Zgjidh Oret"
                      value={rowObj?.hours}
                      defaultValue={rowObj?.hours}
                      onChange={(val) => onChange("hours", val)}
                    />
                  </span>
                  <span className="add-element">
                    <label>Ditet (1=8 ore):</label>
                    <InputNumber
                      // disabled={true}
                      min={0}
                      style={{ width: "150px" }}
                      placeholder="Zgjidh Ditet"
                      value={rowObj?.days}
                      defaultValue={rowObj?.days}
                      onChange={(val) => onChange("days", val)}
                    />
                  </span>
                  <span className="add-element">
                    <label>Shenimi</label>
                    <Input
                      style={{ width: "200px" }}
                      placeholder="Vendos Shenimin"
                      value={rowObj?.note}
                      defaultValue={rowObj?.note}
                      onChange={(val) => onChange("note", val.target.value)}
                    />
                  </span>
                  {/* <InputNumber
					min={0}
					placeholder="Koeficenti"
					value={rowObj?.rate}
					defaultValue={rowObj?.rate}
					onChange={(val) => onChange("rate", val)}
				/> */}
                  <span className="add-element">
                    <label>Koeficenti</label>
                    <Select
                      showSearch
                      placeholder="Zgjidh Koeficentin "
                      optionFilterProp="children"
                      style={{ width: 130 }}
                      value={rowObj?.rate}
                      defaultValue={rowObj?.rate}
                      onChange={(val) => onChange("rate", val)}
                      // onSearch={onSearch}
                    >
                      <Option value={0.8}>0.8</Option>
                      <Option value={1}>1</Option>
                      <Option value={1.2}>1.2</Option>
                      <Option value={1.5}>1.5</Option>
                    </Select>
                  </span>
                  {/* <Button> Shto </Button> */}
                  <span className="add-element">
                    {/* <label>Shto</label> */}
                    <span className="add-icon" onClick={onAdd}>
                      {" "}
                      <PlusIcon />
                    </span>
                  </span>
                </div>
              </div>

              <div className="wrapTable">
                <div
                  className="deductions-table"
                  style={{ marginLeft: 20, marginRight: 20 }}
                >
                  <Additionals {...{ additionsData, setAdditionsData }} />
                </div>
                <div
                  className="additions-table"
                  style={{ marginLeft: 20, marginRight: 20 }}
                >
                  <Deductions {...{ deductionsData, setDeductionsData }} />
                </div>
                <div
                  className="summary-table"
                  style={{ marginLeft: 20, marginRight: 20 }}
                >
                  <Summary {...{ summaryData }} />
                </div>
              </div>
              <div
                className="foter-edit"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <AmountCard
                  title={"Para 19:00"}
                  color={"#1da193"}
                  textColor={"#fff"}
                  amount={amounts?.normal || 0}
                />
                <AmountCard
                  title={"19:00 - 22:00"}
                  color={"#57A773"}
                  textColor={"#fff"}
                  amount={amounts?.late || 0}
                />{" "}
                <AmountCard
                  title={"22:00 - 07:30"}
                  color={"#832232"}
                  textColor={"#fff"}
                  amount={amounts?.extra || 0}
                />{" "}
                <AmountCard
                  title={"Pagesa Oret"}
                  color={"#A7BBEC"}
                  textColor={"#fff"}
                  amount={amounts?.savedTotalDay || 0}
                />{" "}
                <AmountCard
                  title={"Shtimet"}
                  color={"#9AC4F8"}
                  textColor={"#fff"}
                  amount={amounts?.additions || 0}
                />
                <AmountCard
                  title={"Zbritjet"}
                  color={"#FF6663"}
                  textColor={"#fff"}
                  amount={amounts?.deductions || 0}
                />
                <AmountCard
                  title={"Pagesa Ditore"}
                  color={"#0B3954"}
                  textColor={"#fff"}
                  amount={amounts?.totalDay || 0}
                />
                {/* Pagesa ditore: {data?.totalities?.totalPay.toFixed(2)} */}
              </div>
              <div className="foter-edit-modal">
                <Button className="close-btn" onClick={() => setVisible(false)}>
                  Mbyll Faqen
                </Button>

                <Button className="start-btn" onClick={() => onFinish()}>
                  Ndrysho
                </Button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )}
    </>
  );
}

export default ActionsDay;

const dayType = (dayType) => {
  switch (dayType) {
    case "Regular":
      return "Normal";
    case "Sick":
      return "Sëmurë";
    case "Maternity":
      return "Leje Lindje";
    case "NationalHoliday":
      return "Feste Zyrtare";
    case "NationalHolidayWorking":
      return "Feste Zyrtare Punë";
    case "HolidayRequest":
      return "Kërkesë Leje";
  }
};
