import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Button, Modal, Select, Tooltip, DatePicker } from "antd";
import { IconFilter, WarningIcon } from "../../../../assets/icons/index";
import { CaretDownFilled } from "@ant-design/icons";
import "./FilterAgGrid.scss";
import { ClearIcon, IconCalendar, NextIcon } from "../../../dashboard/assets";
import moment from "moment";
import { onDateFilter } from "../../../Documentation/utils/onFilter";
import MondayButton from "../../MondayButton/MondayButton";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import dayjsTZ from "../../../../utils/dayjs";
import { toJSON } from "../../../../utils";

/**
 * @typedef filterKey
 * @property {string} key AgGrid's key to match when filtering
 * @property {string} label dropdown's label & placeholder
 * @property {'string' | 'date-range'} type datatype of column to be filtered (may affect what filter component to render)
 */

/**
 * @typedef props
 * @property {Object} gridApi
 * @property {function} clearFilters provide clearFilters function to reset all filters
 * @property {Array} rowData AgGrid's data to use the filter on
 * @property {Array<Object>} filterKeys AgGrid's data to use the filter on
 * @property {Boolean} filterActive state that specifies outer filters that affect rowData
 * @property {Object<Array>} replaceable provide this new set of keys if AgGrid's data for that key is manipulated
 */
/**
 *
 * @param {props} props
 * @returns
 */

const FilterAgGrid = ({
  title,
  style,
  gridApi,
  rowData,
  children,
  className,
  filterKeys,
  replaceable,
  filterActive,
  setActiveFilter,
  clearFilters = () => {},
}) => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [options, setOptions] = useState(null);
  const [open, setOpen] = useState(false);
  const [disabledRange, setDisabledRange] = useState([]);

  const setFilterModel = async ({
    e,
    key,
    type,
    filterType,
    dateFrom,
    dateTo,
  }) => {
    if (!!gridApi) {
      const filterObj = {
        filterType,
        dateFrom,
        dateTo,
        type,
      };
      await gridApi.getColumnFilterInstance(key).then((comp) => {
        if (filterType !== "date") filterObj.filter = `${e}`;
        comp.setModel(filterObj);
      });
      gridApi.onFilterChanged();
    }
    !!setActiveFilter && setActiveFilter((prev) => ({ ...prev, [key]: e }));
  };

  const generalFilter = (e, key, filterType) => {
    let obj = {};
    const isDate =
      (dayjs.isDayjs(e?.[0]) && dayjs.isDayjs(e?.[1])) || dayjs.isDayjs(e);

    if (!!isDate) {
      if (Array.isArray(e)) {
        obj = {
          filterType: "date",
          dateFrom: e?.[0]?.startOf("day").format("YYYY-MM-DD"),
          dateTo: e?.[1]?.add(1, "day")?.endOf("day").format("YYYY-MM-DD"),
        };
      } else {
        obj = {
          filterType: "date",
          dateFrom: e.startOf("month").format("YYYY-MM-DD"),
          dateTo: e.endOf("month").format("YYYY-MM-DD"),
        };
      }
    }
    setFilterModel({
      ...obj,
      e,
      key,
      type: filterType || "contains",
    });
  };

  const doesExist = (arr, val) =>
    Object.values(arr.map((el) => el.value)).indexOf(val) !== -1;

  useEffect(() => {
    let options = {};
    let min = 9999999999999;
    let max = 0;
    filterKeys?.forEach(({ key, type }) => {
      options[key] = [];
      rowData?.forEach((el) => {
        if (type === "string") {
          if (!!replaceable && !!replaceable[key]?.length) {
            replaceable[key]?.forEach((replace) => {
              for (const [value, label] of Object.entries(replace)) {
                if (!!value && !doesExist(options[key], value))
                  options[key].push({ label, value });
              }
            });
          } else {
            if (!!el?.[key] && !doesExist(options[key], el?.[key]))
              options[key].push({ label: el?.[key], value: el?.[key] });
          }
        } else {
          if (!!el?.[key]) {
            const timestamp = dayjsTZ(el?.[key]).valueOf();
            if (timestamp < min) min = timestamp;
            if (timestamp > max) max = timestamp;
          }
        }
      });
    });
    setOptions(options);
    setDisabledRange([dayjsTZ(min), dayjsTZ(max)]);
  }, [rowData, filterKeys]);

  return (
    <>
      <div
        className={`headerIcon ${className}`}
        style={style}
        onClick={() => setOpen(true)}
      >
        <Tooltip
          placement="top"
          classNames={{ root: "global-icon-tooltip" }}
          title={title || "Filter i avancuar"}
          style={{ opacity: 1.0 }}
        >
          <IconFilter width={18} height={18} fill={style?.fill || "#323338"} />
        </Tooltip>
      </div>
      <Modal
        className="global-filter-modal darkHeader"
        data-testid="filter-ag-grid"
        title={
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <span>Filtrim i Avancuar</span>
            {typeof filterActive == "boolean" && !filterActive && (
              <Tooltip title={"Po filtron vetëm punonjësit aktivë!"}>
                <WarningIcon fill="#fff" width={14} height={14} />
              </Tooltip>
            )}
          </div>
        }
        destroyOnClose
        centered
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <MondayButton
            onClick={() => setOpen(false)}
            className="mondayButtonRed"
          >
            Mbyll
          </MondayButton>,
          <MondayButton
            onClick={() => setOpen(false)}
            className="mondayButtonGreen"
          >
            Filtro
          </MondayButton>,
        ]}
      >
        <Row
          gutter={10}
          style={{
            width: "100%",
            // gridTemplateColumns: !!children ? "3fr 1fr" : "1fr",
          }}
        >
          <Col>
            {filterKeys?.map(
              ({ key, label, type, picker, format, filterType }, idx) => (
                <div className="filterItem" key={idx}>
                  <h3>{label}</h3>
                  {type === "string" ? (
                    <Select
                      showSearch
                      variant={false}
                      className="filter-dropdown"
                      data-testid="filter-dropdown"
                      suffixIcon={<CaretDownFilled />}
                      placeholder={label}
                      onChange={(e) => generalFilter(e, key, filterType)}
                    >
                      {options?.[key]?.map(({ value, label }) => (
                        <Option
                          key={value}
                          value={value}
                          data-testid={`option-${value}`}
                        >
                          {label}
                        </Option>
                      ))}
                    </Select>
                  ) : type === "date-range" ? (
                    <RangePicker
                      onChange={(e) =>
                        generalFilter(e, key, filterType || "inRange")
                      }
                      format={format}
                      allowClear={{ clearIcon: <ClearIcon /> }}
                      suffixIcon={<IconCalendar />}
                      separator={<NextIcon />}
                      minDate={disabledRange[0]}
                      maxDate={disabledRange[1]}
                    />
                  ) : type === "datepicker" ? (
                    <DatePicker
                      onChange={(e) =>
                        generalFilter(e, key, filterType || "inRange")
                      }
                      allowClear={{ clearIcon: <ClearIcon /> }}
                      suffixIcon={<IconCalendar />}
                      picker={picker}
                      format={format}
                      minDate={disabledRange[0]}
                      maxDate={disabledRange[1]}
                    />
                  ) : null}
                </div>
              )
            )}
          </Col>
          <div>{!!children ? children : null}</div>
        </Row>
      </Modal>
    </>
  );
};

export default FilterAgGrid;

FilterAgGrid.propTypes = {
  gridApi: PropTypes.object,
  clearFilters: PropTypes.func,
  rowData: PropTypes.array.isRequired,
  filterKeys: PropTypes.array.isRequired,
  filterActive: PropTypes.bool,
  replaceable: PropTypes.object,
};
