import { Button, Typography, message } from "antd";
import QuillEditor from "./QuillEditor";
import {
  EclipseGreen,
  EclipseOrange,
  EclipseRed,
  EclipseYellow,
} from "../Image";
import dayjsTZ from "../../../../../utils/dayjs";
import { CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title } = Typography;

export const FormItems = ({
  applicantTrainingStatus,
  applicantReferenceStatus,
  handlePassword,
  showPassword,
  form,
  disabledTimesArray,
  selectedDateCandidateArray,
  selectedDate,
}) => {
  const optionStyle = { display: "flex", alignItems: "center", gap: "10px" };

  const onPrevMonth = (value) => {
    const updateMonth = value && dayjsTZ(value).clone().subtract(1, "months");
    form.setFieldValue("applicantInterviewDate", updateMonth);
  };
  const onNextMonth = (value) => {
    const updateMonth = value && dayjsTZ(value).clone().add(1, "months");
    form.setFieldValue("applicantInterviewDate", updateMonth);
  };

  const headerRenderFunction = ({ value, type, onChange }) => {
    return (
      <div className="calendar-header-container">
        <div>
          <Title level={4} style={{ margin: 0 }}>
            {value && dayjsTZ(value).format("YYYY MMMM")}
          </Title>
        </div>
        <div className="buttons-container">
          <Button
            style={{
              backgroundColor: "#1264A3",
              color: "white",
              borderRadius: "5px",
              height: "auto",
            }}
            onClick={() => onPrevMonth(value)}
            icon={<CaretLeftOutlined />}
          />
          <Button
            style={{
              backgroundColor: "#1264A3",
              color: "white",
              borderRadius: "5px",
              height: "auto",
            }}
            onClick={() => onNextMonth(value)}
            icon={<CaretRightOutlined />}
          />
        </div>
      </div>
    );
  };

  const cellRender = (current) => {
    return (
      current && dayjsTZ(current).valueOf() < dayjsTZ().startOf("day").valueOf()
    );
  };

  const disableDateForFillimPune = (current) => {
    return (
      current &&
      dayjsTZ(current).startOf("day").isBefore(dayjsTZ(selectedDate).startOf("day"))
    );
  };

  const disabledDate = (current) => {
    return (
      current &&
      dayjsTZ(current).startOf("year").valueOf() >
        dayjsTZ().startOf("year").valueOf() - 568025136000
    );
  };

  //Llogjika kur bejme klikojm mbi ore qe eshte disabled
  const handleDisabledClick = (e) => {
    const disabledHourCandidate = selectedDateCandidateArray.find(
      (item) => +dayjsTZ(item.applicantInterviewDate).hour() === +e
    );

    message.warning({
      content: `Keni Intervistë me ${disabledHourCandidate.applicantFirstName} ${disabledHourCandidate.applicantLastName} ne këtë Orë`,
      duration: 1.5,
      key: "disabledHourCandidate",
    });
  };

  const popupAlign = {
    points: ["tl", "bl"],
    offset: [0, 4],
  };

  return {
    ["Small Input Fields"]: [
      {
        label: "Emër",
        placeholder: "Shkruani emrin...",
        formItemName: "applicantFirstName",
        type: "input",
        required: true,
        rules: "Emri nevojitet...",
      },
      {
        label: "Mbiemër",
        placeholder: "Shkruani mbiemrin",
        formItemName: "applicantLastName",
        type: "input",
        required: "true",
      },
      {
        label: "Pozicioni",
        placeholder: "Shkruani Pozicionin",
        formItemName: "applicantDepartment",
        type: "select",
        required: "true",
        maxTagCount: "responsive",
        mode: "multiRow",
        options: [
          "Architecture & Engineering",
          "Sales Management",
          "Finance & Accounting",
          "IT & Software Development",
          "Administration",
          "Internal Staff",
          "Human Resources",
          "Project Monitoring & Coordinating",
        ],
      },
      {
        label: "Statusi",
        formItemName: "applicantStatus",
        type: "select",
        required: "true",
        customOptions: [
          {
            key: 0,
            value: "Skualifikuar",
            label: (
              <div style={optionStyle}>
                <img src={EclipseRed} alt="Red Dot" />
                Skualifikuar
              </div>
            ),
          },
          {
            key: 1,
            label: (
              <div style={optionStyle}>
                <img src={EclipseOrange} alt="Orange Dot" />
                Listuar për intervistë
              </div>
            ),
            value: "Listuar për intervistë",
          },
          {
            key: 2,
            value: "Në proçes",
            label: (
              <div style={optionStyle}>
                <img src={EclipseYellow} alt="Yellow Dot" />
                Në proçes
              </div>
            ),
          },
          {
            key: 3,
            value: "Pranuar",
            label: (
              <div style={optionStyle}>
                <img src={EclipseGreen} alt="Green Dot" />
                Pranuar
              </div>
            ),
          },
        ],
      },
      {
        label: "Skuadra",
        placeholder: "Zgjidhni skuadren...",
        formItemName: "applicantTeam",
        type: "select",
        options: ["Flex Tirana", "Flex Prishtina"],
        required: "true",
      },
      {
        label: "Datëlindja",
        type: "datepicker",
        format: "DD/MM/YYYY",
        formItemName: "applicantBirthday",
        allowClear: true,
        required: "true",
        disabledDate: disabledDate,
        className: "custom-date-field",
      },
      {
        label: "Nr.Personal",
        placeholder: "Shkruani numrin personal...",
        formItemName: "applicantPersonalNumber",
        type: "input",
        required: "true",
      },
      {
        label: "Nr.Celulari",
        placeholder: "Shkruani..",
        formItemName: "applicantPhoneNumber",
        type: "phone",
        required: "true",
        className: "custom-phone-input",
      },
      {
        label: "Adresa e banimit",
        placeholder: "Shkruani adresën e banimit të kandidatit",
        formItemName: "applicantAddress",
        type: "input",
        required: "true",
        className: "custom-addres-field",
      },
      {
        label: "Platforma",
        placeholder: "Selektoni platformen",
        formItemName: "applicantPlatform",
        options: ["Linkedin", "Instagram", "Flex", "Referuar"],
        type: "select",
        required: "true",
        className: "input-platform",
      },
      {
        label: "Niveli i diplomës",
        placeholder: "Selektoni nivelin e diplomës",
        formItemName: "applicantDiplomaLevel",
        options: ["Student", "I paarsimuar"],
        type: "select",
        required: "true",
        className: "input-medium-size",
      },
      {
        label: "Universiteti",
        placeholder: "Selektoni Universitetin...",
        formItemName: "applicantUniversity",
        type: "input",
        required: "true",
        className: "input-medium-size",
      },
      {
        label: "Dega",
        placeholder: "Selektoni degën...",
        formItemName: "applicantUniversityField",
        type: "input",
        required: "true",
        className: "input-medium-size custom-size",
      },
      {
        label: "Email",
        placeholder: "Shkruani emailin...",
        formItemName: "applicantEmail",
        type: "input",
        required: "true",
        className: "input-medium-size custom-size ",
      },
    ].filter(Boolean),
    ["First Quill & Radio Inputs"]: [
      {
        type: "customradio",
        label: (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{
                color: "red",
                visibility: applicantTrainingStatus ? "visible" : "hidden",
              }}
            >
              *
            </span>
            Trajnime dhe kualifikime të tjera
          </div>
        ),
        formItemName: "applicantTrainingStatus",
        options: [
          {
            label: "Po",
            value: true,
          },
          {
            label: "Jo",
            value: false,
          },
        ],
      },
      {
        formItemName: "applicantTraining",
        className: "custom-quill-style",
        required: false,
        type: "custominput",
        CustomField: QuillEditor,
        readOnly: !applicantTrainingStatus,
        classNameStyle: applicantTrainingStatus
          ? "quill-box"
          : "invalid-typing",
        placeholder:
          "Shkruani këtu, trajnimet dhe kualifikimet të tjera të kandidatit...",
        rules: [
          {
            required: applicantTrainingStatus,
            message: "Trajnime janë të nevojshme!",
          },
        ],
      },
    ].filter(Boolean),
    ["Second Quill & Radio Inputs"]: [
      {
        type: "customradio",
        label: (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span
              style={{
                color: "red",
                visibility: applicantReferenceStatus ? "visible" : "hidden",
              }}
            >
              *
            </span>
            Referenca
          </div>
        ),
        formItemName: "applicantReferenceStatus",
        options: [
          {
            label: "Po",
            value: true,
          },
          {
            label: "Jo",
            value: false,
          },
        ],
      },
      {
        formItemName: "applicantReference",
        className: "custom-quill-style",
        required: false,
        type: "custominput",
        readOnly: !applicantReferenceStatus,
        classNameStyle: applicantReferenceStatus
          ? "quill-box"
          : "invalid-typing",
        CustomField: QuillEditor,
        placeholder: "Emër Mbiemër / Nr. Celulari / E-mail...",
        rules: [
          {
            required: applicantReferenceStatus,
            message: "Referenca janë të nevojshme!",
          },
        ],
      },
    ].filter(Boolean),
    ["First Normal Quill Inputs"]: [
      {
        type: "custominput",
        CustomField: QuillEditor,
        label: "Avantazhet",
        formItemName: "applicantAdvantages",
        required: true,
        className: "quil-custom-style",
        placeholder: "Shkruani këtu, disavantazhet e kandidatit...",
      },
    ].filter(Boolean),
    ["Second Normal Quill Inputs"]: [
      {
        label: "Disavantazhet",
        formItemName: "applicantDisadvantages",
        required: true,
        type: "custominput",
        CustomField: QuillEditor,
        className: "quil-custom-style",
        placeholder: "Shkruani këtu, disavantazhet e kandidatit...",
      },
    ].filter(Boolean),
    ["Datepicker"]: [
      {
        type: "datepicker",
        label: "Selektoni datën e intervistës",
        formItemName: "applicantInterviewDate",
        fullScreen: false,
        className: "calendar-style",
        headerRender: headerRenderFunction,
        disabledDate: cellRender,
      },
    ].filter(Boolean),
    ["TimePicker"]: [
      {
        type: "timepicker",
        timePickerOpen: false,
        label: "Selektoni orën e intervistës",
        formItemName: "applicantInterviewDate",
        fullScreen: false,
        className: "timepicker-style",
        use12Hours: false,
        format: "HH:mm",
        disabledTime: () => ({
          disabledHours: () =>
            disabledTimesArray.map((time) => dayjsTZ(time, "HH:mm").hour()),
          disabledMinutes: (hour) =>
            hour === dayjsTZ().hour()
              ? disabledTimesArray.map((time) => dayjsTZ(time, "HH:mm").minute())
              : () => [],
        }),
        onOpenChange: (status) => {
          const el = document.getElementsByClassName(
            "ant-picker-time-panel-cell-disabled"
          );

          const handleClick = (event) => {
            const clickedElement = event.currentTarget;
            const valueOfClickedElement = clickedElement.innerText;
            handleDisabledClick(valueOfClickedElement);
          };
          if (status) {
            for (let i = 0; i < el.length; i++) {
              el[i]?.addEventListener("click", handleClick);
            }
          } else {
            for (let i = 0; i < el.length; i++) {
              el[i]?.removeEventListener("click", handleClick);
            }
          }
        },
        getPopupContainer: true,
        popupAlign: { popupAlign },
        minuteStep: 30,
      },
    ].filter(Boolean),
    ["Interview Input Fields"]: [
      {
        label: "Data e mundshme e fillimit",
        type: "datepicker",
        formItemName: "applicantStartDate",
        allowClear: true,
        dateFormat: "DD/MM/YYYY",
        // required: true,
        disabledDate: disableDateForFillimPune,
        className: "custom-date-field",
      },
      {
        type: "input",
        // typePassword: !showPassword,
        formItemName: "applicantPayment",
        label: (
          <div>
            <span style={{ color: "red" }}>*</span>Norma e pagës (rating)
          </div>
        ),
        placeholder: "Shkruani normën e pagës (rating)",
        rules: [
          {
            required: true,
            message: "Norma e pagës nevojitet!",
          },
          {
            pattern: /^\d+$/,
            message: `Pranohen vetëm numra.`,
          },
        ],
        // suffixIcon: (
        //   <span onClick={handlePassword}>
        //     {!showPassword ? <EyeFilled /> : <EyeInvisibleFilled />}
        //   </span>
        // ),
      },
    ].filter(Boolean),
  };
};
