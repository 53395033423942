import { List, Spin, message, Tooltip } from "antd";
import useDrivePermissions from "../../hooks/useDrivePermissions";
import { toSentenceCase } from "../../utils";
import Avatar from "react-avatar";
import { CloseIcon } from "../../assets/icons";
import "./DrivePermissionList.scss";
import { useAuth } from "../../authentication/authHelpers";
import moment from "moment";
import dayjsTZ from "../../utils/dayjs";
import DrivePermissionForm from "./DrivePermissionForm/DrivePermissionForm";
import { useSelector } from "react-redux";

const DrivePermissionList = ({ form, folderId, setKeylogs }) => {
  const { user } = useAuth();
  const { employees } = useSelector((state) => state.employeesList);

  const { spinning, permissions, handleRemove, handleSubmit, permissionSpin } =
    useDrivePermissions({ form, folderId });

  const handleKeylogs = async (data) => {
    const author = `${user?.given_name} ${user?.family_name}`;
    let changes = [];
    data?.forEach(({ field, oldValue = "", newValue }) => {
      changes.push({
        activity: "Ndryshuar",
        author: author,
        changeDate: dayjsTZ().valueOf(),
        field,
        oldValue,
        newValue,
      });
    });
    setKeylogs(changes);
  };

  return (
    <div className="column-gap-10">
      <DrivePermissionForm
        {...{
          form,
          handleSubmit,
          handleKeylogs,
          employees: employees?.filter((el) => !!el?.employeeEmailWork),
          permissions,
        }}
      />
      {!!permissions?.length > 0 ? (
        <div>
          <span className="permission-title">
            Ka{permissions?.length > 1 ? "në" : ""} autorizim për këtë dokument:
          </span>
          <div className="permissions-list">
            {permissions?.map((el) => (
              <List.Item
                key={el?.id}
                className="permission-item"
                data-testid="permissionItem"
              >
                {el?.email === permissionSpin.email && !!permissionSpin.spin ? (
                  <Spin
                    spinning={true}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <List.Item.Meta
                      style={{ display: "flex", alignItems: "center", gap: 10 }}
                      avatar={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar src={el?.photo} size="30" round={true} />
                        </div>
                      }
                      title={el?.name}
                      description={
                        <Tooltip placement="top" title={el?.email}>
                          {el?.email}
                        </Tooltip>
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        fontWeight: 600,
                      }}
                    >
                      {toSentenceCase(el?.role)}
                    </div>
                    {el?.role !== "owner" ? (
                      <Tooltip placement="top" title="Hiq autorizimin">
                        <span
                          className="deletePermissionIcon"
                          onClick={() =>
                            handleRemove({
                              permissionId: el?.id,
                              permissionEmail: el?.email,
                              getKeylogs: (keylog) => {
                                handleKeylogs(keylog);
                              },
                            })
                          }
                        >
                          <CloseIcon className="closeIcon" />
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>
                )}
              </List.Item>
            ))}
          </div>
        </div>
      ) : (
        <Spin
          spinning={spinning}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
          }}
        ></Spin>
      )}
      {!spinning && !permissions?.length > 0 && (
        <div style={{ height: 200 }}>
          <span className="permission-title">
            Nuk ka të dhëna për autorizim
          </span>
        </div>
      )}
    </div>
  );
};

export default DrivePermissionList;
