import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageNotFoundLogo } from "../../assets/icons";
function PageNotFound() {
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/kryefaqja";
  // console.log("FROM", from);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "0%",
        height: "900px",
      }}>
      <div
        className="not-found-logo-wrapper"
        style={{ width: "477px", height: "388px" }}>
        <PageNotFoundLogo />
      </div>
      <h1
        style={{
          font: "normal normal bold 78px/106px Open Sans",
          color: "#1d3943",
        }}>
        404 ERROR
      </h1>
      <h2
        style={{
          font: "normal normal bold 40px/55px Open Sans",
          color: "#239281",
          marginTop: "-50px",
        }}>
        FAQJA NUK U GJET
      </h2>
      <span
        style={{
          width: "140px",
          height: "32px",
          background: "#1da193 0% 0% no-repeat padding-box",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          cursor: "pointer",
          borderRadius: "5px",
        }}
        onClick={() => navigate(from === "/" ? "/kryefaqja" : from)}>
        Kthehu pas
      </span>
    </div>
  );
}
export default PageNotFound;
