import { message } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
// import { useReactToPrint } from "react-to-print";
import {
  getMonthsOfYear,
  // getNextMonthsOfYear,
} from "../../../punonjesit/EmployeeView/Payment/utils/calculations";
import { calcMonthInd } from "../../PaySub/employeeCalc/calculateMonthlyInd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
// import ShiftModal from "../../../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftModal";
// import PayStub from "../../PaySub/PaySub";
import EmployeePaySub from "./EmployeePaySub";
// import NewPayStub from "../../PaySub/previewPayStub/NewPayStub";
// import ExportToPdf from "../../../Konfigurimet/dynamic components/ExportToPdf";
// import { VisibleIcon } from "../../../../assets/icons";
import { useMediaQuery } from "react-responsive";
import MobileWageGrid from "./MobileWageGrid";
import { columnDefs } from "./colDefs";

const EXPORT_COLS = [
  { en: "muaji", al: "Muaji", format: "MMMM YYYY" },
  { en: "neto", al: "Shuma Neto" },
  { en: "bruto", al: "Shuma Bruto" },
];

function WageGrid({ employee }) {
  // const { Option } = Select;
  const [rowData, setRowData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const location = useLocation();
  const [selectedData, setSelectedData] = useState(null);
  const { wages } = useSelector((state) => state.wages);
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  console.log("wages", wages);

  const handleMonthModal = (data) => {
    if (data.neto !== "Pa caktuar") {
      setSelectedData(data);
      setIsModalVisible(true);
    } else {
      message.info({ key: "noData", content: "Nuk ka të dhëna!" });
    }
  };

  const handleClose = () => {
    setSelectedData(null);
    setIsModalVisible(false);
  };

  const initializeMonths = () => {
    let temp = getMonthsOfYear()?.map((el) => ({
      ...el,
      neto: calcMonthInd("neto", wages, el.muaji, { employeeId: employee }),
      bruto: calcMonthInd("bruto", wages, el.muaji, { employeeId: employee }),
    }));
    // console.log("Temp", temp);

    setRowData(temp);
  };

  useEffect(() => {
    if (!!wages) {
      initializeMonths();
    }
  }, [wages]);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  console.log("rowData: ", rowData);
  const ITEMS = {
    search: {},
    icons: {
      excel: { tableCols: EXPORT_COLS },
      pdf: { tableCols: EXPORT_COLS },
      print: { tableCols: EXPORT_COLS },
    },
  };

  return (
    <div className="wageViewGrid">
      <AgGridComponent
        gridApi={gridApi}
        rowData={rowData}
        rowSelection={{
          mode: "multiRow",
          enableClickSelection: true,
        }}
        onGridReady={onGridReady}
        paginationPageSize={12}
        columnDefs={columnDefs({ handleMonthModal, isMobileView })}
        headerProps={{
          items: ITEMS,
          exportTitle: "Paga e punonjësit",
        }}
        onRowClick={(_, row) => handleMonthModal(row)}
        idKey={"muaji"}
      />
      {!!isModalVisible ? (
        <EmployeePaySub {...{ isModalVisible, selectedData, handleClose }} />
      ) : null}
    </div>
  );
}

export default WageGrid;
