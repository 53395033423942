import colorShade from "../../../../utils/colorShade";
import dayjsTZ from "../../../../utils/dayjs";
import getAgThemePreference from "../../../../utils/getAgThemePreference";
const { mode, accentColor } = getAgThemePreference();

export const colDefs = ({ isMobileView, handleMonthModal }) => [
  {
    headerName: "Muaji",
    field: "muaji",
    mobile: true,
    isCollapseTitle: true,
    format: "MMMM YYYY",
    valueGetter: ({ data }) => dayjsTZ(data?.muaji).format("MMMM YYYY"),
    ...(!isMobileView
      ? {
          cellRenderer: ({ data }) => (
            <div style={{ cursor: "pointer" }}>
              <div variant="outlined">
                <span
                  style={{
                    color: mode.includes("dark")
                      ? "#FFF"
                      : colorShade(accentColor, 0.25),
                    fontWeight: 600,
                  }}
                  onClick={() => handleMonthModal(data)}
                >
                  {dayjsTZ(data?.muaji).format("MMMM YYYY")}
                </span>
              </div>
            </div>
          ),
        }
      : {}),
  },
  {
    headerName: "Shuma Neto",
    field: "neto",
    mobile: true,

    // cellRenderer: ({ data }) => (
    // 	<div style={{ cursor: "pointer" }}>
    // 		<div variant="outlined">
    // 			<span style={{ color: "green", fontWeight: 600 }}>
    // 				{calcMonthInd("neto", wages, data.muaji, location?.state)}
    // 			</span>
    // 		</div>
    // 	</div>
    // ),
  },
  {
    headerName: "Shuma Bruto",
    field: "bruto",
    mobile: true,
    // cellRenderer: ({ data }) => (
    // 	<div style={{ cursor: "pointer" }}>
    // 		<div variant="outlined">
    // 			<span style={{ color: "green", fontWeight: 600 }}>
    // 				{calcMonthInd("bruto", wages, data.muaji, location?.state)}
    // 			</span>
    // 		</div>
    // 	</div>
    // ),
  },
];
