import React, { useEffect, useState } from "react";
import "./TimeSheet.scss";
import { Input, DatePicker, Select } from "antd";
import { DeleteFilled, SearchOutlined } from "@ant-design/icons";
import ShiftLogButtonsFilter from "../../dashboard/components/clockInOut/clockShiftLog/ShiftLogButtonsFilter";
import {
  ClearIcon,
  DropDownIcon,
  IconCalendar,
  NextIcon,
} from "../../dashboard/assets";
import { useAuth } from "../../../authentication/authHelpers";
import TimeSheetButtonsFilter from "./TimeSheetButtonsFilter";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import moment from "moment";
import dayjsTZ from "../../../utils/dayjs";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";

const { Option } = Select;
const { RangePicker } = DatePicker;

function TimeSheetHeader({ activeFilter, setActiveFilter, viewType }) {
  const auth = useAuth();
  const { departments } = useSelector((state) => state.departments);
  const { employees } = useSelector((state) => state.employeesList);

  const onChange = (val, key) => {
    setActiveFilter((prev) => {
      if (!!val) {
        return { ...(prev || {}), [key]: val };
      } else {
        const { [key]: _, ...rest } = prev || {};
        return rest;
      }
    });
  };

  return (
    <div className="time-sheet-header-comp" data-testid="timeSheetHeader">
      {viewType !== "user" && (
        <>
          <div className="search-input">
            <Select
              allowClear
              showSearch
              value={activeFilter?.employeeId || null}
              placeholder="Kërko punonjës"
              data-testid="selectEmployee"
              optionFilterProp="children"
              disabled={viewType === "user" ? true : false}
              onChange={(val) => onChange(val, "employeeId")}
              suffixIcon={<DropDownIcon />}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {employees.length > 0 && viewType !== "user" ? (
                employees.map((el, idx) => (
                  <Option
                    key={idx}
                    value={el?.employeeId}
                    data-testid={el?.employeeId}
                  >{`${el?.employeeFirstName} ${el?.employeeLastName}`}</Option>
                ))
              ) : (
                <Option value={auth?.employeeId} key={1}>
                  {auth?.userAccess[0]?.given_name}
                </Option>
              )}
            </Select>
          </div>
          <div className="department-select">
            <Select
              allowClear
              value={activeFilter?.employeeDepartmentName || null}
              showSearch
              placeholder="Zgjidh Departamentin"
              data-testid="selectDepartment"
              optionFilterProp="children"
              suffixIcon={<DropDownIcon />}
              onChange={(val) => onChange(val, "employeeDepartmentName")}
              disabled={viewType === "user" ? true : false}
            >
              {!!departments &&
                departments?.map((el, idx) => (
                  <Option
                    value={el?.departmentName}
                    data-testid={el?.departmentName}
                    key={idx}
                  >
                    {el?.departmentName + ""}
                  </Option>
                ))}
            </Select>
          </div>
        </>
      )}

      <div className="month-filter-btns">
        <TimeSheetButtonsFilter {...{ activeFilter, setActiveFilter }} />
      </div>
      <div className="filter-date-range">
        <RangePicker
          onChange={(val) => onChange(val, "range")}
          format={"DD/MM/YYYY"}
          data-testid="TimeSheetRangeFilter"
          placeholder={["Filtro Fillim", "Mbarim"]}
          disabledDate={(current) => {
            var startDate =
              viewType === "user"
                ? dayjsTZ().subtract(12, "months").startOf("month")
                : dayjsTZ().subtract(2, "months").startOf("month"); //Today.
            // var endDate = dayjsTZ().add(10, "days").calendar(); // 10 days in the future from now.
            // It will return false if its before today or after 10 days from now.
            return current < startDate;
          }}
          allowClear
          suffixIcon={<IconCalendar />}
          separator={<NextIcon />}
        />
      </div>
      {viewType !== "user" ? (
        <MondayButton
          className="mondayButtonRed"
          testid="pastroFiltrimin"
          onClick={() => setActiveFilter({})}
          disabled={!Object.keys(activeFilter || {}).length}
        >
          Pastro Filtrimin
        </MondayButton>
      ) : null}
    </div>
  );
}

export default TimeSheetHeader;
