import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { htmlParser } from "../../../../utils";
import { groupSingleShift } from "../../../Pagat/PaySub/employeeCalc/calculcate";
import { currencyformatter } from "../../../Pagat/PaySub/employeeCalc/methods";
import { getTranslation } from "../../generate/translations";
import { message } from "antd";
import { API } from "aws-amplify";
// import redux store
import { store } from "../../../../store";
//group object by keys
function groupObjects(objects, key) {
	return objects.reduce((acc, obj) => {
		let keyValue = obj[key];
		if (!acc[keyValue]) {
			acc[keyValue] = [];
		}
		acc[keyValue].push(obj);
		return acc;
	}, {});
}

const getReportsClockings = async (timeInterval) => {
	return new Promise(async (resolve, reject) => {
		await API.get(`clocking`, `/clocking`, {
			queryStringParameters: {
				limiter: dayjsTZ(timeInterval[0]).valueOf(),
				endLimiter: dayjsTZ(timeInterval[1]).valueOf(),
				// limiter: dayjs("01/1/2022", "DD/MM/YYYY").valueOf(),
				// start: params,
				// end: dayjsTZ().valueOf(),
			},
		})
			.then((r) => resolve(r))
			.catch((error) => console.error(error));
	});
};

const getShiftDuration = (shift) => {
	return !!shift?.clockOutDate
		? parseFloat(
				dayjsTZ(shift?.clockOutDate)
					.diff(dayjsTZ(shift?.clockInDate), "hours", true)
					.toFixed(2)
			)
		: 0;
}

export const approvals = (prevData, filter, columns, manualColumns, other) => {
	let result = {
		columnsDef: [],
		allColumns: [],
		// rowData: [],
		selectedColumns: [],
		allColumns: [],
	};
	result.selectedColumns = manualColumns || columns.columnDefs;
	result.allColumns = columns.allAppliedColumnDefs;
	
	const data = store?.getState().customReducer?.reportClockings;
	console.log({data})

	if (!!data && !!filter) {
		result = { ...result, rowData: [] };
		const missingShifts = [];
		other
			?.filter((d) => d?.employeeStatus === "Aktiv" && d?.employeePayrollType !== "Page Fikse")
			?.forEach((d) => {
				let validShifts = data?.filter((e) => e?.employeeId === d?.employeeId);
				let missingDates = getDates(
					filter?.timeInterval[0],
					filter?.timeInterval[1],
					validShifts,
					d
				);
				missingShifts.push(...missingDates);
				// console.log("missingDates", missingDates);
			});
		// console.log("missingShifts", missingShifts);
		const merged = data.concat(missingShifts)?.sort((a, b) => a.clockInDate - b.clockInDate);

		const preFilter = merged
			?.filter(
				(d) =>
					d.clockInDate >= dayjsTZ(filter?.timeInterval[0]).startOf("day").valueOf() &&
					d.clockInDate <= dayjsTZ(filter?.timeInterval[1]).endOf("day").valueOf()
			)
			.map((d) => {
				if (d?.dayType === "MUNGESE")
					return {
						...d,
						...d?.employeeDetails,
						employeeDayOfBirth: dayjsTZ(d?.employeeDetails?.employeeDayOfBirth).format("DD/MM/YYYY"),
						employeeHireDate: dayjsTZ(d?.employeeDetails?.employeeHireDate).format("DD/MM/YYYY"),
						clockInDate: dayjsTZ(d.clockInDate).format("HH:mm"),
						clockInPayment: currencyformatter.format(d?.totalities?.totalPay || 0) || 0,
						clockInRate: currencyformatter.format(d?.totalities?.employeeRate || 0) || 0,
						employee: `${d?.employeeDetails?.employeeFirstName} ${d?.employeeDetails?.employeeLastName}`,
						approved: d?.approved ? "Aprovuar" : "Pa Aprovuar",
						clockDate: dayjsTZ(d.clockInDate).format("DD/MM/YYYY"),
						clockOutDate: !!d.clockOutDate ? dayjsTZ(d.clockOutDate).format("HH:mm") : "Not closed",
						clockInNote: !!d?.clockInNote ? htmlParser(d?.clockInNote) : "",
						duration: "0.00",
						durationWithBreak: "0.00",
						before19: "0.00",
						between19and22: "0.00",
						after22: "0.00",
						dayType: getDayTypeTranslations(d?.dayType || ""),
						breakTime: "-",
						breakTimeVal: 0,
					};
				const { EXTRA_HOURS = 0, LATE_HOURS = 0, NORMAL_HOURS = 0 } = groupSingleShift(d) || null;
				// console.log("groupSingleShift", groupSingleShift(d));
				// console.log("NORMAL_HOURS", d);
				// console.log("d", d);
				const slicedHours = {
          before19: parseFloat(NORMAL_HOURS?.amount?.toFixed(2) || 0),
          between19and22: parseFloat(LATE_HOURS?.amount.toFixed(2) || 0),
          after22: parseFloat(EXTRA_HOURS?.amount?.toFixed(2) || 0),
        };
				return {
					...d,
					...d?.employeeDetails,
					employeeDayOfBirth: dayjsTZ(d?.employeeDetails?.employeeDayOfBirth).format("DD/MM/YYYY"),
					employeeHireDate: dayjsTZ(d?.employeeDetails?.employeeHireDate).format("DD/MM/YYYY"),
					clockInDate: dayjsTZ(d.clockInDate).format("HH:mm"),
					clockInPayment: currencyformatter.format(d?.totalities?.totalPay || 0) || 0,
					clockInRate: currencyformatter.format(d?.totalities?.employeeRate || 0) || 0,
					employee: `${d?.employeeDetails?.employeeFirstName} ${d?.employeeDetails?.employeeLastName}`,
					approved: d?.approved ? "Aprovuar" : "Pa Aprovuar",
					clockDate: dayjsTZ(d.clockInDate).format("DD/MM/YYYY"),
					clockOutDate: !!d.clockOutDate ? dayjsTZ(d.clockOutDate).format("HH:mm") : "Not closed",
					clockInNote: !!d?.clockInNote ? htmlParser(d?.clockInNote) : "",
					duration: parseFloat(Object.values(slicedHours).reduce((acc, curr) => acc + curr, 0).toFixed(2)),
					durationWithBreak: getShiftDuration(d),
					...slicedHours,
					dayType: getDayTypeTranslations(d?.dayType || ""),
					breakTime:
						!!d?.totalities?.breakTime?.length > 0
							? `${d?.totalities?.breakTime?.[0] + " - " + d?.totalities?.breakTime?.[1]}`
							: "",
					breakTimeVal: d?.totalities?.breakTimeVal?.toFixed(2) || 0,
				};
			});

		if (!!manualColumns && Array.isArray(manualColumns)) {
			manualColumns.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			preFilter.forEach((emplRecord) => {
				let toAdd = [];
				manualColumns.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
		} else {
			columns?.columnDefs.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			preFilter.forEach((emplRecord) => {
				// console.log("preFilter", preFilter);
				let toAdd = [];
				columns?.columnDefs?.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
		}

		console.log("result", result);
		return result;
	}
	return result;
};

export const getDayTypeTranslations = (englishDayType) => {
	switch (englishDayType) {
		case "Regular":
			return "Normal";
		case "Sick":
			return "Sëmurë";
		case "HolidayRequest":
			return "Kërkesë Leje";
		case "NationalHoliday":
			return "Festë Zyrtare";
		case "NationalHolidayWorking":
			return "Festë Zyrtare Punë";
		case "Maternity":
			return "Leje Lindje";
		default:
			return englishDayType;
	}
};

export function getDates(startDate, endDate, validShifts, employeeDetails) {
	// console.log("get dates", { startDate, endDate, validShifts, employeeDetails });
	var dates = [];
	var tempEnd = dayjsTZ().endOf("day").valueOf();
	var currentDate = dayjsTZ(startDate).valueOf();
	let employeeHireDate = !!employeeDetails?.employeeHireDate
		? dayjsTZ(employeeDetails?.employeeHireDate).valueOf()
		: currentDate;
	// console.log("employeeHireDate", employeeHireDate);
	while (currentDate <= tempEnd) {
		if (dayjsTZ(currentDate).day() !== 0 && dayjsTZ(currentDate).day() !== 6) {
			if (
				!validShifts?.some(
					(e) =>
						dayjsTZ(e.clockInDate).format("DD/MM/YYYY") === dayjsTZ(currentDate).format("DD/MM/YYYY")
				) &&
				currentDate >= employeeHireDate
			) {
				dates.push(dayjsTZ(currentDate).format("DD/MM/YYYY"));
			}
		}
		currentDate = dayjsTZ(currentDate).add(1, "days").valueOf();
	}
	const toReturn = dates?.map((el) =>
		clockInObj(
			dayjsTZ(el, "DD/MM/YYYY 13:00"),
			dayjsTZ(el, "DD/MM/YYYY 13:00"),
			"MUNGESE",
			"manualAdmin",
			true,
			employeeDetails
		)
	);
	return toReturn;
	console.log("toReturn", dates);
}

export const clockInObj = (
	clockInDate,
	clockOutDate,
	dayType = "MUNGESE",
	clockingCategory = "manualAdmin",
	approved = true,
	employeeDetails
) => {
	return {
		clockInDate,
		clockOutDate,
		dayType,
		clockingCategory,
		approved,
		employeeId: employeeDetails?.employeeId,
		employeeDetails,
		duration: "0.00",
		before19: "0.00",
		between19and22: "0.00",
		after22: "0.00",
		breakTime: "",
		breakTimeVal: 0,
	};
};
