import { useAuth } from "../../../authentication/authHelpers";
import { postAnnouncement, editAnnouncement } from "../utils/methods";
import { useSelector } from "react-redux";
import { notificationNoPostToDepartment } from "../../../utils/sendNotification";
import { Select, Input, Modal, DatePicker, Form, message } from "antd";
import { ModalXIcon } from "../../../assets/icons";
import dayjsTZ from "../../../utils/dayjs";
import { RichTextEditor } from "../../commonComponents";
import "./NewAnnouncement.scss";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import { useEffect } from "react";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";

const NewAnnouncement = ({
  mode,
  data,
  gridApi,
  onReset,
  visible,
  setVisible,
  retrievedData,
}) => {
  const auth = useAuth();
  const [form] = Form.useForm();
  const { Option } = Select;

  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { departments } = useSelector((state) => state.departments);
  const { users } = useSelector((state) => state.usersList);

  const getUserName = () => {
    const { user = {} } = auth;
    const name =
      user?.given_name + " " + user?.family_name;
    return name;
  };

  //region SUBMIT
  const onSubmit = () => {
    form
      .validateFields()
      .then(async (res) => {
        const createdBy = getUserName();
        const newAnnouncement = {
          ...res,
          userResponses: {},
          announcementId: data?.announcementId || "",
          announcementTarget: { departments: res?.announcementTarget },
          announcementTeam: { teams: res?.announcementTeam },
          createdBy,
          endAnnouncementDate: res?.endAnnouncementDate?.valueOf(),
        };
        if (mode.modeType === "new") {
          await postAnnouncement(newAnnouncement)
            .then((r) => {
              // apply transaction post
              gridApi.applyTransaction({ add: [r], addIndex: 0 });
              message.success("Announcement created successfully");
              const announcementTeamTarget =
                activeEmployees?.filter((a) =>
                  res?.announcementTeam?.includes(a?.employeeTeam)
                ) || [];
              let usersToPost = users?.allUsers?.Items?.filter((e) => {
                return (
                  res?.announcementTarget?.includes(e?.department) &&
                  announcementTeamTarget?.some((a) => a?.userSub === e?.userSub)
                );
              });
              notificationNoPostToDepartment(
                {
                  notificationTitle: res?.announcementTitle,
                  content: "Ju keni nje lajmerim te ri",
                },
                usersToPost
              );
              // sendNotification({
              // 	headings: { en: announcementTitle },
              // 	contents: { en: "Ju keni nje lajmerim te ri" },
              // 	url: announcementUrl || "https://portal.flex.al/kryefaqja",
              // 	// included_segments: ["s"]
              // 	// data: {
              // 	//   ids: [
              // 	//     { user: auth.userAccess[0].email, read: false, removed: false },
              // 	//   ],
              // 	// },
              // });
              setVisible(false);
              onReset();
              // success;
            })
            .catch((e) => {
              // error
              console.log(e);
              message.error("Error creating announcement");
            });
        } else {
          let editableData = {
            announcementTitle: res?.announcementTitle,
            announcementBody: res?.announcementBody,
          };
          await editAnnouncement(data?.announcementId, editableData).then(
            (r) => {
              // apply transaction post
              retrievedData();
              message.success("Announcement edited successfully!");
              setVisible(false);
              onReset();
              // success;
            }
          );
        }
      })
      .catch((err) => console.log("Error validating form: ", err));
  };

  const onCleanUp = () => {
    setVisible(false);
    onReset();
    form.resetFields();
  };

  useEffect(() => {
    const { createdAt, endAnnouncementDate, ...rest } = data;
    for (const [key, value] of Object.entries(rest)) {
      if (key === "announcementTarget") {
        form.setFieldValue(key, value.departments);
      } else if (key === "announcementTeam") {
        form.setFieldValue(key, value.teams);
      } else {
        form.setFieldValue(key, value);
      }
    }
  }, []);

  //region RETURN
  return visible ? (
    <Modal
      open={visible}
      onCancel={onCleanUp}
      width={"1200px"}
      destroyOnClose={true}
      centered
      wrapClassName="new-announcemenet-modal"
      title={mode.modeType === "new" ? "Krijo Lajmërim" : "Ndrysho Lajmërim"}
      closeIcon={<ModalXIcon />}
      className="lightHeader"
      footer={[
        <MondayButton onClick={onCleanUp} className="mondayButtonRed">
          Anullo
        </MondayButton>,
        <MondayButton
          className="mondayButtonGreen"
          // disabled={
          //   data?.announcementBody === "" ||
          //   data?.announcementTitle === "" ||
          //   !data?.announcementTarget?.departments?.length ||
          //   !data?.announcementTeam?.teams?.length ||
          //   !data?.endAnnouncementDate
          // }
          Icon={mode.modeType === "new" ? <PlusOutlined /> : <CheckOutlined />}
          onClick={onSubmit}
        >
          {mode.modeType === "new"
            ? "Aktivizo Lajmërimin"
            : "Ndrysho Lajmërimin"}
        </MondayButton>,
      ]}
    >
      <Form form={form}>
        <div className="new-announcemenet-content">
          <div className="formItem">
            <h3>Titulli</h3>
            <Form.Item
              name="announcementTitle"
              rules={[
                {
                  required: true,
                  message: "Kjo fushë është e detyrueshme.",
                },
              ]}
            >
              <Input
                // defaultValue={data?.announcementTitle}
                placeholder="Shkruaj titullin"
              />
            </Form.Item>
          </div>
          <div className="formItem">
            <h3>Përmbajtja</h3>
            <Form.Item
              name="announcementBody"
              rules={[
                {
                  required: true,
                  message: "Kjo fushë është e detyrueshme.",
                },
              ]}
            >
              <RichTextEditor
                // defaultValue={data?.announcementBody}
                className={"modal-quill-edit"}
              />
            </Form.Item>
          </div>
          <div className="newAnnouncementDropdowns">
            <div className="formItem">
              <h3>Kategoria</h3>
              <Form.Item
                name="announcementCategory"
                rules={[
                  {
                    required: true,
                    message: "Kjo fushë është e detyrueshme.",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  // defaultValue={data?.announcementCategory}
                  disabled={mode.modeType !== "new"}
                  placeholder="Zgjidh kategorine"
                >
                  <Option value="importantAnnouncement">Lajmërim</Option>
                  <Option value="needAction">
                    Lajmërim që ka nevojë për përgjigje
                  </Option>
                  <Option value="otherWish">Urim</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="formItem">
              <h3>Url</h3>
              <Form.Item
                name="announcementUrl"
                rules={[
                  {
                    required: true,
                    message: "Kjo fushë është e detyrueshme.",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="vendos linkun e lajmërimit"
                  disabled={mode.modeType !== "new"}
                  // defaultValue={data?.announcementUrl}
                />
              </Form.Item>
            </div>
            <div className="formItem">
              <h3>Departamenti</h3>
              <Form.Item
                name="announcementTarget"
                rules={[
                  {
                    required: true,
                    message: "Kjo fushë është e detyrueshme.",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  mode="multiple"
                  disabled={mode.modeType !== "new"}
                  // defaultValue={data?.announcementTarget?.departments || []}
                  placeholder="Zgjidh Departamentet"
                >
                  {departments?.map((item) => {
                    return (
                      <Option
                        key={item.departmentId}
                        value={item.departmentName}
                      >
                        {item.departmentName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="formItem">
              <h3>Kompania</h3>
              <Form.Item
                name="announcementTeam"
                rules={[
                  {
                    required: true,
                    message: "Kjo fushë është e detyrueshme.",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  mode="multiple"
                  disabled={mode.modeType !== "new"}
                  // defaultValue={data?.announcementTeam?.teams || []}
                  placeholder="Zgjidh kompanite"
                >
                  <Option value="Flex Tirana">Flex Tirana</Option>
                  <Option value="Flex Prishtina">Flex Prishtina</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="formItem">
              <h3>Data përfundimtare</h3>
              <Form.Item
                name="endAnnouncementDate"
                rules={[
                  {
                    required: true,
                    message: "Kjo fushë është e detyrueshme.",
                  },
                ]}
              >
                <DatePicker
                  format={"DD/MM/YYYY"}
                  disabledDate={(e) => {
                    let disabled = false;
                    if (e?.valueOf() < dayjsTZ().startOf("day").valueOf())
                      disabled = true;
                    return disabled;
                  }}
                  style={{
                    width: "100%",
                    borderRadius: 5,
                    backgroundColor: "#f5f5f7",
                    border: "none",
                  }}
                  defaultValue={
                    !!data?.endAnnouncementDate
                      ? dayjsTZ(data?.endAnnouncementDate)
                      : null
                  }
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  ) : null;
};

export default NewAnnouncement;
