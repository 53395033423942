import dayjsTZ from "../../../../utils/dayjs";

export const columnDefs = ({ handleMonthModal, isMobileView }) => [
  {
    headerName: "Muaji",
    field: "muaji",
    mobile: true,
    isCollapseTitle: true,
    valueGetter: ({ data }) => dayjsTZ(data?.muaji).format("MMMM YYYY"),
    ...(!isMobileView
      ? {
          cellRenderer: ({ data }) => (
            <div style={{ cursor: "pointer" }}>
              <div variant="outlined">
                <span
                  style={{ color: "#1da193", fontWeight: 600 }}
                  onClick={() => handleMonthModal(data)}
                >
                  {dayjsTZ(data?.muaji).format("MMMM YYYY")}
                </span>
              </div>
            </div>
          ),
        }
      : {}),
  },
  {
    headerName: "Shuma Neto",
    field: "neto",
    mobile: true,
    cellRenderer: ({ data }) =>
      data?.neto !== "Pa caktuar" ? "E gjeneruar" : data?.neto,
  },
  {
    headerName: "Shuma Bruto",
    field: "bruto",
    mobile: true,
    cellRenderer: ({ data }) =>
      data?.bruto !== "Pa caktuar" ? "E gjeneruar" : data?.bruto,
  },
];
