import React, { useState } from "react";
import { Button, Modal, message } from "antd";
import "./SiderModal.scss";
import { ModalXIcon } from "../../../../../../assets/icons";
import { sortBy } from "lodash";
import { fileIcons } from "../../../../../Documentation/View/documentationViewData";
import { openInNewTab } from "../../../../../Documentation/utils/openInNewTab";
import { RedCloseIcon } from "../../../../../Documentation/View/assets";
import MultipleFilePrevieModal from "../MultipleFilePreviewModal/MultipleFilePreviewModal";
import PermissionsDocModal from "../../../../../Intervistat/Kandidatet/Kategorite/PermissionsDocModal";
import { DeleteIcon } from "../../../../../Intervistat/Kandidatet/Kategorite/Image";
import { API } from "aws-amplify";
import { useAuth } from "../../../../../../authentication/authHelpers";
import moment from "moment";
import dayjsTZ from "../../../../../../utils/dayjs";
import { problematikat as problematikatReducer } from "../../../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { driveApi } from "../../../../../DriveRequest";

const SiderModal = ({
  editData,
  onDelete,
  setEditData,
  uploadedFiles,
  setIsFieldTouched,
  isSiderModalVisible,
  setIsSiderModalVisible,
}) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const [previewModalVisible, setPreviewModalVisible] = useState();
  const [currentFile, setCurrentFile] = useState({});

  const handleCancel = () => {
    setIsSiderModalVisible(false);
  };

  //region ON DELETE
  const handleRemove = ({ name, id }) => {
    Modal.confirm({
      className: "confirmModal",
      icon: <DeleteIcon />,
      title: "Ju po fshini një dokument!",
      centered: true,
      cancelButtonProps: {
        type: "primary",
        style: {
          background: "#ea3943",
          borderColor: "#ea3943",
          borderRadius: "5px",
        },
      },
      onCancel: () => false,
      okButtonProps: {
        className: "ant-btn-confirm",
      },
      cancelText: "Jo, mos e fshi",
      okText: "Po, fshije",
      content: (
        <>
          <p>
            "Ju po fshini dokumentin me titull <span>{name}</span> Nëse jeni të
            sigurt për këtë veprim, klikoni <span>Po, fshije.</span>"
          </p>
          {/* <img src={ImageIcon} /> */}
        </>
      ),
      onOk: () => removeFile(id),
    });
  };
  //region >delete()
  const removeFile = async (id) => {
    const filteredArray =
      id && uploadedFiles.filter((files) => files.id !== id);
    const keylog = buildKeylog(filteredArray);
    await API.put("tickets", `/tickets/${editData?.ticketId}`, {
      body: {
        googleDriveFiles: [...filteredArray],
        keylog: [...(editData?.keylog || []), keylog],
      },
    })
      .then(() => {
        try {
          onDelete(id);
          driveRequest.deleteDriveItem(id);
        } catch (err) {
          console.log(err);
        }
        const updatedTicket = {
          ...editData,
          googleDriveFiles: [...filteredArray],
          keylog: [...(editData?.keylog || []), keylog],
        };
        dispatch(problematikatReducer(updatedTicket));
        setEditData(updatedTicket);
        setIsFieldTouched(true);
      })
      .catch((err) => {
        message.error("err: ", err);
        console.log("err: ", err);
      });
  };

  //region >buildKeylog()
  const buildKeylog = (array) => {
    return {
      activity: "Ndryshuar",
      author: `${auth?.user?.given_name} ${auth?.user?.family_name}`,
      changeDate: dayjsTZ().valueOf(),
      field: "Skedarë",
      oldValue: editData?.googleDriveFiles?.map((el) => el.name),
      newValue: array.map((el) => el.name),
    };
  };

  //region ON DOC CLICK
  const handleDocumentClick = async (file) => {
    setPreviewModalVisible(true);
    setCurrentFile(file);
  };

  return (
    <div>
      <Modal
        centered={true}
        width="315px"
        className="siderModal lightHeader"
        mask={false}
        maskClosable={false}
        title="Dokumente"
        open={isSiderModalVisible}
        destroyOnClose={true}
        onCancel={handleCancel}
        wrapClassName={"siderProblemsModal"}
        closeIcon={<ModalXIcon />}
        footer={[
          <Button className="decline" onClick={handleCancel}>
            Mbyllni faqen
          </Button>,
        ]}
      >
        <div className="uploadedFilesSection">
          {sortBy(uploadedFiles, "lastEditedUtc")
            .reverse()
            ?.map((file) => {
              const { name = "", id } = file;
              const fileExtension = name.split(".").pop();

              return (
                <div className="fileCard" key={id}>
                  <div className="uploadedFile">
                    <div
                      className="uploadFileName"
                      onClick={() => handleDocumentClick(file)}
                    >
                      <span className="uploadedFileIcon">
                        {fileIcons({ type: fileExtension }) ||
                          fileIcons({ type: "image" })}
                      </span>
                      <span className="fileName">{name}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: 20,
                      }}
                    >
                      <Button
                        className="closeIconContainer"
                        type="ghosted"
                        onClick={() => handleRemove({ name, id })}
                        icon={<RedCloseIcon className="closeIcon" />}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <MultipleFilePrevieModal
            {...{
              visible: previewModalVisible,
              setVisible: () => {
                setCurrentFile({});
                setPreviewModalVisible(false);
              },
              setCurrentFile,
              file: currentFile,
              uploadedFiles,
              driveRequest,
            }}
          /> */}
          {!!previewModalVisible ? (
            <PermissionsDocModal
              {...{
                setVisible: () => {
                  setCurrentFile({});
                  setPreviewModalVisible(false);
                },
                currentFile,
                endpoint: "tickets",
                recordId: editData?.ticketId,
                currentKeylogs: editData?.keylog,
                folderId: editData?.googleDriveFolderId,
              }}
            />
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default SiderModal;
