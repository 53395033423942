import React from "react";
import { HollowDotsSpinner } from "react-epic-spinners";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PartTime } from "../../../assets/icons";
import DragCard from "./Draggable/DragCard";

const EmployeeMeKoheTePjesshme = () => {
  // get active employes from redoux
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const navigate = useNavigate();

  // filters the employes with type of employment with "Kohe te plote"
  const employeePjesshem = activeEmployees?.filter(
    (el) => el?.employeeTypeOfEmployment === "Kohe te pjesshme"
  );

  const data = {
    icon: <PartTime />,
    number: (
      <div className="link-kryefaqja-card-send">
        {employeePjesshem?.length ? employeePjesshem?.length : 0}
      </div>
    ),
    text: "Punonjës me kohë të pjesshme",
  };

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return (
    <div style={{ height: "100%", width: "100%" }}>
      {!!employeePjesshem ? (
        <DragCard
          data={data}
          // will navigate to employes tab with the state to filter the grid with the type in the state
          onClick={() =>
            navigate("/punonjesit", {
              state: "Kohe te pjesshme",
            })
          }
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1da193" size={24} />
        </div>
      )}
    </div>
  );
};

export default EmployeeMeKoheTePjesshme;
