import React, { createContext, useState, useEffect, useRef } from "react";
// import Approvals from "./approvals/Approvals";
// import { API } from "aws-amplify";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import { IconExcel, IconNgargo } from "../../assets/icons";
import { Tooltip } from "antd";
import AgGridComponent from "../AG-grid/AgGridComponent";
// import ShiftModal from "../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftModal";
import { getMonthsOfYear } from "../punonjesit/EmployeeView/Payment/utils/calculations";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  calculateMonthAllPayment,
  getMonthlyAmounts,
  // calculateWagePerHours,
} from "./wages/utils/calculations";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import HtmlExelTabel from "../Konfigurimet/dynamic components/HtmlExelTabel";
import PagatGridHeader from "./wages/PagatGridHeader";
import ExportToPdf from "../Konfigurimet/dynamic components/ExportToPdf";
import { useMediaQuery } from "react-responsive";
import MobilePagatGrid from "./wages/MobilePagatGrid";
import { onFilter } from "../Documentation/utils/onFilter";
import AgGridHeader from "../commonComponents/AgGridHeader/AgGridHeader";
import getAgThemePreference from "../../utils/getAgThemePreference";
import colorShade from "../../utils/colorShade";
import LoaderComponent from "../commonComponents/LoadableComp/LoadableComp";
const PagatContext = createContext(null);

const EXPORT_COLS = [
  { en: "muaji", format: "MMMM YYYY", al: "Muaji" },
  { en: "bruto", al: "Shuma Bruto" },
  { en: "neto", al: "Shuma Neto" },
  { en: "taxes", al: "Taksat e Punëdhënësit" },
  { en: "totalCost", al: "Kostoja Totale" },
];

const ITEMS = {
  search: {},
  icons: {
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};

const { mode, accentColor } = getAgThemePreference();

function PagatGrid() {
  // const { Option } = Select;
  const [rowData, setRowData] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const { clockings } = useSelector((state) => state.clockings);
  const { employees } = useSelector((state) => state.employeesList);
  const { wages } = useSelector((state) => state.wages);
  let navigate = useNavigate();
  // columns displayed in the grid
  const columnDefs = [
    {
      headerName: "Muaji",
      field: "muaji",
      mobile: true,
      valueGetter: ({ data }) => dayjsTZ(data.muaji).format("MMMM YYYY"),
      isCollapseTitle: true,
      cellRenderer: ({ data }) => (
        <div
          style={{
            color: mode.includes("dark")
              ? "#FFF"
              : colorShade(accentColor, 0.25),
            fontWeight: 600,
            cursor: "pointer",
          }}
          onClickCapture={() => handleMonthModal(data)}
        >
          {dayjsTZ(data.muaji).format("MMMM YYYY") !== "Invalid Date" ? (
            dayjsTZ(data.muaji).format("MMMM YYYY")
          ) : (
            <span>{data.muaji}</span>
          )}
        </div>
      ),
    },
    {
      headerName: "Shuma Bruto",
      field: "bruto",
      mobile: true,
    },
    {
      headerName: "Shuma Neto",
      field: "neto",
      mobile: true,
    },

    {
      headerName: "Taksat e punedhenesit",
      field: "taxes",
      mobile: true,
    },
    {
      headerName: "Kostoja totale",
      field: "totalCost",
      mobile: true,
    },
  ];
  // calculateMonthAllPayment(employees, clockings, { data: { muaji: dayjsTZ() } });

  // make the grid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onBtExport = () => {
    gridApi.exportDataAsExcel();
  };

  const handleCellClick = (params) => {
    // console.log("Click", params);
  };

  const handleMonthModal = (data) => {
    return navigate("/pagat/llogaritja", { state: { data } });
  };

  useEffect(() => {
    if (!!wages) {
      let temp = [...getMonthsOfYear()] || [];
      if (temp.length > 0) {
        let appliedWages = wages.filter((el) => el?.wageStatus === "Finalized");
        temp.forEach((el) => {
          el.neto = getMonthlyAmounts("neto", appliedWages, el.muaji) || 0;
          el.bruto = getMonthlyAmounts("bruto", appliedWages, el.muaji) || 0;
          el.taxes = getMonthlyAmounts("taxes", appliedWages, el.muaji) || 0;
          el.totalCost =
            getMonthlyAmounts("totalCost", appliedWages, el.muaji) || 0;
        });
      }
      setRowData([...temp]);
    }
  }, [wages]);

  return (
    <div className="globalPageWrapper">
      <LoaderComponent loading={!rowData}>
        <AgGridComponent
          gridApi={gridApi}
          rowData={rowData}
          rowSelection={{ mode: "singleRow" }}
          onGridReady={onGridReady}
          paginationPageSize={12}
          columnDefs={columnDefs}
          //   onRowValueChanged={onRowValueChanged}
          // onSelectionChanged={handleSelectionChanged}
          onCellClicked={handleCellClick}
          onCell
          suppressServerSideInfiniteScroll={"full"}
          enableCellChangeFlash={true}
          rowModelType={"serverSide"}
          headerProps={{
            items: ITEMS,
            exportTitle: "Lista e Pagave",
          }}
          collapsible={{
            content: [
              <PagatGridHeader
                rowData={rowData}
                onFilter={onFilter}
                gridApi={gridApi}
              />,
            ],
            label: "Filtra të shumës",
          }}
          // setRowData={setRowData}
          onRowClick={(data) => handleMonthModal(data)}
          idKey={"nr"}
        />
      </LoaderComponent>
    </div>
  );
}

export default PagatGrid;
