import { Tooltip } from "antd";
import "./StatusSquircle.scss";

const StatusSquircle = ({ statusName, value, color = "", onClick }) => {
  return (
    <div
      className={`statusSquircleContainer ${
        !!onClick ? "clickable" : ""
      }`.trim()}
      style={{ backgroundColor: color }}
      onClick={() => !!onClick && onClick(statusName)}
    >
      <div className="statusValue">{value}</div>
      <Tooltip
        {...{
          title: statusName,
          classNames: { root: "statusCardOverlay" },
          placement: "bottom",
        }}
      >
        <div className="statusName">{statusName}</div>
      </Tooltip>
    </div>
  );
};

export default StatusSquircle;
