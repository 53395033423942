import { forwardRef, useCallback } from "react";
import ReactQuill from "react-quill";
import "./QuillTextEditor.scss";
import "react-quill/dist/quill.snow.css";
import { quillFormats, quillModules } from "./quillTextEditorData";

const QuillTextEditor = forwardRef(
  (
    {
      value = "",
      onChange,
      people = [],
      className = "",
      placeholder = "",
      disabled = false,
      defaultValue = "",
    },
    ref
  ) => {
    const formattedPeople = people.map((person, index) => ({
      id: index,
      value: person?.nameOfUser,
      cognitoId: person?.cognitoUserId,
    }));

    const modulesSource = useCallback(
      (searchTerm, renderList, mentionChar) => {
        let values;

        if (mentionChar === "@") {
          values = formattedPeople;
        }
        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < values.length; i++)
            if (
              ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
            )
              matches.push(values[i]);

          renderList(matches, searchTerm);
        }
      },
      [people]
    );

    const modules = {
      ...quillModules,
      mention: !people.length
        ? undefined
        : {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@"],
            spaceAfterInsert: true,
            dataAttributes: ["cognitoId"],
            source: modulesSource,
          },
    };

    return (
      <ReactQuill
        {...{
          ref,
          value,
          modules,
          onChange,
          className: `note_input quillTextEditor ${className}`.trim(),
          placeholder,
          theme: "snow",
          readOnly: disabled,
          formats: quillFormats,
          defaultValue,
        }}
      />
    );
  }
);

export default QuillTextEditor;
