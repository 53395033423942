import { Collapse } from "antd";
import getAgThemePreference from "../../utils/getAgThemePreference";
import hexToRgba from "../../utils/hexToRgba";

const { foregroundColor, mode, backgroundColor } = getAgThemePreference();

const collapseWrapper = ({ content, label, isMobileView }) => {
  if (Array.isArray(content) && !!isMobileView) {
    const items = content.map((el, idx) => ({
      key: idx,
      label: label,
      children: el,
    }));
    const color = mode.includes("dark") ? backgroundColor : foregroundColor;
    return (
      <Collapse
        className="mobileAgCollapse"
        style={{
          color: foregroundColor,
          border: `1px solid ${color}`,
          background: mode.includes("dark")
            ? backgroundColor
            : hexToRgba(foregroundColor, 0.05),
        }}
        items={items}
        accordion
      />
    );
  } else {
    return content;
  }
};

export default collapseWrapper;
