import { API } from "aws-amplify";
import moment from "moment-timezone";
// import { groupShifts } from "../../../Pagat/PaySub/employeeCalc/calculcate";
import { groupShifts } from "../../../Pagat/PaySub/employeeCalc/slicing";
import dayjsTZ from "../../../../utils/dayjs";
import { calculateBreakHours, filterBreaks } from "./filters";
/**
 * Calculates the hours and respective categories for the Google Chart Implementation
 * @param {Array} clockings | Array of objects with all shifts
 * @param {String} employeeId | String identifier for employee
 * @param {Object} timeframe | Object that contains start & end time instance
 * @returns {Array} | Returns Array of objects with categories, hours and colors for the chart specificiation
 */

export const calculateEmployeeChart = (
  clockings,
  employeeId,
  timeframe,
  requests
) => {
  let totalHours = 0;
  let missingHours = 0;
  let raportHours = 0;
  // const test13 = new paySubFactory({
  //   type: "allEarnings",
  //   employee: appliedEmployee,
  //   clockings: approvedClockings,
  //   employeeRequests: employeeRequests,
  //   momentInstance: toGenerate?.timeframe,
  //   prepayments: prepayments,
  // });
  // console.log("TEST 1989", test13)

  let result = {
    total: 0,
    calculations: [],
    allhours: { normalHours: 0, lateHours: 0, extraHours: 0, totalHours: 0 },
  };
  const appliedClockings = clockings.filter(
    (el) =>
      el.clockInDate !== null &&
      el.clockOutDate !== null &&
      el.employeeId === employeeId &&
      dayjsTZ(el.clockInDate).valueOf() > timeframe.start &&
      dayjsTZ(el.clockOutDate).valueOf() < timeframe.end
  );

  result.calculations = groupShifts(appliedClockings);
  console.log("resultttt", result);
  if (result?.calculations.length > 0) {
    result?.calculations.map((calcArr) =>
      // filterBreaks(
      //   calculateBreakHours(calcArr)?.remains,
      //   calcArr
      //     .map((el) => parseFloat(el.duration))
      //     .reduce((a, b) => a + b)
      //     .toFixed(2)
      // )
      calcArr
      ?.map((c) => {
        if (
          Object(result?.allhours).hasOwnProperty(c?.type) &&
          c?.duration > 0 &&
          (!c?.employeeInfo?.isBreakTimePaid ? !c?.isBreakTime : true)
        ) {
          result.allhours[c?.type] += c?.duration;
        }
      })
    );
  }
  result.allhours.totalHours =
    result.allhours.normalHours +
    result.allhours.lateHours +
    result.allhours.extraHours;

  return [
    {
      value: parseFloat(result?.allhours["normalHours"] || 0),
      label: "Deri në 19:00",
      key: "normalHours",
      color: "#1da193",
    },
    {
      value: parseFloat(result?.allhours["lateHours"] || 0),
      label: "19:00 - 22:00",
      key: "lateHours",
      color: "#FCC94A",
    },
    {
      value: parseFloat(result?.allhours["extraHours"] || 0),
      label: "22:00 - 07:30",
      key: "extraHours",
      color: "#ea3943",
    },
    {
      value: result?.allhours?.totalHours,
      label: "TOTAL",
      key: "totalHours",
      color: "#424C55",
    },
  ];
};

export const findEmployee = async (employeeId) => {
  await API.get("employees", "/employees").then((r) => r);
};
