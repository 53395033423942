import { DatePicker, Modal } from "antd";
import "./FetchDateRangeModal.scss";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { ClearIcon, IconCalendar, NextIcon } from "../../../dashboard/assets";
import { useState } from "react";
import dayjsTZ from "../../../../utils/dayjs";

const { RangePicker } = DatePicker;

const FetchDateRangeModal = ({
  open,
  setOpen,
  hasRange,
  pickers = [],
  fetch,
}) => {
  const [filters, setFilters] = useState({});
  const [spin, setSpin] = useState(false);

  const handleFilter = async () => {
    setSpin(true);
    if (!!fetch) await fetch(filters);

    setSpin(false);
    setOpen(false);
  };

  return (
    <Modal
      className="fetchDateRangeModal lightHeader"
      open={open}
      title={"Filtro të dhënat"}
      onCancel={() => setOpen(false)}
      destroyOnClose={true}
      footer={[
        <MondayButton
          className="mondayButtonRed"
          onClick={() => setOpen(false)}
        >
          Mbyll
        </MondayButton>,
        <MondayButton
          className="mondayButtonGreen"
          spin={spin}
          onClick={handleFilter}
        >
          Filtro
        </MondayButton>,
      ]}
      centered
    >
      <div className="filtersWrapper">
        <div className="pickers">
          {pickers?.map(({ picker, format }) => (
            <DatePicker
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  range: !!e ? [e.startOf(picker), e.endOf(picker)] : e,
                }))
              }
              allowClear={{ clearIcon: <ClearIcon /> }}
              suffixIcon={<IconCalendar />}
              picker={picker}
              format={format}
              value={filters?.[picker]}
            />
          ))}
        </div>
        <div className="rangePicker">
          {!!hasRange ? (
            <RangePicker
              onChange={(e) => {
                return setFilters((prev) => ({
                  ...prev,
                  range: e,
                  ...(dayjsTZ(e?.[0]).month() === dayjsTZ(prev?.month).month()
                    ? {}
                    : { month: null }),
                }));
              }}
              format={"DD/MM/YYYY"}
              allowClear={{ clearIcon: <ClearIcon /> }}
              suffixIcon={<IconCalendar />}
              separator={<NextIcon />}
              value={filters?.range}
            />
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default FetchDateRangeModal;
