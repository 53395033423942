import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { useEffect, useMemo, useState } from "react";
import "./FilterDateButtons.scss";

const FilterDateButtons = ({ tabs = 4, filterMonths, activeFilter }) => {
  const [filter, setFilter] = useState(dayjsTZ());

  const ButtonFilter = (idx) => {
    const month = dayjsTZ().subtract(tabs - 1 - idx, "months");
    if (filter.format("MMMM") !== month.format("MMMM")) {
      setFilter(month);
      filterMonths(month);
    }
  };

  const getComputedClassname = (idx) => {
    if (
      dayjsTZ().subtract(idx, "months").format("MMMM") ===
      dayjsTZ(filter).format("MMMM")
    ) {
      return "active-filter";
    }
    return "inactive-filter";
  };

  const buttons = useMemo(() => {
    return (
      <div className="date-buttons-container">
        {Array.apply(null, Array(tabs)).map((_, idx) => (
          <button
            key={idx}
            className={getComputedClassname(tabs - 1 - idx)}
            onClick={() => ButtonFilter(idx)}
          >
            {dayjsTZ()
              .subtract(tabs - 1 - idx, "months")
              .format("MMMM")}
          </button>
        ))}
      </div>
    );
  }, [filter]);

  useEffect(() => {
    setFilter(activeFilter?.range?.[0]);
  }, [activeFilter?.range?.[0]]);

  return buttons;
};

export default FilterDateButtons;
