import { Tooltip } from "antd";
import React from "react";
import "./TimeSheet.scss";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";

function OnGoingShift({
  el,
  handleShiftPreview,
  calculateBorderRad,
  toVisualize,
  statusColors,
  idx,
}) {
  return (
    <div>
      <>
        <Tooltip
          // open={el?.percentage < 8 ? true : false}
          title={
            el?.percentage < 8
              ? `${dayjsTZ(el?.clockInDate).format("HH:mm")} - ${dayjsTZ(
                  el?.clockOutDate
                ).format("HH:mm")}`
              : ""
          }
        >
          <div
            className="load-progress"
            onClick={() => handleShiftPreview(toVisualize)}
            style={{
              backgroundColor: `${statusColors[el.type]}`,
              width: `${el?.percentage}%`,
              height: "100%",
              borderRadius: calculateBorderRad(toVisualize, idx),
            }}
          >
            {" "}
            <span className="time-label">
              {el?.percentage > 8
                ? `${dayjsTZ(el?.clockInDate).format("HH:mm")} - ${dayjsTZ(
                    el?.clockOutDate
                  ).format("HH:mm")}`
                : ""}{" "}
            </span>
          </div>
        </Tooltip>
      </>
    </div>
  );
}

export default OnGoingShift;
