import React from "react";
import { useState, useEffect } from "react";
import DragContainer from "../../dashboard/AdminDashboard/Draggable/DragContainer";
import LoaderComponent from "../../commonComponents/LoadableComp/LoadableComp";
import { useSelector } from "react-redux";

const UserProfile = () => {
  const [loading, setLoading] = useState(true);
  const { users } = useSelector((state) => state.usersList);

  useEffect(() => {
    if (!!users) {
      setLoading(false);
    }
  }, [users]);

  return (
    <LoaderComponent loading={loading}>
      <DragContainer view={"profili"} dynamicMap={users} />
    </LoaderComponent>
  );
};

export default UserProfile;
