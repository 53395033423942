import React, { useEffect, useRef, useState } from "react";
import {
  // IconExcel,
  IconNdrysho,
  IconKerko,
  DeleteIcon,
  IconFilter,
  IconExcel,
  IconNgargo,
} from "../../../../assets/icons";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import { API } from "aws-amplify";
import { Input, Button, Row, Col, Form, message, Tooltip } from "antd";
import moment from "moment-timezone";
import "./sistemi_turnet.scss";
import CreateShiftModal from "./CreateShiftModal";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import PaisjetOraritFilter from "./PaisjetOraritFilter";
import { htmlParser } from "../../../../utils";
import ListaModal from "./ListaModal";
import { useSelector } from "react-redux";
import HtmlExelTabel from "../../dynamic components/HtmlExelTabel";
import { useReactToPrint } from "react-to-print";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import ExportToPdf from "../../dynamic components/ExportToPdf";
import GlobalPopupKonfirm from "../../../commonComponents/GlobalPopupKonfirm";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import { useMediaQuery } from "react-responsive";
import MobileSistemiTurnet from "./MobileSistemiTurnet";
import { useAuth } from "../../../../authentication/authHelpers";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import PaisjetOraritLogs from "../SistemiPaisjetOrarit/PaisjetOraritLogs";
import { LogsIcon } from "../../../Documentation/View/assets";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import AgGridHeader from "../../../commonComponents/AgGridHeader/AgGridHeader";
import ActivityModal from "../../../punonjesit/EmployeeView/components/ActivityModal/ActivityModal";
import dayjsTZ from "../../../../utils/dayjs";

// * @EneaXharau - Added Websocket connection
// const socketHandler = new WebSocket(
//   getSocketUrl("/settings/system/shifts/sockets")
// );

const FILTER_KEYS = [
  { key: "shiftName", label: "Titulli i orarit", type: "string" },
  { key: "description", label: "Pershkrimi per pajisjen", type: "string" },
  { key: "shiftStartTime", label: "Ora Fillimit", type: "string" },
  { key: "shiftEndTime", label: "Ora Mbarimit", type: "string" },
];
const EXPORT_COLS = [
  { en: "shiftName", al: "Titulli i orarit" },
  { en: "shiftDescription", al: "Përshkrimi i orarit" },
  { en: "shiftStartTime", al: "Ora Fillimit", format: "HH:mm" },
  { en: "shiftEndTime", al: "Ora Mbarimit", format: "HH:mm" },
  { en: "createdAt", al: "Momenti Krijimit", format: "DD/MM/YYYY" },
];

function SistemiTurnet() {
  // columnDefs for agGrid
  const columnDefs = [
    {
      headerName: "Titulli i orarit",
      field: "shiftName",
      filter: "agTextColumnFilter",
      flex: 3,
      mobile: true,
      isCollapseTitle: true,
    },
    {
      headerName: "Përshkrimi i orarit",
      field: "shiftDescription",
      filter: "agTextColumnFilter",
      flex: 5,
      mobile: true,
      cellRenderer: ({ data }) => htmlParser(data?.shiftDescription),
    },
    {
      headerName: "Ora Fillimit",
      field: "shiftStartTime",
      filter: "agTextColumnFilter",
      flex: 2,
      mobile: true,
      cellRenderer: ({ data }) => {
        try {
          return dayjsTZ(data?.shiftStartTime).format("HH:mm");
        } catch (err) {
          console.log("shiftStartTime--err");
        }
      },
    },
    {
      headerName: "Ora Mbarimit",
      field: "shiftEndTime",
      filter: "agTextColumnFilter",
      flex: 2,
      mobile: true,
      cellRenderer: ({ data }) => {
        try {
          return dayjsTZ(data?.shiftEndTime).format("HH:mm");
        } catch (err) {
          console.log("shiftEndTime--err");
        }
      },
    },
    {
      headerName: "Punonjësit",
      field: "",
      filter: "agTextColumnFilter",
      flex: 2,
      cellRenderer: (params) => (
        <div
          // variant="outlined"
          cursor="pointer"
          className="column-paisjet-orarit-lista"
        >
          <MondayButton
            className="mondayButtonGreen"
            disabled={loading}
            onClick={() => handelshowLista(params.node.data)}
          >
            Lista
          </MondayButton>
        </div>
      ),
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      headerName: "Momenti Krijimit",
      field: "createdAt",
      filter: "agTextColumnFilter",
      flex: 2,
      mobile: true,
      cellRenderer: ({ data }) => {
        try {
          return dayjsTZ(data?.createdAt).format("DD/MM/YYYY");
        } catch (err) {
          console.log("MomentiKrijimit--err");
        }
      },
    },
    {
      headerName: "",
      field: "",
      flex: 1.3,
      cellRenderer: (params) => (
        <div style={{ marginTop: 0 }} className="iconat-paisjet">
          <div
            // variant="outlined"
            cursor="pointer"
            className="column-paisjet-orarit"
          >
            <div
              className="icon-ndrysho"
              onClick={() => handelshow(params.node.data)}
            >
              <IconNdrysho width={14} height={14} />
            </div>
            <GlobalPopupKonfirm
              Button={
                <div
                  variant="outlined"
                  cursor="pointer"
                  className="icon-delete"
                >
                  <DeleteIcon
                    disabled={true}
                    width={17}
                    height={17}
                    // onClick={() => handleRequestDelete(params.node.data.id)}
                  />
                </div>
              }
              title={"Jeni të sigurt që do të fshini këtë rekord?"}
              onConfirm={() => handleRequestDelete(params.node.data.shiftId)}
              okText={"Po"}
              cancelText={"Jo"}
            />
          </div>
        </div>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [updateData, setUpdateData] = useState();
  // const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [filetModal, setFilterModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataLista, setDataLista] = useState(null);
  const [agGridSearchInput, setAgGridSearchInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const [logsModal, setLogsModal] = useState(false);
  const { userAccess } = useAuth();

  const username = {
    userId: userAccess[0].userSub,
    employeeFirstName: userAccess[0].given_name,
    employeeLastname: userAccess[0].family_name,
  };

  // making ag grid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // showing parms data to update modal
  const handelshow = (row) => {
    setUpdateData(row);
    setVisible(true);
    // console.log(row);
  };

  //opens the lista modal with the state of the clicked row
  const handelshowLista = (row) => {
    setDataLista(row);
    setOpen(true);
    // console.log(row);
  };

  // its function to open the modal to create new record
  const openKrijo = () => {
    setUpdateData(null);
    setVisible(true);
  };

  // delete function to delete the item in grid
  const handleRequestDelete = (id) => {
    // console.log(id);
    API.del("shifts", `/shifts/${id}`).then(() => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */
      // if (socketHandler.readyState === socketHandler.OPEN) {
      //   socketHandler.send(
      //     JSON.stringify({
      //       request: "shift_removed",
      //       message: "A shift was removed",
      //     })
      //   );
      // }
      setTableData((prev) => prev.filter((item) => item.shiftId !== id));
    });
  };

  // get shifts data form server  and passing it to tableData
  const getShifts = async () => {
    await API.get("shifts", "/shifts").then((r) => setTableData(r));
  };

  // reset the form data
  const onReset = () => {
    form.resetFields();
  };

  //function to compare 2 object and return the differences
  function compareObjects(previousObject, currentObject) {
    const keys1 = Object.keys(previousObject);
    const keys2 = Object.keys(currentObject);
    let changesArray = [];

    const commonKeys = keys1.filter((key) => keys2.includes(key));
    if (commonKeys.length === 0) {
    }
    commonKeys.forEach((key) => {
      if (typeof currentObject[key] === "object") {
        if (!!currentObject[key]?._isAMomentObject) {
          if (
            dayjsTZ(currentObject[key]).valueOf() !==
            dayjsTZ(previousObject[key]).valueOf()
          ) {
            let changedData = {
              id: uuidv4(),
              activity: "Ndryshuar",
              author: `${userAccess[0].given_name} ${userAccess[0].family_name}`,
              changeDate: Date.now(),
              field: key,
              oldValue: dayjsTZ(previousObject[key]).format("DD/MM/YYYY HH:mm"),
              newValue: dayjsTZ(currentObject[key]).format("DD/MM/YYYY HH:mm"),
            };
            changesArray.push(changedData);
          }
        }
      } else {
        if (previousObject[key] !== currentObject[key]) {
          let changedData = {
            id: uuidv4(),
            activity: "Ndryshuar",
            author: `${userAccess[0].given_name} ${userAccess[0].family_name}`,
            changeDate: Date.now(),
            field: key,
            oldValue: previousObject[key],
            newValue: currentObject[key],
          };
          changesArray.push(changedData);
        }
      }
    });
    if (changesArray?.length !== 0) {
      return changesArray;
    } else {
      return false;
    }
  }

  //region UPDATE
  const updateItems = async (val) => {
    const {
      shiftDescription,
      shiftEndTime,
      shiftStartTime,
      shiftName,
      breakStartTime,
      breakEndTime,
    } = val;
    let shiftStart = dayjsTZ(shiftStartTime).valueOf();
    let shiftEnd = dayjsTZ(shiftEndTime).valueOf();
    let breakStart = dayjsTZ(breakStartTime).valueOf();
    let breakEnd = dayjsTZ(breakEndTime).valueOf();
    let logs = updateData?.shiftLogs
      ? [...updateData.shiftLogs, ...compareObjects(updateData, val)]
      : [...compareObjects(updateData, val)];

    console.log(logs);
    let toSend = {
      shiftDescription: shiftDescription,
      shiftStartTime: shiftStart,
      shiftEndTime: shiftEnd,
      shiftName: shiftName,
      shiftLogs: [...logs],
      shiftBreak: {
        breakStartTime: breakStart,
        breakEndTime: breakEnd,
        isBreakTimePaid: data?.shiftBreak?.isBreakTimePaid,
      },
    };
    await API.put("shifts", `/shifts/${updateData.shiftId}`, {
      body: { ...toSend },
    }).then(() => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */
      // if (socketHandler.readyState === socketHandler.OPEN) {
      // 	socketHandler.send(
      // 		JSON.stringify({
      // 			request: "shift_edited",
      // 			message: "A shift was changed.",
      // 		})
      // 	);
      // }
      message.success("Orari u ndryshua me sukses !", 10);
      // setTableData((prev) =>
      // 	prev.map((item) =>
      // 		item.shiftId === updateData.shiftId ? updateData : item
      // 	)
      // );
      setVisible(false);
      setUpdateData(null);
      // setTimeout(() => {
      getShifts();
      // }, 1500);
    });
  };

  // the state of the data for creating new record
  const [data, setData] = useState({
    shiftName: "",
    shiftDescription: "",
    shiftStartTime: "",
    shiftEndTime: "",
    shiftBreak: {
      breakStartTime: "",
      breakEndTime: "",
      isBreakTimePaid: false,
    },
    createdAt: dayjsTZ(),
  });

  // change the data  state with the new value

  const onChange = (objField, e) => {
    const value = objField;
    setData((prev) => ({ ...prev, [value]: e }));
  };
  const onNestedChange = (nestedField, field, value) => {
    setData((prev) => ({
      ...prev,
      [nestedField]: {
        ...prev?.[nestedField],
        [field]: value,
      },
    }));
  };

  //region CREATE
  const addItems = (addIndex) => {
    const formData = form.getFieldsValue();
    const toSend = {
      shiftDescription: formData?.shiftDescription,
      shiftEndTime: dayjsTZ(formData?.shiftEndTime).valueOf(),
      shiftStartTime: dayjsTZ(formData?.shiftStartTime).valueOf(),
      shiftName: formData?.shiftName,
      shiftLogs: [],
      shiftBreak: {
        breakStartTime: formData?.breakStartTime,
        breakEndTime: formData?.breakEndTime,
        isBreakTimePaid: formData?.isBreakTimePaid,
      },
    };
    API.post("shifts", "/shifts", { body: { ...toSend } }).then((r) => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */
      // if (socketHandler.readyState === socketHandler.OPEN)
      // 	socketHandler.send(
      // 		JSON.stringify({
      // 			request: "shift_added",
      // 			message: "A shift was added",
      // 		})
      // 	);
      setTableData((prev) => [...prev, r]);
      setVisible(false);
      message.success("Orari i ri u krijua me sukses !", 10);
      onReset();
    });
  };

  let rowData = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowData?.push(node?.data);
  });

  // dropdon filter check to not repeat same value 2 times
  const duplicateCheck = [];
  const endduplicateCheck = [];
  const shiftNameduplicateCheck = [];
  const shiftDescriptionduplicateCheck = [];

  tableData &&
    tableData
      ?.map((data) => {
        if (duplicateCheck.includes(data.shiftStartTime)) return null;
        duplicateCheck.push(data.shiftStartTime);
        return data;
      })
      .filter((e) => e);

  tableData &&
    tableData
      ?.map((data) => {
        if (shiftNameduplicateCheck.includes(data.shiftName)) return null;
        shiftNameduplicateCheck.push(data.shiftName);
        return data;
      })
      .filter((e) => e);

  tableData &&
    tableData
      ?.map((data) => {
        if (endduplicateCheck.includes(data.shiftEndTime)) return null;
        endduplicateCheck.push(data.shiftEndTime);
        return data;
      })
      .filter((e) => e);

  tableData &&
    tableData
      ?.map((data) => {
        if (shiftDescriptionduplicateCheck.includes(data.shiftDescription))
          return null;
        shiftDescriptionduplicateCheck.push(data.shiftDescription);
        return data;
      })
      .filter((e) => e);

  // get the data when the page renders
  useEffect(() => {
    getShifts();
  }, []);

  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // useEffect(() => {
  // 	socketHandler.onmessage = (msg) => {
  // 		const { request, body } = JSON.parse(msg.data);
  // 		switch (request) {
  // 			case "shift_edited_received": {
  // 				getShifts();
  // 				break;
  // 			}
  // 			case "shift_added_received": {
  // 				getShifts();
  // 				break;
  // 			}
  // 			case "shift_removed_received": {
  // 				getShifts();
  // 				break;
  // 			}
  // 			default:
  // 				break;
  // 		}
  // 	};
  // 	return () => socketHandler.close();
  // }, []);

  const ITEMS = {
    search: {},
    icons: {
      filter: {
        filterKeys: FILTER_KEYS,
      },
      excel: { tableCols: EXPORT_COLS },
      pdf: { tableCols: EXPORT_COLS },
      print: { tableCols: EXPORT_COLS },
      modals: [
        {
          onClick: () => setLogsModal(true),
          icon: <LogsIcon width={20} height={20} fill="#323338" />,
        },
      ],
    },
  };

  //region RETURN
  return (
    <LoadableComp loading={!!tableData ? false : true}>
      <div className="globalPageWrapper">
        <AgGridComponent
          gridApi={gridApi}
          rowData={tableData}
          rowSelection={{ mode: "singleRow" }}
          onGridReady={onGridReady}
          paginationPageSize={15}
          columnDefs={columnDefs}
          headerProps={{
            items: ITEMS,
            exportTitle: "Turnet",
            children: [
              <MondayButton
                className="mondayButtonGreen"
                onClick={() => openKrijo()}
              >
                Krijo një orar të ri
              </MondayButton>,
            ],
          }}
          idKey={"shiftId"}
          onRowClick={(_, data) => handelshowLista(data)}
        />
      </div>
      {open ? (
        <ListaModal
          {...{
            setOpen,
            open,
            dataLista,
            employees: activeEmployees,
            setDataLista,
            tableData,
            setLoading,
            // socketHandler,
            socketHandler: () => {},
          }}
        />
      ) : null}
      {visible ? (
        <CreateShiftModal
          {...{
            form,
            visible,
            setVisible,
            onChange,
            onNestedChange,
            addItems,
            data,
            setTableData,
            updateData,
            updateItems,
            setUpdateData,
          }}
        />
      ) : null}
      {logsModal && (
        <ActivityModal
          keylog={tableData?.flatMap((el) => el?.shiftLogs)}
          setIsModalVisible={setLogsModal}
        />
      )}
    </LoadableComp>
  );
}
export default SistemiTurnet;
