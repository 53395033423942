import dayjsTZ from "../../../utils/dayjs";

export const intervals = {
  today: [dayjsTZ().startOf("day"), dayjsTZ().endOf("day")],
  yesterday: [
    dayjsTZ().subtract(1, "days").startOf("day"),
    dayjsTZ().subtract(1, "days").endOf("day"),
  ],
  thisWeek: [dayjsTZ().startOf("week"), dayjsTZ().endOf("week")],
  thisMonth: [dayjsTZ().startOf("month"), dayjsTZ().endOf("month")],
  previousMonth: [
    dayjsTZ().subtract(1, "months").startOf("month"),
    dayjsTZ().subtract(1, "months").endOf("month"),
  ],
  thisYear: [dayjsTZ().startOf("year"), dayjsTZ().endOf("year")],
};
