import React, { useMemo } from "react";
import { Modal, Row, Col, Alert, Button, Tooltip } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import "./ActivityModal.scss";
import _ from "lodash";
import { ModalXIcon } from "../../../../../assets/icons";
import KeylogsExcelExporter from "../../../../Intervistat/MainTabs/KeylogsExcelExporter";
import { formatChanges } from "./formatChanges";
import exportPdf from "./exportPDF";
import PropTypes from "prop-types";

/**
 * @typedef keylog
 * @property {string} activity keylog's activity
 * @property {any} changeDate date about time when this keylog occurred
 * @property {string} author string about who created this keylog
 * @property {string} field on which key of object this keylog occurred
 * @property {any} oldValue old value of data before it was updated
 * @property {any} newValue new value of data after update
 */

/**
 * @typedef props
 * @property {Array<Object>} keylog array of keylogs to display
 * @property {function} setIsModalVisible set modal's visibility state
 * @property {string} view filter out effectiveDate from keylogs
 * @property {string} name pass a string to render export icons for keylog
 */

function ActivityModal({ keylog, setIsModalVisible, view, name = "" }) {
  const filterKeylog = useMemo(() => {
    if (view === "effectiveDate") {
      return keylog?.filter?.(
        (el) => el?.oldValue !== el?.newValue && el?.field === "Data Efektive"
      );
    } else {
      return keylog?.filter?.((el) => el?.oldValue !== el?.newValue);
    }
  }, [keylog]);

  return (
    <Modal
      className="activity-modal lightHeader"
      title="Ndryshimet që janë bërë"
      open={true}
      onCancel={() => setIsModalVisible(false)}
      destroyOnClose={true}
      footer={null}
      centered
      closeIcon={<ModalXIcon />}
    >
      {filterKeylog?.length > 0 && (
        <div className="changes-container">
          {!!name?.length ? (
            <div className="exportButtons">
              <Tooltip
                placement="top"
                classNames={{ root: "global-icon-tooltip" }}
                title={"Eksporto në pdf"}
                className="exportKandidateBtn"
              >
                <Button
                  // icon={<PdfWhiteIcon height={20} width={18} />}
                  className="exportKandidateBtn"
                  style={{ backgroundColor: "#ea3943" }}
                  onClick={() => exportPdf({ filterKeylog, name })}
                  data-testid="pdf-button"
                />
              </Tooltip>
              <KeylogsExcelExporter
                keylogs={filterKeylog}
                headers={tableHeaders}
                filename={`${name}${" "}Keylogs`}
              />
            </div>
          ) : null}
          <Row key={"header-row"} className="table-header">
            {tableHeaders?.map((h, idx) => (
              <Col span={4} key={idx}>
                {h?.value}
              </Col>
            ))}
          </Row>
          <div className="table-body">
            {filterKeylog?.map((data, i) => {
              return (
                <div
                  style={{
                    backgroundColor: `${i % 2 === 0 ? "#F5F5F7" : "#fff"}`,
                  }}
                >
                  <Row className="data-rows" key={"data-row"} gutter={6}>
                    <Col span={4}>{data?.activity}</Col>
                    <Col span={4}>
                      {dayjsTZ(data?.changeDate).format("DD/MM/YYYY H:mm")}
                    </Col>
                    <Col span={4}>{data?.author}</Col>
                    <Col span={4}>{data?.field}</Col>
                    <Col span={4}>{formatChanges(data?.oldValue)}</Col>
                    <Col span={4}>{formatChanges(data?.newValue)}</Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!filterKeylog?.length && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert
            message={"Nuk ka ndryshime për këtë rekord"}
            type="info"
            showIcon
          />
        </div>
      )}
    </Modal>
  );
}
export default ActivityModal;
ActivityModal.propTypes = {
  keylog: PropTypes.array.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  view: PropTypes.string,
  name: PropTypes.string,
};

export const tableHeaders = [
  { key: "activity", value: "Aktiviteti" },
  { key: "changeDate", value: "Ndodhur më" },
  { key: "author", value: "Ndryshuar nga" },
  { key: "field", value: "Fusha" },
  { key: "oldValue", value: "Vlera e vjetër" },
  { key: "newValue", value: "Vlera e re" },
];
