import { useEffect } from "react";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import { useState } from "react";
import "../../../components/dashboard/components/clockInOut/clockShiftLog/ShiftLogButtonsFilter.scss";

function TimeSheetButtonsFilter({ activeFilter, setActiveFilter }) {
  // const [activeFilter, setActiveFilter] = useState(null);
  const ButtonFilter = (idx) => {
    const range = [
      dayjsTZ()
        .subtract(2 - idx, "months")
        .startOf("month"),
      dayjsTZ()
        .subtract(2 - idx, "months")
        .endOf("month"),
    ];
    if (
      activeFilter?.range?.[0].isSame(range[0]) &&
      activeFilter?.range?.[1].isSame(range[1])
    ) {
      setActiveFilter((prev) => {
        const { range, ...rest } = prev || {};
        return rest;
      });
    } else {
      setActiveFilter((prev) => ({ ...(prev || {}), range }));
    }
  };

  const getComputedClassname = (idx) => {
    let month = dayjsTZ()
      .subtract(2 - idx, "months")
      .format("MMMM");
    if (!!activeFilter?.range) {
      const activeMonths = [
        activeFilter.range?.[0]?.format("MMMM"),
        activeFilter.range?.[1]?.format("MMMM"),
      ];
      if (activeMonths.every((el) => el === month)) {
        return "active-filter";
      }
    }
    return "inactive-filter";
  };

  useEffect(() => {
    ButtonFilter(2);
  }, []);

  return (
    <div className="header-filter-buttons" data-testid="TimeSheetButtonsFilter">
      {Array.apply(null, Array(3)).map((_, idx) => (
        <button
          key={idx}
          className={getComputedClassname(idx)}
          onClick={() => ButtonFilter(idx)}
        >
          {dayjsTZ()
            .subtract(2 - idx, "months")
            .format("MMMM YYYY")}
        </button>
      ))}
    </div>
  );
}

export default TimeSheetButtonsFilter;
