import {
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import "./Enable2FaModal.scss";
import { API } from "aws-amplify";
import InputComponent from "../../../../InputComponent/InputComponent";
import { useEffect, useRef, useState } from "react";
import { SearchAgGrid } from "../../../../commonComponents/AgGridHeader/components";
import { IconKerko } from "../../../../../assets/icons";
import { useSelector } from "react-redux";
import { requestData } from "../../../../../helpers/API/RequestFactory";
import { users as usersReducer } from "../../../../../store/actions";
import { useDispatch } from "react-redux";
import { QuestionsMark } from "../../../../Intervistat/Kandidatet/ShtoKandidat/Image";

const Enable2FaModal = ({ open, setOpen, dataList, title }) => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.usersList);

  const [data, setData] = useState(dataList);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkedAll, setCheckedAll] = useState(
    dataList.every((el) => !!el?.is2FaActive)
  );

  const getUpdatedUsers = () => {
    let updatedUsers = [];

    data?.forEach((user) => {
      const dbUsers = users?.allUsers?.Items || [];
      const idx = dbUsers?.findIndex((el) => el?.identityId === user?.id);
      if (idx !== -1) {
        const users2Fa = dbUsers[idx]?.auth2Fa || {};
        if (!!users2Fa?.is2FaActive !== !!user?.is2FaActive) {
          updatedUsers.push({
            id: dbUsers[idx]?.identityId,
            name: user?.name,
            auth2Fa: { ...users2Fa, is2FaActive: !!user?.is2FaActive },
          });
        }
      }
    });
    return updatedUsers;
  };

  const handleCancel = () => {
    const updatedUsers = getUpdatedUsers();

    if (!!updatedUsers.length) {
      Modal.confirm({
        title: "A jeni të sigurt?",
        icon: <QuestionsMark />,
        content: (
          <Text>
            Ju po anuloni ndryshimin e konfigurimit 2FA për{" "}
            {updatedUsers.length > 5 ? (
              "disa punonjës."
            ) : (
              <span>
                punonjësit:{" "}
                <strong>
                  {updatedUsers?.map((el) => el?.name).join(", ")}.
                </strong>{" "}
              </span>
            )}
            <Space className="space-gap">
              Nëse jeni të sigurt për këtë veprim, klikoni
              <span className="text-bold">"Po, anuloje"</span>
            </Space>
          </Text>
        ),
        okText: "Po, anuloje",
        cancelText: "Jo, mos e anulo",
        width: "660px",
        className: "cancelModal",
        centered: true,
        cancelButtonProps: {
          type: "primary",
          style: {
            background: "#ea3943",
            borderColor: "#ea3943",
            borderRadius: "5px",
          },
        },
        okButtonProps: {
          className: "ant-btn-confirm",
        },
        onOk() {
          setData(dataList);
          setOpen(false);
        },
        onCancel() {},
      });
    } else {
      setOpen(false);
    }
  };

  const handle2FaEnable = async () => {
    const updatedUsers = getUpdatedUsers();

    const promises = updatedUsers.map(async ({ id, auth2Fa }) => {
      return API.put("users", `/users/${id}`, {
        body: {
          auth2Fa: { ...auth2Fa },
        },
      });
    });

    setLoading(true);
    Promise.all(promises)
      .then(() => {
        requestData("users").then((res) => dispatch(usersReducer(res)));
        message.success(
          promises.length > 1
            ? "Përdoruesit u ndryshuan me sukses!"
            : "Përdoruesi u ndryshua me sukses!"
        );
        setLoading(false);
        setTimeout(() => {
          setOpen(false);
        }, 1000);
      })
      .catch((err) => console.log("Error: ", err));
  };

  const resetSearch = () => {
    setData((prev) => (prev || []).map((el) => ({ ...el, visible: true })));
    setInput("");
  };

  const handleSearch = (val) => {
    if (val.length < input.length && checkedAll) {
      setCheckedAll(data.every((el) => !!el?.is2FaActive));
    }
    setInput(val);
    setData((prev) =>
      (prev || []).map((el) =>
        (el?.name || "").toLowerCase().includes(val.toLowerCase())
          ? { ...el, visible: true }
          : { ...el, visible: false }
      )
    );
  };

  const checkAll = (action) => {
    if (!!data?.length) {
      const newDataList = data?.map((el) => ({
        ...el,
        is2FaActive: !!el?.visible
          ? action === "check"
            ? true
            : action === "uncheck"
            ? false
            : el?.is2FaActive
          : el?.is2FaActive,
      }));
      console.log(checkedAll);
      setData(newDataList);
      setCheckedAll(
        action === "check" ? true : action === "uncheck" ? false : checkedAll
      );
    }
  };
  const onCheck = (val, id) => {
    setData((prev) =>
      (prev || []).map((el) =>
        el?.id === id ? { ...el, is2FaActive: val } : el
      )
    );
  };

  return (
    <Modal
      title={title}
      className="enable2FaModal darkHeader"
      centered
      open={open}
      onCancel={handleCancel}
      footer={[
        <MondayButton className="mondayButtonRed" onClick={handleCancel}>
          Mbyll
        </MondayButton>,
        <MondayButton
          className="mondayButtonGreen"
          onClick={handle2FaEnable}
          spin={loading}
        >
          Ruaj Ndryshimet
        </MondayButton>,
      ]}
    >
      <div className="enable2FaModalcontainer">
        <div className="headerSearch">
          <div className="icon">
            <IconKerko width={16} height={16} fill="#111" />
          </div>
          <Input
            className="headerInput"
            placeholder="Kërko"
            onChange={(e) => handleSearch(e?.target?.value || "")}
            value={input}
          />
          <MondayButton
            className="mondayButtonRed headerButton"
            onClick={resetSearch}
          >
            Pastro
          </MondayButton>
        </div>

        <div style={{ marginLeft: -10 }}>
          <Checkbox
            checked={checkedAll}
            onChange={() => checkAll(!!checkedAll ? "uncheck" : "check")}
            className="item"
          >
            <span>Selekto të gjithë</span>
          </Checkbox>
          <div className="divider" />
        </div>
        <div className="tableContent">
          {data?.map((el) => (
            <Checkbox
              className={`item ${!el?.visible ? "hide" : ""} ${
                !!el?.is2FaActive ? "checked" : ""
              }`}
              key={el?.id}
              checked={el?.is2FaActive}
              onChange={(e) => onCheck(e?.target?.checked, el?.id)}
            >
              {el?.name}
            </Checkbox>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default Enable2FaModal;
