import {
  Button,
  Card,
  Modal,
  Select,
  Checkbox,
  Row,
  Col,
  message,
  Input,
  Badge,
} from "antd";
import { API, a } from "aws-amplify";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { programFields as programFieldsActions } from "../../../../store/actions";
import {
  UserPune,
  KolltukuKuq,
  UserBardh,
  KolltukuBardh,
  XIcon,
  CloseIcon,
} from "../../../../assets/icons";
import "./ImportantDetailsPage.scss";
import moment from "moment";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import { columnDefs } from "./columnDefs";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
const ImportantDetailsPage = ({
  visible,
  setVisible,
  importantDetails,
  setImportantDetails,
  initialFestat,
  setUpdatedFesta,
}) => {
  const dispatch = useDispatch();
  const { departments } = useSelector((state) => state.departments);
  const { employees } = useSelector((state) => state.employeesList);
  const [deps, setDeps] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [selectedDep, setSelectedDep] = useState({});
  const onChange = (value = "", type = "") => {
    if (type === "dep") {
      const filteredDeps = departments?.filter((dep) =>
        dep?.departmentName?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setDeps(filteredDeps);
    } else {
      gridApi?.setQuickFilter(value);
    }
  };
  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);
  const activEmployees = employees?.filter(
    (o) => o?.employeeStatus === "Aktiv"
  );
  const employeesByDepartment = (departmentName = "") => {
    return activEmployees?.filter(
      (el) => el?.employeeDepartmentName === departmentName
    );
  };
  const newSet = (arr) => {
    return [...new Set(arr)];
  };
  const onSave = async () => {
    const selectedEmpl =
      gridApi?.getSelectedRows()?.map((el) => el?.employeeId) || [];
    const getDeselected = () => {
      const deselected = [];
      gridApi?.forEachNode((node) => {
        if (!node?.isSelected()) {
          deselected.push(node?.data?.employeeId);
        }
      });
      return deselected;
    };
    const deselected = getDeselected();
    let ids = importantDetails?.users || [];
    if (selectedEmpl?.length > 0) {
      ids = newSet([...importantDetails?.users, ...selectedEmpl]);
    }
    if (deselected?.length > 0) {
      ids = ids?.filter((el) => !deselected?.includes(el));
    }
    const hide = message.loading("Duke u ruajtur...", 0);
    message.loading({ key: "onSave", content: "Duke u ruajtur..." });
    await API.put("programFields", `/programFields/${initialFestat?.fieldId}`, {
      body: {
        fieldOptions: [
          ...initialFestat?.fieldOptions?.filter(
            (el) => el?.id !== importantDetails?.id
          ),
          {
            ...importantDetails,
            users: ids || [],
          },
        ],
      },
    })
      .then(async () => {
        await API.get("programFields", "/programFields")
          .then((res) => {
            dispatch(programFieldsActions(res));
            message.success(
              { key: "onSave", content: "Te dhenat u ruajten." },
              2000
            );
          })
          .catch((err) => console.log("Error: ", err));
      })
      .catch((err) => console.log("Error: ", err));
    setImportantDetails((prev) => {
      return {
        ...prev,
        users: ids,
      };
    });
    setUpdatedFesta({ id: importantDetails?.id, users: ids });
    setVisible(false);
  };
  const onFirstDataRendered = (params) => {
    params.api.forEachNode((node) => {
      if (importantDetails?.users?.includes(node?.data?.employeeId)) {
        node?.setSelected(true);
      }
    });
  };
  useEffect(() => {
    if (!!departments) setDeps(departments);
  }, [departments]);
  return (
    <>
      <Modal
        className="ImportantDetailsPage"
        centered
        open={visible}
        onCancel={() => setVisible(false)}
        title={importantDetails?.title + " - " + importantDetails?.date}
        width={1300}
        height={680}
        footer={null}
        closeIcon={<CloseIcon width={20} height={20} />}
      >
        <>
          <div className="festatContent">
            <div className="depsContent">
              <div className="inputSearch">
                <Input
                  placeholder="Kerko departamentin..."
                  onChange={(e) => onChange(e.target.value, "dep")}
                />
              </div>
              <div className="allDeps">
                {deps?.map((dep) => {
                  const { departmentName, departmentId } = dep;
                  return (
                    <div
                      style={{
                        padding: "10px",
                        backgroundColor:
                          selectedDep?.departmentId === departmentId
                            ? "#e6f7ff"
                            : "white",
                      }}
                    >
                      <span
                        style={{
                          color:
                            selectedDep?.departmentId === departmentId
                              ? "#1890ff"
                              : "",
                          fontWeight:
                            selectedDep?.departmentId === departmentId
                              ? "600"
                              : "",
                        }}
                        onClick={() =>
                          setSelectedDep({ departmentName, departmentId })
                        }
                        className="depName"
                      >
                        {departmentName}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              {!!Object.keys(selectedDep || {})?.length && (
                <>
                  <div className="inputSearch">
                    <Input
                      placeholder="Kerko punonjesin..."
                      onChange={(e) => onChange(e.target.value, "")}
                    />
                    <Checkbox checked={true}>Ne pune</Checkbox>
                    <Checkbox checked={false}>Pushim</Checkbox>
                  </div>
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "100%", width: 850, paddingTop: 15 }}
                  >
                    <AgGridComponent
                      {...{
                        rowData: employeesByDepartment(
                          selectedDep?.departmentName
                        ),
                        columnDefs,
                        onGridReady,
                        rowSelection: {
                          mode: "multiRow",
                          enableClickSelection: true,
                        },
                        rowMultiSelectWithClick: true,
                        suppressCellSelection: true,
                        onComponentStateChanged: onFirstDataRendered,
                        animateRows: true,
                        onFirstDataRendered,
                        defaultColDef: {
                          flex: 1,
                          minWidth: 100,
                          resizable: true,
                          sortable: true,
                          filter: true,
                        },
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="footerContent">
            <Button onClick={() => setVisible(false)}>Mbyll Faqen</Button>
            <Button onClick={onSave}>Ruaj te dhenat</Button>
          </div>
        </>
        {/* <div>
          <Card
            title={
              !!selectedFesta
                ? selectedFesta
                : `${importantDetails?.title} - ${moment(
                    importantDetails?.effectiveDate
                  ).format("DD/MM/YYYY")}`
            }
            className="importantDetailsCard"
          >
            <div className="headerActionsCard">
              <Button
                onClick={() => {
                  return setVisible(false), setSelectedFesta(null);
                }}
                className="goBack"
              >
                Kthehu pas
              </Button>
              <Button
                onClick={() => setVisible(false)}
                className="canChangesBtn"
              >
                Anulo ndryshimet
              </Button>
              <Button onClick={onSave} className="saveChangesFestatBtn">
                Ruaj ndryshimet
              </Button>
              <div className="nrOfEmployees">
                <div className="punonjesitNePune">
                  <UserBardh />{" "}
                  {`${lengthOfEmployees?.workNumber} punonjës në punë`}
                </div>
                <div className="punonjesitPushim">
                  <KolltukuBardh />{" "}
                  {`${lengthOfEmployees?.offNumber} punonjës pushim`}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Row className="row-search" style={{ width: "100%" }}>
            {departments?.map((department, i) => {
              return (
                <Col style={{ width: "21.6%" }}>
                  <Card
                    className="departmentCardsContainer"
                    title={department?.departmentName}
                  >
                    <div style={{ height: 220 }}>
                      <div className="gjithDepartamenti">
                        <Checkbox
                          onChange={(val) =>
                            onSelectCheckboxDepartment(
                              department?.departmentName,
                              val
                            )
                          }
                          className="checkboxHeaderEmployee"
                        >
                          <div className="gjithDepartamentiText">
                            I gjithë departamenti në punë
                          </div>
                        </Checkbox>
                      </div>
                      <div style={{ marginTop: 15 }}>
                        {activEmployees
                          ?.filter(
                            (el) =>
                              el?.employeeDepartmentName ===
                              department?.departmentName
                          )
                          ?.map((employee, i) => {
                            return (
                              <div className="dataContainer" key={i}>
                                <Checkbox
                                  className="checkboxEmployee"
                                  checked={
                                    checked?.[department?.departmentName]?.[
                                      employee?.employeeId
                                    ]
                                  }
                                  onChange={(e) =>
                                    onCheck(
                                      e,
                                      employee?.employeeId,
                                      department?.departmentName
                                    )
                                  }
                                >
                                  <div className="checkName">{`${employee?.employeeFirstName} ${employee?.employeeLastName}`}</div>
                                </Checkbox>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: -15,
                                  }}
                                >
                                  {checked?.[department?.departmentName]?.[
                                    employee?.employeeId
                                  ] ? (
                                    <KolltukuKuq />
                                  ) : (
                                    <UserPune />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div> */}
      </Modal>
    </>
  );
};
export default ImportantDetailsPage;
