import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import AgGridComponent from "../../AG-grid/AgGridComponent";
import PunonjesitContext from "../store/PunonjesitContext";
import getAgThemePreference from "../../../utils/getAgThemePreference";
import colorShade from "../../../utils/colorShade";
import { useMediaQuery } from "react-responsive";
import { colDefs } from "./colDefs";

const EXPORT_COLS = [
  { en: "clockInDate", al: "Data", format: "DD/MM/YYYY" },
  { en: "clockInDate", al: "Fillimi", format: "HH:mm" },
  { en: "clockOutDate", al: "Mbarimi", format: "HH:mm" },
  { en: "approved", al: "Statusi" },
  { en: "clockOutDate", al: "Kohëzgjatja" },
];
const ITEMS = {
  search: {},
  icons: {
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};
const { mode, accentColor, foregroundColor } = getAgThemePreference();

const ClockIns = () => {
  const { employee } = useContext(PunonjesitContext);

  const { clockings } = useSelector((state) => state.clockings);

  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [dateFilter, setDateFilter] = useState(dayjsTZ());

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (!!clockings && Array.isArray(clockings)) {
      setRowData(
        clockings
          .filter(
            (el) =>
              el.employeeId === employee?.employeeId &&
              el.clockInDate >=
                dayjsTZ(dateFilter).startOf("month").valueOf() &&
              el.clockInDate <= dayjsTZ(dateFilter).endOf("month").valueOf()
          )
          .map((el) => ({
            ...el,
            fillim_turni: el?.clockInDate,
            mbarim_turni: el?.clockOutDate,
            duration: !!el?.clockOutDate
              ? dayjsTZ(el?.clockOutDate)
                  .diff(dayjsTZ(el?.clockInDate), "hours", true)
                  .toFixed(2)
              : 0,
          }))
          .sort((a, b) => b?.clockInDate - a?.clockInDate)
      );
    }
  }, [clockings, employee, dateFilter]);

  //region RETURN
  return (
    <div className="globalPageWrapper">
      <AgGridComponent
        gridApi={gridApi}
        rowData={rowData}
        rowSelection={{ mode: "singleRow" }}
        onGridReady={onGridReady}
        columnDefs={colDefs}
        idKey={"clockingId"}
        headerProps={{
          items: ITEMS,
          exportTitle: `Oraret - ${employee?.employeeFirstName} ${employee?.employeeLastName}`,
          children: [
            <DatePicker
              style={{
                backgroundColor: mode.includes("dark")
                  ? colorShade(accentColor, 40)
                  : null,
                color: foregroundColor,
              }}
              onChange={(val) => setDateFilter(val)}
              picker="month"
              allowClear={false}
              format={"MMMM"}
              placeholder="Zgjidh Muajin"
            />,
          ],
        }}
        counterProps={{
          className: "rowFlex",
          title: "Totali i orareve (Aprovuar)",
          data: rowData?.map((el) =>
            !!el.clockOutDate
              ? dayjsTZ(el.clockOutDate)
                  .diff(dayjsTZ(el.clockInDate), "hours", true)
                  .toFixed(2)
              : 0
          ),
          derivedData: rowData?.map((el) =>
            !!el.clockOutDate && !!el?.approved
              ? dayjsTZ(el.clockOutDate)
                  .diff(dayjsTZ(el.clockInDate), "hours", true)
                  .toFixed(2)
              : 0
          ),
        }}
      />
    </div>
  );
};

export default ClockIns;
