import { Form, message, Modal, Space, Typography } from "antd";
import "./NewStepModal.scss";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { useEffect, useMemo, useState } from "react";
import RenderDynamicComponents from "../../../RenderDynamicComponents/RenderDynamicComponents";
import { QuestionsMark } from "../../../Intervistat/Kandidatet/ShtoKandidat/Image";
import { apiPost, apiPut } from "../../../DocumentationConfiguration/utils/api";
import { toSentenceCase } from "../../../../utils";
import { useSelector, useDispatch } from "react-redux";
import { programFields as programFieldsReducer } from "../../../../store/actions";
import addInQueue from "../utils/addInQueue";
import PropTypes from "prop-types";

const NewStepModal = ({ open, setOpen, programField, editableStep = {} }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Text } = Typography;

  const { programFields } = useSelector((state) => state.programFields);

  const [spin, setSpin] = useState(false);

  const editMode = useMemo(() => {
    return !!Object.keys(editableStep).length;
  }, [editableStep]);

  const currentSteps = useMemo(() => {
    if (!Object.keys(programField)) return [];
    else {
      return programField?.fieldOptions;
    }
  }, [programField]);

  //region ON CANCEL
  const onCancel = () => {
    let isEdited;
    if (!!editMode) {
      const original = JSON.stringify(editableStep);
      const updated = JSON.stringify(form.getFieldsValue());
      if (original !== updated) isEdited = true;
    } else {
      for (const [key, value] of Object.entries(form.getFieldsValue())) {
        if (!!value) isEdited = true;
      }
    }
    if (!!isEdited) {
      Modal.confirm({
        title: "A jeni të sigurt?",
        icon: <QuestionsMark />,
        content: (
          <Text>
            Ju po anuloni{"  "}
            {!!editMode ? "ndryshimin" : "shtimin"} e një stepi.{"\n"}
            <Space className="space-gap">
              Nëse jeni të sigurt për këtë veprim, klikoni
              <span className="text-bold">"Po, anuloje"</span>
            </Space>
          </Text>
        ),
        okText: "Po, anuloje",
        cancelText: "Jo, mos e anulo",
        width: "660px",
        className: "cancelModal",
        centered: true,
        cancelButtonProps: {
          type: "primary",
          style: {
            background: "#ea3943",
            borderColor: "#ea3943",
            borderRadius: "5px",
          },
        },
        okButtonProps: {
          className: "ant-btn-confirm",
        },
        onOk() {
          form.resetFields();
          setOpen(false);
        },
        onCancel: () => false,
      });
    } else {
      setOpen(false);
    }
  };

  //region ON SUBMIT
  const onSubmit = () => {
    setSpin(true);
    form
      .validateFields()
      .then(async (res) => {
        const oldFieldOptions = programField?.fieldOptions || [];
        if (res?.name !== editableStep?.name) {
          const oldSteps = oldFieldOptions?.map((el) =>
            el?.name?.toLowerCase()
          );
          if (oldSteps.includes(res?.name?.toLowerCase())) {
            message.error({
              content: "Ky step ekziston aktualisht!",
              key: "newStep",
            });
            setSpin(false);
            return;
          }
        }
        message.loading({ content: "Duke ruajtur...", key: "newStep" });

        const field = oldFieldOptions?.find(
          (el) => el?.name === editableStep?.name
        );
        const newField = {
          ...field,
          name: res.name.toLowerCase(),
          step: res.step,
          fields: !!editMode ? field?.fields : [],
        };

        if (!!editMode) {
          const newFieldOptions = addInQueue({
            prevStep: editableStep?.step,
            nextStep: res?.step,
            array: oldFieldOptions,
            element: newField,
          });
          await apiPut("programFields", programField?.fieldId, {
            fieldOptions: newFieldOptions,
          })
            .then((res) => {
              if (!!res.status) {
                updateProgramFields(newFieldOptions);
              }
            })
            .catch((err) => console.log("Error: ", err));
        } else {
          let newFieldOptions = oldFieldOptions?.toSpliced(
            res?.step - 1,
            0,
            newField
          );
          for (let i = res?.step; i < newFieldOptions.length; i++) {
            const field = newFieldOptions[i];
            newFieldOptions[i] = { ...field, step: field?.step + 1 };
          }

          await apiPut("programFields", programField?.fieldId, {
            fieldOptions: newFieldOptions,
          })
            .then((res) => {
              if (!!res.status) {
                updateProgramFields(newFieldOptions);
              }
            })
            .catch((err) => console.log("Error: ", err));
        }
        setSpin(false);
      })
      .catch((err) => {
        setSpin(false);
        console.log("Error: ", err);
      });
  };

  //region UPDATE PROGFIELDS
  const updateProgramFields = (newFieldOptions) => {
    const newProgramFields = [...programFields];
    const index = newProgramFields.findIndex(
      ({ fieldId }) => fieldId === programField?.fieldId
    );
    if (index > -1) {
      newProgramFields[index] = {
        ...newProgramFields[index],
        fieldOptions: newFieldOptions,
      };

      dispatch(programFieldsReducer(newProgramFields));
      message.success({
        content: "U ruajt me sukses!",
        key: "newStep",
      });
      setOpen(false);
    }
  };

  //region FIELDS JSON
  const FIELDS_JSON = [
    {
      formItemName: "name",
      label: "Emri i stepit",
      placeholder: "Shkruaj emrin e stepit",
      type: "input",
      required: true,
    },
    {
      formItemName: "step",
      label: "Rradha e stepit",
      placeholder: "Shkruaj rradhën e stepit",
      type: "inputnumber",
      required: true,
      min: 1,
      initialValue: currentSteps?.length + 1,
      max: editMode ? currentSteps?.length : currentSteps?.length + 1,
    },
  ];

  useEffect(() => {
    if (editMode) {
      for (const [key, value] of Object.entries(editableStep)) {
        form.setFieldValue(key, value);
      }
    }
  }, [editMode]);

  //region RETURN
  return (
    <Modal
      {...{
        open,
        className: "newStepModal lightHeader",
        destroyOnClose: true,
        centered: true,
        maskClosable: false,
        title: !!editMode
          ? `Ndrysho stepin "${toSentenceCase(editableStep?.name)}"`
          : "Shto një step të ri",
        onCancel,
        footer: [
          <MondayButton className="mondayButtonRed" onClick={onCancel}>
            Anullo
          </MondayButton>,
          <MondayButton
            className="mondayButtonGreen"
            onClick={onSubmit}
            spin={spin}
          >
            {!!editMode ? "Ndrysho" : "Shto"}
          </MondayButton>,
        ],
      }}
    >
      <div className="newStepForm">
        <Form form={form}>
          {RenderDynamicComponents(FIELDS_JSON, { form })}
        </Form>
      </div>
    </Modal>
  );
};

export default NewStepModal;

NewStepModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  programField: PropTypes.object.isRequired,
  editableStep: PropTypes.object,
};
