import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  IconNgargo,
  IconFilter,
  IconExcel,
  DeleteIcon,
} from "../../../../assets/icons";
import { Tooltip } from "antd";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
// import totalValue from './totalValue';
import ShiftModal from "./EmployeeShifts/ShiftModal";
import { getMonthsOfYear, getNextMonthsOfYear } from "./utils/calculations";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { calcMonthInd } from "../../../Pagat/PaySub/employeeCalc/calculateMonthlyInd";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import "./PaymentGrid.scss";
import { useMediaQuery } from "react-responsive";
import PunonjesitContext from "../../store/PunonjesitContext";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import getAgThemePreference from "../../../../utils/getAgThemePreference";
import colorShade from "../../../../utils/colorShade";
import { colDefs } from "./colDefs";

const EXPORT_COLS = [
  { en: "muaji", al: "Muaji", format: "MMMM YYYY" },
  { en: "neto", al: "Shuma Neto" },
  { en: "bruto", al: "Shuma Bruto" },
];

function PaymentGrid() {
  const { wages } = useSelector((state) => state.wages);
  const { employee } = useContext(PunonjesitContext);

  const [rowData, setRowData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [agGridRow, setAgGridRow] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [nextGen, setNextGen] = useState({ next: 0, prev: 0 });

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  let rowDataGrid = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowDataGrid?.push(node?.data);
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleMonthModal = (data) => {
    setSelectedData(data);
    setIsModalVisible(true);
  };

  const initializeMonths = () => {
    let temp = getMonthsOfYear()?.map((el) => ({
      ...el,
      neto: calcMonthInd("neto", wages, el.muaji, employee),
      bruto: calcMonthInd("bruto", wages, el.muaji, employee),
    }));

    setRowData(temp);
  };

  //region >onNextGen()
  const onNextGen = (type) => {
    if (!!type) {
      let temp = getNextMonthsOfYear(type, nextGen[type] + 1)?.map((el) => ({
        ...el,
        neto: calcMonthInd("neto", wages, el.muaji, employee),
        bruto: calcMonthInd("bruto", wages, el.muaji, employee),
      }));

      setRowData(temp);
      setNextGen((prev) => ({ ...prev, [type]: prev[type] + 1 }));
    } else {
      initializeMonths();
      setNextGen({ next: 0, prev: 0 });
    }
  };

  useEffect(() => {
    if (!!wages) {
      initializeMonths();
    }
  }, [wages]);

  const ITEMS = {
    search: { handleClean: () => onNextGen(null) },
    icons: {
      excel: { tableCols: EXPORT_COLS },
      pdf: { tableCols: EXPORT_COLS },
      print: { tableCols: EXPORT_COLS },
    },
  };

  //region RETURN
  return (
    <div className="payment-grid">
      <AgGridComponent
        gridApi={gridApi}
        rowData={rowData}
        rowSelection={{ mode: "singleRow", enableClickSelection: true }}
        onGridReady={onGridReady}
        paginationPageSize={12}
        columnDefs={colDefs({ isMobileView, handleMonthModal})}
        dataTestId="payment-ag-grid"
        headerProps={{
          items: ITEMS,
          exportTitle: `Paga - ${employee?.employeeFirstName}.${employee?.employeeLastName}`,
          children: [
            <MondayButton
              className="mondayButtonGreen"
              data-testid="on-next-btn"
              onClick={() => onNextGen("next")}
            >
              Shfaq Muajin Pasardhës
            </MondayButton>,
            <MondayButton
              className="mondayButtonGreen"
              data-testid="on-prev-btn"
              onClick={() => onNextGen("prev")}
            >
              Shfaq Muajin Paraardhës
            </MondayButton>,
          ],
        }}
        onRowClick={(_, row) => handleMonthModal(row)}
        idKey={"muaji"}
        counterProps={{
          title: "Shuma totale bruto (neto)",
          className: "rowFlex",
          data: rowData?.map((el) => el?.bruto),
          derivedData: rowData?.map((el) => el?.neto),
        }}
      />
      {isModalVisible ? (
        <ShiftModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          {...{ selectedData, setSelectedData }}
        />
      ) : null}
    </div>
  );
}
export default PaymentGrid;
