export const VIEW_TITLE = "Konfigurimi i Dokumentacionit";
export const VIEW_SUBTITLE =
  "Ky është një nëntitull që informon përdoruesin se çfarë do të bëjë.";

export const views = {
  CATEGORIES_VIEW: "DocumentationCategoriesView",
  CATEGORY_VIEW: "CategoryView",
  DOCUMENTATION_PREVIEW: "DocumentationPreview",
};

export const defaultStatuses = [
  {
    statusName: "Në pritje",
    statusColor: "#ECDE7C",
  },
  {
    statusName: "Kërkuar",
    statusColor: "#B3D99B",
  },
  {
    statusName: "Marrë",
    statusColor: "#9BCEA8",
  },
  {
    statusName: "Skaduar",
    statusColor: "#F14846",
  },
];
