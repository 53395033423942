import { themeQuartz, themeAlpine, themeBalham } from "ag-grid-community";
import colorShade from "./colorShade";

const THEMES = {
  alpine: themeAlpine,
  balham: themeBalham,
  quartz: themeQuartz,
};

//ag version < 32
const getStyles = (color, mode, theme) => {
  const styles = {
    "--ag-header-background-color": !!mode ? colorShade(color, 70) : color,
    "--ag-odd-row-background-color": !!mode
      ? colorShade(color, 70)
      : colorShade(color, 0.9),
    [`--ag${theme !== "quartz" ? `-${[theme]}` : ""}-active-color`]: color,
    "--ag-tooltip-background-color": color,
    "--ag-selected-row-background-color": !!mode
      ? colorShade(color, 0.3)
      : colorShade(color, 0.65),
    "--ag-checkbox-background-color": "#fbfbfb",
    "--ag-checkbox-checked-color": color,
    "--ag-background-color": !!mode ? colorShade(color, 50) : "#fff",
    "--ag-foreground-color": !!mode ? "#fbfbfb" : color,
  };

  return styles;
};
//ag version > 32
const getParams = (color, mode) => {
  return mode.includes("dark")
    ? {
        accentColor: color,
        selectedRowBackgroundColor: colorShade(color, 0.3),
        backgroundColor: colorShade(color, 50),
        foregroundColor: "#fbfbfb",
        checkboxCheckedColor: color,
        headerBackgroundColor: colorShade(color, 70),
        headerTextColor: "#fbfbfb",
        oddRowBackgroundColor: colorShade(color, 70),
      }
    : mode.includes("light")
    ? {
        accentColor: color,
        selectedRowBackgroundColor: colorShade(color, 0.65),
        backgroundColor: "#fbfbfb",
        checkboxCheckedColor: color,
        foregroundColor: color,
        headerBackgroundColor: color,
        headerTextColor: "#fbfbfb",
        oddRowBackgroundColor: colorShade(color, 0.9),
      }
    : {};
};

const getTheme = (color, mode, theme) =>
  THEMES[theme].withParams(getParams(color, mode), mode);

const getAgThemePreference = () => {
  const ls = JSON.parse(sessionStorage.getItem("agThemePreference"));

  if (!!ls) {
    let { theme, mode, accentColor } = ls;
    document.body.dataset.agThemeMode = mode;
    const style = getStyles(accentColor, mode, theme);

    return {
      mode,
      style,
      accentColor,
      ...getParams(accentColor, mode),
      customTheme: getTheme(accentColor, mode, theme),
    };
  } else {
    const accentColor = "#1d3445";
    const mode = "light";
    const theme = "alpine";

    const style = getStyles(accentColor, `-${mode}`, theme);
    sessionStorage.setItem(
      "agThemePreference",
      JSON.stringify({ theme, mode, accentColor })
    );

    return {
      mode,
      style,
      accentColor,
      ...getParams(accentColor, mode),
      customTheme: getTheme(accentColor, mode, theme),
    };
  }
};
export default getAgThemePreference;
