import React, { useState, useEffect } from "react";
import "./CardEmployees.scss";
import { Badge, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { calculateMonthHoursAllEmp } from "./utils/calculations";
import { HollowDotsSpinner } from "react-epic-spinners";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";

const Employees = () => {
  const { employees } = useSelector((state) => state.employeesList);
  const { clockings } = useSelector((state) => state.clockings);

  const [emplList, setEmpList] = useState(null);
  // const [clockings, setClockings] = useState(null);
  const [calculations, setCalculations] = useState(null);

  useEffect(() => {
    if (!!employees) {
      setEmpList(employees);
      // console.log("Employees", employees);
    }
  }, [employees]);

  useEffect(() => {
    if (emplList !== null && !!clockings) {
      const temp = calculateMonthHoursAllEmp(emplList, clockings, dayjsTZ());
      // console.log("calculated", calculateMonthHoursAllEmp(emplList, clockings, moment()));
      temp.push({
        employeeFirstName: "TOTAL",
        employeeLastName: "",
        totalHours: temp.reduce((acc, curr) => acc + curr?.totalHours || 0, 0),
      });
      setCalculations(temp);
    }
  }, [emplList, clockings]);

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return (
    <>
      {!!employees && !!clockings && !!calculations ? (
        <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
          <div className="employee-card">
            <span className="title-text">Punonjësit</span>
            <span className="report">
              {/* <button className="report-button">
		<span className="report-button-text">Shiko raportin</span>
	  </button> */}
              <span className="title-text">Orët Mujore</span>
            </span>
          </div>
          <div className="content">
            {!!calculations ? (
              calculations
                ?.filter((el) => el.employeeFirstName !== null)
                ?.map((el, key) => (
                  <div
                    key={key}
                    className="employee-card-content"
                    style={{
                      width: "330px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Badge
                      className="name-badger"
                      color={getRandomColor()}
                      text={`${el?.employeeFirstName} ${el?.employeeLastName} `}
                    />
                    <span
                      className="line-span"
                      style={{
                        display: "inline-block",
                        width: 125,
                        borderBottom: "1px solid rgba(0,0,0,.06)",
                        lineHeight: 0,
                        color: "#BCBCBC",
                      }}
                    >
                      &nbsp;
                    </span>
                    <span className="number-em">
                      {el.totalHours.toFixed(2)}
                    </span>
                  </div>
                ))
            ) : (
              <div style={{ margin: "0 20px 0 20px" }}>
                <Skeleton />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1da193" size={24} />
        </div>
      )}
    </>
  );
};

export const getRandomColor = () => {
  const colors = [
    "#7AC14D",
    "#FCC94A",
    "#BCBCBC",
    "#F04F4E",
    "#840032",
    "#ADD9F4",
    "#B0E298",
  ];
  const random = (min, max) => Math.floor(Math.random() * (max - min) + min);
  let id = random(0, colors.length - 1);
  return colors[id];
};

export default Employees;
