import { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, message } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import AgGridComponent from "../../AG-grid/AgGridComponent";
import PunonjesitContext from "../../punonjesit/store/PunonjesitContext";
import getAgThemePreference from "../../../utils/getAgThemePreference";
import colorShade from "../../../utils/colorShade";
import { colDefs } from "./colDefs";
const { RangePicker } = DatePicker;

const EXPORT_COLS = [
  { en: "requestType", al: "Lloji i lejes" },
  { en: "requestDescription", al: "Përshkrimi i lejes" },
  { en: "requestStatus", al: "Statusi i lejes" },
  { en: "createdAt", al: "Krijuar në", format: "DD/MM/YYYY" },
  { en: "requestPeriod", al: "Periudha e lejes" },
];
const ITEMS = {
  search: {},
  icons: {
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};
const { foregroundColor, mode, accentColor } = getAgThemePreference();

function Raportet() {
  const { users } = useSelector((state) => state.usersList);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);

  const { employee } = useContext(PunonjesitContext);

  const [gridApi, setGridApi] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);

  const rowData = useMemo(() => {
    if (!employeeRequests) return [];
    const idx = (users?.allUsers?.Items || []).findIndex(
      (el) => el?.userSub === employee?.userSub
    );
    const matchingUser = idx !== -1 ? users?.allUsers?.Items[idx] : {};

    return employeeRequests
      .filter((el) =>
        !!el?.employeeId
          ? el?.employeeId === employee?.employeeId
          : el?.userSub === matchingUser?.userSub
      )
      .sort((a, b) => b?.clockInDate - a?.clockInDate);
  }, [employeeRequests, employee?.employeeId, users]);

  const filteredData = useMemo(() => {
    if (!activeFilter) return rowData;
    return rowData.filter((el) => {
      const range = el?.requestPeriod || [];
      return range.some((r) =>
        dayjsTZ(r).isBetween(activeFilter[0], activeFilter[1])
      );
    });
  }, [activeFilter, rowData]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // const onFilteredData = async (e) => {
  //   console.log(e);
  //   if (e?.[0] && e?.[1]) {
  //     setFilteredData((prev) => ({ ...prev, active: true, timeInterval: e }));
  //     message.loading({ key: "filter", content: "Duke ngarkuar të dhënat..." });
  //     await getReportsClockings(e, employee?.employeeId, "HolidayRequest")
  //       .then((r) => {
  //         let toFilter = r
  //           ?.filter((el) => el?.employeeId === employee?.employeeId)
  //           .filter(
  //             (rqs) =>
  //               rqs?.dayType === "HolidayRequest" || rqs?.dayType === "Sick"
  //           );
  //         setFilteredData((prev) => ({ ...prev, data: toFilter }));
  //       })
  //       .then(() =>
  //         message.success({
  //           key: "filter",
  //           content: "Të dhënat u ngarkuan me sukses!",
  //         })
  //       )
  //       .catch(() =>
  //         message.error({
  //           key: "filter",
  //           content: "Dicka shkoi keq, ju lutem provoni përsëri!",
  //         })
  //       );
  //   } else {
  //     setFilteredData((prev) => ({ ...prev, active: false, timeInterval: [] }));
  //   }
  // };

  //region RETURN
  return (
    <div className="globalPageWrapper">
      <AgGridComponent
        gridApi={gridApi}
        rowData={filteredData}
        rowSelection={{ mode: "multiRow" }}
        onGridReady={onGridReady}
        columnDefs={colDefs()}
        headerProps={{
          items: ITEMS,
          exportTitle: `Lejet - ${employee?.employeeFirstName} ${employee?.employeeLastName}`,
          children: [
            <RangePicker
              bordered={true}
              style={{
                backgroundColor: mode.includes("dark")
                  ? colorShade(accentColor, 40)
                  : null,
                color: foregroundColor,
              }}
              allowClear={true}
              onChange={setActiveFilter}
              renderExtraFooter={() =>
                "Intervali maksimal i të dhënave për këtë raport është 24 muaj."
              }
              format={"DD/MM/YYYY"}
            />,
          ],
        }}
        counterProps={{
          className: "rowFlex",
          title: "Totali i lejeve (orë)",
          data: filteredData.map((el) =>
            !!el.requestPeriod?.length
              ? dayjsTZ(el.requestPeriod[el.requestPeriod.length - 1])
                  .diff(dayjsTZ(el.requestPeriod[0]), "hours", true)
                  .toFixed(2)
              : 0
          ),
        }}
        idKey={"requestId"}
      />
    </div>
  );
}

export default Raportet;
