import { Tooltip } from "antd";
import dayjsTZ from "../../../utils/dayjs";
import { ApprovedIcon, UnapprovedIcon } from "../../../assets/icons";
import { EyeFilled, HistoryOutlined } from "@ant-design/icons";
import { checkWhetherEdited } from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/validations";
import EditDayPay from "./addition/EditDayPay";

const EYE_ICON_STYLES = {
  color: "#fff",
  padding: 3,
  borderRadius: 3,
  backgroundColor: "#1da193",
  width: "max-content",
  height: "max-content",
  marginLeft: "5px",
};

export const coldefs = ({
  navigate,
  isMobileView,
  onEditShift,
  showModal,
  clockins,
  getShiftData,
  onShiftAdminLog,
}) => [
  {
    headerName: "Statusi",
    field: "approved",
    filter: "agTextColumnFilter",
    width: 75,
    filterParams: { apply: true, newRowsAction: "keep" },
    mobile: true,
    onCellClick: () => navigate("/prezencat"),
    valueGetter: ({ data }) => {
      if (data?.approved === true) {
        return "Aprovuar";
      } else {
        return "Pa Aprovuar";
      }
    },
    cellRenderer: ({ data }) =>
      !isMobileView ? (
        <div style={{ cursor: "pointer" }} onClick={() => onEditShift(data)}>
          <div variant="outlined" cursor="pointer">
            {data?.approved === true ? (
              <Tooltip
                title={`${
                  data?.totalities?.adminLog?.approvedBy
                    ? `Aprovuar nga ${
                        data?.totalities?.adminLog?.approvedBy
                      } - ${dayjsTZ(
                        data?.totalities?.adminLog?.createdAt
                      ).format("DD/MM/YYYY HH:mm:ss")}`
                    : ""
                }`}
              >
                <div className="statusIcon" style={{ background: "#1da193" }}>
                  <ApprovedIcon />
                </div>
              </Tooltip>
            ) : data?.approved === false ? (
              <Tooltip
                title={`${
                  data?.totalities?.adminLog?.approvedBy
                    ? `Hequr Aprovimi nga ${
                        data?.totalities?.adminLog?.approvedBy
                      } - ${dayjsTZ(
                        data?.totalities?.adminLog?.createdAt
                      ).format("DD/MM/YYYY HH:mm:ss")}`
                    : ""
                }`}
              >
                <div className="statusIcon" style={{ background: "#ea3943" }}>
                  <UnapprovedIcon />
                </div>
              </Tooltip>
            ) : (
              <div className="statusIcon" style={{ background: "grey" }}>
                Pa Përcaktuar
              </div>
            )}
          </div>
        </div>
      ) : !!data?.approved ? (
        "Aprovuar"
      ) : (
        "Pa Aprovuar"
      ),
  },
  // {
  // 	headerName: "Emri",
  // 	field: "employee_details.employeeFirstName",
  // 	filterParams: {
  // 		readOnly: true,
  // 	},
  // 	// valueGetter: ({ data }) =>
  // 	// 	data?.employee_details?.employeeFirstName + " " + data?.employee_details?.employeeLastName,
  // },

  // {
  // 	headerName: "Mbiemri",
  // 	field: "employee_details.employeeLastName",
  // 	filterParams: {
  // 		readOnly: true,
  // 	},
  // },
  {
    mobile: true,
    headerName: "Punonjesi",
    field: "employeeFullName",
    filterParams: { apply: true, newRowsAction: "keep" },
    cellClickKey: "employeeId",
    onCellClick: ({ clickId }) => navigate(`/punonjesit/specifikat/${clickId}`),
    valueGetter: ({ data }) => data?.employeeFullName,
    filterParams: { readOnly: true },
    isCollapseTitle: true,
  },
  {
    mobile: true,
    headerName: "Team",
    filterParams: { apply: true, newRowsAction: "keep" },
    // filter: "agTextColumnFilter",
    field: "employeeTeam",
    valueGetter: ({ data }) => {
      return data?.employeeTeam || "";
    },
    // filterParams: { readOnly: true },
  },
  // {
  // 	headerName: "Punesimi",
  // 	field: "employee_details.employeeStatus",
  // 	filterParams: {
  // 		readOnly: true,
  // 	},
  // },
  {
    headerName: "Departamenti",
    field: "employeeDepartmentName",
    mobile: true,
    filterParams: { apply: true, newRowsAction: "keep" },
    onCellClick: () => navigate(`/departamentet`),
    // valueGetter: (params) => {
    // 	// console.log("DADAFWF", params.data);
    // 	try {
    // 		return matchDepartamentWithEmployee(
    // 			params.data.employee_details.employeeDepartmentId,
    // 			departments
    // 		);
    // 	} catch (err) {
    // 		console.log("createdAt--err");
    // 	}
    // },
    // cellRenderer: (params) => {
    //   try {
    //     return matchDepartamentWithEmployee(params.value, departments);
    //   } catch (err) {
    //     console.log("createdAt--err");
    //   }
    // },
  },
  {
    headerName: "Data",
    field: "data",
    filter: "agDateColumnFilter",
    filterParams: { apply: true, newRowsAction: "keep" },
    sort: "desc",
    width: 120,
    mobile: true,
    cellRenderer: ({ data }) => {
      try {
        return dayjsTZ(data?.data).format("DD/MM/YYYY");
      } catch (err) {
        console.log("createdAt--err");
        return "";
      }
    },
    filterParams: {
      inRangeInclusive: true,
    },
  },
  {
    headerName: "Kategoria",
    field: "dayType",
    mobile: true,
    width: 120,
    filterParams: { apply: true, newRowsAction: "keep" },
    hide: false,
    cellRenderer: ({ data }) => {
      switch (data?.dayType) {
        case "Regular":
          return "Normal";
        case "Sick":
          return "Sëmurë";
        case "Maternity":
          return "Leje Lindje";
        case "NationalHoliday":
          return "Festë Zyrtare";
        case "NationalHolidayWorking":
          return "Festë Zyrtare në Punë";
        case "HolidayRequest":
          return "Kërkesë Leje";
        default:
          return "";
      }
    },
  },
  {
    headerName: "Fillimi i turnit",
    field: "fillim_turni",
    filter: "agDateColumnFilter",
    width: 70,
    mobile: true,
    cellRenderer: ({ data }) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {dayjsTZ(data?.fillim_turni).format("HH:mm")}
          {
            // checkWhetherEdited(rowData, data?.clockingId, "editedClockIn") === true ||
            data?.clockInNote ? (
              <EyeFilled
                style={EYE_ICON_STYLES}
                onClick={(e) => showModal(e, data, "clockIn")}
              />
            ) : null
          }
        </div>
      );
    },
    filterParams: { apply: true, newRowsAction: "keep" },
  },
  {
    headerName: "Pushimi",
    field: "breakTime",
    filter: "agNumberColumnFilter",
    mobile: true,
    cellRenderer: ({ data }) => {
      let breakTime =
        (data?.breakTime?.[0] || "") + " - " + (data?.breakTime?.[1] || "");
      return <div>{breakTime}</div>;
    },
    filterParams: { apply: true, newRowsAction: "keep" },
  },
  {
    headerName: "Pushimi i paguar",
    field: "isBreakTimePaid",
    mobile: true,
    width: 40,
    filter: "agNumberColumnFilter",
    cellRenderer: ({ data }) => {
      return data?.isBreakTimePaid ? "Po" : "Jo";
    },
    filterParams: { apply: true, newRowsAction: "keep" },
  },
  {
    headerName: "Mbarimi i turnit",
    field: "mbarim_turni",
    width: 70,
    filter: "agNumberColumnFilter",
    mobile: true,
    cellRenderer: ({ data }) => (
      <div variant="outlined" cursor="pointer">
        {dayjsTZ(data?.mbarim_turni).format("HH:mm") !== "Invalid Date" ? (
          dayjsTZ(data?.mbarim_turni).format("HH:mm")
        ) : (
          <h1 style={{ color: "#ea3943" }}>–</h1>
        )}
        {checkWhetherEdited(clockins, data?.clockingId, "editedClockOut") ===
          true || data?.clockOutNote ? (
          <EyeFilled
            style={EYE_ICON_STYLES}
            onClick={(e) => showModal(e, data, "clockOut")}
          />
        ) : null}
      </div>
    ),
    filterParams: { apply: true, newRowsAction: "keep" },
  },
  {
    headerName: "Orët Totale",
    field: "oret_totale",
    filter: "agNumberColumnFilter",
    width: 80,
    mobile: true,
    filterParams: { apply: true, newRowsAction: "keep" },
    valueGetter: ({ data }) => parseFloat(data?.oret_totale),
    cellRenderer: ({ data }) => (
      <strong>{isNaN(data.oret_totale) ? "0.00" : data.oret_totale}</strong>
    ),
  },
  {
    headerName: "Krijuar",
    // field: "oret_totale",
    // filter: "agNumberColumnFilter",
    filterParams: { apply: true, newRowsAction: "keep" },
    width: 70,
    valueGetter: ({ data }) => data?.clockingLogs[0]?.createdAt,

    cellRenderer: ({ data }) => (
      <span className="cellTimeWrapper">
        {!!data?.clockingLogs
          ? dayjsTZ(data?.clockingLogs[0]?.createdAt).format("HH:mm")
          : "-"}
      </span>
      // <div
      // 	style={{
      // 		background: "#FAF0CA",
      // 		width: "100%",
      // 		display: "flex",
      // 		justifyContent: "center",
      // 		alignItems: "center",
      // 	}}
      // >
      // 	<div
      // 		style={{
      // 			height: 30,
      // 		}}
      // 	>
      // 		{!!data?.clockingLogs ? moment(data?.clockingLogs[0]?.createdAt).format("HH:mm") : "-"}
      // 	</div>
      // </div>
    ),
  },
  {
    headerName: "Perfunduar",
    // field: "oret_totale",
    // filter: "agNumberColumnFilter",
    width: 70,
    filterParams: { apply: true, newRowsAction: "keep" },
    valueGetter: ({ data }) => data?.finishedAt,

    cellRenderer: ({ data }) => (
      <span className="cellTimeWrapper">
        {!!data?.finishedAt ? dayjsTZ(data?.finishedAt).format("HH:mm") : "-"}
      </span>
      // <div
      // 	style={{
      // 		background: "#FAF0CA",
      // 		width: "100%",
      // 		display: "flex",
      // 		justifyContent: "center",
      // 		alignItems: "center",
      // 	}}
      // >
      // 	<div
      // 		style={{
      // 			height: 30,
      // 		}}
      // 	>
      // 		{!!data?.clockingLogs ? moment(data?.clockingLogs[0]?.createdAt).format("HH:mm") : "-"}
      // 	</div>
      // </div>
    ),
  },
  {
    headerName: "employeeId",
    filter: "agTextColumnFilter",

    field: "employee_details.employeeId",
    hide: true,
    filterParams: { apply: true, newRowsAction: "keep" },
  },
  {
    headerName: "Pagesa",
    field: null,
    width: 70,
    // filterParams: { apply: true, newRowsAction: "keep" },
    // cellRenderer: ({ data }) => (
    // 	<div
    // 		style={{
    // 			display: "flex",
    // 			// justifyContent: "center",
    // 			alignItems: "center",
    // 		}}
    // 		cursor="pointer"
    // 		onClick={() => onShiftAdminLog(data)}
    // 	>
    // 		<span
    // 			style={{
    // 				display: "flex",
    // 				justifyContent: "center",
    // 				alignItems: "center",
    // 				width: "100px",
    // 				cursor: "pointer",
    // 				height: "22px",
    // 				marginTop: "8px",
    // 				background: "#1da193",
    // 				color: "white",
    // 				border: "none",
    // 				borderRadius: "5px",
    // 			}}
    // 			// onClick={() => onShiftAdminLog(data)}
    // 		>
    // 			Historiku
    // 		</span>
    // 	</div>
    // ),
    cellRenderer: ({ data }) => {
      try {
        if (data?.approved === true) {
          return <EditDayPay data={data} getShiftData={getShiftData} />;
        } else {
          return "";
        }
      } catch (e) {
        console.error("error on cellRenderer", e);
      }
    },
  },
  // {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   // width: "100px",
  //   // cursor: "pointer",
  //   // height: "22px",
  //   // marginTop: "8px",
  //   background: "#1da193",
  //   // color: "white",
  //   // border: "none",
  //   // borderRadius: "5px",
  //   width: "42px",
  //   padding: "5px",
  //   cursor: "pointer",
  //   height: "30px",
  //   marginTop: "3px",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   borderRadius: "5px",
  //   color: "white",
  // },
  {
    headerName: "Ndryshimet",
    field: null,
    width: 70,
    filterParams: { apply: true, newRowsAction: "keep" },

    cellRenderer: ({ data }) => (
      <div className="cellLogWrapper" onClick={() => onShiftAdminLog(data)}>
        <HistoryOutlined />
      </div>
    ),
  },
];
