import { IconKerko } from "../../../../assets/icons";
import { useState } from "react";
import { Input } from "antd";
import MondayButton from "../../MondayButton/MondayButton";

const SearchAgGrid = ({
  gridApi,
  setActiveFilter,
  handleClean,
  className,
  style,
}) => {
  const [input, setInput] = useState(null);

  // const customSearch = (data, value) => {
  //   if (!data || !value) return data;
  //   else {
  //     const results = [];
  //     data.forEach((row) => {
  //       const values = Object.values(row).filter(
  //         (el) => typeof el === "string" || typeof el === "number"
  //       );
  //       console.log(values);
  //       const matches = values.some((el) =>
  //         el.toString().toLowerCase().includes(value)
  //       );
  //       results.push({ ...row, hide: matches ? false : true });
  //     });
  //     return results;
  //   }
  // };

  const resetSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleClean();
    setInput(null);

    //resetCustomSearch
    if (!!setActiveFilter && typeof setActiveFilter === "function") {
      setActiveFilter({});
    }
  };

  const handleSearchChange = (e) => {
    if (!!gridApi) {
      gridApi.setGridOption("quickFilterText", e.target.value);
    } else if (!!setActiveFilter && typeof setActiveFilter === "function") {
      setActiveFilter((prev) => ({ ...prev, search: e.target.value }));
    }
    setInput(e.target.value);
  };

  return (
    <div
      className={`headerSearch ${className}`}
      data-testid="headerSearch"
      style={style}
    >
      <div className="icon">
        <IconKerko width={16} height={16} fill={style?.fill || "#111"} />
      </div>
      <Input
        className="headerInput"
        placeholder="Kërko"
        onChange={handleSearchChange}
        value={input}
        style={style}
        autoComplete="off"
      />
      <MondayButton
        className="mondayButtonRed headerButton"
        onClick={resetSearch}
      >
        Pastro
      </MondayButton>
    </div>
  );
};

export default SearchAgGrid;
