import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { groupByKey } from "../../../../utils/loadash/methods";
import { paySubFactory } from "../../../Pagat/PaySub/employeeCalc/employeeSubFactory";

/**
 * The Start of a sequence of chained functions which handles the logic behind shift calculation, sorting, grouping
 * @param {Array} dataArr which contains the data to be processed
 * @param {Time} dayInstance is a moment instance which will filter the shifts accordingly
 * @returns iterative to another function
 */
//region filterByDay()
export const filterByDay = (dataArr, dayInstance) => {
  // console.log(
  //   "Day Instance",
  //   dayjsTZ(dayInstance.start).format("DD/MM/YYYY HH:mm"),
  //   "end",
  //   dayjsTZ(dayInstance.end).format("DD/MM/YYYY HH:mm")
  // );
  if (dataArr.length > 0) {
    const filtered = dataArr
      .filter(
        (shift) =>
          shift.clockInDate > dayInstance.start &&
          shift.clockInDate < dayInstance.end &&
          shift.clockOutDate !== null
      )
      .sort((a, b) => a.clockInDate - b.clockInDate);
    // console.log("filtered", filtered);

    return groupAndCalculateHours(dayInstance, filtered);
  }
  return [];
};

//region groupAndCalculateHours()
export const groupAndCalculateHours = (dayInstance, filteredArr) => {
  let result = [];
  let hours = 0;
  let overtime = false;
  let markedOvertime = false;

  filteredArr.map((el) => {
    const duration = dayjsTZ(el.clockOutDate).diff(
      dayjsTZ(el.clockInDate),
      "hours",
      true
    );

    if (duration > 0) {
      result.push({
        ...el,
        clockInDate: el.clockInDate,
        clockOutDate: el.clockOutDate,
        type: overtime === true || hours > 8 ? "extra" : "usual",
      });
    }
  });
  // console.log("result", result, "hours", hours);
  return getMissingHours(result);
};

//region getMissingHours()
export const getMissingHours = (arr) => {
  let res = [];
  let data = arr || [];

  if (data.length > 0) {
    let sorted = data.sort((a, b) => a.clockInDate - b.clockInDate);
    let temp = calculateBreakHours(sorted);

    data = temp?.remains;
    res = [...temp?.res];
    sorted.map((el, idx) => {
      if (idx < sorted.length - 1) {
        res.push({
          ...el,
          clockInDate: dayjsTZ(sorted[idx].clockOutDate).valueOf(),
          clockOutDate: dayjsTZ(sorted[idx + 1].clockInDate).valueOf(),
          type: "missing",
        });
      }
    });
    res = data
      .concat(res)
      .sort((a, b) => a.clockInDate - b.clockInDate)
      .map((el) => ({
        ...el,
        duration: dayjsTZ(el.clockOutDate)
          .diff(dayjsTZ(el.clockInDate), "hours", true)
          .toFixed(2),
      }));
    let test = {};

    // console.log(
    //   "Test",
    //   res.map((el) => ({
    //     ...el,
    //     clockInDate: dayjsTZ(el.clockInDate).format("HH:mm"),
    //     clockOutDate: dayjsTZ(el.clockOutDate).format("HH:mm"),
    //   }))
    // );
    return res;
  } else return [];
};

//region filterBreaks()
export const filterBreaks = (shifts, totalHours) => {
  const arr = [...(shifts || [])];
  const idx = arr.findIndex((el) => el?.type === "breakTime");
  if (idx !== -1) {
    return arr.filter((el) => {
      if (!el?.employeeInfo?.isBreakTimePaid) {
        return el?.type !== "missing" && el?.type !== "breakTime";
      } else {
        return el?.type !== "missing";
      }
    });
  } else if (parseFloat(totalHours) > 4) {
    //cases when breakTime occurs in missing hours
    const shiftIdx = arr.findIndex(
      (el) => parseFloat(el?.duration) > 0.5 && el?.type === "usual"
    );
    if (shiftIdx !== -1) {
      const breakTime = arr[shiftIdx]?.employeeInfo?.breakTime || [];
      const isBreakTimePaid = !!arr[shiftIdx]?.employeeInfo?.isBreakTimePaid;

      if (Array.isArray(breakTime) && !!breakTime?.length && !isBreakTimePaid) {
        const [s_hour, s_minute] = (breakTime?.[0] || ":").split(":");
        const [e_hour, e_minute] = (breakTime?.[1] || ":").split(":");

        const breakDuration = dayjsTZ()
          .set("hour", e_hour)
          .set("minute", e_minute)
          .set("second", "00")
          .diff(
            dayjsTZ()
              .set("hour", s_hour)
              .set("minute", s_minute)
              .set("second", "00")
          );
        arr[shiftIdx] = {
          ...arr[shiftIdx],
          duration: `${
            parseFloat(arr[shiftIdx]?.duration) - breakDuration / 3600000
          }`,
        };
      }
    }
  }
  return arr.filter((el) => el?.type !== "missing");
};

//region calculatePercentages()
export const calculatePercentages = (dataSrc) => {
  // console.log("Data Source", dataSrc);
  let totalHours = 0;
  let workedHours = 0;
  let calculated = [];
  if (dataSrc.length > 0) {
    totalHours = dataSrc
      .map((el) => parseFloat(el.duration))
      .reduce((a, b) => a + b)
      .toFixed(2);
    workedHours = filterBreaks(dataSrc, totalHours)
      .map((el) => parseFloat(el.duration))
      .reduce((a, b) => a + b)
      .toFixed(2);

    if (totalHours > 0) {
      calculated = dataSrc.map((el) => ({
        ...el,
        percentage: (el.duration * 100) / totalHours,
      }));
    }
  }
  // console.log(calculated);
  return [calculated, workedHours];
};

//region initializeShift()
export const initializeShift = (data, instance, type) => {
  let timeFilter = {
    start: dayjsTZ(instance).startOf(type).valueOf(),
    end: dayjsTZ(instance).endOf(type).valueOf(),
  };
  if (!!data) {
    if (data.length > 0) {
      const shifts = data
        .filter(
          (el) =>
            el.clockInDate !== null &&
            el.clockOutDate !== null &&
            el.clockInDate > timeFilter.start &&
            el.clockInDate < timeFilter.end
        )
        .map((el) => ({
          ...el,
          dateGroup: dayjsTZ(el.clockInDate).format("DD/MM/YYYY"),
          clockInDate:
            el?.clockingCategory !== "midnightReset"
              ? el?.clockInDate
              : dayjsTZ(el.clockInDate).add("1", "days").valueOf(),
          clockOutDate:
            el?.clockingCategory !== "midnightReset"
              ? el?.clockOutDate
              : dayjsTZ(el.clockOutDate).add("1", "days").valueOf(),
        }));

      if (shifts.length > 0) {
        const grouped = groupByKey(shifts, "dateGroup");
        // console.log("GROUPED", grouped);
        let toAssign = [];
        for (const [_, val] of Object.entries(grouped)) {
          let secondGrouped = groupByKey(val, "employeeId");
          for (const [_, v] of Object.entries(secondGrouped)) {
            // console.log("second grouped", secondGrouped);
            toAssign.push(filterByDay(v, timeFilter));
          }
        }
        // console.log("to assign", toAssign);
        toAssign.sort((a, b) => b[0]?.clockInDate - a[0]?.clockInDate);
        return toAssign;
      }
    }
  }
  return [];
};

//region filterCustomShifts()
export const filterCustomShifts = (data, timeArr, type) => {
  let timeFilter = {
    start: dayjsTZ(timeArr[0]).startOf(type).valueOf(),
    end: dayjsTZ(timeArr[1]).endOf(type).valueOf(),
  };
  if (data.length > 0) {
    let shifts = data
      .filter(
        (el) =>
          el.clockInDate !== null &&
          el.clockOutDate !== null &&
          el.clockInDate > timeFilter.start &&
          el.clockInDate < timeFilter.end
      )
      .map((el) => ({
        ...el,
        dateGroup: dayjsTZ(el.clockInDate).format("DD/MM/YYYY"),
        clockInDate:
          el?.clockingCategory !== "midnightReset"
            ? el?.clockInDate
            : dayjsTZ(el.clockInDate).add("1", "days").valueOf(),
        clockOutDate:
          el?.clockingCategory !== "midnightReset"
            ? el?.clockOutDate
            : dayjsTZ(el.clockOutDate).add("1", "days").valueOf(),
      }));

    if (shifts.length > 0) {
      const grouped = groupByKey(shifts, "dateGroup");
      let toAssign = [];
      for (const [_, val] of Object.entries(grouped)) {
        let secondGrouped = groupByKey(val, "employeeId");
        for (const [_, v] of Object.entries(secondGrouped)) {
          // console.log("second grouped", secondGrouped);
          toAssign.push(filterByDay(v, timeFilter));
        }
      }
      // console.log("To assign", toAssign);
      // console.log("grouped", grouped);
      toAssign.sort((a, b) => b?.[0]?.clockInDate - a?.[0]?.clockInDate);

      return toAssign;
      // setFetched(true);
    }
  }
  return [];
};

/// To be used in the future if needed to separate the shifts

const getDateGroup = (clockShift) => {
  let clockInDate = clockShift?.clockInDate;
  let clockOutDate = clockShift?.clockOutDate;
  let refTime__lower = dayjsTZ(clockInDate);
  let refTime__upper = dayjsTZ(clockInDate);

  refTime__lower = refTime__lower
    .set("hour", "00")
    .set("minute", "00")
    .set("second", "00");

  refTime__upper = refTime__upper
    .set("hour", "08")
    .set("minute", "00")
    .set("second", "00");

  if (clockInDate >= refTime__lower && clockInDate <= refTime__upper) {
    return dayjsTZ(clockInDate).subtract(1, "days").format("DD/MM/YYYY");
  } else {
    return dayjsTZ(clockInDate).format("DD/MM/YYYY");
  }
};

//region calculateBreakHours()
export const calculateBreakHours = (sorted) => {
  let res = [];
  let remains = [];
  if (Array.isArray(sorted)) {
    sorted?.forEach((sortedEl) => {
      let breakInterval = sortedEl?.totalities?.breakTime || [];
      let breakStart = dayjsTZ(sortedEl.clockOutDate);
      let breakEnd = dayjsTZ(sortedEl.clockOutDate);
      try {
        if (Array.isArray(breakInterval) && !!breakInterval?.length) {
          const [s_hour, s_minute] = (breakInterval?.[0] || ":").split(":");
          const [e_hour, e_minute] = (breakInterval?.[1] || ":").split(":");

          breakStart = breakStart
            .set("hour", s_hour)
            .set("minute", s_minute)
            .set("second", "00");
          breakEnd = breakEnd
            .set("hour", e_hour)
            .set("minute", e_minute)
            .set("second", "00");
        }
      } catch (err) {
        console.log("Error: ", err);
        console.log(sortedEl);
      }

      if (
        dayjsTZ(sortedEl?.clockOutDate).valueOf() >=
          dayjsTZ(breakEnd).valueOf() &&
        dayjsTZ(sortedEl?.clockInDate).valueOf() <=
          dayjsTZ(breakStart).valueOf() &&
        breakInterval.length > 1
      ) {
        res.push({
          ...sortedEl,
          clockInDate: dayjsTZ(sortedEl?.clockInDate).valueOf(),
          clockOutDate: dayjsTZ(breakStart).valueOf(),
        });

        res.push({
          ...sortedEl,
          clockInDate: dayjsTZ(breakStart).valueOf(),
          clockOutDate: dayjsTZ(breakEnd).valueOf(),
          type: "breakTime",
          isBreakTime: true,
        });
        res.push({
          ...sortedEl,
          clockInDate: dayjsTZ(breakEnd).valueOf(),
          clockOutDate: dayjsTZ(sortedEl?.clockOutDate).valueOf(),
        });
      } else {
        remains.push(sortedEl);
      }
    });
  }
  return { res, remains };
};
