import {
  ExcelIcon,
  // ImageIcon,
  PdfIcon,
  PdfWhiteIcon,
  WordIcon,
} from "./assets";

export const fileIcons = ({ type, ...rest }) => {
  const fileIcons = {
    xls: <ExcelIcon {...rest} />,
    xlsx: <ExcelIcon {...rest} />,
    pdf: <PdfWhiteIcon {...rest} />,
    doc: <WordIcon {...rest} />,
    docx: <WordIcon {...rest} />,
    // image: <ImageIcon {...rest} />,
  };
  return fileIcons[type];
};

export const docObjectLabels = {
  expirationDate: "Data Skadencës",
  // notes: "Shënime",
  docStatus: "Statusi i Dokumentacionit",
};

export const uuidRegex = /\/(\w+-){4}\w+/g;
