import { Row, Col, Calendar, Select, Radio, Tooltip } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../../utils/dayjs";
import { memo } from "react";
import { getRandomColor } from "../../../../../../utils";
import Avatar from "react-avatar";

const BirthdayCalendar = ({ renderdBirthdays }) => {
  function dateCellRender(momentDate) {
    // console.log(renderdBirthdays);
    const ts = dayjsTZ(momentDate)?.format("DD/MM");
    // get last 42 birthdays in the employee table
    // setRenderedDays([ts]);
    const birthdays = renderdBirthdays?.filter(
      ({ employeeDayOfBirth }) =>
        dayjsTZ(employeeDayOfBirth)?.format("DD/MM") === ts
    );

    return (
      <div className="custom-date-wrapper">
        {!!birthdays?.length ? (
          <Tooltip
            className="calendar-tooltip"
            classNames={{ root: "calendar-tooltip" }}
            placement="top"
            title={
              <div className="toptil-calendar-content">
                {birthdays?.map(
                  ({
                    employeeFirstName,
                    employeeLastName,
                    employeeId,
                    employeeDayOfBirth,
                  }) => (
                    <div key={employeeId}>
                      {employeeFirstName} {employeeLastName}
                    </div>
                  )
                )}
              </div>
            }
          >
            <div
              className="event-calendar"
              style={{ backgroundColor: getRandomColor() }}
            >
              {momentDate.format("DD")}
            </div>
          </Tooltip>
        ) : (
          momentDate.format("DD")
        )}
      </div>
    );
  }

  return (
    <>
      <div className="site-calendar-customize-header-wrapper">
        <Calendar
          dateFullCellRender={dateCellRender}
          fullscreen={false}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const start = 0;
            const end = 12;
            const monthOptions = [];

            const current = value.clone();
            const localeData = value.localeData();
            const months = [];
            for (let i = 0; i < 12; i++) {
              current.month(i);
              months.push(localeData.months(current));
            }

            for (let index = start; index < end; index++) {
              monthOptions.push(
                <Select.Option className="month-item" key={`${index}`}>
                  {months[index]}
                </Select.Option>
              );
            }
            const month = value.month();

            const year = value.year();
            const options = [];
            for (let i = year - 10; i < year + 10; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>
              );
            }
            return (
              <div style={{ padding: 13 }}>
                <Row gutter={8}>
                  <Col style={{ padding: 0 }}>
                    <Select
                      style={{ pointerEvents: "none" }}
                      size="small"
                      dropdownMatchSelectWidth={false}
                      className="my-year-select"
                      onChange={(newYear) => {
                        const now = value.clone().year(newYear);
                        onChange(now);
                      }}
                      value={String(year)}
                    >
                      {options}
                    </Select>
                  </Col>
                  <Col style={{ paddingRight: 0 }}>
                    <Select
                      size="small"
                      style={{ pointerEvents: "none" }}
                      dropdownMatchSelectWidth={false}
                      value={String(month)}
                      onChange={(selectedMonth) => {
                        const newValue = value.clone();
                        newValue.month(parseInt(selectedMonth, 10));
                        onChange(newValue);
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                  <Col style={{ marginLeft: 130 }}>
                    <Radio.Group
                      size="small"
                      onChange={(e) => onTypeChange(e.target.value)}
                      value={type}
                    >
                      {/* <Radio.Button value="month">Muaj</Radio.Button>
                    <Radio.Button value="year">Viti</Radio.Button> */}
                    </Radio.Group>
                  </Col>
                </Row>
              </div>
            );
          }}
        />
      </div>
      <div className="calendar-footer">
        <div className="footer-card">
          {renderdBirthdays?.map(
            ({
              employeeDayOfBirth,
              employeeId,
              employeeLastName,
              employeeFirstName,
            }) => (
              <div className="card-calendar-foter" key={employeeId}>
                <Tooltip
                  className="calendar-tooltip"
                            classNames={{ root: "singleNotifPopconfirm" }}
 overlayClassName="calendar-tooltip"
                  placement="top"
                  title={
                    <div className="toptil-calendar-content">
                      <div key={employeeId}>
                        {employeeFirstName} {employeeLastName}
                      </div>
                    </div>
                  }
                >
                  <Avatar
                    name={employeeFirstName + " " + employeeLastName}
                    size="30"
                    round={true}
                  />
                  <div
                    className="data"
                    style={{
                      backgroundColor: getRandomColor(),
                    }}
                  >
                    {dayjsTZ(employeeDayOfBirth).format("DD")}{" "}
                  </div>
                </Tooltip>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default memo(BirthdayCalendar);
