import React from "react";
import { useSelector } from "react-redux";
import { FullTime } from "../../../assets/icons";
import DragCard from "./Draggable/DragCard";
import { useNavigate } from "react-router-dom";
import { HollowDotsSpinner } from "react-epic-spinners";

const EmployeeMeKoheTePlote = () => {
  //get active employes from redoux
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const navigate = useNavigate();

  // filters the employes with type of employment with "Kohe te plote"
  const employeeKoheEPlote = activeEmployees?.filter(
    (el) => el?.employeeTypeOfEmployment === "Kohe te plote"
  );

  const data = {
    icon: <FullTime />,
    number: (
      <div className="link-kryefaqja-card-send">
        {employeeKoheEPlote?.length || 0}
      </div>
    ),
    text: "Punonjës me kohë të plotë",
  };

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return (
    <div style={{ height: "100%", width: "100%" }}>
      {employeeKoheEPlote?.length !== 0 && !!employeeKoheEPlote ? (
        <DragCard
          data={data} // will navigate to employes tab with the state to filter the grid with the type in the state
          onClick={() =>
            navigate("/punonjesit", {
              state: "Kohe te plote",
            })
          }
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1da193" size={24} />
        </div>
      )}
    </div>
  );
};
export default EmployeeMeKoheTePlote;
