import { API } from "aws-amplify";
import { useState, useEffect, useMemo } from "react";
import ProgressShift from "./ProgressShift";
import "./TimeSheet.scss";
import TimeSheetHeader from "./TimeSheetHeader";
import { groupByKey } from "../../../utils/loadash/methods";
import {
  calculatePercentages,
  filterByDay,
  filterCustomShifts,
  initializeShift,
} from "./utils/filters";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import ChartSummary from "../../dashboard/components/charts/Chart";
import { calculateEmployeeChart, findEmployee } from "./utils/calculate";
import LoadableComp from "../../commonComponents/LoadableComp/LoadableComp";
import { Alert, FloatButton, Tooltip } from "antd";
import { paySubFactory } from "../../Pagat/PaySub/employeeCalc/employeeSubFactory";
import { UnfoldIcon } from "../../../assets/icons";
import FooterCounter from "../../AG-grid/FooterCounter/FooterCounter";
import { useAuth } from "../../../authentication/authHelpers";
import { useSelector } from "react-redux";

function TimeSheet({ data }) {
  const auth = useAuth();
  const { employees } = useSelector((state) => state.employeesList);

  const [activeFilter, setActiveFilter] = useState({});

  const [shiftsConfig, setShiftsConfig] = useState(null);
  const [totalHours, setTotalHours] = useState([]);
  // PAGINATION
  const [pagination, setPagination] = useState({ head: 0, tail: 20 });

  const onPagination = () => {
    let tempLen = filteredShifts.filter((el) => el.length > 0).length;
    if (tempLen > pagination.tail) {
      if (tempLen - pagination.tail > 20) {
        setPagination((prev) => ({ ...prev, tail: pagination.tail + 20 }));
      } else {
        setPagination((prev) => ({ ...prev, tail: tempLen }));
      }
    }
  };

  const filteredShifts = useMemo(() => {
    let toReturn = [];
    const hasRange = (activeFilter || {}).hasOwnProperty("range");

    const entries = Object.entries(activeFilter || {});
    const otherEntries = entries.filter(([key, _]) => key !== "range");

    if (!!otherEntries.length) {
      data.forEach((shift) => {
        const doesMatch = otherEntries.some(
          ([key, val]) => shift?.[key] === val
        );
        !!doesMatch && toReturn.push(shift);
      });
    }
    const currentData = !!otherEntries.length ? toReturn : data;
    if (hasRange) {
      toReturn = filterCustomShifts(currentData, activeFilter.range, "days");
    } else {
      toReturn = initializeShift(currentData, dayjsTZ(), "month");
    }
    let calculatedHours = [];
    const oldArray = [...toReturn];

    toReturn = oldArray.map((el) => {
      const [newEl, workedHours] = calculatePercentages(el);
      calculatedHours.push(parseFloat(workedHours));

      return { shift: newEl, workedHours };
    });
    setTotalHours(calculatedHours);

    return toReturn;
  }, [data, activeFilter]);

  const viewType = useMemo(() => {
    let toReturn;
    if (!!auth?.userRole) {
      if (
        auth.userRole === "Admin" ||
        auth.userRole === "Super Admin" ||
        auth.userRole === "Supervisor"
      ) {
        toReturn = "admin";
      } else {
        setActiveFilter((prev) => ({
          ...(prev || {}),
          employeeId: auth.employeeId,
        }));
        toReturn = "user";
      }
    }
    return toReturn;
  }, [auth]);

  const getShiftsConfig = async () => {
    await API.get("shifts", "/shifts")
      .then((res) => setShiftsConfig(res))
      .catch((err) => console.log("Error: ", err));
  };

  useEffect(() => {
    getShiftsConfig();
  }, []);

  return (
    <LoadableComp loading={!viewType}>
      <div
        className="timesheet-wrapper"
        style={{ position: "relative" }}
        data-testid="timesheet-wrapper"
      >
        <div className="timesheet-header">
          <TimeSheetHeader {...{ activeFilter, setActiveFilter, viewType }} />
        </div>
        <div className="timesheet-content">
          <div className="timesheet-calculations">
            <div className="timesheet-progress-wrapper">
              {!!filteredShifts?.length ? (
                filteredShifts
                  ?.slice(pagination.head, pagination.tail)
                  .map((el) => (
                    <ProgressShift
                      processedShift={el?.shift}
                      totalHours={el?.workedHours}
                      shiftsConfig={shiftsConfig}
                    />
                  ))
              ) : (
                <Alert
                  type="warning"
                  description="Nuk ka të dhëna për këtë filtrim"
                />
              )}

              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                {pagination.tail <
                filteredShifts.filter((el) => el.length > 0).length ? (
                  <span onClick={onPagination} style={{ cursor: "pointer" }}>
                    <UnfoldIcon /> <FloatButton.BackTop />
                  </span>
                ) : null}
              </div>
            </div>
            <FooterCounter
              title={"Orët Totale"}
              className={"rowFlex"}
              data={totalHours}
              style={{
                //winter
                // background: style?.["--ag-background-color"],
                // color: style?.["--ag-foreground-color"],
                padding: "20px",
                borderRadius: 10,
              }}
            />
          </div>

          {/* <span>TOTAL HOURS: {totalHours}</span> */}
          {!!activeFilter?.employeeId ? (
            <div
              className="timesheet-statistics"
              data-testid="timesheet-statistics"
            >
              <DynamicName
                {...{
                  employeeId: activeFilter?.employeeId,
                  employees,
                }}
              />
              <ChartSummary
                activeFilter={activeFilter}
                employeeChart={activeFilter?.employeeId}
                dataTitle="Oret"
                dataSrc={data}
              />
            </div>
          ) : null}
        </div>
      </div>
    </LoadableComp>
  );
}

export default TimeSheet;

const DynamicName = ({ employeeId, employees }) => {
  const [name, setName] = useState("");

  useEffect(() => {
    if (!!employees && employeeId !== null) {
      const r = employees.filter((el) => el.employeeId === employeeId);
      // console.log("rrrr", r);
      setName(`${r[0]?.employeeFirstName} ${r[0]?.employeeLastName}`);
    }
  }, [employeeId, employees]);

  return <div className="statistic-name">{name}</div>;
};

const getEmployee = (employeeId, employeeList) => {
  if (employeeId !== null && employeeList.length > 0) {
    return employeeList.filter((el) => el.employeeId === employeeId);
  }
};
