import "./App.scss";
import React, { useEffect, useMemo, useState } from "react";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Login from "./components/Auth/Login";
import { Routes, Route, useLocation } from "react-router-dom";
import SignUp from "./components/Auth/SignUp";
import AuthenticatedRoute from "./AuthenticatedRoute";
import { NotifContextProvider } from "./components/dashboard/components/notifications/context/notifContext";
import { AuthProvider, useAuth } from "./authentication/authHelpers";
import PublicRoute from "./PublicRoute";
import { API, Auth } from "aws-amplify";
import moment from "moment-timezone";
import { Provider } from "react-redux";
import { useDispatch } from "react-redux";
import { store } from "./store/store";
import accessToken from "./store/actions/accessToken";
import { hotCredentials } from "./store/actions";
import { gapi } from "gapi-script";
import Axios from "axios";
import "antd/dist/reset.css";
import { ReportProvider } from "./components/Reports/generate/reportContext/reportHelpers";
import OtpLogin from "./components/Auth/OtpLogin";
import AuthInterface from "./components/Auth/AuthInterface";
import dayjs from "dayjs";
import dayjsTZ from "./utils/dayjs";

function App() {
  moment.tz.setDefault("Europe/Tirane");
  const dispatch = useDispatch();
  const auth = useAuth();
  const location = useLocation();

  //* state to get hotCreds
  const [hotCreds, setHotCreds] = useState([]);
  const [authData, setAuthData] = useState({});

  //! gets hotCreds from database
  //UseEffect to reset the window location state when the page gets reloaded
  useEffect(() => {
    API.get("hotCredentials", "/hotCredentials")
      .then((res) => {
        dispatch(hotCredentials(res));
        setHotCreds(res);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
    window.addEventListener("beforeunload", () => {
      window.history.replaceState({}, "");
    });

    let values = [
      "draggableSetingsPozicionet",
      "draggableSetingsFitimet",
      "draggableSetingsZbritjet",
      "draggableSetingsInfoRrethKompanis",
      "draggableOrderDepartments",
      "draggableProfili",
      "draggableOrderSuperVisor",
      "draggableOrderUser",
      "draggableOrderAdmin",
    ];

    values.forEach((item) => {
      let value = localStorage.getItem(item);
      if (value) {
        value = JSON.parse(value);
        if (value[0].hasOwnProperty("id")) {
          localStorage.removeItem(item);
        }
      }
    });
  }, []);

  //! initializes accesToken
  const auth2 = () => {
    gapi.client?.init({
      clientId: hotCreds?.find(({ credentialId }) => credentialId === "3")
        ?.credentialValue,
      scope:
        "https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.insert https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.readonly https://mail.google.com/",
    });

    gapi.client.setApiKey(
      hotCreds?.find(({ credentialId }) => credentialId === "2")
        ?.credentialValue
    );
  };

  //! gets accessToken
  useEffect(() => {
    if (hotCreds.length) {
      const toSend = {
        clientId: hotCreds?.find(({ credentialId }) => credentialId === "3")
          ?.credentialValue,
        client_secret: hotCreds?.find(
          ({ credentialId }) => credentialId === "4"
        )?.credentialValue,
        refresh_token: hotCreds?.find(
          ({ credentialId }) => credentialId === "1"
        )?.credentialValue,
        grant_type: "refresh_token",
      };
      Axios.post("https://oauth2.googleapis.com/token", { ...toSend })
        .then(({ data }) => {
          dispatch(accessToken(data.access_token));
        })
        .catch((err) => {
          console.log("Error loading google token: ", err);
        });
      gapi.load("client", () => {
        auth2();
        gapi.client.load("drive", "v3", function () {});
      });
    }

    //interval to get the new access token every 1 hour
    const accessTokenIterval = setInterval(() => {
      if (hotCreds) {
        const toSend = {
          clientId: hotCreds?.find(({ credentialId }) => credentialId === "3")
            ?.credentialValue,
          client_secret: hotCreds?.find(
            ({ credentialId }) => credentialId === "4"
          )?.credentialValue,
          refresh_token: hotCreds?.find(
            ({ credentialId }) => credentialId === "1"
          )?.credentialValue,
          grant_type: "refresh_token",
        };
        Axios.post("https://oauth2.googleapis.com/token", { ...toSend }).then(
          ({ data }) => {
            dispatch(accessToken(data.access_token));
          }
        );
        gapi.load("client", () => {
          auth2();
          gapi.client.load("drive", "v3", function () {});
        });
      }
    }, [3500000]);

    return () => clearInterval(accessTokenIterval);
  }, [hotCreds]);

  const getAuthData = async () => {
    await API.get("users", "/users")
      .then((res) => setAuthData(res?.auth2Fa))
      .catch((err) => setAuthData({}));
  };

  useEffect(() => {
    if (location.pathname === "/login") {
      getAuthData();
    }
  }, [auth]);

  const renderLogin = useMemo(() => {
    const { codeSent, isAuthenticated } = authData || {};

    if (!!auth?.auth2Fa?.codeSent && !auth?.auth2Fa?.isAuthenticated) {
      return (
        <AuthInterface
          title={"Shkruani kodin e ardhur në emailin tuaj!"}
          children={<OtpLogin />}
        />
      );
    } else if (
      (!codeSent && !isAuthenticated) ||
      !auth?.hasOwnProperty("auth2Fa")
    ) {
      return (
        <AuthInterface title={"Futu në adresën tënde!"} children={<Login />} />
      );
    }
  }, [authData, auth]);

  return (
    <div className="App" style={{ width: "100% ", height: "100%" }}>
      <ReportProvider>
        <Provider store={store}>
          {" "}
          <Routes>
            <Route exact path="/" element={<PublicRoute />}>
              <Route path="/login" element={renderLogin} />
              {/* <Route path="/2fa" element={<OtpLogin />} /> */}
              <Route path="/rregjistrohu" element={<SignUp />} />
              <Route
                path="/harruatkodin"
                element={
                  <AuthInterface
                    title={"KE HARRUAR FJALËKALIMIN ?"}
                    children={<ForgotPassword />}
                  />
                }
              />
            </Route>
          </Routes>
          <NotifContextProvider>
            <AuthenticatedRoute />
          </NotifContextProvider>
        </Provider>
      </ReportProvider>
    </div>
  );
}

export default App;
