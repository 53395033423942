import React, { useEffect, useMemo, useState } from "react";
import { groupBy, wrap } from "lodash";
import "./Wages.scss";
import { currencyformatter } from "./utils/formatter";
import { Collapse, Tooltip } from "antd";
import getAgThemePreference from "../../../utils/getAgThemePreference";
import { useMediaQuery } from "react-responsive";

function PagatGridHeader({ onFilter, gridApi, statuses, rowData }) {
  const [headerData, setHeaderData] = useState(null);

  useEffect(() => {
    if (!!rowData && Array.isArray(rowData)) {
      if (rowData.length > 0) {
        let temp = rowData.map((el) => ({
          neto: getNumber(el?.neto),
          bruto: getNumber(el?.bruto),
          taxes: getNumber(el?.taxes),
          totalCost: getNumber(el?.totalCost),
        }));
        // console.log("tmep", temp);
        const totalities = {
          totalNeto: {
            value: 0,
            color: "#1da193",
            name: "Shuma Neto (vjetore)",
            column: "neto",
          },
          totalBruto: {
            value: 0,
            color: "#FCC94A",
            name: "Shuma Bruto (vjetore)",
            column: "bruto",
          },
          totalTaxes: {
            value: 0,
            color: "gray",
            name: "Taksat (vjetore)",
            column: "taxes",
          },
          totalCost: {
            value: 0,
            color: "#ea3943",
            name: "Kosto (vjetore)",
            column: "totalCost",
          },
        };
        temp?.map((record) => {
          totalities.totalNeto.value += record?.neto || 0;
          totalities.totalBruto.value += record?.bruto || 0;
          totalities.totalTaxes.value += record?.taxes || 0;
          totalities.totalCost.value += record?.totalCost || 0;
        });
        setHeaderData(totalities);
        // console.log("totalities", totalities);
      }
    }
  }, [rowData]);

  const isMobileView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  const collapseWrapper = (props) => {
    if (Array.isArray(props) && isMobileView) {
      const items = props.map((el, idx) => ({
        key: idx,
        label: "Wages Filters",
        children: el,
      }));
      return <Collapse items={items} accordion />;
    } else {
      return props;
    }
  };

  return collapseWrapper(
    <div className="header-wrapper">
      {!!headerData &&
        Object.entries(headerData).map(([key, value]) => (
          <FilterCard
            value={value?.value}
            name={value?.name}
            column={value?.column}
            background={value?.color}
            onFilter={onFilter}
            gridApi={gridApi}
          />
        ))}
    </div>
  );
}

export default PagatGridHeader;

const FilterCard = ({ value, name, column, background, onFilter, gridApi }) => {
  return (
    <div
      className="wage-header__card"
      style={{ background: background, cursor: "pointer" }}
      onClick={() => onFilter({ gridApi, column, current: "" })}
    >
      <span className="statusValue">
        {currencyformatter.format(value).replace("ALL", "")}
      </span>
      <span className="statusName">{name}</span>
    </div>
  );
};

const getNumber = (val) => {
  return Number(val.replace(/[^0-9.-]+/g, ""));
};
