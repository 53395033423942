import { FixedSalary, HourlyIcon } from "../../../../assets/icons";
import { currencyformatter } from "./formatter";
import "./../Wages.scss";
import getAgThemePreference from "../../../../utils/getAgThemePreference";
import colorShade from "../../../../utils/colorShade";

const { mode, accentColor } = getAgThemePreference();

export const wagesColumnDefs = (onEditWage) => [
  {
    headerName: "Punonjësi",
    field: "employeeFullName",
    mobile: true,
    isCollapseTitle: true,
    filterParams: { readOnly: true },
    resizable: true,
    flex: 2.6,
  },
  {
    headerName: "Team",
    field: "employeeTeam",
    mobile: true,
    filterParams: { readOnly: true },
    resizable: true,
    flex: 2.6,
  },
  {
    headerName: "Departamenti",
    field: "employeeDepartmentName",
    mobile: true,
    flex: 2.6,
    filterParams: {
      readOnly: true,
    },
    // valueGetter: (params) => {
    // 	// console.log("DADAFWF", params.data);
    // 	try {
    // 		return matchDepartamentWithEmployee(params.data.employeeDepartmentId, departments);
    // 	} catch (err) {
    // 		console.error("createdAt--err");
    // 	}
    // },
  },
  {
    headerName: "Lloji i pagesës",
    flex: 2,
    field: "employeePayrollType",
    mobile: true,
    filterParams: {
      readOnly: true,
    },
    cellStyle: {
      color: "red",
      display: "flex",
      "justify-content": "center",
      "margin-top": "5px",
      fill: "red",
    },

    cellRenderer: ({ data }) => (
      <div>
        {/* <strong style={{ color: "#1da193" }}>
            {data.employeePayrollType}
            </strong> */}
        <span className="paymentTypeIcons">
          {data.employeePayrollType === "Page Fikse" ? (
            <FixedSalary />
          ) : (
            <HourlyIcon />
          )}
        </span>
      </div>
    ),
  },

  {
    headerName: "Periudha",
    field: "periudha",
    mobile: true,
    filter: "agNumberColumnFilter",
    flex: 2,
    filterParams: {
      inRangeInclusive: true,
    },
  },

  // {
  // 	headerName: "Oret Totale",
  // 	field: "oret_totale",
  // 	filter: "agNumberColumnFilter",
  // 	filterParams: {
  // 		readOnly: true,
  // 	},
  // 	cellRenderer: ({ data }) => (
  // 		<div>
  // 			<strong
  // 				style={{ color: "green", cursor: "pointer" }}
  // 				onClick={() =>
  // 					navigate("/aprovimet", { state: { paga: data, data: { muaji: data.timeStance } } })
  // 				}
  // 			>
  // 				{isNaN(data.oret_totale) ? "0.00" : data.oret_totale.toFixed(2)}
  // 			</strong>
  // 		</div>
  // 	),
  // },
  {
    headerName: "Pagesa per ore",
    field: "employeeSalaryPerHour",
    mobile: true,
    isCollapseTitle: true,
    filter: "agNumberColumnFilter",
    flex: 2.2,
    // editable: true,
    // cellEditorSelector: cellEditorSelector,
    filterParams: {
      readOnly: true,
    },
    defaultValue: <strong>0.00L</strong>,
    cellRenderer: ({ data }) => (
      <strong>
        {isNaN(data.employeeSalaryPerHour)
          ? "0.00"
          : Number(data.employeeSalaryPerHour).toFixed(2)}
        L
      </strong>
    ),
  },
  {
    headerName: "Statusi",
    field: "wageStatus",
    flex: 2,
    mobile: true,
    // filter: "agNumberColumnFilter",

    filterParams: {
      readOnly: true,
    },
    cellRenderer: ({ data }) => <div>{data?.wageStatus}</div>,
  },
  {
    headerName: "Paga e llogaritur",
    field: "calculatedWage",
    filter: "agNumberColumnFilter",
    flex: 2.2,
    mobile: true,

    filterParams: {
      readOnly: true,
    },
    cellRenderer: ({ data }) => (
      <strong
        style={{
          color: mode.includes("dark")
            ? "#FFF"
            : colorShade(accentColor, 0.25),
          fontWeight: 600,
        }}
      >
        {isNaN(data.calculatedWage)
          ? "0.00"
          : `${currencyformatter.format(data.calculatedWage.toFixed(2))}`}
      </strong>
    ),
  },
  {
    headerName: "employeeId",
    filter: "agTextColumnFilter",
    field: "employeeId",
    hide: true,
    filterParams: {
      readOnly: true,
    },
  },
  {
    headerName: "Ndrysho",
    field: null,
    flex: 2,
    filterParams: { readOnly: true },
    // mobile: true,
    cellRenderer: ({ data }) => (
      <div
        style={{
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
        }}
        cursor="pointer"
      >
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100px",
            cursor: "pointer",
            height: "22px",
            marginTop: "8px",
            background: "#1da193",
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
          onClick={() => onEditWage(data)}
        >
          Ndrysho
        </span>
      </div>
    ),
  },
];
