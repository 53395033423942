import { message, Tooltip } from "antd";
import { Delete } from "../../../Documentation/View/assets";
import { apiDelete } from "../../../DocumentationConfiguration/utils/api";
import GlobalPopupKonfirm from "../../GlobalPopupKonfirm";

const BulkDeleteAgGrid = ({
  gridApi,
  selectedIds,
  endpoint,
  idKey,
  onClick,
  className,
  style,
}) => {
  const handleDelete = async () => {
    let selectedNodes = [...(selectedIds || [])];
    if (!!gridApi) {
      selectedNodes = gridApi.getSelectedNodes();
    }
    if (!selectedNodes?.length) {
      message.info({
        content: "Nuk ka asnjë rresht të selektuar!",
        key: "selectedNodes",
      });
      return;
    } else {
      message.loading({ key: "bulkDelete", content: "Duke fshirë..." });
      let nodesIds = [...selectedNodes];
      if (!!gridApi) {
        nodesIds = selectedNodes.map((node) => node.data[idKey]);
        const selectedData = nodesIds.map((node) => ({ [idKey]: node }));
        gridApi.applyTransaction({
          remove: selectedData,
        });
      }
      const promises = nodesIds.map(
        async (id) => await apiDelete(endpoint, id)
      );
      await Promise.all(promises)
        .then(() => {
          message.success({
            key: "bulkDelete",
            content: "U fshinë me sukses!",
          });
        })
        .catch((err) => console.log("Error: ", err));
    }
  };

  return (
    <div className={`headerIcon ${className}`} style={style}>
      <GlobalPopupKonfirm
        title={"Jeni të sigurt që doni të fshini të selektuarat?"}
        Button={
          <Tooltip
            placement="top"
            classNames={{ root: "global-icon-tooltip" }}
            title={"Fshi të selektuarat"}
          >
            <Delete width={20} height={20} fill={style?.fill || "#323338"} />
          </Tooltip>
        }
        cancelText={"JO"}
        okText={"PO"}
        onConfirm={
          onClick && typeof onClick === "function"
            ? () => onClick(selectedIds)
            : handleDelete
        }
      />
    </div>
  );
};

export default BulkDeleteAgGrid;
