import { createContext, useState, useContext, useEffect, useMemo } from "react";
import { Layout, message } from "antd";
import Hader from "../components/header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { API, Auth } from "aws-amplify";
import LoaderComponent from "../components/commonComponents/LoadableComp/LoadableComp";
import PageNotFound from "../components/commonComponents/PageNotFound";
import { useNavigate, useLocation } from "react-router-dom";
import KonfigurimetMenu from "../components/Konfigurimet/KonfigurimetMenu/KonfigurimetMenu";
import AutumnLeaves from "../components/commonComponents/LoadableComp/Autumn/AutumnLeaves";
import { LoginImage } from "../assets/images";
import { useUserSession } from "../hooks/useUserSession";
import swal from "sweetalert";
import dayjsTZ from "../utils/dayjs";
import { callGmailAPI } from "../utils";
import { compareToken, generateToken } from "./handle2FaToken";
import { v4 } from "uuid";

const { Header, Sider, Content, Footer } = Layout;

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userSession = useUserSession();
  const [initializedData, setInitializedData] = useState({});

  //region INITIALIZE DATA
  const initializeData = async (user, action) => {
    let toReturn = {
      user,
      userAccess: [{ ...user }],
      userRole: user?.userRole,
    };
    const { userSub = null } = user;

    await API.get("employees", "/employees")
      .then(async (employees) => {
        let employee = employees.find((el) => el.userSub === userSub) || null;

        if (!!employee && !!userSub) {
          const { employeeId = null } = employee || {};
          toReturn = { ...toReturn, employeeId, employee };
        } else {
          toReturn = { ...toReturn, employeeId: null, employee: null };
        }

        setInitializedData((prev) => ({ ...prev, ...toReturn }));

        let auth2FaObj = {};
        let codeSentOn = dayjsTZ().format();
        if (action === "signin") {
          if (!!user?.auth2Fa?.is2FaActive) {
            let require2Fa;

            const session = (user?.auth2Fa?.active2FaSessions || [])?.find(
              (el) => compareToken("2FaSessionToken", el?.sessionId)
            );

            if (!user?.auth2Fa?.last2FAuthed || !session) {
              require2Fa = true;
            } else {
              require2Fa =
                dayjsTZ()
                  .startOf("day")
                  .diff(
                    dayjsTZ(user?.auth2Fa?.last2FAuthed).startOf("day"),
                    "days"
                  ) >= 30;
            }
            if (!!require2Fa) {
              const successOTP = generateCode();
              await sendOtpEmail({
                name: user.given_name,
                code: successOTP,
                email: user.email,
              });
              console.log(successOTP);
              auth2FaObj = {
                codeSent: true,
                is2FaActive: true,
                successOTP,
                isAuthenticated: false,
                active2FaSessions: user?.auth2Fa?.active2FaSessions || [],
              };
              await updateUserConfig(
                { auth2Fa: { ...auth2FaObj } },
                user?.identityId
              ).then(() => {
                codeSentOn = dayjsTZ().format();
                setInitializedData((prev) => ({
                  ...prev,
                  ...toReturn,
                  auth2Fa: { ...auth2FaObj, codeSentOn },
                }));
              });
            } else {
              await updateUserConfig(
                {
                  auth2Fa: {
                    ...user?.auth2Fa,
                    isAuthenticated: true,
                  },
                  lastLoggedIn: dayjsTZ().valueOf(),
                },
                user?.identityId
              ).then(async () => {
                setInitializedData((prev) => ({
                  ...prev,
                  ...toReturn,
                  auth2Fa: {
                    ...user?.auth2Fa,
                    isAuthenticated: true,
                  },
                }));

                await userSession.open(user);
                navigate("/kryefaqja");
              });
            }
          } else {
            await updateUserConfig(
              {
                auth2Fa: {
                  ...user?.auth2Fa,
                  isAuthenticated: true,
                },
                lastLoggedIn: dayjsTZ().valueOf(),
              },
              user?.identityId
            ).then(async () => {
              setInitializedData((prev) => ({
                ...prev,
                ...toReturn,
                auth2Fa: {
                  ...user?.auth2Fa,
                  isAuthenticated: true,
                },
              }));

              await userSession.open(user);
              navigate("/kryefaqja");
            });
          }
        } else {
          setInitializedData((prev) => ({
            ...prev,
            ...toReturn,
            auth2Fa: { ...user?.auth2Fa },
          }));
        }
      })
      .catch((err) => console.log("Error initialising employees: ", err));
  };

  //region >generateCode()
  const generateCode = (prev) => {
    let min = Math.ceil(100000);
    let max = Math.floor(999999);
    const code = Math.floor(Math.random() * (max - min + 1)) + min;
    if (code !== prev) {
      return code.toString();
    }
    return (Math.floor(Math.random() * (max - min + 1)) + min).toString();
  };

  //region >sendEmail()
  const sendOtpEmail = async ({ email, name, code }) => {
    const emailBody = {
      to: email,
      subject: "One time verification code",
      body: `<div style="max-width: 600px; margin: auto; background: #ffffff; border-radius: 10px; box-shadow: 0 4px 10px rgba(0,0,0,0.1); padding: 30px;">
              <h2 style="color: #1d3445; text-align: center;">One time verification code</h2>
              <p style="color: #555;">Hi ${name},</p>
              <p style="color: #555;">You are receiving this email because a request was made for a one-time code that can be used for authentication.</p>
              <p style="color: #555;">Please enter the following code for verification:</p>
              <h1 style="font-size: 28px; color: #02686b; text-align: center; background: #f7f7f7; padding: 15px; border-radius: 5px;">${code}</h1>
              <p style="color: #555;">If you believe you have received this email in error, please reach out to your supervisor.</p>
              <footer style="margin-top: 20px; text-align: center; color: #777">
                <p>&copy; Flex Business Solutions</p>
              </footer>
            </div>`,
      attachments: [],
      category: "error",
      authData: false,
    };
    await callGmailAPI("sendEmail", { ...emailBody })
      .then(() => {})
      .catch((err) => console.log("Error sending email: ", err));
  };

  //region >redirectToLogin()
  const redirectToLogin = async (callback) => {
    navigate("/login");
    !!callback && callback();
    setInitializedData({});
  };

  //region >onRefresh()
  const onRefresh = async (callback = () => {}, location) => {
    await API.get("users", "/users")
      .then(async (res) => {
        const { isAuthenticated } = res?.auth2Fa || {};
        if (!isAuthenticated) {
          redirectToLogin(callback);
        } else if (!!isAuthenticated) {
          await initializeData(res);
          navigate(location || "/kryefaqja");
          !!callback && callback();
        }
      })
      .catch((err) => {
        console.log("Error fetching /users: ", err);
        redirectToLogin(callback);
      });
  };

  // region SEND NEW CODE
  const sendNewCode = async () => {
    const toReturn2Fa = {
      ...initializedData?.auth2Fa,
      codeSent: true,
      codeSentOn: dayjsTZ().format(),
      successOTP: generateCode(initializedData?.auth2Fa?.successOTP),
    };
    await sendOtpEmail({
      email: initializedData.user.email,
      code: toReturn2Fa.successOTP,
      name: initializedData.user.given_name,
    });

    setInitializedData((prev) => ({ ...prev, auth2Fa: { ...toReturn2Fa } }));
  };

  //region SIGNIN/OUT
  const signin = async (newUser, callback) => {
    await initializeData(newUser, "signin");
    callback();
  };
  const signout = async (callback) => {
    const { is2FaActive, last2FAuthed, active2FaSessions } =
      initializedData?.auth2Fa || {};

    const session = (active2FaSessions || [])?.find((el) =>
      compareToken("2FaSessionToken", el?.sessionId)
    );

    let new2FaSessions = [...(active2FaSessions || [])];
    if (session?.expirationDate < dayjsTZ().valueOf()) {
      new2FaSessions = new2FaSessions?.filter(
        (el) => el?.sessionId !== session?.sessionId
      );
      localStorage.removeItem("2FaSessionToken");
    }

    await updateUserConfig({
      auth2Fa: {
        isAuthenticated: false,
        is2FaActive,
        last2FAuthed,
        active2FaSessions: [...new2FaSessions],
      },
    });
    setInitializedData({});
    callback();
  };

  //region CONFIRM SIGNIN
  const confirmSignIn = async (code) => {
    if (code === initializedData?.auth2Fa?.successOTP) {
      let SuccessImg = document.createElement("img");
      SuccessImg.src = LoginImage;

      const newSession = {
        sessionId: v4(),
        expirationDate: dayjsTZ().add(30, "day").valueOf(),
      };
      const active2FaSessions = [
        ...(initializedData?.user?.auth2Fa?.active2FaSessions || []),
        { ...newSession },
      ];

      const toSend = {
        isAuthenticated: true,
        is2FaActive: true,
        last2FAuthed: dayjsTZ().format(),
        active2FaSessions,
      };
      const lastLoggedIn = dayjsTZ().valueOf();
      await API.put("users", `/users/${initializedData?.user?.identityId}`, {
        body: {
          lastLoggedIn,
          auth2Fa: { ...toSend },
        },
      }).then(async () => {
        swal({
          title: `Identifikimi u krye me sukses! ${"    "}
                      Mirë se erdhe, ${initializedData?.user?.given_name} ${
            initializedData?.user?.family_name
          }! `,
          icon: "success",
          content: SuccessImg,
          className: "custum-swal-loginSuccess",
        });
        generateToken("2FaSessionToken", newSession.sessionId);
        navigate("/kryefaqja");

        setInitializedData((prev) => ({
          ...prev,
          auth2Fa: { ...prev?.auth2Fa, ...toSend },
        }));

        await userSession.open(initializedData?.user, lastLoggedIn);
      });
    } else {
      message.error({
        content: "Kodi i vendosur nuk eshte i sakte!",
        key: "login",
      });
    }
  };

  //region UPDATE /users
  const updateUserConfig = async (body, id) => {
    // flex us-east-1:b292bd41-8007-c722-d0e9-021c0e2f4573
    // lona us-east-1:b692b4b7-8931-482b-bc61-e9f4f4393f26
    // aldo us-east-1:b292bd41-8048-c949-6b83-b644c6d05227
    if (!!id || !!initializedData?.user?.identityId) {
      await API.put(
        "users",
        `/users/${id || initializedData?.user?.identityId}`,
        {
          // await API.put(
          //   "users",
          //   "/users/us-east-1:7f8848cf-210f-46fd-b206-7a06023a0058",
          //   {
          body: {
            ...body,
          },
        }
      )
        .then(() => {})
        .catch((err) => console.log("Error: ", err));
    }
  };

  const value = useMemo(
    () => ({
      signin,
      signout,
      sendNewCode,
      onRefresh,
      confirmSignIn,
      updateUserConfig,
      redirectToLogin,
      updateAuthCtx: setInitializedData,
      ...(initializedData || {}),
    }),
    [initializedData]
  );

  // useEffect(() => {
  //   updateUserConfig(
  //     { auth2Fa: { is2FaActive: true } },
  //     "us-east-1:7f8848cf-210f-46fd-b206-7a06023a0058"
  //   );
  // }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export const useAuth = () => {
  return useContext(AuthContext);
};
/**
 * @returns instance of auth status
 */
export const AuthStatus = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  useEffect(() => {
    if (!!Object.keys(auth)?.length && !!auth?.userAccess?.length) {
      setData(auth.userAccess);
      setLoading(false);
    }
  }, [auth]);

  const specifikatRoute = {
    auth: true,
    exact: false,
    path: "punonjesit/specifikat/:employeeId",
    title: "Specifikat Punonjesit",
    write: true,
  };

  if (loading === false && data.length > 0) {
    //&& location.pathname.startsWith("/konfigurimet") === false
    //&& auth.userAccess[0].userRole !== "Admin"
    if (location.pathname !== null) {
      let filter =
        data[0].accessConfig?.routeConfig
          .concat([specifikatRoute])
          ?.filter((el) => {
            return (
              (el.path === location.pathname ||
                el.path.includes("punonjesit/specifikat/")) &&
              el?.auth !== false
            );
          }) || [];
      if (filter.length === 0) {
        return <PageNotFound />;
      }
    }
  }
  // if (!auth.user) {
  //   return <Login />;
  // }

  return (
    <LoaderComponent loading={loading}>
      {/* <AutumnLeaves /> */}

      <div>
        <Layout>
          <Sider
            style={{
              position: "sticky",
              zIndex: 1,
            }}
            width="270px"
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            breakpoint={"xl"}
          >
            {/* winter */}
            {/* {new Array(100).fill(<div className="snowflake" />).map((el) => el)} */}
            {location.pathname.startsWith("/konfigurimet") === true ? (
              <KonfigurimetMenu data={data} />
            ) : (
              <Sidebar data={data} collapsed={collapsed} />
            )}
          </Sider>
          <Layout>
            <Header style={{ position: "sticky", zIndex: 1, width: "100%" }}>
              <Hader />
            </Header>
            <Content style={{ width: "100%", height: "calc(100dvh - 70px)" }}>
              {children}
            </Content>
          </Layout>
        </Layout>
      </div>
    </LoaderComponent>
  );
};
