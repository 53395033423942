import { useMemo } from "react";
import "./FooterCounter.scss";
import getAgThemePreference from "../../../utils/getAgThemePreference";

/**
 * @typedef props
 * @property {string} title counter's title on display
 * @property {Array<Number>} data array of numbers that will be counted
 * @property {'rowFlex' | 'columnFlex'} style flex-direction: column | row
 * @property {Array<Number>} derivedData subarray of numbers from data
 */
/**
 * @param {props} props
 * @returns
 */

const FooterCounter = ({
  title,
  data,
  className = "columnFlex",
  style,
  derivedData,
}) => {
  const count = useMemo(() => {
    return data
      ?.filter((item) => !isNaN(Number(item)))
      ?.map((el) => parseFloat(el))
      ?.reduce((acc, curr) => acc + curr, 0);
  }, [data]);
  const derivedCount = useMemo(() => {
    if (!derivedData) return null;
    return derivedData
      ?.filter((item) => !isNaN(Number(item)))
      ?.map((el) => parseFloat(el))
      ?.reduce((acc, curr) => acc + curr, 0);
  }, [derivedData]);

  const { foregroundColor } = getAgThemePreference();

  return (
    <div className="footerCounterWrapper" style={style}>
      <div
        className={`footerCounter ${className}`}
        style={{ color: !style?.color ? foregroundColor : style?.color }}
      >
        <span>{title}</span>
        <span>{count.toFixed(2)}</span>
        {typeof derivedCount === "number" ? (
          <span>{`(${derivedCount.toFixed(2)})`}</span>
        ) : null}
      </div>
    </div>
  );
};

export default FooterCounter;
