import { message } from "antd";
import { API } from "aws-amplify";
import moment from "moment";
import dayjsTZ from "../../utils/dayjs";

/**
 * Used to fetch data from the API endpoint
 * @param {string} dataTable endpoint definition/table
 * @returns a Promise (reject/success)
 */

export const requestData = async (dataTable) => {
  if (dataTable === "clockings")
    return await requestDataWithParams(
      `${dataTable}`,
      dayjsTZ().subtract(1, "months").startOf("month").valueOf()
    );
  return new Promise(async (resolve, reject) => {
    await API.get(`${dataTable}`, `/${dataTable}`)
      .then((r) => resolve(r))
      .catch((error) => console.error(error, dataTable));
  });
};

export const requestDataWithParams = async (
  dataTable,
  params,
  endLimiter,
  employeeId = false
) => {
  return new Promise(async (resolve, reject) => {
    let defaultEnd = dayjsTZ().valueOf();
    if (dataTable === "clockings") {
      defaultEnd = dayjsTZ().add(2, "weeks").startOf("month").valueOf();
    }
    const objFilter = {
      limiter: params,
      endLimiter: endLimiter || defaultEnd,
    };
    if (employeeId) {
      objFilter.employeeId = employeeId;
    }
    await API.get(`${dataTable}`, `/${dataTable}`, {
      queryStringParameters: {
        ...objFilter,
        // limiter: params,
        // endLimiter: endLimiter || defaultEnd,
        // dayType: "workday",
        // limiter: dayjs("01/1/2022", "DD/MM/YYYY").valueOf(),
        // start: params,
        // end: dayjsTZ().valueOf(),
      },
    })
      .then((r) => resolve(r))
      .catch((error) => console.error(error));
  });
};

export const getNextPage = async (dataTable, key, data) => {
  if (key) {
    await API.get(`${dataTable}`, `/${dataTable}`, {
      queryStringParameters: {
        startKey: key,
      },
    })
      .then(async (r) => {
        await getNextPage(dataTable, r?.startKey?.clockingId, [...data, ...r]);
      })
      .catch((error) => console.error(error));
  } else {
    return data;
  }
};

export const getSecondPage = async (dataTable, key, prev) => {
  return await API.get(`${dataTable}`, `/${dataTable}`, {
    queryStringParameters: {
      startKey: key,
    },
  }).then((res) => {
    return { records: [...prev, ...res.records], startKey: res.startKey };
  });
};
export const requestAllPages = async (dataTable, startKeyName) => {
  let firstPage = await API.get(`${dataTable}`, `/${dataTable}`).then(
    (res) => res
  );
  let remainings = {
    records: [...firstPage?.records],
    startKey: firstPage?.startKey,
  };
  do {
    if (!!firstPage.startKey) {
      remainings = await getSecondPage(
        dataTable,
        remainings?.startKey?.[startKeyName],
        [...remainings?.records]
      ).then((res) => {
        return res;
      });
    }
  } while (remainings?.startKey !== undefined);
  if (!!remainings) {
    return remainings?.records;
  } else {
    return firstPage?.records;
  }
};

/**
 * Used to request an array of tables, it waits until all promises are resolved.
 * @param {Array} dataTablesArray | Array of Strings with the names of Requests
 * @returns {Array of Promises} |
 * The Array will contain the tables array, ranked as called
 */

export const requestMultipleTables = async (dataTablesArray) => {
  let requestPromises = dataTablesArray.map(
    async (el) => await requestData(el)
  );
  return Promise.all(requestPromises);
};

/**
 *
 * @param {string} dataTable endpoint definition/table
 * @param {Object} contentBody object request body to send POST request
 * @returns {Promise} | success/reject
 */
export const postData = async (dataTable, contentBody) => {
  return new Promise(async (resolve, reject) => {
    await API.post(`${dataTable}`, `/${dataTable}`, {
      body: {
        ...contentBody,
      },
    })
      .then((r) => resolve("Data Posted Successfully ||" + r))
      .catch((error) => console.log(error));
  });
};
